import React, { Component } from 'react'
import './Style.scss'
import { LoginForm, ForgotForm } from './Component'
export default class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      view: 'login'
    }
  }
  switchView = view => {
    this.setState({ view })
  }
  render () {
    const { view } = this.state
    return (
      <div
        className='loginWrapper'
        style={{
          backgroundImage: `url(${this.props.whiteLabling?.logInPageImage})`
        }}
      >
        <div className='loginWrapperInner'>
          {view === 'login' ? (
            <LoginForm {...this.props} switchView={this.switchView} />
          ) : null}
          {view === 'forgot' ? (
            <ForgotForm {...this.props} switchView={this.switchView} />
          ) : null}
        </div>
        <div
          className='white-label-bg'
          style={{ backgroundImage: 'url(/assets/images/login_wallpaper.png)' }}
        ></div>
      </div>
    )
  }
}

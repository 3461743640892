import React, { Component } from 'react'
import Checkbox from '../../Components/common/Checkbox'
// import classNames from 'classnames'
import { connect } from 'react-redux'
// import { withStyles } from '@mui/styles'
import TextField from './../../Components/common/TextField'
import { logInUserInfo, logout } from '../../Actions/Users'
import { ServerInfo } from '../../Actions/serverSetting'
import Button from './../../Components/common/Button'
import {
  displayErrorMessages,
  checkedDevelopmentType
} from '../../Helpers/index'
import Loader from './../../Layout/Loader'
import Typography from '@mui/material/Typography'
import { themecolors } from './../../Layout/theme'
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setCookie } from '../../Helpers'
import { getPrivileges } from '../../Actions/Privileges'
import { Navigate } from 'react-router-dom'
import { styled } from '@mui/material'
import withRoutes from '../../HOC/HocRoutes'
let themeColors
const MyStyledComponent = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),

}));
const MyStyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: themecolors['400'],
  '&:hover': {
    backgroundColor: themecolors['600'],
  },

}));


class LoginForm1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isError: false,
      isSession: false,
      loading: false,
      loginErrorMessage: '',
      exPrivilige :false,
      redirect:false

    }
    this.handleChange = this.handleChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.id]: event.target.value
    })
  }
  goToAccount = () => {
    this.setState({ redirect: true });
  };

  componentWillMount () {
    this.setState({
      failed: false,
      loading: true,
      loginErrorMessage: ''
    })
    let fetchUrl = checkedDevelopmentType()
    if (localStorage.getItem('userToken')) {
      fetch(
        `/api/session/user?token=${localStorage.getItem(
          'userToken'
        )}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
        {
          method: 'Get'
        }
      )
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                this.props.dispatch(logInUserInfo(userInfo))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res1 => {
                        if(res1.status ==='success'){ 
                          let server = res1.data
                          if (server&&server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){ 
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        localStorage.setItem('userToken', '')
                                        if (privilegeKeys.includes("excetiveDashboard")) {
                                          this.goToAccount()
                                          // this.props.navigate('/accounts');
                                        } else {
                                          this.goToAccount()

                                          // this.props.navigate('/accounts');
                                        }
                                        // this.props.navigate('/accounts')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                    }
                                    else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                    else if(res3.statusCode){
                                      var err = res3.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                     
                                        toast.error(
                                           this.props.translate(err[1])
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.loginAsUsererrorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.navigate('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
                        }
                        else if(res1?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res1.statusCode){
                          this.props.navigate('/logout')
                          var err = res1?.message.split(':')
                          err[1] =err[1].replace(')', "")
                       
                            toast.error(
                               this.props.translate(err[1])
                          )
                          this.setState({loading:false})
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.loginAsUsererrorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
                window.location.replace('/login')
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          this.loginAsUsererrorMessage(e)
        })
        
    } else if (localStorage.getItem('adminToken')) {
      fetch(
        `/api/session/user?token=${localStorage.getItem(
          'adminToken'
        )}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
        {
          method: 'Get'
        }
      )
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                this.props.dispatch(logout())
                this.props.dispatch(logInUserInfo(userInfo))
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res2 => {
                        if(res2.status ==='success'){ 
                          let server = res2.data
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        localStorage.setItem('adminToken', '')
                                        if (privilegeKeys.includes("excetiveDashboard")) {
                                          this.props.navigate('/accounts');
                                        } else {
                                          this.props.navigate('/accounts');
                                        }
                                        // this.props.navigate('/accounts')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                     }
                                     else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                     else if(res3.statusCode){
                                      var err = res3?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                    
                                        toast.error(
                                           this.props.translate(err[1])
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.errorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.navigate('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }

                        }
                        else if(res2?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res2.statusCode){
                          var err = res2?.message.split(':')
                          err[1] =err[1].replace(')', "")
                         
                            toast.error(
                              this.props.translate(err[1])
                          )
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.errorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          console.log('e =',e)
          // this.props.dispatch(toast.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        })
        // .catch(e => {
        //   if(e && e.text){
        //     e.text().then(err => {
        //       let mgs = this.props.translate(displayErrorMessages(err))
        //       this.props.dispatch(
        //         toast.success({
        //           message: mgs,
        //           autoDismiss: 5,
        //           location: this.props.location
        //         })
        //       )
        //       localStorage.setItem('userToken', '')
        //       this.setState({
        //         isSession: true,
        //         loading: false
        //       })
        //     })
        //   }
        // })
    } else if (this.props.location?.search) {
      let params = this.props.location.search.toString().split('&')
      if(params[3]){
        fetch(
           `/api/session/user?token=${params[3]}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
           {
             method: 'Get'
           }
         )
         .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
                let userInfo = res.data
                localStorage.setItem('domainToken', true)
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                this.props.dispatch(logInUserInfo(userInfo))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res2 => {
                        if(res2.status ==='success'){ 
                          let server = res2.data
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        if (privilegeKeys.includes("excetiveDashboard")) {
                                          this.props.navigate('/accounts');
                                        } else {
                                          this.props.navigate('/accounts');
                                        }
                                        // this.props.navigate('/accounts')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                     }
                                     else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                     else if(res3.statusCode){
                                      var err = res3?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                      
                                        toast.error(
                                           this.props.translate(err[1])
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.loginAsUsererrorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.navigate('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
                        }
                        else if(res2?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res2.statusCode){
                          this.props.navigate('/login')
                          var err = res2?.message.split(':')
                          err[1] =err[1].replace(')', "")
                         
                            toast.error(
                             this.props.translate(err[1])
                          )
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.loginAsUsererrorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          this.loginAsUsererrorMessage(e)
        })  
      } 
      else {
        fetch(
           `/api/session/user?token=${params[2]}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`,
           {
             method: 'Get'
           }
         )
         .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){ 
               
                let userInfo= res.data
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                this.props.dispatch(logInUserInfo(userInfo))
                fetch(`/api/accounts?accountId=${userInfo.accountId}`)
                  .then(response => {
                    if (response.ok) {
                      response.json().then(res2 => {
                        if(res2.status ==='success'){ 
                          let server = res2.data
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res3 => {
                                    if(res3.status ==='success'){
                                      let privileges = res3.data
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        if (privilegeKeys.includes("excetiveDashboard")) {
                                          this.props.navigate('/accounts');
                                        } else {
                                          this.props.navigate('/accounts');
                                        }
                                        // this.props.navigate('/accounts')
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'lackOfPrivileges'
                                        })
                                      }
                                     }
                                     else if(res3?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                     else if(res3.statusCode){
                                      var err = res3?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                     
                                        toast.error(
                                          this.props.translate(err[1])
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.loginAsUsererrorMessage(e)
                                window.location.replace('/login')
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.navigate('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                   })
                                })
                                .catch(e => {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
                        }
                        else if(res2?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res2.statusCode){
                          var err = res2?.message.split(':')
                          err[1] =err[1].replace(')', "")
                         
                            toast.error(
                              this.props.translate(err[1])
                          )
                          window.location.replace('/login')
                        }
                      })
                    } else {
                      throw response
                    }
                  })
                  .catch(e => {
                    this.loginAsUsererrorMessage(e)
                    window.location.replace('/login')
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
                window.location.replace('/login')
              }
            })
          } else {
            this.setState({
              isSession: true
            })
            throw response
          }
        })
        .catch(e => {
          this.loginAsUsererrorMessage(e)
        })
      }
      
    } else {
      this.setState({
        isSession: true,
        loading: false,
        email: this.getCookie('userName'),
        password: this.getCookie('pass')
      })
    }
  }
  loginAsUsererrorMessage = e => {
    if(e && e.text){
      e.text().then(err => {
        let mgs = this.props.translate(displayErrorMessages(err))
      
          toast.success("Trestste")
        
        localStorage.setItem('userToken', '')
      })
    }
    this.props.navigate('/logout')
  }
  errorMessage = e => {
        this.setState({
          failed: true,
          password: '',
          loading: false,
          isError: true,
          loginErrorMessage: displayErrorMessages(e)
        })
  }

  handleLogin (event) {
    event.preventDefault()
    const { email, password } = this.state
    this.setState({
      loading: true
    })
    let url;
    let fetchUrl = checkedDevelopmentType()
    fetch('/api/session', {
      method: 'POST',
      body: new URLSearchParams(
        `email=${email}&password=${password}&app=2bd5bd10345cd1555b14c221ec6e8647c46ee21e8ee45312${fetchUrl}`
      )
    })
      .then(response => {
        if(response&&response.status === 500){
            let msg = response?.statusText || "Server is down"
            this.errorMessage(msg)
          }
          else{
            
              response.json().then(res => {
                  //APIDIS@email.com
              if(res.status === 'success'){
                let userInfo = res.data
                // this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo }))
                localStorage.setItem('adminToken', '')
                fetch(`/api/accounts?accountId=${userInfo?.accountId}`)
                  .then(response => {
                      response.json().then(res1 => {
                        if(res1.status ==='success'){ 
                          let server = res1.data
                          if (server) {
                            this.props.dispatch(ServerInfo(server[0]))
                            // fetch call for Api  privileges
                            if(userInfo.userType === -1){
                              url = `/api/privileges?roleId=${userInfo.roleId}&all=true`
                            }
                            else{
                              url = `/api/privileges?roleId=${userInfo.roleId}`
                            }
                            fetch(url, {
                              method: 'Get'
                            })
                              .then(response => {
                                if (response.ok) {
                                  response.json().then(res2 => {
                                    if(res2.status ==='success'){ 
                                      let privileges = res2.data
                                      this.props.dispatch(getPrivileges(privileges))
                                      let privilegeKeys = []
                                      privileges.map(item => {
                                        privilegeKeys.push(item.key)
                                      })
                                      localStorage.setItem(
                                        'privileges',
                                        JSON.stringify({ ...privilegeKeys })
                                      )
                                      if (privileges.length) {
                                        // this.props.navigate('/accounts')
                                        if (privilegeKeys.includes("excetiveDashboard")) {
                                          this.goToAccount()
                                          // this.props.navigate('/accounts');
                                        } else {
                                          this.goToAccount()

                                          // this.props.navigate('/accounts');
                                        }
                                      } else {
                                        this.setState({
                                          loading: false,
                                          loginErrorMessage: 'Account Data Not Found'
                                        })
                                        fetch('/api/session', {
                                          method: 'DELETE'
                                        }).then(res => {
                                          res.json().then(response=>{
                                            if (response.status === 'success') {
                                              this.props.navigate('/login')
                                              localStorage.setItem('privileges', '')
                                              localStorage.setItem('userInfo', '')
                                              localStorage.setItem('adminToken', '')
                                              this.props.dispatch(logout())
                                              setCookie('JSESSIONID', 0)
                                              this.setState({
                                                loading: false
                                              })
                                            } 
                                            else if(response.statusCode) {
                                                this.props.navigate('/login')
                                                localStorage.setItem('privileges', '')
                                                localStorage.setItem('userInfo', '')
                                                localStorage.setItem('adminToken', '')
                                                this.props.dispatch(logout())
                                                setCookie('JSESSIONID', 0)
                                                this.setState({loading: false })
                                              }
                                                })
                                            })
                                            .catch(e => {
                                                this.props.navigate('/login')
                                                localStorage.setItem('privileges', '')
                                                localStorage.setItem('userInfo', '')
                                                localStorage.setItem('adminToken', '')
                                                this.props.dispatch(logout())
                                                setCookie('JSESSIONID', 0)
                                                this.setState({ loading: false })
                                          })
                                      }
                                    }
                                    else if(res2?.statusCode === '440'){
                                      window.location.replace('/login')
                                    }
                                    else if(res2.statusCode){
                                      var err = res2?.message.split(':')
                                      err[1] =err[1].replace(')', "")
                                    
                                        toast.error(
                                         this.props.translate(err[1])
                                      )
                                    }
                                  })
                                } else {
                                  throw response
                                }
                              })
                              .catch(e => {
                                this.errorMessage(e)
                              })
                          } else {
                            this.setState({
                              loading: false,
                              loginErrorMessage: 'Account Data Not Found'
                            })
                            fetch('/api/session', {
                              method: 'DELETE'
                            }).then(res => {
                              res.json().then(response=>{
                                if (response.status === 'success') {
                                  this.props.navigate('/login')
                                  localStorage.setItem('privileges', '')
                                  localStorage.setItem('userInfo', '')
                                  localStorage.setItem('adminToken', '')
                                  this.props.dispatch(logout())
                                  setCookie('JSESSIONID', 0)
                                  this.setState({
                                    loading: false
                                  })
                                } 
                                else if(response.statusCode) {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({loading: false })
                                  }
                                    })
                                })
                                .catch(e => {
                                    this.props.navigate('/login')
                                    localStorage.setItem('privileges', '')
                                    localStorage.setItem('userInfo', '')
                                    localStorage.setItem('adminToken', '')
                                    this.props.dispatch(logout())
                                    setCookie('JSESSIONID', 0)
                                    this.setState({ loading: false })
                              })
                          }
  
                        }
                        else if(res1?.statusCode === '440'){
                          window.location.replace('/login')
                        }
                        else if(res1.statusCode){
                          var err = res1?.message.split(':')
                          err[1] =err[1].replace(')', "")
                          
                            toast.error(
                              this.props.translate(err[1])
                          )
                        }
                      })
                    
                  })
                  .catch(e => {
                    this.errorMessage(e)
                  })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                this.errorMessage(res?.message)
              }
              
          })
          }
      })
      .catch(e => {
         this.errorMessage(e)
      })
  }
  handleClick = e => {
    let { email, password } = this.state
    if (e.target.checked && email && password) {
      this.setCookie('userName', email)
      this.setCookie('pass', password)
    } else {
      this.setCookie('userName', '')
      this.setCookie('pass', '')
    }
  }
  getCookie (cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  setCookie (name, value, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie =
      name + '= ' + encodeURIComponent(value) + ';' + expires + ';path=/login;'
  }
  render () {
    const { classes } = this.props

    if(this.state.redirect){
      return <Navigate to="/users" />;
    }
    if (this.state.loading) {
      return <Loader />
    } else {
      if (this.state.isSession && !localStorage.getItem('adminToken')) {
        return (
          <MyStyledComponent>

    

        
          <form
            className='loginForm'
            onSubmit={this.handleLogin}
            autoComplete='off'
          >
            <img
              className='login-logo'
              src={this.props.whiteLabling?.logo || "logo.png"}
              alt='logo'
            />
            {this.state.failed && (
              <Typography style={{ color: this.props.themecolors['error'] }}>
                {this.props.translate(this.state.loginErrorMessage)}
              </Typography>
            )}
            <TextField
              id='email'
              label={this.props.translate('userEmail')}
              type='text'
              value={this.state.email}
              onChange={this.handleChange}
              margin='normal'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              error={this.state.isError}
            />
                {/* <TextField
              id='email'
              label={this.props.translate('userEmail') + ' *'}
              type='text'
              value={this.state.email || ''}
              onChange={this.handleChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            /> */}
            <div className='form-group'>
              <TextField
                id='password'
                label={this.props.translate('userPassword')}
                type='password'
                value={this.state.password}
                onChange={this.handleChange}
                margin='normal'
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                error={this.state.isError}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <MyStyledButton
                id="loginSubmit"
                type='submit'
                fullWidth
                color='secondary'
                // className={classNames(classes.cssRoot)}
              >
                {this.props.translate('loginLogin')}
              </MyStyledButton>
            </div>
            <div style={{ marginRight: 'auto' }}>
              <Checkbox
                disableRipple
                onChange={event => this.handleClick(event)}
                label={this.props.translate('rememberme')}

              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                type="button"
                id="forgotPassword"
                style={{ marginTop: 14 }}
                className='button-white'
                onClick={e => this.props.switchView('forgot')}
              >
                {/* {this.props.translate('forgotPassword')} */}
                {'forgotPassword'}

              </Button>
            </div>
          </form>
          </MyStyledComponent>
        )
      } else {
        return null
      }
    }
  }
}

// LoginForm1.propTypes = {
// 	classes: PropTypes.object.isRequired,
// };

const mapState = state => {
  themeColors = state.themecolors
  return {
    themecolors: state.themecolors
  }
}

const mapStateToProps = connect(mapState)
export const LoginForm = mapStateToProps((withRoutes(LoginForm1))
)

class ForgotForm1 extends Component {
  constructor (props) {
    super(props)
    this.state = { email: '', errorMessage: '', loading: false }
  }
  handleSubmit = event => {
    event.preventDefault()
    const { email } = this.state
    this.setState(
      {
        loading: true,
        errorMessage: ''
      },
      () => {
        fetch('/api/password/forget', {
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
          }),
          method: 'POST',
          body: new URLSearchParams(`email=${email}`)
        }).then(response => {
          if (response.ok) {
            response.json().then(res => {
              if (res.status === 'success') {
                this.setState({
                  loading: false,
                  errorMessage: this.props.translate('emailSentSuccessfully')
                })
               
                  toast.success(
                   this.props.translate('emailSentSuccessfully')
                )
              }else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
               else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                  this.setState({loading:false,errorMessage:err[1]})
              }
            })
          }
          else{
            throw response
          }
        })
        .catch(e => {
          toast.error(
             'somethingWentWrong')
        })
      }
    )
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  render () {
    let { errorMessage, loading } = this.state
    if (loading) {
      return <Loader />
    } else {
      return (
        <form
          className='loginForm'
          onSubmit={this.handleSubmit}
          autoComplete='off'
        >
          <img
            className='login-logo'
            src={this.props.whiteLabling.logo}
            alt='logo'
          />
          <Typography>
            {this.props.translate('resetPasswordMessage')}
          </Typography>
          {errorMessage && (
            <Typography
              style={{
                color:
                  errorMessage === 'Email Sent Successfully'
                    ? this.props.themecolors['success']
                    : this.props.themecolors['error']
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <div className='form-group'>
            <TextField
              id='email'
              label={this.props.translate('userEmail') + ' *'}
              type='text'
              value={this.state.email || ''}
              onChange={this.handleChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <p>
            <Button type='submit' fullWidth>
              {this.props.translate('submit')}
            </Button>
          </p>
          <div style={{ textAlign: 'center', marginTop:"10px" }}>
            <Button
              className='button-white'
              onClick={e => this.props.switchView('login')}
            >
              {this.props.translate('loginTitle')}
            </Button>
          </div>
        </form>
      )
    }
  }
}

export const ForgotForm = mapStateToProps(withRoutes(ForgotForm1))

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import Dashboard from './Dashboard';
import withRoutes from '../../HOC/HocRoutes';

 
const ExDashboard = (props) => {
    const [userInfo, setUserInfo] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.logInUser) {
          // Render the dashboard if the user is logged in
          return;
        }
    
        fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
          headers: { Accept: "application/json; odata=verbose" },
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === 'success') {
                  let userInfo = res.data;
                  props.setActiveLanguage(userInfo?.attributes?.lang || 'en');
                  dispatch(logInUserInfo(userInfo));
                } else if (res?.statusCode === '440') {
                  window.location.replace('/login');
                } else if (res.statusCode) {
                  var err = res?.message.split(':');
                  err[1] = err[1].replace(')', '');
                
                    toast.error(
                     props.translate(err[1])  
                    )
              
                 props.navigate('/login');
                }
              });
            } else {
             props.navigate('/login');
              throw response;
            }
          })
          .catch((e) => {
          
              toast.error(
               "somethingWentWrong",              
              )          
          });
      }, [props.logInUser, props, dispatch]);

        if (props.logInUser) {
            return (
            <Layout {...props} MaintenanceDashboard>
                    <Dashboard {...props}  />
             </Layout>
            );
        } else {
            return null;
         }
    };
const mapStateToProps = (state) => ({
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    });

export default connect(mapStateToProps)(withRoutes(ExDashboard))
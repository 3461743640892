import React, { Component } from 'react'
import { connect } from 'react-redux'
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { WebHooks } from '../../Components/WebHooks'
import { checkPrivileges } from '../../Helpers'
import withRoutes from '../../HOC/HocRoutes';

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisable: true,
      sessionCallTrack: true
    }
  }
  // componentWillMount () {
  //   this.props.dispatch(toast.removeAll())
  // }

  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(response => {
        if (response.ok) {
          this.props.navigate('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }
  // <WebHooks history={this.props.history} />

  render () {
    // console.log('this.props =', this.props.logInUser.userType)
    if (checkPrivileges('account')) {
      if (this.props.logInUser && this.props.logInUser.userType===-1) {
          return (
            <Layout {...this.props}>
              <WebHooks history={this.props.history} />
            </Layout>
          )
       
      } else {
        if (this.state.sessionCallTrack) {
          this.setState(
            {
              sessionCallTrack: false
            },
            () => {
              fetch('/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05', {
                headers: { Accept: 'application/json; odata=verbose' }
              }).then(response => {
                if (response.ok) {
                  response.json().then(userInfo => {
                    if(userInfo.status ==='success'){ 
                      this.props.setActiveLanguage(
                        userInfo?.attributes?.lang || 'en'
                      )
                      //API privilige call
                      if(userInfo?.roleId){
                        fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                          method: 'Get'
                        })
                          .then(response => {
                            if (response.ok) {
                              response.json().then(privileges => {
                                if(privileges.status ==='success'){ 
                                  let privilegeKeys = []
                                  privileges.map(item => {
                                    privilegeKeys.push(item.key)
                                  })
                                  localStorage.setItem(
                                    'privileges',
                                    JSON.stringify({ ...privilegeKeys })
                                  )
                                  if (privileges.length) {
                                    localStorage.setItem('userToken', '')
                                    if(this.props.logInUser.userType===-1){
                                      this.props.navigate('/webHooks')
                                    }
                                    else{
                                      this.props.navigate('/users')
                                    }
                                  } else {
                                    this.setState({
                                      loading: false,
                                      loginErrorMessage: 'lackOfPrivileges'
                                    })
                                  }
                                }
                                else if(privileges?.statusCode === '440'){
                                  window.location.replace('/login')
                                }
                                else if(privileges.statusCode){
                                  var err = privileges?.message.split(':')
                                  err[1] =err[1].replace(')', "")
                                 
                                    toast.error(
                                      this.props.translate(err[1])
                                  )
                                }
                              })
                            } else {
                              throw response
                            }
                          })
                          .catch(e => {
                            console.log('e =',e)
                            // this.props.dispatch(toast.error({
                            //   message: 'somethingWentWrong',
                            //   autoDismiss: 5
                            // }))
                          })
                      }
  
                      //Privilige call ends
  
                      this.props.dispatch(logInUserInfo(userInfo))
                    }
                    else if(userInfo?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(userInfo.statusCode){
                      var err = userInfo?.message.split(':')
                      err[1] =err[1].replace(')', "")
                    
                        toast.error(
                          this.props.translate(err[1])
                      )
                    }
                  })
                } else {
                  this.props.navigate('/logout')
                    throw response
                  }
                }).catch(e => {
                 toast.error(
                   'somethingWentWrong'
                  )
                })
            }
          )
        }
        return null
      }
    } else if (
      !checkPrivileges('user') &&
      !checkPrivileges('device') &&
      !checkPrivileges('group')
    ) {
      this.controleSession()
      return null
    } else {
      return defaultPages.map(page => {
        if (checkPrivileges(page)) {
          if (page === 'device') {
            this.props.navigate(`/users`)
          } else {
            this.props.navigate(`/${page}s`)
          }
        }
        return null
      })
    }
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withRoutes(Users))
const defaultPages = ['user', 'group', 'device']

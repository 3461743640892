import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function SimpleMenu (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [fileTye, setFileType] = React.useState()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const exportFile = type => {
    setLoader(true)
    setFileType(type)

    let api, Accept
    api = `/api/${props.title}/export`
    if (type === 'pdf') {
      Accept = 'application/pdf'
    } else {
      Accept =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    axios({
      method: 'GET',
      url: api,
      headers: {
        Accept: Accept
      },
      responseType: 'blob'
    })
      .then(response => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(new Blob([response.data]))
        if (type === 'pdf') {
          a.setAttribute('download', `${props.title}.pdf`)
        } else {
          a.setAttribute('download', `${props.title}.xlsx`)
        }
        document.body.appendChild(a)
        a.href = url
        a.click()
        setLoader(false)

          toast.success('Downloaded Successfully'
        )
      })

      .catch(error => {
   
          toast.error('Downloading Failed'
        )
      })
  }

  return (
    <>
      {
        <div>
          <Button
            size='small'
            variant='outlined'
            color='inherit'
            style={{ marginRight: '20px' }}
            onClick={handleClick}
          >
            Export
          </Button>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <>
              <MenuItem
                color=''
                onClick={e => exportFile('pdf')}
                disabled={loader && fileTye === 'pdf'}
              >
                {loader && fileTye === 'pdf' ? 'Processing...' : 'Download PDF'}
              </MenuItem>

              <MenuItem
                onClick={e => exportFile('excel')}
                disabled={loader && fileTye === 'excel'}
              >
                {loader && fileTye === 'excel'
                  ? 'Processing...'
                  : 'Download EXCEL'}
              </MenuItem>
            </>
          </Menu>
        </div>
      }
    </>
  )
}

const mapState = state => {
  return null
}

const mapStateToProps = connect(mapState)
export default mapStateToProps((SimpleMenu))

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import { CreditDebitList } from "../../Components/CreditDebitNotes";
import axios from "axios";
import TextField from "../../Components/common/TextField";
import { Grid } from "@mui/material";
import Button from "../../Components/common/Button";
import Table from "../../Components/common/tableWithBackEndPagination";
import Loader from "../../Layout/Loader";
import withRoutes from "../../HOC/HocRoutes";

let source;
class CreditDebitNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userFetch: false,
      to: "",
      from: "",
      account: "",
      vehcile: "",
      area: "",
      filteredData: false,
      page: 1,
      pageSize: 20,
      itemSearch: "",
      notesData: "",
      value: "maintenance",
      invoiceData: "",
      showAllNotes: false,
      searchText: "",
      selectedAccount: "",
      selectSingleInvoice: false,
      filterInvoices: "",
      showServices: false,
      openTable: false,
      searchPayment: "",
      leasing: false,
      showPayments: false,
      openCreateModale: false,
      recievingPartyAccounts: [],
      contractType: 1,
      loader1: false,
      loader2: false,
      type: 1,

      recievingAccounts: []
    };
    this.creditDebitFilter = this.creditDebitFilter.bind(this);
  }
  componentWillMount() {
    this.getContractsData();
  }
  showAllNotesFun = () => {
    this.setState(
      {
        to: "",
        from: "",
        account: "",
        showAllNotes: true,
        showPayments: false,
        selectedAccount: "",
      },
      () => {
        this.getAllNotes(this.state.to, this.state.from, this.state.account);
      }
    );
  };

  closeModale = () => {
    // select modal to create new note
    this.setState(
      {
        searchText: "",
        pageSize: 20,
        openCreateModale: false,
      },
      () =>
        this.getAllNotes(
          this.state.to,
          this.state.from,
          this.state.selectedAccount.contractNumber || this.state.account
        )
    );
  };
  creditDebitFilter = async (to, from, account) => {
    this.setState(
      {
        to: to,
        from: from,
        account: account,
        page: 1,
        pageSize: 20,
        itemSearch: "",
      },
      () => {
        this.getAllNotes(to, from, account);
      }
    );
  };
  getFilteredData = async (to, from, account) => {
    let { page, pageSize, searchPayment } = this.state;

    let result;
    if (to) {
      result = await axios.get(
        `/api/creditdebitnotes/get?&to=${to}&from=${from}${account}&search=${searchPayment}`
      );
    } else {
      result = await axios.get(
        `/api/creditdebitnotes/get?&page=${page}&limit=${pageSize}`
      );
    }
    this.setState({
      filterInvoices: result?.data?.data || [],
      filteredData: true,
      openTable: false,
      loader: false,
      showServices: false,
    });

  };

  downloadCreditDebit = (to, from, account, type) => {
    let accept;
    if (type === "downloadPdf") {
      accept = "application/pdf";
    } else {
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    let header;
    header = {
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: accept,
      }),
      method: "GET",
    };
    // &type=${this.state.type}
    fetch(
      `api/creditdebitnotes/export?sample=false${account}&to=${to}&from=${from}`,
      { ...header }
    )
      .then((response1) => {
        if (response1.status === 200) {
          if (type === "downloadPdf") {
            response1.blob().then((response) => {
              this.setState(
                {
                  // selectedAreaId: "",
                  // selectedvehicleId: "",
                  to: "",
                  from: "",
                  loader: false,
                },
                () => {
                  this.saveData(response, "CreditDebit.pdf");
                }
              );
            });
          } else {
            response1.blob().then((response) => {
              this.setState(
                {
                  // selectedAreaId: "",
                  // selectedvehicleId: "",
                  to: "",
                  from: "",
                  loader: false,
                },
                () => {
                  this.saveData(response, "CreditDebit.xlsx");
                }
              );
            });
          }
        } else {
          throw response1;
        }
      })

      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  };

  searchAccount = (e) => {
    source.cancel();
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        this.getContractsData();
      }
    );
  };

  searchNote = (e) => {
    source.cancel();
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        this.getAllNotes(
          this.state.to,
          this.state.from,
          this.state.account || this.state.selectedAccount.contractNumber
        );
      }
    );
  };
  clickContractTable = (item) => {
    this.setState(
      {
        searchText: "",
        selectSingleInvoice: true,
        showAllNotes: true,
        selectedAccount: item,
        showServices: true,
        showPayments: true,
        to: "",
        from: "",
      },
      () => {
        this.getAllNotes(this.state.to, this.state.from, this.state.selectedAccount.contractNumber);
      }
    );
  };
  goBack = () => {
    this.setState(
      {
        showAllNotes: false,
        showPayments: false,
        openTable: false,
        showServices: false,
        selectSingleInvoice: false,
        account: "",
        selectedAccount: "",
        searchText: "",
      },
      () => {
        this.getContractsData();
      }
    );
  };

  setPagination = () => {
    let nAcc;
    if (this.state.showAllNotes || this.state.showPayments) {
      nAcc = { ...this.state.filterInvoices }; // for   Notes
    } else {
      nAcc = { ...this.state.invoiceData }; // for contract All accounts
    }
    let lastPage = nAcc.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nAcc.data;
    nAcc.pageSize = this.state.pageSize;
    nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nAcc;
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangePage = (value) => {
    if (this.state.showAllNotes || this.state.showPayments) {
      this.setState({ page: value }, () =>
        this.getAllNotes(
          this.state.to, // for   Notes
          this.state.from,
          this.state.account || this.state.selectedAccount.contractNumber
        )
      );
    } else {
      this.setState({ page: value }, () => this.getContractsData()); // for contract All accounts
    }
  };

  handleChangeRowsPerPage = (value) => {
    if (this.state.showAllNotes || this.state.showPayments) {
      this.setState({ page: 1, pageSize: value }, () =>
        this.getAllNotes(
          this.state.to,
          this.state.from,
          this.state.account || this.state.selectedAccount.contractNumber // for   Notes
        )
      );
    } else {
      this.setState({ page: 1, pageSize: value }, () => this.getContractsData()); // for contract All accounts
    }
  };

  getAllNotes = async (to, from, account) => {
    let { page, pageSize, contractType, type } = this.state;
    let api;
    if (this.state.showPayments) {
      if (to) {
        api = `/api/creditdebitnotes/get?to=${to}&from=${from}${account}&search=${this.state.searchText}&page=${page}&limit=${pageSize}&type=${type}`;
      } else {
        api = `/api/creditdebitnotes/get?contractNumber=${account}&page=${page}&limit=${pageSize}&search=${this.state.searchText}&type=${type}`;
      }
    } else {
      if (to) {
        api = `/api/creditdebitnotes/get?contractType=${contractType}&to=${to}&from=${from}${account}&search=${this.state.searchText}&page=${page}&limit=${pageSize}&type=${type}`;
      } else {
        api = `/api/creditdebitnotes/get?contractType=${contractType}&page=${page}&limit=${pageSize}&search=${this.state.searchText}&type=${type}`;
      }
    }
    source = axios.CancelToken.source();
    this.setState({
      loader2: true,
    }, () => {
      axios
        .get(api, {
          cancelToken: source.token,
        })
        .then((response) => {

          this.setState({
            filterInvoices: response?.data?.data || [],
            filteredData: true,
            openTable: false,
            loader: false,
            loader2: false,
            showServices: false,
          });
        })
        .catch((e) => {
          // console.log("e ===", e);
        });
    })
  };
  getMoreAccounts = () => {
    let cc = this.state.value === "maintenance" ? 1 : this.state.value === "leasing" ? 2 : this.state.value === "cashCustomer" ? 3 : "";
    let {
      searchText
    } = this.state;
    let apiUrl = `/api/accounts/get?userId=${this.props?.logInUser.id}&contractType=${cc}&all=true&limit=-1&search=${searchText}`;

    source = axios.CancelToken.source();

    this.setState({
      recievingAccounts: [],
    }, () => {
      axios
        .get(apiUrl, {
          cancelToken: source.token,
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              recievingAccounts: response?.data?.data.data || {},
            });
          }
        })
        .catch((e) => {
        });
    })

  };

  handleChangeTabs = (e) => {
    if (e === "leasing") {
      this.setState(
        {
          value: e,
          leasing: true,
          contractType: 2
        },
        () => {
          this.getContractsData();
        }
      );
    } else if (e === "maintenance") {
      this.setState(
        {
          value: e,
          leasing: false,
          contractType: 1
        },
        () => {
          this.getContractsData();
        }
      );
    }
  };
  getContractsData = () => {
    // Get All Accounts maintenanace or leasing
    const { page, pageSize, searchText, value } = this.state;
    let apiUrl;
    if (value === "leasing") {
      apiUrl = `/api/leaseinvoices/contracts?page=${page}&limit=${pageSize}&search=${searchText}`;
    } else if (value === "maintenance") {
      apiUrl = `/api/invoices/contracts?page=${page}&limit=${pageSize}&search=${searchText}`;
    }
    source = axios.CancelToken.source();
    this.setState({ loader1: true }, () => {
      axios
        .get(apiUrl, {
          cancelToken: source.token,
        })

        .then(async (response) => {
          const accountsList = [];
          let allAccounts = response?.data?.data.data || [];

          const desiredKeys = ["id", "accountName", "contractNumber"];
          allAccounts &&
            allAccounts.map((obj) => {
              const newObj = {};
              desiredKeys.forEach((key) => {
                if (obj.hasOwnProperty(key)) {
                  newObj[key] = obj[key];
                }
              });
              accountsList.push(newObj);
            });

          // openCreateModale
          this.setState({
            loader1: false,
            invoiceData: response?.data?.data || [],
            recievingPartyAccounts: accountsList,
          });
        })
        .catch((e) => {
          console.log("e ===", e);
        });
    })
  };

  handleChangeNotesTabs = (e) => {
    this.setState(
      {
        type: e,
      },
      () => {
        this.getAllNotes(this.state.to,
          this.state.from,
          this.state.selectedAccount.contractNumber || this.state.account);
      }
    );
  };

  setCDTabs = () => {
    this.setState(
      {
        type: 1,
      },
      () => {
        this.getAllNotes()
      }
    );
  };
  render() {
    if (this.props.logInUser) {
      const { value } = this.state;

      return (
        <Layout
          {...this.props}
          {...this.state}
          creditDebitFilter={this.creditDebitFilter}
          downloadCreditDebit={this.downloadCreditDebit}
          {...this.props}
          noSidebar={
            this.state.showAllNotes || this.state.selectSingleInvoice
              ? ""
              : "noSidebar"
          }
        >
          {!this.state.showServices && !this.state.showAllNotes ? (
            <>
              <Grid container columns={{ xs: 12, sm: 12 }} style={{ display: "flex" }}>
                <Grid item xs={4} sm={4} style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleChangeTabs("maintenance")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "maintenance" ? "#00C179" : "#ffff",
                      color: value === "maintenance" ? "#ffff" : "#00C179",
                    }}
                  >
                    {this.props.translate("maintenance")}
                  </Button>

                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={() => this.handleChangeTabs("leasing")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "leasing" ? "#00C179" : "#ffff",
                      color: value === "leasing" ? "#ffff" : "#00C179",
                    }}
                  >
                    {" "}
                    {this.props.translate("leasing")}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div style={{ width: "15%", marginRight: 5, marginTop: 6 }}>
                    <Button
                      // disabled={devices.data && devices.data.length === 0}
                      aria-label="filter"
                      size="small"
                      color="inherit"
                      onClick={this.showAllNotesFun}
                      style={{ marginRight: "10px" }}
                    >
                      {this.props.translate("allNotes")}
                    </Button>
                  </div>
                  <div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("search")}
                      value={this.state.searchText}
                      onChange={(e) => this.searchAccount(e)}
                    />
                  </div>
                </Grid>
              </Grid>
              {this.state.loader1 ? <Loader /> :
                <Table
                  rows={
                    (this.state.invoiceData && this.state.invoiceData.data) || []
                  }
                  {...this.props}

                  pagination={this.setPagination()}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleChangePage={this.handleChangePage}
                  // SearchItem={this.SearchItem}
                  onClick={this.clickContractTable}
                  btnName1={"accounts"}
                  rowsPerPage={15}
                  isEditable={true}
                  themecolors={this.props.themecolors}
                  filterDropdown
                  // setSerialNo
                  isCursoPointer
                  rowDefinition={[
                    {
                      id: "accountName",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("accountName"),
                    },
                    {
                      id: "contractName",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("contractName"),
                    },
                    {
                      id: "contractNumber",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("contractNumber"),
                    },
                    {
                      id: "contractStartDate",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("contractStartDate"),
                    },
                    {
                      id: "contractEndDate",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("contractEndDate"),
                    },
                    {
                      id: "totalBalance",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("totalBalance"),
                    },
                    {
                      id: "totalPaid",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("totalPaid"),
                    },
                    {
                      id: "totalRevenue",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("totalRevenue"),
                    },
                  ]}
                />
              }
            </>
          ) : null}

          {(this.state.openTable && this.state.showServices) ||
            this.state.showAllNotes ? (
            <CreditDebitList
              {...this.state}
              invoiceData={this.state.recievingPartyAccounts}
              openModale={this.openModale}
              closeModale={this.closeModale}
              {...this.props}
              handleChangeNotesTabs={this.handleChangeNotesTabs}
              setCDTabs={this.setCDTabs}
              notesData={this.state.filterInvoices}
              getMoreAccounts={this.getMoreAccounts}
              searchNote={this.searchNote}
              getAllNotes={this.getAllNotes}
              setPagination={this.setPagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              goBack={this.goBack}
            />
          ) : null}
        </Layout>
      );
    } else {
      fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if (res.status === 'success') {
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if (res?.statusCode === '440') {
                window.location.replace('/login')
              }
              else if (res.statusCode) {
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
              
                  toast.error(
                   this.props.translate(err[1])
                )
                this.props.navigate('/login')
              }
            })
          } else {
            this.props.navigate('/login')
            throw response
          }
        })
        .catch((e) => {
          
            toast.error(
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(CreditDebitNotes));

import React, { Component } from "react";
import Stepper from "../Invoice/Stepper";
import moment from "moment";
import DateRangePicker from "../Invoice/DateRangePicker";
// import TextField from './TextField';
import { Grid, FormControlLabel } from "@mui/material";
import Loader from "../../Layout/Loader";
import AccountSelector from "../Invoice/AccountSelector";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import { GetApp } from "@mui/icons-material";

export default class MissedOpportunitiesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      isSubmitBtn: false,
      isVisible:false,
      selectedaccountId: [],
      contractNumber: [],
      status:"",
      selectAll:true
      // contractNumber:""
    };
  }
  componentWillReceiveProps(nextProps) {
    // console.log("hrer=====", nextProps);
    // console.log("this.props=====", this.props.to);
    // Compare incoming props with previous props
    if ((nextProps.to=== "" || nextProps.from=== "") &&nextProps.selectedUser=== "") {
      // Props are not equal, update the state
      this.setState({
        to: '',
        from: '',
        selectedDate:"",
         selectedaccountId: [],
         contractNumber:[],
         selectAll:false

      });
    }
  }

 
  


  showDates = (e, date) => {
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  handleChange = (name, e) => {
    if(name==="status"){
      this.setState({
        [name]:e.target.value,
      });
    }
    else{
      this.setState({
        contractNumber: name,
      });
      this.setState({ contractNumber: name }, () => {
        let filter = "";
        Object.entries(this.state.contractNumber).map(([key, value]) => {
          if (value) {
            filter += "&contractNumber=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedaccountId: filter,
        });
      });
    }
  
  };

  validatedForm = (type) => {
     this.props.getMissingItems(this.state.selectedaccountId, this.state.to, this.state.from, this.state.status, type)
  };

  checkRequiredFields = () => {
    const { to,from,status,selectedDate } =this.state;   
    if ( to && from && status) {
        this.setState({ isVisible: true });
      }
      else {
        this.setState({ isVisible: false });
      }
  };
  render() {
    // console.log("to ======", this.state.to, this.state.from);
    // console.log("acc ======", this.state.selectedaccountId);
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12} style={{ padding: 10 }}>
              <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  tab={this.props.tab}
                  onEvent={this.showDates}
             
                  futureDates={this.props.tab==="potentialOpportunity"?true:false}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 10 }}>
              <AccountSelector
                    // {...this.props}
                    contractType={this.props.contractType}
                    creditDebitNotes
                    api="accounts"
                    fill
                    hideIcons
                    isClearable
                    placeholder={this.props.translate("searchAccount")}
                    onChange={this.handleChange}
                    tab={this.props.tab}
                    translate={this.props.translate}
                    value={this.state?.contractNumber || []}
                  />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 20,
                    }}
                  >
                       <Button
                      // disabled={this.state.isSubmitBtn ? "" : "disabled"}
                      variant="contained"
                      color="primary"
                      onClick={(e) =>
                        this.validatedForm("downloadExcel")
                      }
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                    >
                      <GetApp />
                      {this.props.translate("Excel")}
                    </Button>
                    <Button
                      onClick={(e) => this.validatedForm()}
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                      // disabled={!this.state.isVisible }
                    >
                    {this.props.translate("submit")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
      </form>
    );
    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Checkbox from './../common/Checkbox'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Place'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import MarkerMap from '../common/Maps'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '../common/Button'
import { logInUserInfo } from './../../Actions/Users'
import CustomDialog from '../common/Dialog'
import isEqual from 'react-fast-compare'
import { checkPrivileges } from '../../Helpers'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const coordinateFormatTypes = [
  {
    key: 'dd',
    name: 'sharedDecimalDegrees'
  },
  {
    key: 'ddm',
    name: 'sharedDegreesDecimalMinutes'
  },
  {
    key: 'dms',
    name: 'sharedDegreesMinutesSeconds'
  }
]

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class ServicePreferences extends Component {
  constructor () {
    super()
    this.state = {
      form: {
        name: '',
        map: '',
        latitude: '',
        longitude: '',
        zoom: '',
        twelveHourFormat: '',
        coordinateFormat: '',
        poiLayer: ''
      },
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
      isVisableAddBtn: false,
      maps: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    let mapsArr = []
    this.setState({
      form: { ...this.props.logInUser }
    })
    this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.maps&&this.props.logInUser.attributes.maps.map((item)=>{
      if(item!=='baidu'){
        mapsArr.push(item)
      }
    })
    this.setState({maps:mapsArr})
  }
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false
    })
  }
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      })
    }
  }
  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom
          },
          isMapModal: false
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields = () => {
    let { zoom } = this.state.form
    let value = !isEqual(this.state.form, this.props.logInUser)
    this.setState({
      isVisableAddBtn: value
    })
    if (zoom) {
      if (value && zoom > 2 && zoom < 19) {
        this.setState({
          isVisableAddBtn: value
        })
      } else {
        this.setState({
          isVisableAddBtn: false
        })
      }
    } else {
      this.setState({
        isVisableAddBtn: false
      })
    }
  }
  formReset () {
    this.setState({
      form: { ...this.props.logInUser },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { logInUser } = this.props
    const { form } = this.state
    logInUser = { ...form }
    fetch(`api/users/${logInUser.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...logInUser
      })
    })
      .then(response => {
          response.json().then(res => {
            if(res.status === 'success'){
              let user = res.data
              this.props.dispatch(logInUserInfo(user))
                toast.success(this.props.translate('servicePerferenceInfoIsUpdated'))
            } 
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
                toast.error(this.props.translate(err[1]))
            }
            else {
              throw response
            }   
          })
          this.setState({
            isVisableAddBtn: false
          })
      })
      .catch(e => {
      toast.error('somethingWentWrong')
      })
  }
  handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (target.type === 'number') {
      value = parseFloat(value)
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value
        }
      },
      () => this.checkRequiredFields()
    )
  }
  onCloseModal = () => {
    this.setState({
      isMapModal: false
    })
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate('setLocation')}
            themeColors={this.props.themeColors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            fullWidth
            CancelText={this.props.translate('sharedBack')}
            headerActions={
              <Button
                size='small'
                variant='contained'
                color='secondary'
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {' '}
                {this.props.translate('sharedAdd')}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.form.zoom || 0}
              lat={this.state.form.latitude || 0}
              lng={this.state.form.longitude || 0}
              height={'100%'}
            />
          </CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='name'
              label={this.props.translate('sharedName')}
              type='text'
              value={this.state.form.name}
              onChange={this.handleChange('name')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>

          {this.state.maps && (
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='map'
                  select
                  label={this.props.translate('mapTitle')}
                  value={
                    (this.state && this.state.form && this.state.form.map) || ''
                  }
                  onChange={this.handleChange('map')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin='dense'
                  fullWidth
                >
                  {' '}
                  <MenuItem value={0}>
                    <em>{this.props.translate('none')}</em>
                  </MenuItem>
                  {this.state.maps&&this.state.maps.map(option => (
                      <MenuItem key={option} value={option}>
                        {this.props.translate(option)}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}

          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='coordinateFormat'
              select
              label={this.props.translate('CoordinateFormat')}
              value={this.state.form.coordinateFormat || ''}
              onChange={this.handleChange('coordinateFormat')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              <MenuItem value=''>
                <em>{this.props.translate('none')}</em>
              </MenuItem>
              {coordinateFormatTypes.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/*  <Grid item xs={12} md={3} sm={6}>
                        <TextField
                            id='poiLayer'
                            label={this.props.translate('mapPoiLayer')}
                            type="text"
                            placeholder=""
                            value={this.state.form.poiLayer}
                            onChange={this.handleChange('poiLayer')}
                            variant="outlined"
                            margin='dense'
                            fullWidth
                        />
                    </Grid> */}
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='zoom'
              label={this.props.translate('serverZoom')}
              type='number'
              placeholder=''
              error={
                this.state.form.zoom > 18 || this.state.form.zoom < 3
                  ? true
                  : false
              }
              helperText='Enter Value 3 to 18'
              value={this.state.form.zoom || ''}
              onChange={this.handleChange('zoom')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='latitude'
              type='number'
              label={this.props.translate('positionLatitude')}
              placeholder=''
              value={this.state.form.latitude || ''}
              onChange={this.handleChange('latitude')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='longitude'
              type='number'
              label={this.props.translate('positionLongitude')}
              value={this.state.form.longitude || ''}
              onChange={this.handleChange('longitude')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Tooltip title='Add Location'>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip>
            <FormControlLabel
              style={{ marginLeft: 16 }}
              control={
                <Checkbox
                  checked={this.state.form.twelveHourFormat}
                  onChange={this.handleChange('twelveHourFormat')}
                  value='twelveHourFormat'
                />
              }
              label={this.props.translate('settingsTwelveHourFormat')}
            />
          </Grid>
        </Grid>

        <br />
        {checkPrivileges('userUpdate') && (
          <Fragment>
            <Button
              variant='contained'
              onClick={this.formReset}
              style={{ marginLeft: 8, marginRight: 8 }}
            >
              {' '}
              {this.props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Fragment>
        )}
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(ServicePreferences)

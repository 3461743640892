import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import Table from '../../../common/TableMultiCheckbox'
import {
  checkPrivileges,
  PaginationConfig
} from '../../../../Helpers'
import LoginAsUser from '../../../../Helpers/loginAsUser'
import Loader from '../../../../Layout/Loader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid,  } from '@mui/material'
import TextField from '../../../common/TextField'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source,
  totalUserId = {
    userId: [],
    vehicleId: ''
  }
class Vehicle extends Component {
  constructor () {
    super()
    this.state = {
      usersReceived: false,
      selectedUnitsList: '',
      accessUserList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: '',
      isChecked: false,
      isIndeterminate: false,
      allSelectedIds: '',
      noRecords: false,
      assignedFilter:false,
      managedUserId: '',
      selected: 'all',
      loader:false
    }
    this.unitToUserpermission = this.unitToUserpermission.bind(this)
  }
  componentWillMount () {
    this.getMoreUnits()
  }
  // componentWillUnmount () {
  //   if (source) {
  //     source.cancel()
  //   }
  // }
  getMoreUnits = () => {
    let { pagination, managedUserId, cancelTokenSource } = this.state;
    let { page, pageSize, itemSearch } = pagination;
  
    // Ensure the cancel token source is available
    if (!cancelTokenSource) {
      cancelTokenSource = axios.CancelToken.source();
      this.setState({ cancelTokenSource });
    }
  
    axios
      .get(
        `api/vehicles/get?userId=${managedUserId ? managedUserId : this.props.logInUser.id}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: cancelTokenSource.token, // Attach cancel token to the request
        }
      )
      .then((resp) => {
        let response = resp.data;
  
        if (response.status === "success") {
          let lastPage = Math.ceil(response.data.total / pageSize);
  
          this.setState({ loader: true }, () => {
            axios
              .get(`api/vehicles/linked?userId=${this.props.selectItemId.id}`)
              .then((res1) => {
                let res = res1.data;
  
                if (res.status === "success") {
                  let len = (res.data && res.data.length) === response.data.total;
                  let selectedIds = res.data.map((userId) => userId.entityId);
                  let units = response.data.data.map((loginData) => {
                    const findItem = res.data.find((it) => it.entityId === loginData.id);
                    loginData.check = findItem?.directAccess || false;
                    loginData.groupAccess = findItem?.groupAccess || 0;
                    return loginData;
                  });
  
                  this.setState({
                    loader: false,
                    allSelectedIds: selectedIds,
                    pagination: {
                      ...this.state.pagination,
                      lastPage,
                      hasNext: response.data.hasNext,
                      total: response.data.total,
                    },
                    selectedUnitsList: units,
                    usersReceived: true,
                    noRecords: false,
                    isChecked: len,
                  });
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  toast.error(this.props.translate(err[1]));
                }
              });
          });
        } else if (response?.statusCode === "440") {
          window.location.replace("/login");
        } else {
          var err = response?.message.split(":");
          err[1] = err[1].replace(")", "");
          toast.error(this.props.translate(err[1]));
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.error("Error fetching data:", err);
        }
      });
  };
  
 
  handleChangeFilter = (id,event) => {
    if(event.target.value === 'assigned'){
      this.setState({
          assignedFilter:true,
          managedUserId:id,
          pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
          selected:event.target.value
      },()=>{this.getMoreUnits()})
    }
    else{
      this.setState({
        assignedFilter:false,
        managedUserId:'',
        selected:event.target.value
       },()=>{this.getMoreUnits()})
    }
  }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        this.getMoreUnits()
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUnits()
    )
  }
  SearchItem = (searchValue) => {
    // Cancel previous request if it exists
    if (this.state.cancelTokenSource) {
      this.state.cancelTokenSource.cancel("Operation canceled due to new search.");
    }

    const cancelTokenSource = axios.CancelToken.source(); // Create a new CancelToken source

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue,
        },
        cancelTokenSource, // Store the new token source in state
      },
      () => this.getMoreUnits()
    );
  };

  allUnitsId = event => {
    // console.log("eve1111====",event);
    
    let sellectedIds = []
    let check = event.target.checked
    let allUserId = []
    this.selectAllUser(check)
  }

  selectAllUser = event => {
   
    let option = 'GET'
    let apiUrl;
    if (event) {
      apiUrl = `api/vehicles/assignall?assignFrom=${this.props?.logInUser?.id}&assignTo=${this.props?.selectItemId?.id}&all=true&unlink=false`
    } else {
      apiUrl = `/api/vehicles/assignall?assignFrom=${this.props?.logInUser?.id}&assignTo=${this.props?.selectItemId?.id}&all=true&unlink=true`
    }
    fetch(apiUrl, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify({
      //   ...obj
      // })
    })
    .then(response => {
      response.json().then(res => {
        // console.log("res====",res);
        if(res.status ==='success' || res.status ==='ambiguous'){ 
          if (event) {
            this.setState({
              isChecked: true
            },()=> this.getMoreUnits())

           
              toast.success(
                 this.props.translate('allVehiclesAssignedSuccessfully')
            )
          } else {
            this.setState({
              isChecked: false
            },()=> this.getMoreUnits())
            
              toast.success(
                this.props.translate('allVehiclesUnAssignedSuccessfully')
            )
          }
         
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
          var err = res?.message.split(':')
          err[1] =err[1].replace(')', "")
        
            toast.error(
              this.props.translate(err[1])
          )
          this.setState({
            isChecked: false
          },()=> this.getMoreUnits())
        }
       })
      })
      .catch(e => {
        toast.error(
        'somethingWentWrong')
      })
      totalUserId = {
          userId: [],
          vehicleId: ''
       }

  }

  unitToUserpermission (event, item) {
    let sellectedIds = []
    // console.log("eve===",event);
    let  option
    let count = 0,
      allUserId = 0
    if (event) {
      option = 'POST'
    }
    else{
      option = 'DELETE'
    }
    // console.log("eve===",option);

    let allData = [...this.state.selectedUnitsList]
    let obj = {
      userId: this.props.selectItemId.id,
      vehicleId: item.id
    }
    fetch(`/api/permissions`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        response.json().then(res=>{
          if (res.status === 'success') {
            if (option === 'POST') {
          
                toast.success(
                  this.props.translate('vehicleIsAssignedSuccessfully')
              )
            } else {
        
                toast.success(
                  this.props.translate('vehicleIsUnAssignedSuccessfully')
              )
            }
          } 
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res?.statusCode){
            var err = res.message.split(':')
            var err2 =err[1].split('&#')
           
              toast.error(
                this.props.translate(err2[0])
            )
          }
          else {
            throw response
          }
        })
      })
      .catch(e => {
        if(e && e.text){
          e.text().then(err => {
            if (err.includes('You cannot unlink unit')) {
            
                toast.error(
                 this.props.translate('youCannotUnlinkUnit')
              )
            }
          })
        }
      })
      this.getMoreUnits()
  }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedUnitsList } = this.state
    selectedUnitsList.entity.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
      return null
    })
    this.setState({
      selectedUnitsList
    })
  }

  render () {
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
               <Grid item xs={12} sm={6}>
                   <h3 style={{margin: 0}}>{this.props.translate('vehicles')}</h3>
                </Grid>
              <Grid item xs={12} sm={2}   >
                 <Select
                      name="name"
                      value={this.state.selected}
                      onChange={e=>this.handleChangeFilter(this.props.selectedUser.id,e)}
                      input={<Input id="name" />}
                    >
                      <MenuItem value="assigned">Assigned</MenuItem>
                      <MenuItem value="all">All</MenuItem>
                    </Select>
                {/* <Checkbox 
                    name='assigned'
                    checked={this.state.assignedFilter}
                    onChange={e=>this.handleChangeFilter(this.props.selectedUser.id,e)} 
                    canAssign
                    canRemove
                  />
                  <span>Assigned</span> */}
                </Grid>

                    <Grid item xs={12} sm={4} style={{justifyContent: 'flex-end'}}>
                      <div style={{ marginRight: '20px', width: "80%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
            
               </Grid>
        {this.state && !this.state.loader && !this.state.noRecords ? (
          <div styles={{ postion: 'relative' }}>
            <Table
              // rows={
              //   this.state.selectedUnitsList.map(item => {
              //     return item.entity
              //   }) || []
              // }
              rows={
                this.state.selectedUnitsList|| []
              }
              translate={this.props.translate}
              allUsersId={this.allUnitsId}
              selectItemParentId={this.props.selectItemId.id}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              loginAsUser={this.loginAsUser}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              isChecked={this.state.isChecked}
              isIndeterminate={this.state.isIndeterminate}
              rowsPerPage={10}
              isEditable={false}
              // notshowAllCheckbox={true}
              showCheckbox2={true}
              showGroupAccess={true}
              ServerSetting={this.props.ServerSetting}
              themecolors={this.props.themecolors}
              checkHandleChange={this.unitToUserpermission}
              canAssign={checkPrivileges('userLinkDevice')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              // rowDefinition={rowDefinitionR(this.props.translate)}
              rowDefinition={[
                {
                  id: 'label',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedName')
                },
                // (logInUser.attributes?.viewIMEI || logInUser.userType===-1) &&
                {
                  id: 'vehicleLicensePlate',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('vehicleLicensePlate')
                },
                {
                  id: 'category',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('category')
                },
                // (logInUser.attributes?.viewModel || logInUser.userType===-1) &&{
                //   id: 'model',
                //   numeric: false,
                //   disablePadding: false,
                //   label: this.props.translate('model')
                // },
          
                // {
                //   id: 'created',
                //   numeric: false,
                //   disablePadding: false,
                //   label: this.props.translate('createdAt')
                // },
                // {
                //   id: 'status',
                //   numeric: false,
                //   disablePadding: false,
                //   label: this.props.translate('status')
                // }
              ]}
            />{' '}
          </div>
        ) :  this.state && this.state.loader && !this.state.noRecords ? 
         (<div style={{ textAlign: 'center' }}>
              <Loader defaultStyle />
        </div>)
        :
         !this.state.loader && this.state.noRecords ? (
          <div style={{ textAlign: 'center' , marginTop:"100px"}}>
              <h4> {this.props.translate('noUnitsFound')}</h4>
          </div>
      ) : <Loader defaultStyle />
      }

        {/* {!this.state.loader && this.state.noRecords && (
              <div style={{ textAlign: 'center' }}>
                  <h4> {this.props.translate('noUnitsFound')}</h4>
              </div>
          )} */}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Vehicle)
import React, { Component } from "react";
import axios from "axios";
import { getDevices } from "../../Actions/Devices/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import CustomDialog from "./Dialog";
import Button from "./Button";
import Radio from "./Radio";
import SingleSelect from "../common/SingleSelect";
import {
  checkUserRoles,
  prepareUserForLogin,
  prepareUsers,
} from "../../Helpers";
import {
  getUsers,
} from "../../Actions/Users";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Moment from "moment";
import withTranslationWrapper from "../../HOC/HocTranslate";

let source;

class ImportExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      showTable: false,
      defaultAccountsList: [],
      selectedUser: "",
      itemSearch: "",
    };
  }

  componentDidMount() {
    // this.fetchAccountsList(this.props.logInUser.accountId);
    this.getMoreUsers();
  }

  getMoreUsers = () => {
    let { itemSearch, userTypes, userStatuses, userTimes } =
      this.state;
    if (this.props.logInUser.id) {
      let apiCall = `/api/users/get?userId=${
        this.props.logInUser.id
      }&all=true&page=${1}&limit=${-1}&search=${itemSearch}${userTypes || ""}${
        userStatuses || ""
      }${userTimes || ""}`;

      source = axios.CancelToken.source();
      axios
        .get(apiCall, {
          cancelToken: source.token,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === "success") {
            if (res.data.data.length === 0) {
              this.setState({
                noRecord: true,
              });
            } else {
              this.setState({
                noRecord: false,
              });
            }
            if (res.data.data) {
              let udata = prepareUserForLogin(
                checkUserRoles(prepareUsers(res.data.data), this.props.roles)
              );
              this.props.dispatch(getUsers(udata));

              let users = udata.map((u) => {
                if (u.created) {
                  return {
                    ...u,
                    created: this.props.logInUser.twelveHourFormat
                      ? Moment(u.created).format("YYYY-MM-DD hh:mm A")
                      : Moment(u.created).format("YYYY-MM-DD HH:mm"),
                  };
                } else {
                  return { ...u };
                }
              });
              this.setState({
                defaultAccountsList: users,
                defaultUserList: true,
                loader: false,
                pagData: response.data.data,
              });
            } else {
              this.setState({
                defaultAccountsList: [],
                defaultUserList: true,
                pagData: "",
              });
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
     
              toast.error( this.props.translate(err[1])
          
            );
          }
        })
        .catch((e) => {
          //   this.props.dispatch(toast.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        });
    }
  };

  // fetchAccountsList = (id) => {
  //   let fetchApi = `/api/accounts/list?accountId=${id}&limit=100`;
  //   if (this.props.logInUser.userType === -1) {
  //     fetchApi = `/api/accounts/all?accountId=${id}&all=true&limit=100`;
  //   }
  //   fetch(fetchApi, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         response.json().then((res) => {
  //           if (res.status === "success") {
  //             let userlist = res.data?.data;
  //             let result =
  //               userlist.data &&
  //               userlist.data.map((item) => ({
  //                 ...item,
  //                 label: item.label || item.name,
  //                 name: item.label || item.name,
  //                 value: item.id,
  //               }));
  //             // result.unshift({
  //             //   id: this.props.ServerSetting.id,
  //             //   label: this.props.ServerSetting.name,
  //             //   value: this.props.ServerSetting.id,
  //             // });
  //             this.setState({
  //               defaultAccountsList: res.data?.data || [],
  //             });
  //           } else if (res?.statusCode === "440") {
  //             window.location.replace("/login");
  //           } else if (res.statusCode) {
  //             var err = res?.message.split(":");
  //             err[1] = err[1].replace(")", "");
  //             this.props.dispatch(
  //               toast.error({
  //                 message: this.props.translate(err[1]),
  //                 autoDismiss: 5,
  //                 location: this.props.location,
  //               })
  //             );
  //           }
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("e =", e);
  //       //   this.props.dispatch(toast.error({
  //       //   message: 'somethingWentWrong',
  //       //   autoDismiss: 5
  //       // }))
  //     });
  // };

  handleChangeUser = (name, event) => {
    this.setState({
      selectedUser: event,
    });
  };
  onClose = () => {
    this.props.onClose();
  };
  formSubmit = async (e, fileName) => {
  
    this.setState({ isSubmit: false });
    let api;
    if (fileName === "services") {
      api = `/api/expensetypes/subtypes/import?expensetypeId=${
        this.props?.expensetypeId || ""
      }`;
    } else if (fileName === "category" && this.props.v2Report) {
      api = `/api/expensetypes/import/v2`;
    }
    else if (fileName === "category") {
      api = `/api/expensetypes/import/${this.props?.expensetypeId}`;
    } else if (fileName === "payments") {
      api = `api/payments/import`;
    } else if (fileName === "units") {
      api = `api/devices/import`;
    } else if (fileName === "vehicles") {
      // if(this.props.vehicleStatusUpload){
        api = this.props.vehicleStatusUpload ? `/api/${fileName}/importstatus` : `/api/${fileName}/import`;
      // }else{
      //   api = `api/vehicles/import`;
      // }
    } else if (fileName === "deliverynotes") {
      api = `api/deliverynotes/import`;
    } else {
      api = "/api/deliverynotes/import";
    }
    const file = await this.convertBase64(e);
    const base64 = file.split("base64,");

    // let vehicleData = {
    //   name: "anyName",
    //   contentType:
    //     "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   oldUrl: "",
    //   insertionMode: this.state.insertionMode,
    //   // data: base64[1],
    //   parentIdForData: this.state.selectedUser?.id || 0,
    // };

    axios({
      method: "POST",
      url: api,
      data: {
        name: "anyName",
        contentType:
          "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        oldUrl: "",
        insertionMode: this.state.insertionMode,
        data: base64[1],
        parentIdForData:
          fileName === "vehicles" ? this.state.selectedUser?.id || 0 : "",
      },
    })
      .then((response) => {
        if (fileName === "devices") {
          this.props.dispatch(getDevices(this.props.logInUser));
        }
        if (response.data.status === "success") {
          if (fileName === "category") {
            this.props.fetchAccountServices(this.props?.selectedAccount);
      
              toast.success(this.props.translate("contractUploadedSuccessfully")
            );
          } 
          // else if (fileName === "vehicles") {
          //   // this.props.fetchAccountServices(this.props?.selectedAccount)
          //   this.props.dispatch(
          //     toast.success({
          //       message: this.props.translate("vehiclesImportedSuccessfully"),
          //       autoDismiss: 5,
          //       location: this.props.location,
          //     })
          //   );
          // } 
          else if (fileName === "units") {
            // this.props.fetchAccountServices(this.props?.selectedAccount)
       
              toast.success( this.props.translate("devicesImportedSuccessfully")
            );
          } else if (fileName === "payments") {
            // this.props.fetchAccountServices(this.props?.selectedAccount)
        
              toast.success( this.props.translate("Payment Uploaded")
            
            );
          } 
          else if (fileName === "deliverynotes"){
            let data =
            response &&
            response.data &&
            response.data.data &&
            response.data.data;
           

            if (data.errorMsg) this.props.openImportTable(data.errorMsg);
            const valuesArray = Object.values(data.errorMsg);
            const valueToPrint = valuesArray[0];
            
            if( data.added <=0) {  
           
              toast.error( this.props.translate(valueToPrint)
            ); }
            else{
       
              toast.success( this.props.translate(valueToPrint)
            )
            }

          }

          else if (fileName === "vehicles"){
            let data =
            response &&
            response.data &&
            response.data.data &&
            response.data.data;
           
            let flag = this.props.vehicleStatusUpload ? "vehicleStatus" : this.state.selectedUser
            // console.log("Res=====", response);
            if(data) this.props.openImportTable(data, flag);

            // if(data) this.props.openImportTable(data, this.state.selectedUser);
           }
           else if(fileName === 'services'){
       
              toast.success( this.props.translate("Expense Sub-Type Imported Successfully")
            );
           }
          else if (fileName === "invoices") {
            let data =
              response &&
              response.data &&
              response.data.data &&
              response.data.data;
            if (data.errorMsg) this.props.openImportTable(data.errorMsg);

              toast.success( this.props.translate(
                  "Total " +
                    data.total +
                    "   " +
                    "  Added " +
                    "   " +
                    data.added +
                    "   " +
                    "  Updated " +
                    "   " +
                    data.updated
                    +
                    "    " +
                    " Skipped " +
                    "   " +
                    data.skipped
                )
              
            );
          } else {
         
              toast.success( this.props.translate("Invoice Imported Successfully")
            );
          }
        } else if (response?.data?.statusCode === "440") {
          window.location.replace("/login");
        } else if (response?.data?.statusCode) {
          var err = response?.data?.message.split(":");
          err[1] = err[1].replace(")", "");
        
            toast.error( this.props.translate(err[1])
          );
        }
        this.onClose();
      })
      .catch((error) => {
   
          toast.error( this.props.translate("Error")
        );
      });
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  onCloseModal = () => {
    this.setState({
      enableImportPopup: false,
    });
    this.onClose();
  };
  onOpenModal = () => {
    this.setState({
      enableImportPopup: true,
    });
  };
  uploadFile = (e) => {
    let format =
      e.target.files[0] &&
      e.target.files[0].name &&
      e.target.files[0].name.split(".").pop();
    if (format === "xlsx" || format === "xls") {
      this.setState({
        uploadedFile: e.target.files && e.target.files[0],
        selectedFile:
          e.target.files && e.target.files[0] && e.target.files[0].name,
        isSubmit: true,
      });
    } else {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
     
            toast.error(this.props.translate(
                "Wrong format please upload excel file only!"
              )
          );
        }
      );
    }
  };
  selectInsertionMode = (e) => {
    this.setState({
      insertionMode: e.target.value,
    });
  };
  render() {
    const { fileName } = this.props;
    return (
      <div>
        <CustomDialog
          title={this.props.translate("importExcel")}
          // themecolors={this.props.themecolors}
          visable={true}
          onClose={this.onCloseModal}
          bodyPadding={10}
          hideDragIcon
          fullWidth
          headerActions={
            <Button
              onClick={() =>
                this.formSubmit(this.state.uploadedFile, this.props.fileName)
              }
              disabled={!this.state.isSubmit}
            >
              {this.props.translate("Submit")}
            </Button>
          }
          isVisableBtn
          noFullScreen
        >
          <label
            style={{
              padding: 10,
              border: `1px dashed`,
              borderRadius: 6,
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div style={{ marginRight: "auto" }}>
              {this.state.selectedFile || this.props.translate("uploadExcel")}
              <input type="file" onChange={(e) => this.uploadFile(e)} hidden />
            </div>
            <Button component="span">
              {this.props.translate("browseFile")}
            </Button>
          </label>
          {!this.props.service && this.props.vehicleStatusUpload === false &&  (
            <RadioGroup
              aria-label="selectedDate"
              name="selectedDate"
              defaultValue={"1"}
              value={this.state.insertionMode}
              onChange={(e) => this.selectInsertionMode(e)}
              row
            >
              <FormControlLabel
                classes={{
                  label: "form-contol-label",
                }}
                value={"1"}
                control={<Radio size="small" />}
                label={this.props.translate("addNewData")}
                labelPlacement="end"
              />
              {fileName !== "geofences" && fileName !== "vehicles" && (
                <FormControlLabel
                  classes={{
                    label: "form-contol-label",
                  }}
                  value={"2"}
                  control={<Radio size="small" />}
                  label={this.props.translate("addNewAndUpdate")}
                  labelPlacement="end"
                />
              )}
            </RadioGroup>
          )}
          {this.props.fileName === "vehicles" && this.props.vehicleStatusUpload === false ? (
            <>
              <SingleSelect
                array={this.state.defaultAccountsList || []}
                async
                selectName="users"
                // isClearable
                label={this.props.translate("selectChildUsers")}
                value={
                  this.state.selectedUser && this.state.selectedUser.id
                    ? {
                        // key: this.state.selectedUser.id,
                        id: this.state.selectedUser.id,
                        label: this.state.selectedUser.label,
                      }
                    : ""
                }
                // value={this.props.form.reportNumber || ""}
                handleChange={this.handleChangeUser}
                canAssign={true}
              />
            </>
          ) : null}
        </CustomDialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withTranslationWrapper(ImportExcel));

import React, { Component, Fragment } from "react";
//import Access from './Components/Access'
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import { checkPrivileges } from "../../../Helpers";
import UpdateUserDetail from "./Components/updateAccount";
import Invoices from "./Components/Invoices";
import Style from "style-it";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Breadcrumb from "../../common/Breadcrumb";
import Button from "../../common/Button";
import ContractManagement from "./Components/ContractManagement";
import PaymentModule from "./Components/Payment";
import TermsAndConditions from "./Components/termsAndConditions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Vehicles from "./Components/Vehicles";
import Garages from "./Components/Garages";
import MonthlyTargets from "./Components/monthltyTargets";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2,
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4,
  },
});
class viewAccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "edit",
      units: "",
      selectedAccount: "",
      selectedUser: "",
      termsArabic: "",
      termsEnglish: "",
      termsobj: {},
      accountFilesData: [],
    };
  }

  componentWillMount() {
    if (this.props.AccountSettings) {
      this.setState({
        selectedAccount: this.props.ServerSetting,
      });
    } else {
      this.setState({
        selectedAccount: this.props.selectItem,
      });
    }

    fetch(`/api/accounts/${this.props.selectAccountId}/terms`)
      .then((response) => {
        response.json().then((res) => {
          // console.log("My",res)
          if (res.status === "success") {
            if (res.data != null) {
              this.setState({
                termsEnglish: res.data.attributes.termsEnglish,
                termsArabic: res.data.attributes.termsArabic,
                termsobj: res ? res : null,
                loader: false,
              });
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
            toast.error(this.props.translate(err[1]));
          }
        });
      })
      .catch((e) => {
        toast.error("somethingWentWrong");
      });
    this.getAccountFilesData(this.props.selectAccountId);
  }
  getAccountFilesData = (selectAccountId) => {
    fetch(`/api/uploads/get?entityId=${selectAccountId}&entity=Account`)
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
            this.setState({ accountFilesData: res.data });
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
            toast.error(this.props.translate(err[1]));
          }
        });
      })
      .catch((e) => {
        toast.error("somethingWentWrong");
      });
  };
  removeAccountFile = (mediaId) => {
    fetch(` /api/uploads/${mediaId}`, {
      method: "DELETE",
    })
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
            this.setState({ accountFilesData: res.data }, () =>
              this.getAccountFilesData(this.props.selectAccountId)
            );
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
            this.props.dispatch(toast.error(this.props.translate(err[1])));
          }
        });
      })
      .catch((e) => {
        toast.error("somethingWentWrong");
      });
  };
  UpdateSelectedItem = (item) => {
    this.setState({
      selectedAccount: item,
      selectedUser: item,
    });
  };
  handleChange = (event, value) => {
    this.setState({
      value: this.props.logInUser.userType === 6 && value === 2 ? 3 : value,
    });
  };
  updateSelectedAccount = (item) => {
    this.setState({ selectedAccount: { ...item } });
  };

  render() {
    const { classes, logInUser } = this.props;
    const { value } = this.state;
    const orientation = window.innerWidth <= 959 ? "horizontal" : "vertical";
    let tabName = "Edit";
    if (value === "contractManagement") {
      tabName = "contractManagement";
    } else if (value === "Invoices") {
      tabName = "Invoices";
    } else if (value === "payment") {
      tabName = "Payment";
    }
    // else if(value==='theme'){tabName='Theme Settings'}
    // else if(value==='waslApi'){tabName='Wasl Api Key'}
    if (logInUser) {
      return (
        <div className={classes.root}>
          <Breadcrumb
            itemName={this.props.selectItemName}
            tabName={tabName}
            onClose={this.props.onClose}
            moduleName={this.props.translate("accountDetails")}
            backLink={
              !this.props.AccountSettings ? (
                <Button size="small" onClick={this.props.onClose}>
                  {this.props.translate("sharedBack")}
                </Button>
              ) : null
            }
          />
          <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected2 {
            color: ${this.props.themecolors["500"]};
          }
          .Mui-selected  {
            color: ${this.props.themecolors["500"]}  !important;
          }
          `}</Style>

          <div className="v-tabs">
            <AppBar
              elevation={0}
              position="static"
              style={{ background: "transparent" }}
              className="tab-vertial-wrapper"
            >
              <Tabs
                value={value}
                onChange={this.handleChange}
                orientation={orientation}
                classes={{ root: "custom-tabs" }}
                TabIndicatorProps={{
                  style: { background: "transparent", color: "red" },
                }}
                style={{ color: "red" }}
              >
                <Tab
                  value="edit"
                  classes={{
                    root: "custom-tab-button2",
                    selected: "custom-tab-button-selected2",
                  }}
                  style={{ alignItems: "flex-start" }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon style={{ margin: "0px 8px" }} />
                      {this.props.translate("sharedEdit")}
                    </div>
                  }
                />
                <Tab
                  value="monthlyTargets"
                  classes={{
                    root: "custom-tab-button2",
                    selected: "custom-tab-button-selected2",
                  }}
                  style={{ alignItems: "flex-start" }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon style={{ margin: "0px 8px" }} />
                      {this.props.translate("monthlyTargets")}
                    </div>
                  }
                />
                {this.state.selectedAccount.userType === 3 && (
                  <>
                    <Tab
                      value="contractManagement"
                      classes={{
                        root: "custom-tab-button2",
                        selected: "custom-tab-button-selected2",
                      }}
                      style={{ alignItems: "flex-start" }}
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowRightIcon
                            style={{ margin: "0px 8px" }}
                          />
                          {this.props.translate("contractManagement")}
                        </div>
                      }
                    />
                    <Tab
                      value="Invoices"
                      classes={{
                        root: "custom-tab-button2",
                        selected: "custom-tab-button-selected2",
                      }}
                      style={{ alignItems: "flex-start" }}
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowRightIcon
                            style={{ margin: "0px 8px" }}
                          />
                          {this.props.translate("Invoices")}
                        </div>
                      }
                    />
                    <Tab
                      value="payment"
                      classes={{
                        root: "custom-tab-button2",
                        selected: "custom-tab-button-selected2",
                      }}
                      style={{ alignItems: "flex-start" }}
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowRightIcon
                            style={{ margin: "0px 8px" }}
                          />
                          {this.props.translate("Payment")}
                        </div>
                      }
                    />
                  </>
                )}
                <Tab
                  value="vehicles"
                  classes={{
                    root: "custom-tab-button2",
                    selected: "custom-tab-button-selected2",
                  }}
                  style={{ alignItems: "flex-start" }}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon style={{ margin: "0px 8px" }} />
                      {this.props.translate("vehicles")}
                    </div>
                  }
                />
              </Tabs>
              <Paper
                style={{ padding: 16, minHeight: 270, flex: 1 }}
                square={true}
              >
                {value === "monthlyTargets" && (
                  <Fragment>
                    <MonthlyTargets
                      {...this.props}
                      {...this.state}
                      updateSelectedAccount={this.updateSelectedAccount}
                    />
                  </Fragment>
                )}
                {value === "businessVerticalTargets" &&
                  this.props?.logInUser?.userType === -1 && (
                    <Fragment>
                      <Garages
                        {...this.props}
                        {...this.state}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                        updateSelectedAccount={this.updateSelectedAccount}
                      />
                    </Fragment>
                  )}

                {value === "edit" && checkPrivileges("userUpdate") && (
                  <Fragment>
                    <UpdateUserDetail
                      {...this.props}
                      {...this.state}
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      updateSelectedAccount={this.updateSelectedAccount}
                      accountFilesData={this.state.accountFilesData}
                      removeAccountFile={this.removeAccountFile}
                      getAccountFilesData={this.getAccountFilesData}
                      translate={this.props.translate}
                    />
                  </Fragment>
                )}
                {value === "contractManagement" && (
                  <Fragment>
                    <ContractManagement
                      {...this.props}
                      {...this.state}
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      translate={this.props.translate}
                    />
                  </Fragment>
                )}
                {value === "Invoices" && (
                  <Fragment>
                    <Invoices
                      invoiceType={this.props.value}
                      {...this.props}
                      {...this.state}
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      translate={this.props.translate}
                    />
                  </Fragment>
                )}
                {value === "payment" && (
                  <Fragment>
                    <PaymentModule
                      {...this.props}
                      {...this.state}
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      translate={this.props.translate}
                    />
                  </Fragment>
                )}
                {value === "vehicles" && (
                  <Fragment>
                    {/* Vehicles here */}
                    <Vehicles
                      {...this.props}
                      {...this.state}
                      UpdateSelectedItem={this.UpdateSelectedItem}
                      translate={this.props.translate}
                    />
                  </Fragment>
                )}
                {value === "termsAndConditions" && (
                  <Fragment>
                    <TermsAndConditions
                      selectItemId={
                        this.state.selectedAccount && this.state.selectedAccount
                      }
                      selectAccountId={this.props.selectAccountId}
                      termsobj={
                        this.state.termsobj ? this.state.termsobj : null
                      }
                      termsEnglish={this.state.termsEnglish}
                      accountId={this.props.logInUser.accountId}
                      termsArabic={this.state.termsArabic}
                      translate={this.props.translate}
                      {...this.props}
                    />
                  </Fragment>
                )}
                {/* {value === 'settings' && (
                    <Fragment>
                      <Serversettings
                        updateOtherServerSetting={true}
                        otherServerSetting={this.state.selectedAccount}
                        updateSelectedAccount={this.UpdateSelectedItem}
                        translate={this.props.translate}
                        getMoreAccounts={this.props.getMoreAccounts}
                      />
                    </Fragment>
                  )}
                  {value === 'user' && (
                    <Fragment>
                      <AccountUser {...this.props} {...this.state} />
                    </Fragment>
                  )}
                  {value === 'unit' && this.props.logInUser.userType !== 6 && (
                    <Fragment>
                      <AccountUnits {...this.props} {...this.state} />
                    </Fragment>
                  )}
                  {value === 'theme' && checkPrivileges('themeChange') && (
                    <Fragment>
                      <UpdateThemes
                        {...this.props}
                        {...this.state}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                        hasAccessOfUpdate={checkPrivileges('accountUpdate')}
                      />
                    </Fragment>
                  )}
                  {value === 'waslApi' &&  (
                    <Fragment>
                      <WaslApi
                        dispatch={this.props.dispatch}
                        location={this.props.location}
                        selectedItem={this.state.selectedAccount}
                        UpdateSelectedItem={this.UpdateSelectedItem}
                      />
                    </Fragment>
                  )} */}
              </Paper>
            </AppBar>
          </div>
        </div>
      );
    }
  }
}

viewAccountDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapState = (state) => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    users: state.users,
    accounts: state.accounts,
    roles: state.roles,
    ServerSetting: state.ServerSetting,
  };
};
const mapStateToProps = connect(mapState);
export const ViewAccountDetail = mapStateToProps(
  withStyles(styles)(viewAccountDetail)
);

import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'date-fns'
import Button from '../../Components/common/Button'
import Grid from '@mui/material/Grid'
import { getStatistics } from './../../Actions/Reports'
// import Table from '../../Components/common/Table'
import Table from '../../Components/common/TableServices'
import 'react-confirm-alert/src/react-confirm-alert.css'
import DateRangePicker from './../common/DateRangePicker'
import moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class showReports extends Component {
  constructor () {
    super()
    this.state = {
      defaultGroupList: false,
      isVisableBtn: false,
      form: {
        from: new Date(),
        to: new Date()
      },
      rowsPerPage: 5,
      page: 1,
      pageSize: 10,

      selectedDate: ''
    }
    this.showStatisticsRecords = this.showStatisticsRecords.bind(this)
    this.showDates = this.showDates.bind(this)
  }

  showDates (e, date) {
    if (date.startDate._d && date.endDate._d) {
      this.setState({
        isVisableBtn: true
      })
    } else {
      this.setState({
        isVisableBtn: false
      })
    }
    var formatedDate = {
      from: moment(date.startDate._d).toISOString(),
      to: moment(date.endDate._d).toISOString()
    }
    this.setState({
      selectedDate:
        moment(formatedDate.from).format('YYYY-MM-DD HH:mm') +
        ' - ' +
        moment(formatedDate.to).format('YYYY-MM-DD HH:mm'),
      form: { ...this.state.form, ...formatedDate }
    })
  }
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: 1,
        pageSize: value,
        //defaultUserList: false
      },
      () => this.showStatisticsRecords()
    );
  };
  showStatisticsRecords () {
    const { form } = this.state
    fetch(`api/statistics?from=${form.from}&to=${form.to}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          response.json().then(statistics => {
            if(statistics.status ==='success'){
              this.props.dispatch(getStatistics(statistics.data))
            }
            else if(statistics?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(statistics?.statusCode){
              var err = statistics?.message.split(':')
              err[1] =err[1].replace(')', "")
  
                toast.error(this.props.translate(err[1])
               
              )
            }
          })
        } else {
          throw response
        }
      })
      // .catch(e => {
      //   if(e && e.text){
      //   e.text().then(err => {
      //     confirmAlert({
      //       message: err,
      //       buttons: [
      //         {
      //           label: 'OK',
      //           onClick: () => {}
      //         }
      //       ]
      //     })
      //   })
      // }
      // })
      .catch(e => {
        console.log('e =', e)
        // this.props.dispatch(toast.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      })
  }
  handleChangeRowsPerPage = (value) => {
    this.setState({
      rowsPerPage: value,
    });
  };

  handleChangePage = (value) => {
    this.setState({
      page: value,
    });
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  setPagination = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };
  render () {
    let { reports } = this.props

    return (
      <div>
         {/* <Style>{`
            .custom-tab-button {
              min-height: 29px;
              border-radius: 6px 6px 0 0;
              margin: 0 2px;
              background: ${this.props.themecolors["600"]};
              color: ${this.props.themecolors["themeInverse"]};
            }
            .custom-tabs {
              min-height: 30px;
              margin: 10px 6px 0;
            }

            .custom-tab-button-selected {
              background: ${this.props.themecolors["500"]};
              color: ${this.props.themecolors["themeInverse"]};
            }`}</Style> */}
        <Grid
          container
          spacing={4}
          alignItems='center'
          style={{ marginBottom: 8 }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <DateRangePicker
              selectedDate={this.state.selectedDate}
              label={
                this.props.translate('reportFrom') +
                ' ' +
                this.props.translate('reportTo')
              }
              onEvent={this.showDates}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Button
              variant='contained'
              disabled={!this.state.isVisableBtn}
              onClick={() => this.showStatisticsRecords()}
            >
              {' '}
              Show
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
                <h3 style={{ margin: 0 }}>
                  {this.props.translate("statisticsTitle")}
                </h3>
              </Grid>
        {reports.length > 0 && (
          <div style={{backgroundColor:"#fff"}}>
          <Table
            rows={reports || []}
            // rowsPerPage={10}
            themecolors={this.props.themecolors}
            // title='Statistics'
            // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            pagination={this.setPagination( reports || [] )}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPage={this.state?.rowsPerPage}
            handleChangePage={this.handleChangePage}
            rowDefinition={[
              {
                id: 'captureTime',
                numeric: false,
                disablePadding: false,
                label: 'Capture Time'
              },
              {
                id: 'activeUsers',
                numeric: false,
                disablePadding: false,
                label: 'Active Users'
              },
              {
                id: 'activeDevices',
                numeric: false,
                disablePadding: false,
                label: 'Active Devices'
              },
              {
                id: 'messagesReceived',
                numeric: false,
                disablePadding: false,
                label: 'Messages Received'
              },
              {
                id: 'requests',
                numeric: false,
                disablePadding: false,
                label: 'Requests'
              },
              {
                id: 'messagesStored',
                numeric: false,
                disablePadding: false,
                label: 'Messages Stored'
              },
              {
                id: 'mailSent',
                numeric: false,
                disablePadding: false,
                label: 'Mail Sent'
              },
              {
                id: 'smsSent',
                numeric: false,
                disablePadding: false,
                label: 'SMS Sent'
              },
              {
                id: 'geocoderRequests',
                numeric: false,
                disablePadding: false,
                label: 'Geocoder Requests'
              },
              {
                id: 'geolocationRequests',
                numeric: false,
                disablePadding: false,
                label: 'Geolocation Requests'
              }
            ]}
          />
          </div>
        )}
      </div>
    )
  }
}

const mapState = state => ({
  reports: state.reports,
  themecolors: state.themecolors
})
const mapStateToProps = connect(mapState)
export const ShowReports = mapStateToProps((showReports))

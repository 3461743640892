import merge from "lodash/merge"

export default function () {

    let defaultConfig = {

        chart: {
            backgroundColor: 'none',
            type: 'pie',
        },
        title: {
            text: '',
            style:{
                color: '#fff',
                fontSize: '16px',
                fontFamily: 'Roboto'
            },
        },
        tooltip: {
            backgroundColor: 'rgba(40, 40, 40, 1)',
            borderColor: 'none',
            borderRadius: 20,
            shadow: false,
            padding: 15,
            style:{
                color: '#f4f4f4',
                fontSize: '16px',
                fontFamily: 'Roboto'
            },
        },
       
        plotOptions: {
            pie: {
                size: 150,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        credits: {
            enabled: false
        },
    };

    return {
        getDefaultConfig: function () {
            return  Object.assign({}, defaultConfig) //angular.copy(defaultConfig)
        },

        initializeWithOptions: function (options) {
            return merge(Object.assign({}, defaultConfig), options)
        }
    }
}

import React, { Component } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../../../common/TextField";
import { withStyles } from "@mui/styles";
import Button from "../../../common/Button";
import Grid from "@mui/material/Grid";
import { updateAccount } from "../../../../Actions/Accounts";
import { ServerInfo } from "../../../../Actions/serverSetting";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});


class garages extends Component {
  constructor() {
    super();
    this.state = {
      errorMgs: false,
      c_garageTarget: {
        PE:"",
        PCVC:"",
        PAC:"",
        DEX:"",
        TRISTAR:"",
        JV:"",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);

    // this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
 
  componentWillMount() {
    // console.log("this=====",this.props?.selectedAccount?.attributes);
    this.setState({
      c_garageTarget: this.props?.selectedAccount?.attributes?.c_garageTarget || {}
    })
    
    }

  formSubmit() {

    const payload = {
      ...this.props.selectedAccount,
      attributes: {
        ...this.props.selectedAccount.attributes,
        c_garageTarget: this.state.c_garageTarget,
      },
    };


    fetch(`api/accounts/${this.props.selectAccountId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...payload,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
                toast.success( this.props.translate('garagesUpdatedSuccessfully'))
              this.setState({
                errorMgs: false,
              });
             this.props.dispatch(updateAccount(res.data))

              if (this.props.selectedAccount.id === 1) {
                this.props.dispatch(ServerInfo(res.data));
              }
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                toast.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
        } else {
          throw response;
        }
      })
      .catch((e) => {
        this.props.dispatch(
          toast.error({
            message: this.props.translate("somethingWentWrong"),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      });
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const value = parseInt(target.value);
    this.setState({
      c_garageTarget: {
        ...this.state.c_garageTarget,
        [name]: value,
      },
    });
  };

  // checkRequiredFields() {
  //   let { termsEnglish, termsArabic } = this.state.form;
  //   if (termsEnglish.trim().length > 0 && termsArabic.trim().length > 0) {
  //     this.setState({
  //       errorMgs: false,
  //     });
  //   } else {
  //     this.setState({
  //       errorMgs: true,
  //     });
  //   }
  // }

  render() {
 
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id="PE"
              error={this.state.errorMgs}
              label={this.props.translate("PE")}
              type="number"
              placeholder=""
              value={this.state.c_garageTarget.PE || ""}
              onChange={this.handleChange("PE")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id="PCVC"
              error={this.state.errorMgs}
              label={this.props.translate("PCVC")}
              type="number"
              placeholder=""
              value={this.state.c_garageTarget.PCVC || ""}
              onChange={this.handleChange("PCVC")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id="PAC"
              error={this.state.errorMgs}
              label={this.props.translate("PAC")}
              type="number"
              placeholder=""
              value={this.state.c_garageTarget.PAC || ""}
              onChange={this.handleChange("PAC")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id="DEX"
              error={this.state.errorMgs}
              label={this.props.translate("DEX")}
              type="number"
              placeholder=""
              value={this.state.c_garageTarget.DEX || ""}
              onChange={this.handleChange("DEX")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id="TRISTAR"
              error={this.state.errorMgs}
              label={this.props.translate("TRISTAR")}
              type="number"
              placeholder=""
              value={this.state.c_garageTarget.TRISTAR || ""}
              onChange={this.handleChange("TRISTAR")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <TextField
              id="JV"
              error={this.state.errorMgs}
              label={this.props.translate("JV")}
              type="number"
              placeholder=""
              value={this.state.c_garageTarget.JV || ""}
              onChange={this.handleChange("JV")}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>

        <br />

        <Button
          variant="contained"
          style={{ marginLeft: 8 }}
          // disabled={!this.state.isVisableBtn}
          onClick={() => this.formSubmit()}
        >
          {this.props.translate("sharedSave")}
        </Button>
      </div>
    );
  }
}
const Garages = withStyles(styles);
export default Garages(garages);

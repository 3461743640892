import React, { Component, Fragment } from "react";
import "./index.scss";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import { UsersShortList } from "./../../Components/Users/shortList";
import { RoleShortList } from "./../../Components/RoleManagement/shortList";
import { ContractShortList } from "./../../Components/ContractManagment/shortList";
import InvoiceFilter from "./../../Components/Invoice/invoiceFilter";
import DeliveryNoteFilter from "./../../Components/DeliveryNote/deliveryNoteFilter";
import BulkInvoiceFilter from "./../../Components/Invoice/BulkInvoiceFilter";

import PaymentFilter from "./../../Components/NewPayment/PaymentFilter";
import ReportsFilter from "../../Components/Reports/ReportsFilter";
import TriggersFilter from "../../Components/Triggers/TriggersFilter";
import { sortDevices, searchDevices } from "./../../Actions/Devices";
import { sortUsers, searchUser } from "./../../Actions/Users";
import { sortRoles, searchRole } from "./../../Actions/Roles";
import MaterialIcon from "material-icons-react";
import Button from "./../../Components/common/Button";
import TextField from "./../../Components/common/TextField";
import { library } from "@fortawesome/fontawesome-svg-core";
import TemplateGeneratorSidebar from "./Components/TemplateGenerator";
import { checkPrivileges, isOwnerOrDealer } from "../../Helpers";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Scrollbar from "react-scrollbars-custom";
import CreditDebitFilter from "./../../Components/CreditDebitNotes/CreditDebitFilter";
import MissingItemFilter from "./../../Components/MissingItems/MissingItemsFilter";
import SimahFilter from "./../../Components/Simah/SimahFilter";

import { ReportAndNotificationFilter } from "./../../Components/ScheduleRepotsNotifications/ReportAndNotificationsSidebar";

import {
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import MissedOpportunitiesFilter from "../../Components/MissedOpportunities/MissedOpportunitiesFilter";
// import ReportAndNotificationFilter from "../../Components/ScheduleRepotsNotifications/ReportAndNotificationsSidebar";
library.add([
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle,
]);

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      showStats: false,
    };
    this.collapseSidebar = this.collapseSidebar.bind(this);
    this.collapseSidebarWithButton = this.collapseSidebarWithButton.bind(this);
  }

  collapseSidebar() {
    let w = window.innerWidth;
    if (w <= 959) {
      let v = this.state.collapse;
      this.setState({ collapse: !v }, () => {
        if (!v) {
          this.props.sidebarClose("sidebar-collapse");
        } else {
          this.props.sidebarClose("");
        }
      });
    }
  }
  collapseSidebarWithButton() {
    let v = this.state.collapse;
    this.setState({ collapse: !v }, () => {
      if (!v) {
        this.props.sidebarClose("sidebar-collapse");
      } else {
        this.props.sidebarClose("");
      }
    });
  }
  componentWillUnmount() {
    this.props.dispatch(searchUser({ user: "" }));
  }

  render() {
    if (this.props.noSidebar) {
      return null;
    } else {
      const collpaseIconStyle = {
        left: "100%",
        position: "absolute",
        padding: "10px 3px 7px",
        top: "50%",
        marginLeft: 0,
        background: this.props.themecolors["500"],
        color: this.props.themecolors["themeInverse"],
        borderRadius: "0 5px 5px 0",
        boxShadow: "0 0 15px rgba(0,0,0,0.2)",
      };
      return (
        <div
          className={
            this.props.SidebarToggle
              ? "fms-sidebar-wrapper2"
              : "fms-sidebar-wrapper"
          }
        >
          {!this.state.collapse && (
            <a
              href="javascript:void(0)"
              style={{ ...collpaseIconStyle }}
              onClick={(e) => this.collapseSidebarWithButton()}
            >
              <ArrowLeftIcon fontSize="large" />
            </a>
          )}
          {this.state.collapse && (
            <a
              href="javascript:void(0)"
              style={{ ...collpaseIconStyle }}
              onClick={(e) => this.collapseSidebarWithButton()}
            >
              <ArrowRightIcon fontSize="large" />
            </a>
          )}
          <SidebarInner
            collapseSidebar={this.collapseSidebar}
            {...this.props}
          />
        </div>
      );
    }
  }
}
class SidebarInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLimit: true,
      searchAccount: "",
      showStats: true,
    };

    this.searchItem = this.searchItem.bind(this);
    this.sortByName = this.sortByName.bind(this);
    this.toggleFilterForm = this.toggleFilterForm.bind(this);
  }

  toggleFilterForm() {
    this.setState({ showStats: !this.state.showStats });
  }

  sortByName() {
    if (this.props.location.pathname.indexOf("/roleManagement") !== -1) {
      this.props.dispatch(sortRoles({ sort: this.state.sort || "DESC" }));
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    } else if (
      this.props.logInUser &&
      isOwnerOrDealer(this.props.logInUser.userType)
    ) {
      this.props.dispatch(sortUsers({ sort: this.state.sort || "DESC" }));
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    } else {
      if (this.props.location.pathname === "/users") {
        this.props.dispatch(sortUsers({ sort: this.state.sort || "DESC" }));
        this.setState({
          sort: this.state.sort === "ASC" ? "DESC" : "ASC",
        });
      } else if (this.props.location.pathname === "/trackes") {
        this.props.dispatch(sortDevices({ sort: this.state.sort || "DESC" }));
        this.setState({
          sort: this.state.sort === "ASC" ? "DESC" : "ASC",
        });
      }
    }
  }

  searchItem(event) {
    if (this.props.location.pathname.indexOf("/roleManagement") !== -1) {
      this.props.dispatch(searchRole({ role: event.target.value }));
    } else if (this.props.location.pathname === "/contractManagement") {
      this.setState({ searchAccount: event.target.value });
    } else if (this.props.location.pathname === "/new_payment") {
      this.setState({ searchAccount: event.target.value });
    } else if (this.props.location.pathname === "/invoice") {
      this.setState({ searchAccount: event.target.value });
    }
    else if (this.props.location.pathname === "/delivery-note") {
      this.setState({ searchAccount: event.target.value });
    } 
    else if (this.props.location.pathname === "/triggers") {
      this.setState({ searchAccount: event.target.value });
    }
    else if (this.props.location.pathname === "/reports") {
      this.setState({ searchAccount: event.target.value });
    } else if (
      this.props.logInUser &&
      isOwnerOrDealer(this.props.logInUser.userType)
    ) {
      this.props.dispatch(searchUser({ user: event.target.value }));
    } else {
      if (this.props.location.pathname === "/trackes") {
        this.props.dispatch(searchDevices({ device: event.target.value }));
      } else if (this.props.location.pathname === "/users") {
        this.props.dispatch(searchUser({ user: event.target.value }));
      }
    }
  }

  addDevices() {
    this.setState({
      isPopupVisable: !this.state.isPopupVisable,
    });
  }

  checkUserLimit = () => {
    if (
      this.props.logInUser &&
      this.state.userLimit &&
      this.props.logInUser.userLimit !== -1
    ) {
      if (
        this.props.users &&
        this.props.users.length === this.props.logInUser.userLimit
      ) {
        this.setState({
          userLimit: false,
        });
      }
    } else if (
      this.props.users &&
      this.props.users.length < this.props.logInUser.userLimit
    ) {
      this.setState({
        userLimit: true,
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }

  render() {
    if (this.props.location.pathname.indexOf("/units") !== -1) {
      return renderSideBarForOwner(
        "My units",
        this.state,
        this.props,
        this.searchItem,
        this.sortByName,
        "units",
        "deviceCreate",
        this.props.deviceLimit
      );
    }
    if (this.props.location.pathname === "/templateGenerator") {
      return (
        <TemplateGeneratorSidebar
          {...this.props}
          collapseSidebar={this.props.collapseSidebar}
        />
      );
    } else if (this.props.location.pathname.indexOf("/users") !== -1) {
      return (
        <aside className="fms-sidebar">
          <div className="fms-sidebar-monitoring">
            {checkPrivileges("user") && (
              <Fragment>
                <div className="section-head clearfix">
                  <TextField
                    label="Search User"
                    type="search"
                    placeholder="exp: ID, Name"
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className="section-head clearfix">
                  <ul className="filter-row clearfix pull-right">
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={this.sortByName}
                        title="Sort by name"
                      >
                        <MaterialIcon
                          size="18"
                          icon="sort_by_alpha"
                          color="currentColor"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
            <div className="sidebar-devices-list">
              <UsersShortList
                {...this.state}
                match={this.props.match}
                selecteItem={this.props.selecteItem}
              />
            </div>
          </div>
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/groups") !== -1) {
      return renderSideBarForOwner(
        "My groups",
        this.state,
        this.props,
        this.searchItem,
        this.sortByName,
        "groups",
        "groupCreate",
        true
      );
    } else if (this.props.location.pathname.indexOf("/roleManagement") !== -1) {
      return (
        <aside className="fms-sidebar">
          <div className="fms-sidebar-monitoring">
            {checkPrivileges("role") && (
              <Fragment>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                  }}
                  className="headerDiv"
                >
                  <div className="searchBar">
                    <TextField
                      label={this.props.translate("searchRole")}
                      type="search"
                      placeholder="exp: ID, Name"
                      value={this.state.search}
                      onChange={this.searchItem}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                  {/* <ul className='createBtn'> */}
                  {checkPrivileges("roleCreate") && (
                    // <li className='filter-nav'>
                    <Button size="small" onClick={() => this.props.addItem()}>
                      {" "}
                      {this.props.translate("create")}
                    </Button>
                    // {/* </li> */}
                  )}
                  {/* </ul> */}
                </div>
                <div className="section-head clearfix">
                  <ul className="filter-row clearfix pull-right">
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={this.sortByName}
                        title="Sort by name"
                      >
                        <MaterialIcon
                          size="18"
                          icon="sort_by_alpha"
                          color="currentColor"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )}
            <div className="sidebar-devices-list">
                <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "550px",
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <RoleShortList
                      {...this.state}
                      match={this.props.match}
                      selecteItem={this.props.selecteItem}
                      removedItem={this.props.removedItem}
                      editItem={this.props.editItem}
                      pathName={"roleManagement"}
                    />
              </Scrollbar>

            </div>
          </div>
        </aside>
      );
    } else if (
      this.props.location.pathname.indexOf("/contractManagement") !== -1
    ) {
      return (
        <aside className="fms-sidebar">
          <Scrollbar>
            <div className="fms-sidebar-monitoring">
              {checkPrivileges("role") && (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                    }}
                    className="headerDiv"
                  >
                    <div className="searchBarContractManagment">
                      <TextField
                        label={this.props.translate("searchAccount")}
                        type="search"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={this.searchItem}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                  </div>
                </Fragment>
              )}
              <div className="sidebar-devices-list">
                <ContractShortList
                  {...this.state}
                  match={this.props.match}
                  selecteItem={this.props.selecteItem}
                  pathName={"contractManagement"}
                  searchAccount={this.state.searchAccount}
                />
              </div>
            </div>
          </Scrollbar>
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/invoice") !== -1) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            {checkPrivileges("role") && (
              <Fragment>
                <div style={{ padding: 13 }}>
                  <h3
                    style={{ display: "flex", alignItems: "center", margin: 0 }}
                  >
                    <span>{this.props.translate("sharedInvoice")}</span>
                    {/* {checkPrivileges('notification') ? ( */}
                    {/* <Tooltip title={this.props.translate("invoiceFilter")}>
                      <span style={{ marginLeft: "auto" }}>
                        <Button
                          style={{ minWidth: "inherit" }}
                          color="inherit"
                          disabled
                          //  disabled={  this.props.value ==="maintenance" && 
                          //             checkPrivileges("invoiceBulkInvoiceRegenerate")
                          //              ? "" : "disabled"
                                      
                          //             }
                          onClick={() => { 
                            this.toggleFilterForm();
                            // this.props.navigate("/invoice");
                          }}
                        >
                          {!this.state.showStats ? (
                            <>
                              Show Filter
                            </>
                          ) : (
                            <>
                              Bulk Reg
                            </>
                          )}
                        </Button>
                      </span>
                    </Tooltip> */}

                    {/* ) : null} */}

                    {/* <span style={{paddingRight:10}}>
                            <ExportImportMenu
                              fileName = 'services/subtypes'
                              // category
                              // checkPrivilege = 'serviceCreate'
                              // data = {this.state?.expense || []}
                              themecolors = {this.props.themecolors}
                              // expensetypeId={this.props?.selectedServiceTypeId ||''}
                              // selectedAccount={this.state?.selectedAccount?.contractNumber || ''}
                              // fetchAccountServices={this.fetchAccountServices}
                            />
                        </span> */}
                  </h3>
                </div>
              </Fragment>
            )}
            <div className="sidebar-devices-list">
              {/* {!this.state.showStats ?
              <>
                 <div
                   style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center'
                          }}
                     className='headerDiv'      
                    >                                
                    <div className='searchBarContractManagment'>
                      <TextField
                        label={this.props.translate('searchAccount')}
                        type='search'
                        placeholder='Search'
                        value={this.state.search}
                        onChange={this.searchItem}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                         
                    </div>
                  </div>
              <InvoiceShortList
                {...this.state}
                match={this.props.match}
                selecteItem={this.props.selecteItem}
                pathName={'invoice'}
                searchAccount={this.state.searchAccount}
              />
              </>
              :
              <> */}
              {this.state.showStats ? (
                <InvoiceFilter
                  // {...this.state}
                  showStats={this.state.showStats}
                  {...this.props}
                  // contractType={this.props.contractType}
                  downloadInvoices={this.props.downloadInvoices}
                  selecteItem={this.props.selecteItem}
                  invoicesData={this.props.invoicesData}
                  selecteFilters={this.props.selecteFilters}
                  translate={this.props.translate}
                  loader={this.props.loader}
                />
              ) : (
                <BulkInvoiceFilter // {...this.state}
                  showStats={this.state.showStats}
                  {...this.props}
                  contractType={this.props.contractType}
                  regenerateInvoices={this.props.regenerateInvoices}
                  downloadInvoices={this.props.downloadInvoices}
                  selecteItem={this.props.selecteItem}
                  invoicesData={this.props.invoicesData}
                  selecteFilters={this.props.selecteFilters}
                  translate={this.props.translate}
                  loader={this.props.loader}
                />
              )}
              {/* </>
              } */}
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    } 
    else if (this.props.location.pathname.indexOf("/delivery-note") !== -1) {
      return (
        <aside className="fms-sidebar">
          <div className="fms-sidebar-monitoring">
            {checkPrivileges("role") && (
              <Fragment>
                <div style={{ padding: 13 }}>
                  <h3
                    style={{ display: "flex", alignItems: "center", margin: 0 }}
                  >
                    <span>{this.props.translate("deliveryNotes")}</span>
                  </h3>
                </div>
              </Fragment>
            )}
            <div className="sidebar-devices-list">
              {this.state.showStats ? (
                <DeliveryNoteFilter
                  // {...this.state}
                  showStats={this.state.showStats}
                  {...this.props}
                  // contractType={this.props.contractType}
                  downloadInvoices={this.props.downloadInvoices}
                  selecteItem={this.props.selecteItem}
                  invoicesData={this.props.invoicesData}
                  selecteFilters={this.props.selecteFilters}
                  translate={this.props.translate}
                  loader={this.props.loader}
                />
              ) : (
                <BulkInvoiceFilter // {...this.state}
                  showStats={this.state.showStats}
                  {...this.props}
                  contractType={this.props.contractType}
                  regenerateInvoices={this.props.regenerateInvoices}
                  downloadInvoices={this.props.downloadInvoices}
                  selecteItem={this.props.selecteItem}
                  invoicesData={this.props.invoicesData}
                  selecteFilters={this.props.selecteFilters}
                  translate={this.props.translate}
                  loader={this.props.loader}
                />
              )}
            </div>
          </div>
        </aside>
      );
    } 
    else if (this.props.location.pathname.indexOf("/new_payment") !== -1) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            {checkPrivileges("role") && (
              <Fragment>
                {/* <div style={{ padding: 13 }}>
            <h3  style={{ display: 'flex', alignItems: 'center', margin: 0 }} >
                <span>{this.props.translate('sharedPayment')}</span>
                  <Tooltip
                      title={this.props.translate('invoiceFilter')}
                    >
                      <span style={{ marginLeft: 'auto'}}>
                      <Button
                      style={{minWidth: 'inherit' }}
                        color='inherit'
                        onClick={()=>{this.toggleFilterForm(); this.props.navigate('/new_payment')}}
                        // onClick={this.toggleFilterForm}
                      >
                        {!this.state.showStats ? <FilterIcon style={{ fontSize: 18 }} /> : <CloseIcon style={{ fontSize: 18 }} />}
                      </Button></span>
                    </Tooltip>


                        <span style={{paddingRight:10}}>
                            <ExportImportMenu
                              fileName = 'payments'
                              // category
                              // checkPrivilege = 'serviceCreate'
                              // data = {this.state?.expense || []}
                              themecolors = {this.props.themecolors}
                              // expensetypeId={this.props?.selectedServiceTypeId ||''}
                              // selectedAccount={this.state?.selectedAccount?.contractNumber || ''}
                              // fetchAccountServices={this.fetchAccountServices}
                            />
                        </span>
                      </h3>
                    </div>             */}
              </Fragment>
            )}
            <div className="sidebar-devices-list">
              {/* {!this.state.showStats ?
                  <>
                  <div
                   style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center'
                          }}
                     className='headerDiv'      
                    >                                
                    <div className='searchBarContractManagment'>
                      <TextField
                        label={this.props.translate('searchAccount')}
                        type='search'
                        placeholder='Search'
                        value={this.state.search}
                        onChange={this.searchItem}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                         
                    </div>

                  </div>
              <PaymentShortList
                {...this.state}
                match={this.props.match}
                selecteItem={this.props.selecteItem}
                pathName={'new_payment'}
                searchAccount={this.state.searchAccount}
              />
            </>
              :<> */}
              <PaymentFilter
                {...this.props}
                downloadInvoices={this.props.downloadInvoices}
                paymentFilter={this.props.paymentFilter}
                translate={this.props.translate}
                loader={this.props.loader}
              />

              {/* </>} */}
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/schedule-Reports-And-Notifications") !== -1) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <ReportAndNotificationFilter
                {...this.state}
                {...this.props}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    } 
    else if (this.props.location.pathname.indexOf("/triggers") !== -1) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <TriggersFilter
                {...this.state}
                {...this.props}
                form={this.props.form}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    }
    
    else if (this.props.location.pathname.indexOf("/reports") !== -1) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <ReportsFilter
                {...this.state}
                {...this.props}
                form={this.props.form}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    } else if (
      this.props.location.pathname.indexOf("/credit_debit_notes") !== -1
    ) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <CreditDebitFilter
                {...this.props}
                downloadCreditDebit={this.props.downloadCreditDebit}
                creditDebitFilter={this.props.creditDebitFilter}
                translate={this.props.translate}
                loader={this.props.loader}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    }
    else if (
      this.props.location.pathname.indexOf("/missing-items") !== -1
    ) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <MissingItemFilter
                {...this.props}
                accounts={this.props.accounts}
                getMissingItems={this.props.getMissingItems}
                // downloadCreditDebit={this.props.downloadCreditDebit}
                // creditDebitFilter={this.props.creditDebitFilter}
                translate={this.props.translate}
                // loader={this.props.loader}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    } 
    else if (
      this.props.location.pathname.indexOf("/missed-opportunities") !== -1
    ) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <MissedOpportunitiesFilter
                {...this.props}
                accounts={this.props.accounts}
                getMissingItems={this.props.getMissingItems}
                // downloadCreditDebit={this.props.downloadCreditDebit}
                // creditDebitFilter={this.props.creditDebitFilter}
                translate={this.props.translate}
                // loader={this.props.loader}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    }
    else if (
      this.props.location.pathname.indexOf("/simah") !== -1
    ) {
      return (
        <aside className="fms-sidebar">
          {/* <Scrollbar> */}

          <div className="fms-sidebar-monitoring">
            <div className="sidebar-devices-list">
              <SimahFilter
                translate={this.props.translate}
                {...this.props}
              />
            </div>
          </div>
          {/* </Scrollbar> */}
        </aside>
      );
    }
    


    else {
      return "";
    }
  }
}
const mapState = (state) => ({
  devices: state.devices,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(Sidebar);

function renderSideBarForOwner(
  renderIdem,
  state,
  props,
  searchItem,
  sortByName,
  pathName,
  hasAccess,
  limit
) {
  return (
    <aside className="fms-sidebar">
      <div className="fms-sidebar-monitoring">
        {checkPrivileges("user") && (
          <Fragment>
            <div className="section-head clearfix">
              <TextField
                label="Search User"
                type="search"
                placeholder="exp: ID, Name"
                value={state.search}
                onChange={searchItem}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </div>
            <div className="section-head clearfix">
              <ul className="filter-row pull-left">
                <li style={{ marginLeft: 10 }}>
                  <Button
                    size="small"
                    onClick={() => {
                      props.showMyUsers();
                      props.history.push(`/${pathName}`);
                    }}
                  >
                    {props.translate(renderIdem)}
                  </Button>
                </li>
              </ul>
              <ul className="filter-row clearfix pull-right">
                <li>
                  {" "}
                  <a
                    href="javascript:void(0)"
                    className="sidebar-collapse-button"
                    onClick={() => {
                      props.backToPrvUsers();
                    }}
                    title="back to prv stage"
                  >
                    <img src="/assets/images/common/arrow.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    onClick={sortByName}
                    title="Sort by name"
                  >
                    <MaterialIcon
                      size="18"
                      icon="sort_by_alpha"
                      color="currentColor"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </Fragment>
        )}
        <div className="sidebar-devices-list">
          <UsersShortList
            {...state}
            match={props.match}
            selecteItem={props.selecteItem}
            pathName={pathName}
            translate={props.translate}
            showNextChild={props.showNextChild}
            removedItem={props.removedItem}
            editItem={props.editItem}
            assignUnits={props.assignUnits}
            assignGroup={props.assignGroup}
          />
        </div>
      </div>
    </aside>
  );
}
// function renderSideBarForDealer(renderIdem, state, props, searchItem, sortByName, pathName, hasAccess) {
//   return (
//     <aside className="fms-sidebar">
//       <div className="fms-sidebar-monitoring">
//         {checkPrivileges('user') &&
//           <Fragment>
//             <div className="section-head clearfix">
//               <TextField
//                 label="Search User"
//                 type="search"
//                 placeholder="exp: ID, Name"
//                 value={state.search}
//                 onChange={searchItem}
//                 variant="outlined"
//                 margin='dense'
//                 fullWidth
//               />
//             </div>
//             <div className="section-head clearfix">
//               <ul className="filter-row pull-left">
//                 {checkPrivileges(hasAccess) &&
//                   <li className="filter-nav">
//                     <Button size="small"
//                       onClick={() => props.addItem()}> {props.translate("Create")}
//                     </Button>
//                   </li>}
//                 <li style={{ marginLeft: 10 }}>
//                   <Button size="small"
//                     onClick={() => { props.showMyUsers(); props.history.push(`/${pathName}`) }}>
//                     {props.translate(renderIdem)}
//                   </Button>
//                 </li>
//               </ul>
//               <ul className="filter-row clearfix pull-right">
//                 <li>
//                   <a href="javascript:void(0)" onClick={sortByName} title="Sort by name"><MaterialIcon size="18" icon='sort_by_alpha' color="currentColor" /></a>
//                 </li>
//               </ul>
//             </div>
//           </Fragment>}
//         <div className="sidebar-devices-list" >
//           <UsersShortList {...state} match={props.match}
//             selecteItem={props.selecteItem}
//             pathName={pathName}
//           />
//         </div>
//       </div>
//     </aside>
//   )

// }

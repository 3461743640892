import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '../../Components/common/TableMultiCheckbox'
import {
  checkUsersOfUnits1,
  checkPrivileges,
  prepareUserForLogin,
  PaginationConfig
} from '../../Helpers'
import LoginAsUser from '../../Helpers/loginAsUser'
import Loader from '../../Layout/Loader'
import { Grid } from '@mui/material'
import TextField from '../../Components/common/TextField'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source
class User extends Component {
  constructor () {
    super()
    this.state = {
      usersRecived: false,
      selectedTemplateUsersList: '',
      accessUserList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: ''
    }
    this.userToTemplatepermission = this.userToTemplatepermission.bind(this)
  }
  componentWillMount () {
    this.getMoreUsers()
  }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }
  componentWillReceiveProps (){
      this.getMoreUsers()
    }

  getMoreUsers = () => {
    let { pagination } = this.state
    let { page, pageSize, itemSearch } = pagination
    source = axios.CancelToken.source()

    axios
      .get(
        `api/users/linked?rpTmplId=${this.props.selectItemId}&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: source.token
        }
      )
      .then(res => {
        let response= res.data
        if (response.status === "success") {
          let lastPage = response.data.total / pageSize
          let IsFloate = this.checkFloteNumber(lastPage)
          this.setState({
            pagination: {
              ...this.state.pagination,
              lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
              hasNext: response.data.hasNext,
              total: response.data.total
            },
            selectedTemplateUsersList: prepareUserForLogin(
              checkUsersOfUnits1(response.data.data)
            ),
            usersRecived: true
          })
        }
        else if(response?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(response.statusCode){
          var err = response?.message.split(':')
          err[1] =err[1].replace(')', "")
        
            toast.error(
              this.props.translate(err[1])
          )
        }

      })
      .catch(e => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      })
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => this.getMoreUsers()
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUsers()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreUsers()
    )
  }
  userToTemplatepermission (event, item) {
    let option = 'DELETE'
    if (event) {
      option = 'POST'
    }
    let allData = [...this.state.selectedTemplateUsersList]
    let obj = {
      userId: item.id,
      rpTmplId: this.props.selectItemId
    }
    fetch(`/api/permissions`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        response.json().then(res =>{
        if (res.status === "success") {
          if (option === 'POST') {
            item.check = true
            item.indeterminate = false
            allData.map(dt => {
              if (dt.id === item.userId) {
                dt.check = true
                dt.indeterminate = false
              }
              return null
            })
            this.setState({
              selectedTemplateUsersList: allData
            })
           
              toast.success(
                this.props.translate('templateIsAssignedSuccessfully')
            )
          } else {
            item.check = false
            allData.map(dt => {
              if (dt.id === item.userId) {
                dt.check = false
              }
              return null
            })
            this.setState({
              selectedTemplateUsersList: allData
            })
           
              toast.success(
               this.props.translate(
                  'templateIsUnAssignedSuccessfully'
                )
            )
          }
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
            var err = res?.message.split(':')
            var err1 = err[1].split('&#')
           
              toast.error(
                 this.props.translate(err1[0])
            )
          }
        else {
          throw res
         }
        })
      })
      .catch(err =>{
      
            toast.error(
              this.props.translate('somthingWentWrongMessage')
        )
       })
      // .catch(e => {
      //   if(e && e.text){
      //   e.text().then(err => {
      //     if (err.includes('You cannot unlink Template owner')) {
      //       this.props.dispatch(
      //         toast.error({
      //           message: this.props.translate('youCannotUnlinkUser'),
      //           autoDismiss: 5,
      //           location: this.props.location
      //         })
      //       )
      //     } else if (err.includes('Not enough privileges')) {
      //       this.props.dispatch(
      //         toast.error({
      //           message: this.props.translate('lackOfPrivileges'),
      //           autoDismiss: 5,
      //           location: this.props.location
      //         })
      //       )
      //     } else {
      //       this.props.dispatch(
      //         toast.error({
      //           message: this.props.translate('somthingWentWrongMessage'),
      //           autoDismiss: 5,
      //           location: this.props.location
      //         })
      //       )
      //     }
      //   })
      // }
      // })
  }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedTemplateUsersList } = this.state
    selectedTemplateUsersList.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
    })
    this.setState({
      selectedTemplateUsersList
    })
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
        {this.state.usersRecived ? (<>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={4}>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div>
                <TextField
                  fullWidth
                  label={this.props.translate('search')}
                  onChange={e => this.SearchItem(e.target.value)}
                  />
              </div>
            </Grid>
          </Grid>
          <div styles={{ postion: 'relative' }}>
            <Table
              rows={this.state.selectedTemplateUsersList || []}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              // SearchItem={this.SearchItem}
              loginAsUser={this.loginAsUser}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              rowsPerPage={10}
              ServerSetting={this.props.ServerSetting}
              isEditable={false}
              showCheckbox={true}
              translate={this.props.translate}
              themecolors={this.props.themecolors}
              checkHandleChange={this.userToTemplatepermission}
              canAssign={checkPrivileges('userLinkDevice')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              //title={this.props.translate('users')}
              // searchable
              rowDefinition={rowDefinitionR(this.props.translate)}
            />{' '}
          </div>
          </>
        ) : (
          // ) : (
          //   <div style={{ textAlign: 'center' }}>
          //     <h4> {this.props.translate('noUserFound')}</h4>
          //   </div>
          // )
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(User)

const rowDefinitionR = translate => {
  if (localStorage.getItem('adminToken')) {
    return [
      {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parentName')
      },
      {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: translate('accountName')
      }
    ]
  } else {
    return [
      {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parentName')
      },
      {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: translate('accountName')
      },
      {
        id: 'loginAsUser',
        numeric: false,
        disablePadding: false,
        label: translate('loginAsUser')
      }
    ]
  }
}

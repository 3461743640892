import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";
import { Grid, Paper, Tooltip } from "@mui/material";
import SingleSelect from "../common/SingleSelect";
import "react-toastify/dist/ReactToastify.css";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "../common/TextField";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../Layout/Loader";
// import { TablePaginationStyle } from "../common/TableServices";
import Table from "../../Components/common/TableServices";

const AdjustmentTypes = [
  {
    key: "fifo",
    name: "FIFO",
  },
  {
    key: "manual",
    name: "MANUAL",
  },
];
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class settleAmount extends Component {
  constructor() {
    super();
    this.state = {
      resultMessage: "",
      value: [],
    };
  }
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };
  render() {
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableSettleBtn}
          content={
            <>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <SingleSelect
                    isdiabled
                    array={(this.props && this.props.accounts) || []}
                    async
                    selectName="paymentAccount"
                    isClearable
                    label={this.props.translate("contractNumber")}
                    value={
                      this.props?.paymentAccount && this.props.paymentAccount.id
                        ? {
                            id: this.props.paymentAccount,
                            value: this.props.paymentAccount.contractNumber,
                            label:
                              this.props?.paymentAccount.label ||
                              this.props.paymentAccount.name ||
                              this.props.paymentAccount.contractName,
                          }
                        : ""
                    }
                    handleChange={this.props.handleChange1}
                    canAssign={true}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  sm={1}
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Payment Adjustment from Advance Payments Only.">
                    <InfoIcon />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <TextField
                    id="adjustmentType"
                    select
                    label={this.props.translate("adjustmentType")}
                    value={this.props.formSettleAmount.adjustmentType || ""}
                    onChange={(e) =>
                      this.props.handleChange1("adjustmentType", e)
                    }
                    // SelectProps={{
                    //   MenuProps: {
                    //     className: classes.menu,
                    //   },
                    // }}
                    required
                    margin="dense"
                    fullWidth
                  >
                    {AdjustmentTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="adjustAmount"
                    label={this.props.translate("adjustAmount")}
                    // label="Amount"
                    type="number"
                    placeholder=""
                    value={this.props.formSettleAmount.adjustAmount || ""}
                    // name="adjustAmount"
                    onChange={(e) =>
                      this.props.handleChange1("adjustAmount", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <h3>
                    {this.props.translate("totalInvoiceBalance")} ={" "}
                    {this.props && this.props?.overallInvoiceBalance
                      ? this.props?.overallInvoiceBalance?.toFixed(2)
                      : 0}
                  </h3>
                  <h3>
                    {this.props.translate("creditAmount")} ={" "}
                    {(this.props &&
                      this.props.creditAmountForSettle &&
                      this.props?.creditAmountForSettle?.toFixed(2)) ||
                      0}
                  </h3>
                </Grid>
              </Grid>

              {this.props?.paymentAccount &&
              this.props.formSettleAmount.adjustmentType === "manual" ? (
                <>
                  {!this.props.invoiceLoader ? (
                    <Paper
                      {...this.props}
                      style={{
                        boxShadow: "none",
                        padding: this.props.padding ? 0 : 16,
                        // color: "rgba(0,0,0,0.54)",
                        position: "relative",
                        border: "1px dashed",
                        // marginTop: 10,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div
                            style={{ display: "flex", justifyContent: "right" }}
                          >
                            <TextField
                              fullWidth
                              label={this.props.translate("search")}
                              value={this.props.invoiceSearch}
                              onChange={(e) => this.props.searchInvoices(e)}
                            />
                          </div>
                        </Grid>

                        <Table
                          {...this.props}
                          rows={
                            (this.props.manualPayments &&
                              this.props.manualPayments) ||
                            []
                          }
                          disableHead
                          translate={this.props.translate}
                          // allUsersId={this.props.allUsersId}
                          showCheckbox
                          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                          // handleChangePage={this.handleChangePage}
                          isChecked={this.props.isChecked}
                          rowsPerPage={this.state.rowsPerPage}
                          isEditable={false}
                          // ServerSetting={this.props.ServerSetting}
                          themecolors={this.props.themecolors}
                          // checkHandleChange={this.unitToUserpermission}
                          canAssign
                          canRemove
                          payment
                          rowDefinition={[
                            {
                              id: "invoiceNumber",
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate("InvoiceNo"),
                            },
                            {
                              id: "contractNumber",
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate("contractNumber"),
                            },
                            {
                              id: "invoicebalance",
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate("remainingBalance"),
                            },
                            {
                              id: "salenetamount",
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate("totalAmount"),
                            },
                            // {
                            //   id: "remainingBalance",
                            //   numeric: false,
                            //   disablePadding: false,
                            //   label: this.props.translate("remainingBalance"),
                            // },
                          ]}
                        />
                      </Grid>
                    </Paper>
                  ) : (
                    <Loader component="filter" />
                  )}
                </>
              ) : null}
            </>
          }
        />
      </Fragment>
    );
  }
}

const SettleAmount = withStyles(styles);
export default SettleAmount(settleAmount);

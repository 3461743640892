import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Style from "style-it";
import AddAlertsModal from "./addAlerts";

import moment from "moment";
import ConfirmDialoag from "../common/ConfirmDialoag";

class ScheduleReportsNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  addOption:false,
    };
  }

  render() {
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        <Fragment>
            {this.props.openForm || this.props.editForm ? (
            <AddAlertsModal
              // {...this.state}
              {...this.props}
              title={ this.props.openForm ? 
                      this.props.translate("addAlerts") : 
                      this.props.editForm ?
                      this.props.translate("editAlerts") : null}
              data={this.props.form}
              //   users={this.props.users}
              //   group={group}
              formSubmit={this.props.handleSubmit}
              //   handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}

              handleChange={this.props.handleChange}
              modalControle={this.props.closeForm}
              buttonText={ this.props.openForm ? 
                            this.props.translate("sharedCreate") : 
                            this.props.editForm ?
                            this.props.translate("sharedUpdate") : null}
              isVisableAddBtn={this.props.isVisableAddBtn}
            />
       ) :null} 
         
         {this.props.enableDelete && (
            <ConfirmDialoag
              onCancel={this.props.cancleDelete}
              onOk={this.props.deleteNotification}
              title={this.props.translate("areYouWantToDelete")}
              children={
                this.props.form.type === "arrears" ?
                this.props.translate("Due Overdue Payment Alerts") :
                this.props.form.type === "services" ?
                this.props.translate("Schedule Report : Maintenance Due Overdue") :
                this.props.translate(this.props.form.type) 
                }
            />
          )}
          
        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const ReportsNotificationList = mapStateToProps((ScheduleReportsNotifications));

import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from "@mui/styles";
import Table from "../../../common/tableWithBackEndPagination";
import { PaginationConfig } from "../../../../Helpers";
import Loader from "../../../../Layout/Loader";
import { Grid } from "@mui/material";
import TextField from "../../../common/TextField";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
let source;
class Invoices extends Component {
  constructor() {
    super();
    this.state = {
      invoicesReceived: false,
      selectedInvoiceList: [],
      pagination: { ...PaginationConfig, hasNext: "", pageSize: 10 },
      total: "",
      lastPage: "",
      noRecords: false,
      rowsPerPage: 10,
      pageSize: 10,
      page: 1,
      itemSearch: "",
    };
  }
  componentDidMount() {
    this.getMoreInvoices();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        itemSearch: searchValue,

      },
      () => this.getMoreInvoices()
    );
  };
  getMoreInvoices = () => {
    let api;
    // console.log("=====invoiceType",this.props.invoiceType);
    if (this.props.invoiceType === "leasing") {
      api = `api/leaseinvoices/get?&contractNumber=${this.props.selectedAccount.contractNumber}&page=${this.state.page}&limit=${this.state.pageSize}&invoicenumber=${this.state.itemSearch}`;
    } else if (this.props.invoiceType === "maintenance") {
      api = `api/invoices/get?&contractNumber=${this.props.selectedAccount.contractNumber}&page=${this.state.page}&limit=${this.state.pageSize}&invoicenumber=${this.state.itemSearch}`;
    }
    else if (this.props.invoiceType === "cashCustomer") {
      api = `api/cashinvoices/get?&contractNumber=${this.props.selectedAccount.contractNumber}&page=${this.state.page}&limit=${this.state.pageSize}&invoicenumber=${this.state.itemSearch}`;
    
      // api = `/api/cashinvoices/get?contractNumber=${this.props.selectedAccount.contractNumber}&page=${page}&limit=${pageSize}&search=${searchInvoice}`;
    
    }

    // let api = `/api/users/get?accountId=${this.props.selectItemId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`
    source = axios.CancelToken.source();
    axios
      .get(api, {
        cancelToken: source.token,
      })
      .then((res) => {
        let response = res.data && res.data.data;
        this.setState({
          selectedInvoiceList: response,
          invoicesReceived: true,
        });
      })
      .catch((e) => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      });
  };

  regenerateInvoice = (item, type) => {
      
    let api;
    let invoiceType ;
    if (this.props.invoiceType === "leasing") {
      api = `api/leaseinvoices/${item[0].id}/recompute`
    }else{
      api = `/api/invoices/${item[0].id}/recompute`
    }
    // if(item[0].)
    // let api = ;
    axios({
      method: "GET",
      url: api,
    })
      .then((response) => {
        if (response?.data.status === "success"  ) {
              toast.success(this.props.translate("invoiceRegeneatedSuccessFul"))
          }
          else{
              toast.error(this.props.translate(response?.data?.message))
          }
      })
      .catch((error) => {
        console.log("er =", error);
          toast.error(this.props.translate("somethingWentWrong"))
      });
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ pageSize: rowsPerPage, page: 1 }, () => {
      this.getMoreInvoices();
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      this.getMoreInvoices();
    });
  };



  setPagination = (user) => {
    if (
      this.state.selectedInvoiceList &&
      this.state.selectedInvoiceList.data &&
      this.state.selectedInvoiceList.data.length
    ) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  downloadInvoice = (item, type) => {
    let Accept = type
      ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      : "application/pdf";
    let api =
      this.props.selectedAccount.contractType == 2
        ? `/api/leaseinvoices/export/${item[0].id}`
        : `/api/invoices/export/${item[0].id}`;
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        if (!type) {
          a.setAttribute("download", `invoice.pdf`);
        } else {
          a.setAttribute("download", `invoice.xlsx`);
        }
        document.body.appendChild(a);
        a.href = url;
        a.click();
      })
      .catch((error) => {
        console.log("er =", error);
          toast.error(this.props.translate("somethingWentWrong"))
      });
  };

  render() {
    return (
      <Fragment>
        <Grid container className="breadcrumb-row">
          <Grid item xs={12} sm={3}>
            <h3 style={{ margin: 0 }}>{this.props.translate("Invoices")}</h3>
          </Grid>
          <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
            <div style={{ marginRight: "20px", width: "25%" }}>
              <TextField
                fullWidth
                label={this.props.translate("search")}
                onChange={(e) => this.SearchItem(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        {this.state && this.state.invoicesReceived && !this.state.noRecords ? (
          <>
            <Table
              rows={this.state?.selectedInvoiceList?.data || []}
              translate={this.props.translate}
              pagination={this.setPagination(this.state.selectedInvoiceList)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              rowsPerPage={this.state?.rowsPerPage}
              ServerSetting={this.props.ServerSetting}
              isEditable={true}
              filterDropdown
              themecolors={this.props.themecolors}
              downloadInvoice={this.downloadInvoice}
              regenerateInvoice={this.regenerateInvoice}
              timezone={this.props.logInUser?.attributes?.timezone || "Asia/Riyadh" }
              rowDefinition={
                this.props.selectedAccount.contractType == 1
                  ? rowDefinitionM(this.props.translate)
                  :  this.props.selectedAccount.contractType == 2 ?
                   rowDefinitionL(this.props.translate) :
                  rowDefinitionC(this.props.translate)
              }
              leasing={
                this.props.selectedAccount.contractType == 1 ? false : true
              }
            />
          </>
        ) : !this.state.invoicesReceived && !this.state.noRecords ? (
          <div style={{ textAlign: "center" }}>
            <Loader defaultStyle />
          </div>
        ) : null}
        {this.state.noRecords && (
          <div style={{ textAlign: "center" }}>
            <h4> {this.props.translate("noInvoiceFound")}</h4>
          </div>
        )}
      </Fragment>
    );
  }
}

const enhance = compose(withStyles(styles), connect());

export default enhance(Invoices);

const rowDefinitionC = (translate) => {
  return [
    {
      id: "contractNumber",
      numeric: false,
      disablePadding: false,
      label:translate("contractNumber"),
    },
    {
      id: "invoiceDate",
      numeric: false,
      disablePadding: false,
      label:translate("invoiceDate"),
    },
    {
      id: "saleTotalAmount",
      numeric: false,
      disablePadding: false,
      label: translate("Amount"),
    },
    {
      id: "saleVATAmount",
      numeric: false,
      disablePadding: false,
      label: translate("VAT Amount"),
    },
    {
      id: "saleNetAmount",
      numeric: false,
      disablePadding: false,
      label: translate("Total Amount"),
    },
  ];
};

const rowDefinitionM = (translate) => {
  return [
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: translate("invoiceNumber"),
    },
    {
      id: "invoiceRefNumber",
      numeric: false,
      disablePadding: false,
      label: translate("refNum"),
    },
    {
      id: "plateNumber",
      numeric: false,
      disablePadding: false,
      label: translate("vehicleLicensePlate"),
    },
    {
      id: "serviceDate",
      numeric: false,
      disablePadding: false,
      label: translate("invoiceDate"),
    },
    {
      id: "saleTotalAmount",
      numeric: false,
      disablePadding: false,
      label: translate("Amount"),
    },
    {
      id: "saleVATAmount",
      numeric: false,
      disablePadding: false,
      label: translate("VAT Amount"),
    },
    {
      id: "saleNetAmount",
      numeric: false,
      disablePadding: false,
      label: translate("Total Amount"),
    },
    {
      id: "viewInvoice",
      numeric: false,
      disablePadding: false,
      label: translate("invoice"),
      hide: false,
    },
  ];
};
const rowDefinitionL = (translate) => {
  return [
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: translate("invoiceNumber"),
    },
    {
      id: "invoiceDate",
      numeric: false,
      disablePadding: false,
      label: translate("invoiceDate"),
    },
    {
      id: "saleTotalAmount",
      numeric: false,
      disablePadding: false,
      label: translate("Amount"),
    },
    {
      id: "saleVATAmount",
      numeric: false,
      disablePadding: false,
      label: translate("VAT Amount"),
    },
    {
      id: "saleNetAmount",
      numeric: false,
      disablePadding: false,
      label: translate("Total Amount"),
    },
    {
      id: "viewInvoice",
      numeric: false,
      disablePadding: false,
      label: translate("invoice"),
      hide: false,
    },
  ];
};

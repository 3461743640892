import React, { Component } from "react";
import Loader from "../../Layout/Loader";
import TextField from "../common/TextField";
import {  Grid  } from "@mui/material";
import SingleSelect from "../common/SingleSelect";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import CustomDatePicker from "../common/CustomDatePicker";



const InvoiceOperations = [
  {
    key: 1,
    name: "Trigger DN",
  },
  {
    key: 2,
    name: "Send Invoice & DN to Client",
  },

];



export default class triggersFilter extends Component {
  render() {
    let serverTimeZone 
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZone = this.props.logInUser.attributes.timezone
    }
    else{
      serverTimeZone = "Asia/Riyadh"
    }
    // console.log("res======", this.props.selectedOperation);
    const viewScreen = (
          <form  style={{padding:"10px"}} >
          <h3> {this.props.translate("monthlyInvoices&DnFilter")}</h3>
        <Grid container spacing={2} style={{marginTop:"10px"}}  >
             <Grid item md={12} sm={12} xs={12}>
                   <SingleSelect
                      array={InvoiceOperations || []}
                      async
                      selectName="selectedOperation"
                      // isClearable
                      label={this.props.translate("selectedOperation")}
                      value={
                        this.props.form && this.props.selectedOperation.id
                          ? {
                              id: this.props.selectedOperation.id,
                              value: this.props.selectedOperation.name,
                              label: this.props.selectedOperation.name,
                            }
                          : ""
                      }
                      // value={this.props.selectedOperation || ""}
                      handleChange={this.props.handleChange}
                      canAssign={true}
                    />
            </Grid>

              {this.props.selectedOperation.id === 1 && (
              <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    id="refNum"
                    label={this.props.translate("refNum")}
                    placeholder=""
                    value={this.props.refNum}
                    onChange={(e)=>this.props.handleChange("refNum", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
              <Checkbox 
                    checked={this.props.repost}
                    label={this.props.translate('repost')}
                    value='repost'
                    color='primary'
                    // disabled
                    onChange={(e)=>this.props.handleChange('repost', e)}
                    style={{marginLeft:"5px"}}
                  /> 
              </Grid>


              )}


            <Grid item md={12} sm={12} xs={12}>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="MM/yyyy"
                  required
                  // error={false}
                  margin="dense"
              
                  label={this.props.translate("date")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month"]}
                  error={
                    !this.props?.from
                  }
                  helperText={
                    !this.props?.from
                      ? this.props.translate("enterDate")
                      : ""
                  }
                  value={
                    this.props && this.props && this.props.from
                      ? new Date(`${this.props.from}`)
                      : null
                  }
                  // allowEmpty={true}
                  onChange={(e) => this.props.handleChange("from", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
            </LocalizationProvider> */}

                    <CustomDatePicker
                        format="dd/MM/yyyy"
                        error={false}
                        label={this.props.translate(
                          "date"
                        )}
                        views={["month","year"]}
                        fullWidth={true}  
                        value={
                          this.props &&
                          this.props.from
                            ? 
                                `${this.props.from}`
                            : null
                        }
                        onChange={(e) => this.props.handleChange("from", e)}
                      />
          </Grid>
            <Grid item xs={12}>
          <SingleSelect
                      array={(this.props && this.props.accounts) || []}
                      async
                      selectName="selectedAccount"
                      isClearable
                      label={this.props.translate("selectedAccount")}
                      value={
                        this.props?.selectedAccount &&
                        this.props.selectedAccount.contractNumber
                          ? {
                              id: this.props.selectedAccount,
                              value:
                                this.props.selectedAccount.contractNumber,
                              label: this.props?.selectedAccount.label || this.props?.selectedAccount.name,
                            }
                          : ""
                      }
                      // value={this.props.selectedAccount || ""}
                      handleChange={this.props.handleChange}
                      canAssign={true}
                    />

          </Grid>
          <Grid item xs={12}>
                <Button
                      onClick={(e) => this.props.handleSubmit()}
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                      disabled={!this.props.enableSubmit }
                    >
                    {this.props.translate("submit")}
                    </Button>

          </Grid>
          </Grid>
          </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <>{viewScreen}</>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}

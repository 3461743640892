import React, { useState } from "react";
import { useSelector } from "react-redux";

const DualListBox = ({
  availableTitle,
  assignTitle,
  availableFooter,
  assignFooter,
  availableItems,
  assignedItems, // passed in as IDs
  onChange,
}) => {
  const [searchAvailable, setSearchAvailable] = useState("");
  const [searchAssign, setSearchAssign] = useState("");
const themecolors = useSelector((state) => state.themecolors);
  // Filter logic for Available and Assigned items
  const filteredAvailableItems = availableItems.filter(
    (item) => !assignedItems.includes(item.value) // Items not in assigned should be available
  );

  const filteredAssignedItems = availableItems.filter((item) =>
    assignedItems.includes(item.value) // Only items in assigned should appear here
  );

  const handleSearchAvailable = (e) => {
    const search = e.target.value.toLowerCase();
    setSearchAvailable(search);
  };

  const handleSearchAssign = (e) => {
    const search = e.target.value.toLowerCase();
    setSearchAssign(search);
  };

  return (
    <div style={styles.container}>
      {/* Headers */}
      <div 
       style={{
        ...styles.headerContainer,
        backgroundColor: themecolors?.themeLightColor || "#009688", // fallback color
      }}
      >
        <div style={styles.header}>{availableTitle}</div>
        <div style={styles.header}>{assignTitle}</div>
      </div>

      {/* Search and List Container */}
      <div style={styles.listContainer}>
        {/* Available List */}
        <div style={styles.listBox}>
          <input
            type="text"
            placeholder="Search..."
            value={searchAvailable}
            onChange={handleSearchAvailable}
            style={styles.searchBox}
          />
          <ul style={styles.list}>
            {filteredAvailableItems
              ?.filter((item) =>  (item?.label || item?.name)?.toLowerCase().includes(searchAvailable)) // Filter by search
              .map((item, index) => (
                <li
                  key={index}
                  onClick={() => onChange(item.value,"assign")} // Pass the value when clicked
                  style={styles.listItem}
                  onMouseEnter={(e) => (e.target.style.background = "#f0f0f0")}
                  onMouseLeave={(e) => (e.target.style.background = "white")}
                >
                   {item.label ? item.label : item.name} {/* Show the name */}
                </li>
              ))}
          </ul>
        </div>

        {/* Assigned List */}
        <div style={styles.listBox}>
          <input
            type="text"
            placeholder="Search..."
            value={searchAssign}
            onChange={handleSearchAssign}
            style={styles.searchBox}
          />
          <ul style={styles.list}>
            {filteredAssignedItems
              ?.filter((item) => (item?.label || item?.name)?.toLowerCase().includes(searchAssign)) // Filter by search
              .map((item, index) => (
                <li
                  key={index}
                  onClick={() => onChange(item.value,"delete")} // Pass the value when clicked
                  style={styles.listItem}
                  onMouseEnter={(e) => (e.target.style.background = "#f0f0f0")}
                  onMouseLeave={(e) => (e.target.style.background = "white")}
                >
                  {item.label ? item.label : item.name} {/* Show the name */}
                </li>
              ))}
          </ul>
        </div>
      </div>

      {/* Footers */}
      <div style={styles.footerContainer}>
        <div style={styles.footer}>{availableFooter}</div>
        <div style={styles.footer}>{assignFooter}</div>
      </div>
    </div>
  );
};


// Inline styles for the component
const styles = {
  container: {
    width: "98%",
    margin: "0 auto",
    textAlign: "center",
    border: "1px solid #ccc",
    padding: "10px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    // backgroundColor: "#009688",
    color: "white",
    fontWeight: "bold",
    padding: "10px",
  },
  header: {
    flex: 1,
    textAlign: "center",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  listBox: {
    flex: 1,
    margin: "0 5px",
  },
  searchBox: {
    width: "100%",
    padding: "5px",
    marginBottom: "10px",
  },
  list: {
    listStyle: "none",
    padding: 0,
    maxHeight: "200px",
    overflowY: "auto",
    border: "1px solid #ddd",
  },
  listItem: {
    padding: "8px 10px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
    cursor: "pointer",
    transition: "background 0.3s", // for smooth hover transition
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    backgroundColor: "#f1f1f1",
    padding: "10px",
  },
  footer: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default DualListBox;

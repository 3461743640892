// import { Translate } from 'react-localize-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
export const validEmailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,30}(?:\.[a-z]{2})?)$/i
export const validNamePattern = /^[a-zA-Z0-9]{2,50}$/;

export function checkPrivileges (type) {
  let privileges = localStorage.getItem('privileges')
  let userInfo = localStorage.getItem('userInfo')
  if (privileges && userInfo) {
    let userPrivileges = JSON.parse(privileges)
    const info = JSON.parse(userInfo)
    if (userPrivileges && info && info.id) {
      return Object.values(userPrivileges).indexOf(type) > -1
    } else {
      window.location.assign('/logout')
      return false
    }
  } else {
    window.location.assign('/logout')
  }
}

export function displayErrorMessages (err) {
  
  let errormgs = ''
  if (err.includes('Admin')) {
    errormgs = 'youRequiredAdministrationAccess'
  } else if (err.includes('Unauthorized')) {
    errormgs = 'yourEmailOrPasswordIsWrong'
  } else if (err.includes('Account has expired')) {
    errormgs = 'yourAccountHasExpired'
  } else if (err.includes('Account is disabled')) {
    errormgs = 'accountIsDisabled'
  } else if (err.includes('Invalid Current Password')) {
    errormgs = 'wrongCurrentPassword'
  } else if (err.includes('Invalid User. Please try again with other user')) {
    errormgs = 'invalidUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('UnAuthorize User')) {
    errormgs = 'UnAuthorizeUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('Not enough privileges')) {
    errormgs = 'somthingWentWrongMessage1'
  }else if (err.includes('bad request (HINT: Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.)')) {
    errormgs = 'Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.'
  } 
  else {
    errormgs = 'serverIsDown'
  }
  return errormgs
}

export function isOwnerOrDealer (type) {
  if (type === -1 || type === 5) {
    return true
  } else {
    return false
  }
}

export function isAuthenticated (props) {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  if (info && info.id) {
    return info.id
  } else {
    if (props && props.history) {
      props.history.push('/logout')
    }
  }
}

export function prepareUsers (users) {
  const userArray = []
  if (users) {
    users.map(item => {
      let singleUser = {}
      const parent = {
        parentId: item.parent.id || '',
        parentName: item.parent.name || '',
        accountName: item.extraInfo.accountName || ''
      }
      const extraInfo = { ...item.extraInfo }
      singleUser = { ...item.entity, ...parent, extraInfo }
      const keys = Object.keys(singleUser)
      const final = {}
      keys.map(key => {
        if (typeof singleUser[key] === 'string') {
          final[key] = singleUser[key]
          // final[key] = parse(singleUser[key])[0]
        } else final[key] = singleUser[key]
      })

      userArray.push(final)
    })
  }
  return userArray
}
export function prepareUserForLogin (users) {
  if (users) {
    users.map(item => {
      item.logInAsUserState = ''
    })
  }
  return users
}

export function checkUserRoles (users, roles) {
  if (users && roles) {
    users.map(value => {
      for (let item of roles) {
        if (value.roleId === item.id) {
          value['roleName'] = item.name
        }
      }
    })
  }
  return users
}
export function checkUserAcount (users, account) {
  if (users && account) {
    users.map(value => {
      for (let item of account) {
        if (value.accountId === item.id) {
          value['accountName'] = item.name || item.label
        }
      }
    })
  }
  return users
}

export function prepareUnits (units) {
  let unitsArray = [],
    singleUnit = {}
  if (units) {
    units.map(item => {
      let parent = {
        parentId: item.parent.id,
        parentName: item.parent.name
      }
      singleUnit = { ...item.entity, ...parent }
      unitsArray.push(singleUnit)
    })
  }
  return unitsArray
}

export function checkRolesOfParent (units, users, roles) {
  if (units && users && roles) {
    units.map(value => {
      for (let item of users) {
        if (value.parentId === item.id) {
          for (let role of roles) {
            if (role.id === item.roleId) {
              value['ownerRole'] = role.name
            }
          }
        }
      }
    })
  }
  return units
}

export function checkUsersOfUnits (unitUser, users) {
  let usersArray = [],
    singleUser = {}
  if (users && unitUser) {
    users.map(value => {
      singleUser = JSON.parse(JSON.stringify(value))
      singleUser['check'] = false
      singleUser['indeterminate'] = false
      singleUser['readonly'] = false
      for (let item of unitUser) {
        if (value.id === item.userId) {
          //   singleUser.check=true
          if (item.parent) {
            item.check = true
            singleUser.check = true
            item.readonly = true
            singleUser.readonly = true
          } else {
            if (item.directAccess) {
              item.readonly = false
              singleUser.readonly = false
              item.check = true
              singleUser.check = true
            } else {
              item.readonly = false
              singleUser.readonly = false
              item.check = false
              singleUser.check = false
              singleUser.indeterminate = true
            }
          }
        }
      }
      usersArray.push(singleUser)
    })
  }
  return usersArray
}

export function checkUsersOfUnits1 (users) {
  if (users) {
    users.map(item => {
      item['check'] = false
      item['indeterminate'] = false
      item['readonly'] = false
      if (item.directAccess) {
        item.check = true
        item.readonly = true
        item.readonly = true
      } else {
        if (item.directAccess === false) {
          item.readonly = true
          item.check = false
          item.indeterminate = true
        } else {
          item.readonly = true
          item.check = false
          item.indeterminate = false
        }
      }
    })
  }
  return users
}

export const PaginationConfig = {
  page: 1,
  pageSize: 20,
  itemSearch: ''
}

export function getValue(o, k) {
  return k.split('.').reduce((r, e) => {
    if (!r) return r;
    else return r[e] || undefined;
  }, o);
}

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
export function setCookie (cname, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '= ;' + expires + ';path=/'
}

export function checkedDevelopmentType () {
  let result = `&host=${window.location.host}`
  let serverType = window.location.host.includes('localhost')
  if (serverType) {
    result = ''
  }
  return result
}

export const selectStyles = themecolors => ({
  option: base => ({
    ...base,
    padding: '10px 14px',
    color: themecolors['themeInverse']
  }),
  noOptionsMessage: base => ({
    ...base,
    padding: '10px 14px'
  }),
  multiValue: base => ({
    ...base,
    margin: '2px',
    background: themecolors[600],
    color: themecolors['themeInverse']
  }),
  multiValueLabel: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  placeholder: base => ({
    ...base,
    color: themecolors['themeInverse'],
    opacity: 0.5
  }),
  dropdownIndicator: base => ({
    ...base,
    color: themecolors['themeInverse'],
    padding: 4
  }),
  clearIndicator: base => ({
    ...base,
    color: themecolors['themeInverse'],
    padding: 4
  }),
  indicatorsContainer: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  input: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  container: base => ({
    ...base,
    margin: '8px 0 4px'
  }),
  control: base => ({
    ...base,
    boxShadow:
      '0px 2px 4px 0px rgba(20, 20, 20, 0.15), 0px 2px 4px 0px rgba(20, 20, 20, 0.15) inset',
    background: 'none',
    borderColor: themecolors['600'],
    minHeight: 26,
    '&:hover': {
      borderColor: themecolors['600']
    }
  }),
  menuList: base => ({
    ...base,
    background: themecolors['500'],
    color: themecolors['themeInverse']
  }),
  singleValue: base => ({
    ...base,
    color: themecolors['themeInverse']
  })
})

export const errorHandler = (error, dispatch, callback) => {
  if(error?.message?.includes("HINT")){
    let message = error.message.split(":")[1]
    if(message.includes("Duplicate entry")){

        toast.error(
          // 'Duplicate entry'
      )
    }else{
   
        toast.error(
          // <Translate id={message.replace(')','')}/>)
      )
    }
}else if(error?.statusCode === '440'){
 
    toast.error(
      //  <Translate id={error?.message}/>
  )
  window.location.replace('/login')
}
else {
  if(!axios.isCancel(error)){
  
      toast.error(
        // <Translate id={'somthingWentWrongMessage'}/>
    )
  }
}
  callback && typeof callback === 'function' && callback(error)
}

export const replaceSpecialCh = (input)=>{
  return input?.replace(/amp;/g, '')
}
export const Api = 'http://185.230.210.44:3003/'

export const  nFormatter =(num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const getTooltipMessage = (fieldValue, fieldName) => {
  if(fieldName === "c_schemeId")
    switch (fieldValue) {
      case "TIN":
        return (
          <>
            {/* <span style={{ display: "block" }}>رقم التعريف الضريبي</span> Arabic Translation */}
            <span style={{ display: "block" }}>TIN is one</span>
          </>
        );
      case "CRN":
        return (
          <>
            {/* <span style={{ display: "block" }}>رقم السجل التجاري</span> Arabic Translation */}
            <span style={{ display: "block" }}>CRN is two</span>
          </>
        );
      case "MOM":
        return (
          <>
            {/* <span style={{ display: "block" }}>وزارة التجارة</span> Arabic Translation */}
            <span style={{ display: "block" }}>MOM is three</span>
          </>
        );
      case "MLS":
        return (
          <>
            {/* <span style={{ display: "block" }}>خدمات متعددة</span> Arabic Translation */}
            <span style={{ display: "block" }}>MLS is four</span>
          </>
        );
      case "700":
        return (
          <>
            {/* <span style={{ display: "block" }}>رقم السجل الوطني</span> Arabic Translation */}
            <span style={{ display: "block" }}>700 is five</span>
          </>
        );
      case "SAG":
        return (
          <>
            {/* <span style={{ display: "block" }}>الهيئة السعودية العامة</span> Arabic Translation */}
            <span style={{ display: "block" }}>SAG is six</span>
          </>
        );
      case "NAT":
        return (
          <>
            {/* <span style={{ display: "block" }}>الجنسية</span> Arabic Translation */}
            <span style={{ display: "block" }}>NAT is seven</span>
          </>
        );
      case "GCC":
        return (
          <>
            {/* <span style={{ display: "block" }}>مجلس التعاون الخليجي</span> Arabic Translation */}
            <span style={{ display: "block" }}>GCC is eight</span>
          </>
        );
      case "IQA":
        return (
          <>
            {/* <span style={{ display: "block" }}>رقم الإقامة</span> Arabic Translation */}
            <span style={{ display: "block" }}>IQA is nine</span>
          </>
        );
      case "OTH":
        return (
          <>
            {/* <span style={{ display: "block" }}>آخر</span> Arabic Translation */}
            <span style={{ display: "block" }}>OTH is ten</span>
          </>
        );
      default:
        return ""; // Return empty string for unknown values
    }
    
  else if(fieldName === "c_idIssuer") {
    switch (fieldValue) {
      case "CM":
        return (
          <>
            <span style={{ display: "block" }}>هيئة سوق المال</span>
            <span style={{ display: "block" }}>Capital Market Authority</span>
          </>
        );
      case "CU":
        return (
          <>
            <span style={{ display: "block" }}>الجمارك السعودية</span>
            <span style={{ display: "block" }}>Saudi Customs</span>
          </>
        );
      case "CW":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الشؤون الاجتماعية</span>
            <span style={{ display: "block" }}>Ministry of Social Affairs for Charity & Welfare organizations</span>
          </>
        );
      case "EN":
        return (
          <>
            <span style={{ display: "block" }}>الهيئة السعودية للمهندسين</span>
            <span style={{ display: "block" }}>Saudi Council of Engineers</span>
          </>
        );
      case "FI":
        return (
          <>
            <span style={{ display: "block" }}>هيئة الاستثمار الأجنبي للمستثمرين الأجانب</span>
            <span style={{ display: "block" }}>Foreign Investment Commission (for Foreign Investors)</span>
          </>
        );
      case "GI":
        return (
          <>
            <span style={{ display: "block" }}>الهيئة العامة للاستثمار</span>
            <span style={{ display: "block" }}>Saudi Arabian General Investment Authority ‘SAGIA’ for Joint Ventures with Foreign Ownership</span>
          </>
        );
      case "IN":
        return (
          <>
            <span style={{ display: "block" }}>رقم داخلي (قروض موحدة)</span>
            <span style={{ display: "block" }}>Internal (Syndicated Loans)</span>
          </>
        );
      case "MA":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الزراعة</span>
            <span style={{ display: "block" }}>Ministry of Agriculture</span>
          </>
        );
      case "MC":
        return (
          <>
            <span style={{ display: "block" }}>وزارة التجارة والاستثمار</span>
            <span style={{ display: "block" }}>Ministry of Commerce and Investment</span>
          </>
        );
      case "MD":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الدفاع والطيران</span>
            <span style={{ display: "block" }}>Ministry of Defence and Aviation for Foreign Airlines</span>
          </>
        );
      case "ME":
        return (
          <>
            <span style={{ display: "block" }}>وزارة التربية والتعليم</span>
            <span style={{ display: "block" }}>Ministry of Education</span>
          </>
        );
      case "MF":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الخارجية</span>
            <span style={{ display: "block" }}>Ministry of Foreign Affairs (Diplomatic)</span>
          </>
        );
      case "MH":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الصحة</span>
            <span style={{ display: "block" }}>Ministry of Health</span>
          </>
        );
      case "MI":
        return (
          <>
            <span style={{ display: "block" }}>وزارة المعلومات</span>
            <span style={{ display: "block" }}>Ministry of Information</span>
          </>
        );
      case "MJ":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الحج والعمرة</span>
            <span style={{ display: "block" }}>Ministry of Hajj and Umrah</span>
          </>
        );
      case "ML":
        return (
          <>
            <span style={{ display: "block" }}>وزارة العمل</span>
            <span style={{ display: "block" }}>Ministry of Labour for Cooperative Societies</span>
          </>
        );
      case "MN":
        return (
          <>
            <span style={{ display: "block" }}>وزارة الداخلية</span>
            <span style={{ display: "block" }}>Ministry of Interior</span>
          </>
        );
      case "MU":
        return (
          <>
            <span style={{ display: "block" }}>أمانة المدن</span>
            <span style={{ display: "block" }}>Municipality</span>
          </>
        );
      case "MX":
        return (
          <>
            <span style={{ display: "block" }}>وزارة التجارة والصناعة</span>
            <span style={{ display: "block" }}>Ministry of Commerce Commercial Licences</span>
          </>
        );
      case "RD":
        return (
          <>
            <span style={{ display: "block" }}>قرار ملكي</span>
            <span style={{ display: "block" }}>Royal Decree</span>
          </>
        );
      case "PP":
        return (
          <>
            <span style={{ display: "block" }}>الجوازات الأجنبية</span>
            <span style={{ display: "block" }}>Foreign Passports</span>
          </>
        );
      case "SA":
        return (
          <>
            <span style={{ display: "block" }}>مؤسسة النقد العربي السعودي</span>
            <span style={{ display: "block" }}>SAMA for Money Exchange and Insurance</span>
          </>
        );
      case "TV":
        return (
          <>
            <span style={{ display: "block" }}>المؤسسة العامة للتدريب التقني</span>
            <span style={{ display: "block" }}>Technical and Vocational Training Corporation</span>
          </>
        );
      case "UN":
        return (
          <>
            <span style={{ display: "block" }}>غير معروف</span>
            <span style={{ display: "block" }}>Unknown</span>
          </>
        );
      default:
        return (
          <>
            <span style={{ display: "block" }}></span>
            <span style={{ display: "block" }}></span>
          </>
        );
    }
    
  }
  else if(fieldName === "c_productType"){
    switch (fieldValue) {
      case "ADFL":
        return (
          <>
            <span style={{ display: "block" }}>قرض زراعي</span>
            <span style={{ display: "block" }}>Agricultural Loan</span>
          </>
        );
      case "AMTG":
        return (
          <>
            <span style={{ display: "block" }}>تمويل إضافي للرهن العقاري</span>
            <span style={{ display: "block" }}>Additional Mortgage</span>
          </>
        );
      case "AQAR":
        return (
          <>
            <span style={{ display: "block" }}>تمويل عقاري حكومي</span>
            <span style={{ display: "block" }}>Government Mortgage Real Estate Fund</span>
          </>
        );
      case "CDC":
        return (
          <>
            <span style={{ display: "block" }}>بطاقة الأدوات المنزلية</span>
            <span style={{ display: "block" }}>Consumer Durables Card</span>
          </>
        );
      case "CDL":
        return (
          <>
            <span style={{ display: "block" }}>قرض الأدوات المنزلية</span>
            <span style={{ display: "block" }}>Consumer Durables Loan</span>
          </>
        );
      case "CHC":
        return (
          <>
            <span style={{ display: "block" }}>بطاقة دفع</span>
            <span style={{ display: "block" }}>Charge Card</span>
          </>
        );
      case "CHCK":
        return (
          <>
            <span style={{ display: "block" }}>شيكات مرتجعة</span>
            <span style={{ display: "block" }}>Bounced Cheques</span>
          </>
        );
      case "COM":
        return (
          <>
            <span style={{ display: "block" }}>استفسار تجاري</span>
            <span style={{ display: "block" }}>Commercial Enquiry</span>
          </>
        );
      case "CRC":
        return (
          <>
            <span style={{ display: "block" }}>بطاقة ائتمانية</span>
            <span style={{ display: "block" }}>Credit Card</span>
          </>
        );
      case "EDUF":
        return (
          <>
            <span style={{ display: "block" }}>تمويل التعليم</span>
            <span style={{ display: "block" }}>Education Finance</span>
          </>
        );
      case "GFC":
        return (
          <>
            <span style={{ display: "block" }}>مطالبات مالية رسمية</span>
            <span style={{ display: "block" }}>Official Financial Claims</span>
          </>
        );
      case "IAC":
        return (
          <>
            <span style={{ display: "block" }}>تأمين الحوادث وما يتعلق بالمنتج</span>
            <span style={{ display: "block" }}>Accident & related services</span>
          </>
        );
      case "IEN":
        return (
          <>
            <span style={{ display: "block" }}>التأمين الهندسي وما يتعلق بالمنتج</span>
            <span style={{ display: "block" }}>Engineering & related</span>
          </>
        );
      case "ILB":
        return (
          <>
            <span style={{ display: "block" }}>تأمين المسؤولية وما يتعلق بالمنتج</span>
            <span style={{ display: "block" }}>Liability & related</span>
          </>
        );
      case "IMD":
        return (
          <>
            <span style={{ display: "block" }}>التأمين الطبي وما يتعلق بالمنتج</span>
            <span style={{ display: "block" }}>Medical & related products</span>
          </>
        );
      case "IMR":
        return (
          <>
            <span style={{ display: "block" }}>التأمين البحري وما يتعلق بالمنتج</span>
            <span style={{ display: "block" }}>Marine & related</span>
          </>
        );
      case "IMTG":
        return (
          <>
            <span style={{ display: "block" }}>تمويل إنشاء استصناع</span>
            <span style={{ display: "block" }}>Istisna Construction Financing</span>
          </>
        );
      case "IMV":
        return (
          <>
            <span style={{ display: "block" }}>تأمين المركبات وما يتعلق بها</span>
            <span style={{ display: "block" }}>Motor Vehicle & related products</span>
          </>
        );
      case "IPR":
        return (
          <>
            <span style={{ display: "block" }}>تأمين الممتلكات وما يتعلق بها</span>
            <span style={{ display: "block" }}>Property & Related</span>
          </>
        );
      case "IPS":
        return (
          <>
            <span style={{ display: "block" }}>تأمين الحماية و الادخار</span>
            <span style={{ display: "block" }}>Protection & Savings Insurance</span>
          </>
        );
      case "LEGL":
        return (
          <>
            <span style={{ display: "block" }}>استفسار قانوني</span>
            <span style={{ display: "block" }}>Legal Enquiry</span>
          </>
        );
      case "LND":
        return (
          <>
            <span style={{ display: "block" }}>هاتف ثابت</span>
            <span style={{ display: "block" }}>Landline Phone</span>
          </>
        );
      case "MBL":
        return (
          <>
            <span style={{ display: "block" }}>هاتف جوال</span>
            <span style={{ display: "block" }}>Mobile Phone</span>
          </>
        );
      case "MGLD":
        return (
          <>
            <span style={{ display: "block" }}>تسهيلات المتاجرة</span>
            <span style={{ display: "block" }}>Margin Lending</span>
          </>
        );
      case "MIS":
        return (
          <>
            <span style={{ display: "block" }}>متنوع</span>
            <span style={{ display: "block" }}>Miscellaneous</span>
          </>
        );
      case "MSKN":
        return (
          <>
            <span style={{ display: "block" }}>مسكن (منتج حكومي كالرهن العقاري)</span>
            <span style={{ display: "block" }}>Muskun (Government product as mortgage)</span>
          </>
        );
      case "MTG":
        return (
          <>
            <span style={{ display: "block" }}>تمويل سكني</span>
            <span style={{ display: "block" }}>Mortgage</span>
          </>
        );
      case "NET":
        return (
          <>
            <span style={{ display: "block" }}>خدمة الانترنت</span>
            <span style={{ display: "block" }}>Internet service</span>
          </>
        );
        case "PE":
          return (
            <>
              <span style={{ display: "block" }}>عام</span>
              <span style={{ display: "block" }}>Public</span>
            </>
          );
        case "PLN":
          return (
            <>
              <span style={{ display: "block" }}>تمويل شخصي</span>
              <span style={{ display: "block" }}>Personal Finance</span>
            </>
          );
        case "POD":
          return (
            <>
              <span style={{ display: "block" }}>سحب على المكشوف المصرح به</span>
              <span style={{ display: "block" }}>Authorized Over Draft</span>
            </>
          );
        case "RCSR":
          return (
            <>
              <span style={{ display: "block" }}>خدمة التوظيف</span>
              <span style={{ display: "block" }}>Recruiting Service</span>
            </>
          );
        case "RERA":
          return (
            <>
              <span style={{ display: "block" }}>اتفاقية تأجير عقاري</span>
              <span style={{ display: "block" }}>Real Estate Rental Agreement</span>
            </>
          );
        case "RMSKN":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة مسكن</span>
              <span style={{ display: "block" }}>Restructured Muskun</span>
            </>
          );
        case "RMTG":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة تمويل سكني</span>
              <span style={{ display: "block" }}>Restructured Mortgage</span>
            </>
          );
        case "RPLN":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة القرض</span>
              <span style={{ display: "block" }}>Restructured Loan</span>
            </>
          );
        case "RSMEI":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة تأجير المؤسسات الصغيرة والمتوسطة</span>
              <span style={{ display: "block" }}>Restructured Small Medium Enterprise Leasing</span>
            </>
          );
        case "RSMEL":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة قسط المؤسسات الصغيرة والمتوسطة</span>
              <span style={{ display: "block" }}>Restructured Small Medium Enterprise Instalment</span>
            </>
          );
        case "RVIN":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة اتفاقية تقسيط السيارة</span>
              <span style={{ display: "block" }}>Restructured Car Instalment Agreement</span>
            </>
          );
        case "RVLS":
          return (
            <>
              <span style={{ display: "block" }}>إعادة هيكلة اتفاقية تأجير السيارة</span>
              <span style={{ display: "block" }}>Restructured Car Lease Agreement</span>
            </>
          );
        case "SFB":
          return (
            <>
              <span style={{ display: "block" }}>برنامج التمويل المضمون</span>
              <span style={{ display: "block" }}>Secured Finance Program</span>
            </>
          );
        case "SME":
          return (
            <>
              <span style={{ display: "block" }}>المؤسسات الصغيرة والمتوسطة</span>
              <span style={{ display: "block" }}>Small Medium Enterprise</span>
            </>
          );
        case "SMEI":
          return (
            <>
              <span style={{ display: "block" }}>تأجير المؤسسات الصغيرة والمتوسطة</span>
              <span style={{ display: "block" }}>Small Medium Enterprise Leasing</span>
            </>
          );
        case "SMEL":
          return (
            <>
              <span style={{ display: "block" }}>قسط المؤسسات الصغيرة والمتوسطة</span>
              <span style={{ display: "block" }}>Small Medium Enterprise Installment</span>
            </>
          );
        case "SMS":
          return (
            <>
              <span style={{ display: "block" }}>استفسار عبر الرسائل القصيرة</span>
              <span style={{ display: "block" }}>SMS Enquiry</span>
            </>
          );
        case "STFM":
          return (
            <>
              <span style={{ display: "block" }}>تمويل الأسهم</span>
              <span style={{ display: "block" }}>Stock Finance Murabaha</span>
            </>
          );
        case "TMTG":
          return (
            <>
              <span style={{ display: "block" }}>تمويل سكني إضافي</span>
              <span style={{ display: "block" }}>Top-up Mortgage</span>
            </>
          );
        case "TPLN":
          return (
            <>
              <span style={{ display: "block" }}>قرض إضافي</span>
              <span style={{ display: "block" }}>Top-up Loan</span>
            </>
          );
        case "TOD":
          return (
            <>
              <span style={{ display: "block" }}>سحب على المكشوف غير المصرح به</span>
              <span style={{ display: "block" }}>Unauthorized Over Draft</span>
            </>
          );
        case "UNFS":
          return (
            <>
              <span style={{ display: "block" }}>خدمات مالية غير مسددة</span>
              <span style={{ display: "block" }}>Unsettled Financial Services</span>
            </>
          );
        case "VCLM":
          return (
            <>
              <span style={{ display: "block" }}>مطالبات السيارات</span>
              <span style={{ display: "block" }}>Vehicle Claim</span>
            </>
          );
        case "VEHE":
          return (
            <>
              <span style={{ display: "block" }}>معدات ثقيلة</span>
              <span style={{ display: "block" }}>Heavy Equipment</span>
            </>
          );
        case "VESP":
          return (
            <>
              <span style={{ display: "block" }}>قطع غيار</span>
              <span style={{ display: "block" }}>Spare Parts</span>
            </>
          );
        case "VIN":
          return (
            <>
              <span style={{ display: "block" }}>اتفاقية تقسيط السيارة</span>
              <span style={{ display: "block" }}>Car Instalment Agreement</span>
            </>
          );
        case "VLS":
          return (
            <>
              <span style={{ display: "block" }}>اتفاقية تأجير السيارة</span>
              <span style={{ display: "block" }}>Car Lease Agreement</span>
            </>
          );
        case "VRA":
          return (
            <>
              <span style={{ display: "block" }}>اتفاقية تأجير السيارات</span>
              <span style={{ display: "block" }}>Car Rentals Agreement</span>
            </>
          );
        case "WAT":
          return (
            <>
              <span style={{ display: "block" }}>فواتير المياه</span>
              <span style={{ display: "block" }}>Water bills</span>
            </>
          );
        default:
          return (
            <>
              <span style={{ display: "block" }}></span>
              <span style={{ display: "block" }}></span>
            </>
          );
    }
  }
  else if(fieldName === "c_productStatus"){
    switch (fieldValue) {
      case "A":
        return (
          <>
            <span style={{ display: "block" }}>متحرك</span>
            <span style={{ display: "block" }}>Active</span>
          </>
        );
      case "S":
        return (
          <>
            <span style={{ display: "block" }}>معلق</span>
            <span style={{ display: "block" }}>Suspended</span>
          </>
        );
      case "C":
        return (
          <>
            <span style={{ display: "block" }}>مغلق</span>
            <span style={{ display: "block" }}>Closed</span>
          </>
        );
      case "W":
        return (
          <>
            <span style={{ display: "block" }}>الحساب المتعثر فقط</span>
            <span style={{ display: "block" }}>Default only</span>
          </>
        );
      case "X":
        return (
          <>
            <span style={{ display: "block" }}>الحساب المتعثر فقط</span>
            <span style={{ display: "block" }}>
              Default only. May only be used for Credit Instruments created with the ‘Standalone Default Upload’ Service
            </span>
          </>
        );
      case "L":
        return (
          <>
            <span style={{ display: "block" }}>المفقودة / المسروقة</span>
            <span style={{ display: "block" }}>Lost / Stolen</span>
          </>
        );
      default:
        return (
          <>
            <span style={{ display: "block" }}></span>
            <span style={{ display: "block" }}></span>
          </>
        );
    }
    
  }
  else if(fieldName === "c_defaultStatus"){
    switch (fieldValue) {
      case "FS":
        return (
          <>
            <span style={{ display: "block" }}>مدفوع بالكامل</span>
            <span style={{ display: "block" }}>Fully Paid</span>
          </>
        );
      case "NS":
        return (
          <>
            <span style={{ display: "block" }}>تسوية بالاتفاق</span>
            <span style={{ display: "block" }}>Negotiated Settlement</span>
          </>
        );
      case "OS":
        return (
          <>
            <span style={{ display: "block" }}>غير مدفوعة</span>
            <span style={{ display: "block" }}>Outstanding</span>
          </>
        );
      case "PP":
        return (
          <>
            <span style={{ display: "block" }}>مدفوع جزئيا</span>
            <span style={{ display: "block" }}>Partially Paid</span>
          </>
        );
      case "RG":
        return (
          <>
            <span style={{ display: "block" }}>شيك مصرفي مسجل</span>
            <span style={{ display: "block" }}>Registered</span>
          </>
        );
      default:
        return (
          <>
            <span style={{ display: "block" }}></span>
            <span style={{ display: "block" }}></span>
          </>
        );
    }
    
  }
  else if(fieldName === "c_goodsServices"){
    switch (fieldValue) {
      case "ALOTH":
        return (
          <>
            <span style={{ display: "block" }}>كل الآخرين</span>
            <span style={{ display: "block" }}>All Other</span>
          </>
        );
      case "APPLN":
        return (
          <>
            <span style={{ display: "block" }}>الأجهزة المنزلية</span>
            <span style={{ display: "block" }}>Appliances</span>
          </>
        );
      case "MCHNR":
        return (
          <>
            <span style={{ display: "block" }}>الآلات</span>
            <span style={{ display: "block" }}>Machinery</span>
          </>
        );
      case "CARS":
        return (
          <>
            <span style={{ display: "block" }}>السيارات</span>
            <span style={{ display: "block" }}>Cars</span>
          </>
        );
      case "BLDMT":
        return (
          <>
            <span style={{ display: "block" }}>مواد البناء</span>
            <span style={{ display: "block" }}>Building Material</span>
          </>
        );
      case "TRCTH":
        return (
          <>
            <span style={{ display: "block" }}>الجرارات</span>
            <span style={{ display: "block" }}>Tractors</span>
          </>
        );
      case "OTHFD":
        return (
          <>
            <span style={{ display: "block" }}>أطعمة أخرى</span>
            <span style={{ display: "block" }}>Other Food</span>
          </>
        );
      case "LMEAT":
        return (
          <>
            <span style={{ display: "block" }}>اللحوم الخالية من الدهون</span>
            <span style={{ display: "block" }}>Lean Meat</span>
          </>
        );
      case "SUGTC":
        return (
          <>
            <span style={{ display: "block" }}>منتجات السكر</span>
            <span style={{ display: "block" }}>Sugar Products</span>
          </>
        );
      case "FRVEG":
        return (
          <>
            <span style={{ display: "block" }}>الخضروات الطازجة</span>
            <span style={{ display: "block" }}>Fresh Vegetables</span>
          </>
        );
      default:
        return <span>No information available</span>;
    }
    
  }
};
import React, { Component } from "react";
import moment from "moment";
import DateRangePicker from "../Invoice/DateRangePicker";
import { Grid, } from "@mui/material";
import Loader from "../../Layout/Loader";
import AccountSelector from "../Invoice/AccountSelector";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import { GetApp } from "@mui/icons-material";

export default class creditDebitFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilterModal: false,
      from: "",
      to: "",
      selectedDate: "",
      contractNumber: [],
      loader: false,
      selectedaccountId: [],
      isSubmitBtn: false,
    };
  }

 

  showDates = (e, date) => {
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    },()=>this.handleSubmitBtn());
  };



handleSubmitBtn = () => {
  const { selectedDate, selectedaccountId } = this.state;
  const { showPayments } = this.props;

  if (showPayments) {
    if (selectedDate) {
      this.setState({ isSubmitBtn: true });
    } else {
      this.setState({ isSubmitBtn: false });
    }
  } else {
    if (selectedDate && selectedaccountId && selectedaccountId.length > 0) {
      this.setState({ isSubmitBtn: true });
    } else {
      this.setState({ isSubmitBtn: false });
    }
  }
}




  handleChange = (name, e) => {
      this.setState({
        contractNumber: name,
      });
      this.setState({ contractNumber: name }, () => {
        let filter = "";
        Object.entries(this.state.contractNumber).map(([key, value]) => {
          if (value) {
            filter += "&contractNumber=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedaccountId: filter,
        }, ()=>this.handleSubmitBtn());
      });
  };

  validatedForm = (step, type, index) => {
    let validated = false;
    let errorMsg = null;

    if (this.state.to && this.state.from) {
      validated = true;
      errorMsg = null;
      this.setState({ isSubmitBtn: true });
    } else {
      validated = false;
      errorMsg = (
        <span className="text-warning">
          Please select 'Date and Maintenance Status' from above
        </span>
      );
    }

    if (this.props.showPayments) {
      validated = true;
    } else {
      if (this.state.selectedaccountId.length > 0) {
        validated = true;
        errorMsg = null;
        this.setState({ isSubmitBtn: true });
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">
            Please select atleast one account
          </span>
        );
      }
    }
    if (validated === true) {
      this.setState({}, () => this.handleNext(step, type, index));
    } else {
      validated = false;
      errorMsg = (
        <span className="text-warning">
          Please select 'Date and Maintenance Status' from above
        </span>
      );
    }
  };

  handleNext = (step, type, index) => {
    if (this.props.showPayments) {
      let filter = "";
      filter = "&contractNumber=" + this.props.selectedAccount?.contractNumber;
   
      this.setState({ selectedaccountId: filter }, () => {
        if (type === "downloadExcel" || type === "downloadPdf") {
          this.props.downloadCreditDebit(
            this.state.to,
            this.state.from,
            this.state.selectedaccountId,
            type
          );
        } else {
          this.props.creditDebitFilter(
            this.state.to,
            this.state.from,
            this.state.selectedaccountId
          );
        }
      });
    } else {
      this.setState({}, () => {
        if (type === "downloadExcel" || type === "downloadPdf") {
          this.props.downloadCreditDebit(
            this.state.to,
            this.state.from,
            this.state.selectedaccountId,
            type
          );
        } else {
          this.props.creditDebitFilter(
            this.state.to,
            this.state.from,
            this.state.selectedaccountId,
          );
        }
      });
    }
  };

  render() {
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12} style={{ padding: 10 }}>
                {this.props.showPayments ? (
                  <h4 style={{ margin: 10 }}>
                    Account : {this.props.selectedAccount.accountName}
                  </h4>
                ) : (
                  ""
                )}
                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
              {this.props.showPayments ? (
                ""
              ) : (
                <Grid item xs={12} style={{ padding: 10 }}>
                  <AccountSelector
                    contractType={this.props.contractType}
                    creditDebitNotes
                    api="accounts"
                    fill
                    hideIcons
                    isClearable
                    placeholder={this.props.translate("searchAccount")}
                    onChange={this.handleChange}
                    value={this.state?.contractNumber || []}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 20,
                    }}
                  >
                    <Button
                      disabled={this.state.isSubmitBtn ? "" : "disabled"}
                      variant="contained"
                      color="primary"
                      onClick={(e) =>
                        this.validatedForm("", "downloadExcel", "")
                      }
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                    >
                      <GetApp />
                      {this.props.translate("Excel")}
                    </Button>
                    <Button
                      disabled={this.state.isSubmitBtn ? "" : "disabled"}

                      onClick={(e) => this.validatedForm("", "", "")}
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                    >
                      {this.props.translate("submit")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
      </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}

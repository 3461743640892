import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
// import PaymentList from '../../Components/Payment'
import { PaymentList } from "../../Components/NewPayment";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";

import axios from "axios";
import instance from "../../axios";
import withRoutes from "../../HOC/HocRoutes";

let source;
const formDefault = {
  id: 0,
  attributes: {},
  refNum: "",
  type: true,
  amount: "",
  contractNumber: "",
  paymentMode: "",
  paymentDate: "",
  remarks: "",
  adjustmentType: "",
  parentId: 0,
  receipt: "",
  docs: " ",
  archive: null,
  archiveTime: null,
  recipientDate: "",
  invoiceIds: [],
  searchAccount: "",
  useCredit: false,
};
const formDefaultSettleAmount = {
  contractNumberForSettle: "",
  adjustAmount: 0,
  adjustmentType: "",
  invoiceIds: [],
};
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: "",
      paymentList: "",
      form: { ...formDefault },
      formSettleAmount: { ...formDefaultSettleAmount },
      selectedPayment: "",
      editOption: false,
      onDeleteConfirmation: false,
      itemAttributes: {},
      selectedGroup: "",
      isVisableAddBtn: false,
      isVisableSettleBtn: false,
      isVisable: false,
      activeOperation: "",
      selectedItem: "",
      openTable: false,
      users: "",
      addOption: false,
      to: "",
      from: "",
      account: "",
      vehcile: "",
      area: "",
      filteredData: false,
      selectedAccount: {},
      page: 1,
      pageSize: 20,
      loader: true,
      showPayments: false,
      openFilter: false,
      searchPayment: "",
      selectSingleAccount: false,
      paymentAccount: "",
      manualPayments: "",
      isChecked: false,
      selectedInvoices: [],
      totalInvoiceBalance: 0,
      // objects: [],
      creditAmount: "",
      creditAmountForSettle: "",
      contractType: 1,
      loader2: false,
      balanceError: "",
      // invoiceIds:[]
      invoiceLoader: false,
      invoiceSearch: "",
      paymentNumber: "",
      refNumber: "",
      settleAmountOption: false,
      overallInvoiceBalance: "",
      onSettleAmountConfirmation: false,
      // settlementForm: {
      //   adjustAmount: 0,
      //   adjustmentType: "",
      //   invoiceIds: "",
      // },
    };
    this.modalControle = this.modalControle.bind(this);
    this.addDevice = this.addDevice.bind(this);
  }

  allUsersId = (event, items) => {
    // for selecting single Invoice
    let allData = [];
    let totalBalance;
    // const roundedNum = Math.round(num * 100) / 100;
    if (event.target.checked) {
      let ids = Array.isArray(this.state.form.invoiceIds)
        ? [...this.state.form.invoiceIds, items.id]
        : [items.id];
      let cal = this.state.totalInvoiceBalance + items.invoicebalance;
      totalBalance = Math.round(cal * 100) / 100;
      this.setState(
        {
          totalInvoiceBalance: totalBalance,
          form: {
            ...this.state.form,
            invoiceIds: ids,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      let ids = Array.isArray(this.state.form.invoiceIds)
        ? this.state.form.invoiceIds.filter((id) => id !== items.id)
        : [];
      let cal = this.state.totalInvoiceBalance - items.invoicebalance;
      totalBalance = Math.round(cal * 100) / 100;
      this.setState(
        {
          totalInvoiceBalance: totalBalance,
          form: {
            ...this.state.form,
            invoiceIds: ids,
          },
        },
        () => this.checkRequiredFields()
      );
    }

    this.state.manualPayments.map((it) => {
      if (it.id === items.id) {
        let row = {
          ...it,
          check: event.target.checked,
        };
        allData.push(row);
      } else {
        allData.push(it);
      }
    });

    this.setState({ manualPayments: allData }, () =>
      this.checkRequiredFields()
    );
  };

  selectAll = (e) => {
    let allData = [];
    let totalBalance;
    let ids = [];
    let cal = 0;
    this.state.manualPayments.map((it) => {
      let row = {
        ...it,
        check: e,
      };
      if (e) {
        cal = cal + it.invoicebalance;
        ids.push(it.id);
      } else {
        cal = 0;
        ids = [];
      }
      allData.push(row);
    });
    totalBalance = Math.round(cal * 100) / 100;
    this.setState(
      {
        totalInvoiceBalance: totalBalance,
        form: {
          ...this.state.form,
          invoiceIds: ids,
        },
      },
      () => this.checkRequiredFields()
    );
    this.setState(
      { manualPayments: allData, totalInvoiceBalance: totalBalance },
      () => this.checkRequiredFields()
    );
  };

  searchInvoices = (e) => {
    source.cancel();
    this.setState(
      {
        invoiceSearch: e.target.value,
      },
      () => {
        this.getInvoicesForSingleAccount();
      }
    );
  };
  getInvoicesForSingleAccount = () => {
    const { paymentAccount, selectedAccount, showPayments } = this.state;
    let url;
    let account;
    if (showPayments) {
      account = selectedAccount;
    } else {
      account = paymentAccount;
    }

    if (account.contractType === 1) {
      url = `api/invoices/unpaid/list?contractNumber=${account.contractNumber}&invoiceNumber=${this.state.invoiceSearch}`;
    } else if (account.contractType === 2) {
      url = `api/leaseinvoices/unpaid/list?contractNumber=${account.contractNumber}&search=${this.state.invoiceSearch}`;
    }
    source = axios.CancelToken.source();

    axios
      .get(url, {
        cancelToken: source.token,
      })
      .then((res) => {
        const filteredInvoices = res?.data?.data?.filter(
          (invoice) => invoice.invoicebalance > 0
        );
        let totalInvBalance = res?.data?.data?.reduce(
          (sum, item) => sum + item.invoicebalance,
          0
        );
        this.setState(
          {
            manualPayments: filteredInvoices,
            // isChecked: true,
            overallInvoiceBalance: totalInvBalance,
            invoiceLoader: false,
          },
          () => this.checkRequiredFields()
        );
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  getCredtit = () => {
    const { paymentAccount, selectedAccount, showPayments, searchAccount } =
      this.state;

    let account;
    let url;
    if (showPayments) {
      account = selectedAccount;
    } else {
      account = paymentAccount;
    }

    url = `api/payments/credit?contractNumber=${account.contractNumber}`;

    instance({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          creditAmount: res,
          // isChecked: true,
        });
        this.setState({ creditAmountForSettle: res }, () => {
          this.checkRequiredFields();
        });
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  handleChangeForAccount = async (name, event, link) => {
    this.setState(
      {
        [name]: event,
        form: {
          ...this.state.form,
          contractNumber: event.contractNumber,
          useCredit: true,
        },
      },
      () => {
        this.getInvoicesForSingleAccount();
        this.getCredtit();
        this.checkRequiredFields();
      }
    );
    // this.props.handleChange('useCredit')(event);
  };

  handleChange = (name) => (event) => {
    if (name === "useCredit") {
      if (event.target.checked) {
        this.setState((prevState) => ({
          form: {
            ...this.state.form,
            useCredit: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          form: {
            ...this.state.form,
            useCredit: false,
          },
        }));
      }
    } else if (name === "adjustmentType") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.target.value,
          },
        },
        () => {
          if (event.target.value === "manual") {
            this.getInvoicesForSingleAccount();
          }
        }
      );
    } else {
      let value =
        name === "amount" ? parseFloat(event.target.value) : event.target.value;

      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  handleSubmit = async (event) => {
    let { form, selectedAccount, paymentAccount } = this.state;
    const obj = { ...form };

    let result = await axios.post(`/api/payments`, obj);

    if (result.data.status === "success") {
      this.setState(
        {
          addOption: false,
          editOption: false,
          isVisableAddBtn: false,
          paymentAccount: "",
          totalInvoiceBalance: "",
          form: {
            refNum: "",
            amount: "",
            paymentMode: "",
            paymentDate: "",
            remarks: "",
            adjustmentType: "",
            recipientDate: "",
            contractNumber: "",
          },
          selectedAccount: {},
        },
        () => this.getPaymentList(selectedAccount.contractNumber)
      );

      toast.success(this.props.translate("paymentAdded"));
    } else {
      let result1;
      const splitString = result.data.message.split(": ");
      if (splitString.length >= 2) {
        result1 = splitString[1]; // Take the second part after splitting
      }

      toast.error(this.props.translate(result1));
      // this.props.dispatch(
      //   toast.error({
      //     message: this.props.translate(result.data.message),
      //     autoDismiss: 5,
      //     location: this.props.location,
      //   })
      // );
    }
  };

  getAccounts = () => {
    if (this.props.logInUser && this.props.logInUser.id) {
      instance({
        method: "GET",
        url: `api/accounts/get?userId=${this.props.logInUser.id}&all=true&limit=20&page=1`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({
            users: res.data,
          });
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  };

  handleChangeLicenseExpDate = (name, event) => {
    if (name === "paymentDate" || name === "recipientDate") {
      const dateValue = event ? event.toISOString() : null;
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: dateValue,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  updatePayment = async () => {
    const { selectedPayment, form } = this.state;
    const obj = { ...form };
    let result = await axios.put(`/api/payments/${selectedPayment.id}`, obj);
    if (result.data.status === "success") {
      this.setState({
        addOption: false,
        editOption: false,
        isVisableAddBtn: false,
      });

      toast.success(this.props.translate("Payment Updated"));
    } else {
      toast.error(this.props.translate(result.data.message));
    }
  };

  deletePayment = async () => {
    const { selectedPayment } = this.state;
    let result = await axios.delete(`/api/payments/${selectedPayment.id}`);
    if (result.data.status === "success") {
      // this.getFilteredPAyments(this.state.to,this.state.from,this.state.account,this.state.vehcile,this.state.area)

      toast.success(this.props.translate("Payment Deleted"));
    } else {
      toast.error(this.props.translate(result.data.message));
    }
  };

  addDevice() {
    this.setState({
      // pageSize:-1,
      addOption: true,
    });
  }

  paymentSelection = async (item) => {
    if (item) {
      await this.setState({
        // defaultDeviceList: false,
        selectedPayment: item[0],
        editOption: true,
        form: { ...item[0] },
      });
    }
  };

  modalControle() {
    this.setState({
      addOption: false,
      editOption: false,
      settleAmountOption: false,
      totalInvoiceBalance: "",
      selectedInvoices: "",
      form: "",
      pageSize: 20,
    });
  }

  goBack = () => {
    this.setState({
      addOption: false,
      settleAmountOption: false,
      editOption: false,
      showPayments: false,
      openFilter: false,
      totalInvoiceBalance: 0,
      selectedInvoices: "",
      form: "",
      paymentNumber: "",
      refNumber: "",
      searchPayment: "",
      pageSize: 20,
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  componentDidMount() {
    this.getAccounts();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  setPagination = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    if (this.state.filteredData || this.state.openFilter) {
      this.setState({ page: 1, pageSize: rowsPerPage }, () => {
        this.getFilteredPAyments(
          this.state.to,
          this.state.from,
          this.state.account,
          this.state.vehcile,
          this.state.area
        );
      });
    } else {
      this.setState({ page: 1, pageSize: rowsPerPage }, () => {
        this.getPaymentList(this.state.selectedAccount.contractNumber);
      });
    }
  };

  handleChangePage = (value) => {
    if (this.state.filteredData || this.state.openFilter) {
      this.setState({ page: value }, () => {
        this.getFilteredPAyments(
          this.state.to,
          this.state.from,
          this.state.account,
          this.state.vehcile,
          this.state.area
        );
      });
    } else {
      this.setState({ page: value }, () => {
        this.getPaymentList(this.state.selectedAccount.contractNumber);
      });
    }
  };
  openFilters = (type) => {
    this.setState(
      {
        to: "",
        from: "",
        account: "",
        vehcile: "",
        area: "",
        openFilter: true,
        showPayments: false,
        filteredData: false,
        form: {
          contractNumber: this.props?.ServerSetting?.contractNumber,
        },
        selectedAccount: this.props?.ServerSetting,
      },
      () =>
        this.getFilteredPAyments(
          this.state.to,
          this.state.from,
          this.state.account,
          this.state.vehcile,
          this.state.area,
          type
        )
    );
  };

  singlePaymentSelection = (type) => {
    // console.log("paymentAccount=====", type);
    this.setState(
      {
        to: "",
        from: "",
        account: type?.contractNumber,
        vehcile: "",
        area: "",
        openFilter: false,
        showPayments: true,
        filteredData: false,
        form: {
          contractNumber: type?.contractNumber,
        },
        selectedAccount: type,
        formSettleAmount: {
          contractNumberForSettle: type.contractNumber,
        },
        paymentAccount: type,
        // isVisableSettleBtn: true,
      },
      () => {
        this.getFilteredPAyments(
          this.state.to,
          this.state.from,
          this.state.account,
          this.state.vehcile,
          this.state.area,
          type
        );
        this.checkRequiredFields();
        this.checkRequiredFields1();
      }
    );
  };

  paymentFilter = async (to, from, account, vehcile, area) => {
    this.setState(
      {
        // selectedAccount:item,
        to: to,
        from: from,
        account: account,
        vehcile: vehcile,
        area: area,
        page: 1,
        pageSize: 20,
        itemSearch: "",
        filteredData: true,
      },
      () => {
        this.getFilteredPAyments(to, from, account, vehcile, area);
      }
    );
  };

  downloadInvoices = (to, from, account, vehcile, area, type) => {
    let accept;
    let contractNumber = this.state.showPayments
      ? `&contractNumber=${this.state.form.contractNumber}`
      : account
      ? account
      : null;
    if (type === "downloadPdf") {
      accept = "application/pdf";
    } else {
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    let header;
    header = {
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: accept,
      }),
      method: "GET",
    };
    fetch(`/api/payments/export?to=${to}&from=${from}${contractNumber}`, {
      ...header,
    })
      .then((response1) => {
        if (response1.status === 200) {
          if (type === "downloadPdf") {
            response1.blob().then((response) => {
              this.setState(
                {
                  selectedAreaId: "",
                  selectedvehicleId: "",
                  to: "",
                  from: "",
                  loader: false,
                },
                () => {
                  this.saveData(response, "payments.pdf");
                }
              );
            });
          } else {
            response1.blob().then((response) => {
              this.setState(
                {
                  selectedAreaId: "",
                  selectedvehicleId: "",
                  to: "",
                  from: "",
                  loader: false,
                },
                () => {
                  this.saveData(response, "payments.xlsx");
                }
              );
            });
          }
        } else {
          throw response1;
        }
      })

      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // this.setState({ loading: false })
      }, 0);
    }
    // this.setState({ loading: false })
  };
  getSearchedPayments = () => {
    this.getFilteredPAyments(
      this.state.to,
      this.state.from,
      this.state.account,
      this.state.vehcile,
      this.state.area,
      ""
    );
  };

  getFilteredPAyments = (to, from, account, vehcile, area, type) => {
    let {
      page,
      pageSize,
      searchPayment,
      contractType,
      showPayments,
      selectedAccount,
      paymentNumber,
      refNumber,
    } = this.state;
    let apiUrl;
    if (showPayments) {
      // inCase of selecting single account
      if (to) {
        apiUrl = `/api/payments/get?contractNumber=${selectedAccount?.contractNumber}&to=${to}&from=${from}${account}${vehcile}${area}&page=${page}&limit=${pageSize}&paymentNumber=${paymentNumber}&refNumber=${refNumber}`;
      } else {
        apiUrl = `/api/payments/get?contractNumber=${selectedAccount?.contractNumber}&page=${page}&limit=${pageSize}&paymentNumber=${paymentNumber}&refNumber=${refNumber}`;
      }
    } else {
      if (to) {
        apiUrl = `/api/payments/get?&contractType=${contractType}&to=${to}&from=${from}${account}${vehcile}${area}&page=${page}&limit=${pageSize}&paymentNumber=${paymentNumber}&refNumber=${refNumber}`;
      } else {
        apiUrl = `/api/payments/get?&contractType=${contractType}&page=${page}&limit=${pageSize}&paymentNumber=${paymentNumber}&refNumber=${refNumber}`;
      }
    }
    source = axios.CancelToken.source();
    this.setState(
      {
        loader2: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            this.setState({
              filteredData: true,
              paymentList: response?.data?.data || [],
              openTable: true,
              loader: false,

              loader2: false,
            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  };

  SearchItem = (e, name) => {
    source.cancel();
    if (this.state.filteredData || this.state.openFilter) {
      if (name === "paymentNumber" || name === "refNumber") {
        this.setState(
          {
            [name]: e.target.value,
          }
          // () =>
          //   this.getFilteredPAyments(
          //     this.state.to,
          //     this.state.from,
          //     this.state.account,
          //     this.state.vehcile,
          //     this.state.area,
          //     ""
          //   )
        );
      }
    } else {
      this.setState(
        {
          searchPayment: e.target.value,
        },
        () => {
          this.getPaymentList(this.state.selectedAccount.contractNumber);
        }
      );
    }
  };

  getPaymentList = (id) => {
    let { page, pageSize, searchPayment } = this.state;
    // &search=${searchPayment}
    let apiUrl;
    source = axios.CancelToken.source();

    apiUrl = `/api/payments/get?contractNumber=${id}&page=${page}&limit=${pageSize}&search=${searchPayment}`;
    this.setState(
      {
        loader: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            this.setState({
              payments: response?.data?.data || [],
              // paymentList: result.data.data,
              filteredData: false,
              openTable: true,
              loader: false,
            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  };

  setContractType = (value) => {
    this.setState({
      contractType: value,
    });
  };

  setDefaultForm = () => {
    this.setState({
      form: {
        ...formDefault,
        contractNumber: this.state.showPayments
          ? this.state.form.contractNumber
          : "",
      },
      // formSettleAmount: {},
      // paymentAccount: "",
      // isVisableSettleBtn: false,
      creditAmountForSettle: "",
      overallInvoiceBalance: "",
    });
  };
  //  --------------------------------------Settelemt Settings-------------------------------------------------------------

  settleAmount = () => {
    this.setState({
      // settlementForm: {},
      settleAmountOption: true,
    });
  };

  allUsersId1 = (event, items) => {
    // for selecting single Invoice
    let allData = [];
    let totalBalance;
    // const roundedNum = Math.round(num * 100) / 100;
    if (event.target.checked) {
      let ids = Array.isArray(this.state.formSettleAmount.invoiceIds)
        ? [...this.state.formSettleAmount.invoiceIds, items.id]
        : [items.id];
      let cal = this.state.totalInvoiceBalance + items.invoicebalance;
      totalBalance = Math.round(cal * 100) / 100;
      this.setState(
        {
          totalInvoiceBalance: totalBalance,
          formSettleAmount: {
            ...this.state.formSettleAmount,
            invoiceIds: ids,
          },
        },
        () => this.checkRequiredFields1()
      );
    } else {
      let ids = Array.isArray(this.state.formSettleAmount.invoiceIds)
        ? this.state.formSettleAmount.invoiceIds.filter((id) => id !== items.id)
        : [];
      let cal = this.state.totalInvoiceBalance - items.invoicebalance;
      totalBalance = Math.round(cal * 100) / 100;
      this.setState(
        {
          totalInvoiceBalance: totalBalance,
          formSettleAmount: {
            ...this.state.formSettleAmount,
            invoiceIds: ids,
          },
        },
        () => this.checkRequiredFields1()
      );
    }

    this.state.manualPayments.map((it) => {
      if (it.id === items.id) {
        let row = {
          ...it,
          check: event.target.checked,
        };
        allData.push(row);
      } else {
        allData.push(it);
      }
    });

    this.setState({ manualPayments: allData }, () =>
      this.checkRequiredFields1()
    );
  };

  selectAll1 = (e) => {
    let allData = [];
    let totalBalance;
    let ids = [];
    let cal = 0;
    this.state.manualPayments.map((it) => {
      let row = {
        ...it,
        check: e,
      };
      if (e) {
        cal = cal + it.invoicebalance;
        ids.push(it.id);
      } else {
        cal = 0;
        ids = [];
      }
      allData.push(row);
    });
    totalBalance = Math.round(cal * 100) / 100;
    this.setState(
      {
        totalInvoiceBalance: totalBalance,
        formSettleAmount: {
          ...this.state.formSettleAmount,
          invoiceIds: ids,
        },
      },
      () => this.checkRequiredFields1()
    );
    this.setState(
      { manualPayments: allData, totalInvoiceBalance: totalBalance },
      () => this.checkRequiredFields1()
    );
  };

  checkRequiredFields = () => {
    const {
      refNum,
      amount,
      paymentMode,
      adjustmentType,
      recipientDate,
      paymentDate,
      contractNumber,
    } = this.state.form;

    if (
      refNum &&
      amount &&
      paymentMode &&
      adjustmentType &&
      recipientDate &&
      paymentDate &&
      contractNumber &&
      amount
    ) {
      //   if(totalInvoiceBalance){
      //     if(totalInvoiceBalance &&  amount <= totalInvoiceBalance){
      //     this.setState({ isVisableAddBtn: true, balanceError:"" });
      //   } else {
      //     this.setState({ isVisableAddBtn: false,  balanceError:"Invoice Balance should be greater then Amount" });
      //   }
      // }else{
      this.setState({ isVisableAddBtn: true, balanceError: "" });
      // }
    } else {
      this.setState({ isVisableAddBtn: false, balanceError: "" });
    }
    // const { contractNumberForSettle } = this.state.formSettleAmount;
    // const { creditAmountForSettle, overallInvoiceBalance } = this.state;

    // if (
    //   contractNumberForSettle &&
    //   creditAmountForSettle &&
    //   overallInvoiceBalance
    // ) {
    //   this.setState({ isVisableSettleBtn: true });
    // } else {
    //   this.setState({ isVisableSettleBtn: true });
    // }
  };

  checkRequiredFields1 = () => {
    const {
      contractNumberForSettle,
      adjustAmount,
      adjustmentType,
      invoiceIds,
    } = this.state.formSettleAmount;
    const { creditAmountForSettle, overallInvoiceBalance } = this.state;

    if (
      contractNumberForSettle &&
      creditAmountForSettle &&
      overallInvoiceBalance
    ) {
      if (adjustmentType === "manual") {
        if (
          adjustAmount <= creditAmountForSettle &&
          adjustAmount <= overallInvoiceBalance &&
          invoiceIds?.length > 0
        ) {
          this.setState({ isVisableSettleBtn: true });
        } else {
          this.setState({ isVisableSettleBtn: false });
        }
      } else {
        this.setState({ isVisableSettleBtn: true });
      }
    } else {
      this.setState({ isVisableSettleBtn: false });
    }
  };
  handleChangeSettleAmount = (name, event) => {
    if (event) {
      if (name === "adjustAmount" || name === "adjustmentType") {
        this.setState(
          {
            formSettleAmount: {
              ...this.state.formSettleAmount,
              [name]: event.target.value,
            },
            // isVisableSettleBtn: true,
          },
          () => {
            this.checkRequiredFields1();
            // this.getInvoicesForSingleAccount();
            // this.getCredtit();
          }
        );
      } else {
        this.setState(
          {
            [name]: event,
            formSettleAmount: {
              ...this.state.formSettleAmount,
              contractNumberForSettle: event.contractNumber,
            },
            // isVisableSettleBtn: true,
          },
          () => {
            this.checkRequiredFields1();
            this.getInvoicesForSingleAccount();
            this.getCredtit();
          }
        );
      }
    } else {
      this.setDefaultForm();
    }
  };

  onCancelSettle = () => {
    this.setState({ onSettleAmountConfirmation: false });
  };
  onOKSettle = () => {
    this.setState({ onSettleAmountConfirmation: false }, () => {
      this.handleSubmitSettleAmount();
    });
  };
  onConfirmSettle = () => {
    this.setState({ onSettleAmountConfirmation: true });
  };
  handleSubmitSettleAmount = async () => {
    const { formSettleAmount } = this.state;
    const {
      contractNumberForSettle,
      adjustmentType,
      adjustAmount,
      invoiceIds,
    } = formSettleAmount;

    let url;

    // Conditional URL construction based on adjustmentType
    if (adjustmentType === "manual") {
      // Convert invoiceIds array into query parameters
      const invoiceIdsParam = invoiceIds
        ?.map((id) => `invoiceIds=${id}`)
        ?.join("&");
      url = `/api/payments/settle?contractNumber=${contractNumberForSettle}&adjustAmount=${adjustAmount}&${invoiceIdsParam}`;
    } else if (adjustmentType === "fifo") {
      url = `/api/payments/settle?contractNumber=${contractNumberForSettle}`;
    }
    // console.log("Url=====", url);
    instance({
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // Reset form and state
        this.setState({
          form: {
            ...formDefault,
            contractNumber: this.state.showPayments
              ? this.state.form.contractNumber
              : "",
          },
          formSettleAmount: {},
          paymentAccount: "",
          isVisableSettleBtn: false,
          creditAmountForSettle: "",
          overallInvoiceBalance: "",
          settleAmountOption: false,
        });

        toast.success(this.props.translate("settledAmountSuccessful"));
      })
      .catch((error) => {
        // Handle and display error message
        let result1;
        const splitString = error.message.split(": ");
        if (splitString.length >= 2) {
          result1 = splitString[1]; // Take the second part after splitting
        }

        toast.error(this.props.translate(result1));
      });
  };

  render() {
    // console.log("form====", this.state.formSettleAmount);
    if (this.props.logInUser) {
      return (
        <Layout
          {...this.state}
          {...this.props}
          paymentFilter={this.paymentFilter}
          downloadInvoices={this.downloadInvoices}
          noSidebar={
            this.state.openFilter || this.state.showPayments ? "" : "noSidebar"
          }
        >
          <PaymentList
            {...this.state}
            {...this.props}
            allUsersId1={this.allUsersId1}
            selectAll1={this.selectAll1}
            getSearchedPayments={this.getSearchedPayments}
            searchInvoices={this.searchInvoices}
            users={this.state.users}
            payments={this.state.payments}
            paymentList={
              this.state.openFilter || this.state.filteredData
                ? this.state.paymentList
                : this.state.payments
            }
            setDefaultForm={this.setDefaultForm}
            goBack={this.goBack}
            setContractType={this.setContractType}
            selectAll={this.selectAll}
            SearchItem={this.SearchItem}
            addDevice={this.addDevice}
            addOption={this.state.addOption}
            settleAmount={this.settleAmount}
            settleAmountOption={this.state.settleAmountOption}
            handleSubmit={this.handleSubmit}
            data={this.state.form}
            handleChangeForAccount={this.handleChangeForAccount}
            handleChange1={this.handleChangeSettleAmount}
            handleSubmitSettleAmount={this.onConfirmSettle}
            creditAmountForSettle={this.state.creditAmountForSettle}
            overallInvoiceBalance={this.state.overallInvoiceBalance}
            allUsersId={this.allUsersId}
            handleChange={this.handleChange}
            editOption={this.state.editOption}
            paymentSelection={this.paymentSelection}
            selectedPayment={this.state.selectedPayment}
            updatePayment={this.updatePayment}
            removeEnable={this.removeEnable}
            onDeleteConfirmation={this.state.onDeleteConfirmation}
            modalControle={this.modalControle}
            deletePayment={this.deletePayment}
            onCancel={this.onCancel}
            // editEnable={this.editEnable}
            setPagination={this.setPagination}
            isVisableAddBtn={this.state.isVisableAddBtn}
            isVisableSettleBtn={this.state.isVisableSettleBtn}
            checkRequiredFields={this.checkRequiredFields}
            handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            selectedAccount={this.state?.selectedAccount || {}}
            showPayments={this.state.showPayments}
            accountSelection={this.accountSelection}
            // selecteItem={this.selecteItem}
            singlePaymentSelection={this.singlePaymentSelection}
            openFilter={this.state.openFilter}
            openFilters={this.openFilters}
          />
          {this.state.onSettleAmountConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancelSettle}
              onOk={this.onOKSettle}
              title={this.props.translate(
                "Do You want to Settle Outstanding Payment from Advance Payment?"
              )}
            />
          )}
        </Layout>
      );
    } else {
      fetch(
        "/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05",
        {
          headers: { Accept: "application/json; odata=verbose" },
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              if (res.status === "success") {
                let userInfo = res.data;
                this.props.setActiveLanguage(
                  userInfo?.attributes?.lang || "en"
                );
                this.props.dispatch(logInUserInfo(userInfo));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");

                toast.error(this.props.translate(err[1]));
                this.props.navigate("/login");
              }
            });
          } else {
            this.props.navigate("/login");
            throw response;
          }
        })
        .catch((e) => {
          toast.error("somethingWentWrong");
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(Payment));

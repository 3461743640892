import React, { Component } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleModalWrapped from './../common/Modal'
import TextField from './../common/TextField'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class addRole extends Component {
  render () {
    const Content = (
      <div>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='name'
              label={this.props.translate('sharedName')}
              type='text'
              placeholder=''
              value={this.props.form.name}
              onChange={this.props.handleChange('name')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='description'
              label={this.props.translate('description')}
              type='text'
              placeholder=''
              value={this.props.form.description}
              onChange={this.props.handleChange('description')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          {/* <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='reportInterval'
              label={this.props.translate('reportInterval')}
              type='text'
              placeholder=''
              value={this.props.addOption?this.props.form.reportInterval:Math.floor(Math.abs((this.props.form.reportInterval) /86400))}
              onChange={this.props.handleChange('reportInterval')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id='historyInterval'
              label={this.props.translate('historyInterval')}
              type='text'
              placeholder=''
              value={this.props.form.historyInterval}
              onChange={this.props.handleChange('historyInterval')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid> */}
        </Grid>
        <br />
      </div>
    )
    return (
      <SimpleModalWrapped
        visable={true}
        translate={this.props.translate}

        isButtonVisable={true}
        title={this.props.translate('roleManagement')}
        formSubmit={this.props.formSubmit}
        modalControle={this.props.modalControle}
        btnclosetext={this.props.translate('sharedBack')}
        content={Content}
        buttonText={this.props.buttonText}
        isVisableBtn={this.props.isVisableRoleBtn}
        attBtnNotshow
      />
    )
  }
}
const AddRoleModal = withStyles(styles)
export default AddRoleModal((addRole))

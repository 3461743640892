import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";

import { Grid, InputAdornment } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from "../common/TextField";
import moment from "moment";
import MenuItem from "../common/MenuItem";
import DateRangePicker from "../Invoice/DateRangePicker";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
const AlertType = [
  {
    key: "services",
    name: "Maintenance Due",
  },
  {
    key: "arrears",
    name: "Payment Due",
  }
];
const day = [
  {
    id: '1',
    name: 'su'
  },
  {
    id: '2',
    name: 'mo'
  },
  {
    id: '3',
    name: 'tu'
  },
  {
    id: '4',
    name: 'we'
  },
  {
    id: '5',
    name: 'th'
  },
  {
    id: '6',
    name: 'fr'
  },
  {
    id: '7',
    name: 'sa'
  }
]
const repeat = [
  {
    key: 'once',
    value: 'once'
  },
  {
    key: 'daily',
    value: 'daily'
  },
  {
    key: 'weekly',
    value: 'weekly'
  },
  {
    key: 'monthly',
    value: 'monthly'
  }
]
class addAlerts extends Component {
  constructor() {
    super();
    this.state = {};
  }
  
  valuetext = value => {
    return moment('2016-03-13')
      .startOf('day')
      .add(value, 'minutes')
      .format('HH:mm')
  }

  selectRange = type => {
    const rangesFuture = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      'Next 7 Days': [
        moment().endOf('day'),
        moment()
          .add(7, 'days')
          .endOf('day')
      ],
      'Next 30 Days': [
        moment().endOf('day'),
        moment()
          .add(30, 'days')
          .endOf('day')
      ],
      'This Month': [
        moment()
          .startOf('month')
          .startOf('day'),
        moment()
          .endOf('month')
          .endOf('day')
      ],
      'Next Month': [
        moment()
          .add(1, 'month')
          .startOf('month')
          .startOf('day'),
        moment()
          .add(1, 'month')
          .endOf('month')
          .endOf('day')
      ],
      'Never End': [null, null]
    }
    if (rangesFuture && rangesFuture[type]) {
      this.props.showJobDates(rangesFuture[type][0], rangesFuture[type][1])
    }
    this.props.handleSelectedJobDate(type)
  }
  selectRangePicker = (e, date) => {
    this.props.showJobDates(date.startDate, date.endDate)
  }
  render() {


    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          // isVisableBtn={true}
          isVisableBtn={this.props.enableSubmit}

          notVisableBackbtn={false}
          // showPortList={this.props.showPortList}
          // showPort={this.props.showPort}
          content={
            <>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3} style={{zIndex:99}}>
                <TextField
                  id='description'
                  label={this.props.translate('sharedName')}
                  type='text'
                  placeholder=''
                  value={this.props.form?.description || ''}
                  onChange={(e) => this.props.handleChange("description", e)}

                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                />
              </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{zIndex:99}}>
                    <TextField
                      id="scheduleAlerts"
                      select
                      label={this.props.translate("scheduleAlerts")}
                      value={this.props.form?.type || ""}
                      onChange={(e) => this.props.handleChange("type", e)}
                      SelectProps={{
                        MenuProps: {
                          // className: classes.menu,
                        },
                      }}
                      margin="dense"
                      fullWidth
                      // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                      required
                    >
                      {AlertType.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.name)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid container spacing={1} style={{padding:"0px 10px"}}>

                
                <Grid item xs={12} sm={6} md={4} lg={3} style={{zIndex:99}}>
                <h4 style={{ marginTop: 15, marginBottom: 10 }}>{this.props.translate('Execution Schedule')}</h4>      
                  
                  
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format="dd/MM/yyyy"
                  required
                  // error={false}
                  margin="dense"
                  disabled
                  label={this.props.translate("from")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month", "day"]}
                  error={
                    !this.props.form?.from
                  }
                  helperText={
                    !this.props.form?.from
                      ? this.props.translate("enterDate")
                      : ""
                  }
                  value={
                    this.props && this.props.form && this.props.form
                      ? new Date(`${this.props.form.attributes.start}`)
                      : null
                  }
                  // allowEmpty={true}
                  onChange={(e) => this.props.handleChange("from", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
            </LocalizationProvider> */}
                {/* <FormControl component='fieldset'>
          <RadioGroup
            aria-label='selectedJobDate'
            name='selectedJobDate'
            value={this.props.selectedJobDate}
            onChange={e =>
              this.selectRange(
                e.target.value
              )
            }
            row
          >
            {['reportToday', 'reportNext7Days', 'reportNext30Days', 'reportThisMonth', 'reportNextMonth', 'endNever', 'custom'].map(option => (
              <FormControlLabel
                key={option}
                classes={{
                  label: 'form-contol-label'
                }}
                value={option}
                control={<Radio size='small' />}
                label={this.props.translate(option)}
                labelPlacement='end'
              />
            ))}


          </RadioGroup>
                      </FormControl>
                      {this.props.form.attributes.selectedJobDate === 'custom' ? (
                        <div
                          style={{
                            width: 225
                          }}
                        > */}
                          <DateRangePicker
                            fill
                            fullWidth
                            ranges={[]}
                            minDate={moment()}
                            selectedDate={this.props.selectedDate}

                            // selectedDate={`${
                            //   this.props.form.start
                            //     ? moment(this.props.form.start).format(
                            //         'YYYY-MM-DD HH:mm'
                            //       )
                            //     : ''
                            // }-${
                            //   this.props.form.end
                            //     ? moment(this.props.form.end).format(
                            //         'YYYY-MM-DD HH:mm'
                            //       )
                            //     : ''
                            // }`}
                            label={this.props.translate('schedule')}
                            onEvent={this.props.showDates}
                          />
                        {/* </div>
                       ) : null}  */}
                      <h4 style={{ marginTop: 15, marginBottom: 10 }}>{this.props.translate('triggerTime')}</h4>      

                       <TextField
                          id="time"
                          label={this.props.translate('setTime')}
                          type="time"
                          value={
                            this.props.form.triggerTime
                              ? moment(this.props.form.triggerTime).format(
                                  'HH:mm'
                                )
                              : null
                              
                          }
                           onChange={(e) => this.props.handleChange("triggerTime", e)}
                          //  onChange={(e) => this.props.handleChange}
                          // defaultValue="07:30"
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                        />  
                      {/* <TimePicker
                        clearIcon={false}
                        disableClock
                        value={
                          this.props.form.triggerTime
                            ? moment(this.props.form.triggerTime).format(
                                'HH:mm'
                              )
                            : null
                        }
                        minutePlaceholder='mm'
                        hourPlaceholder='hh'
                        // onChange={this.props.handleChange('triggerTime')}
                        onChange={(e) => this.props.handleChange("triggerTime", e)}
                      /> */}
                      <h4 style={{ marginTop: 15, marginBottom: 10 }}>{this.props.translate('repeat')}</h4>
                      <TextField
                      select
                      label={this.props.translate('repeat')}
                      value={this.props.form.period || ''}
                      variant='outlined'
                      onChange={e => this.props.handleChangePeriod(e.target.value)}
                      margin='dense'
                      fullWidth
                      >
                        {repeat.map(option => (
                          <MenuItem key={option.key} value={option.key} >
                            {this.props.translate(option.value)}
                          </MenuItem>
                        ))}
                      </TextField>

                      {this.props.form &&
                      this.props.form.period &&
                      ['daily', 'weekly', 'monthly'].includes(
                        this.props.form.period
                      ) && (
                        <Grid item xs={12}>
                          {this.props.form.period === 'daily'
                            ? (<div
                            style={{
                              border: `1px solid ${this.props.themecolors.textColor}15`,
                              display: 'inline-flex'
                            }}
                          >
                            {day.map(option => (
                              <div
                                key={option.id}
                                style={{
                                  display: 'inline-flex',
                                  textTransform: 'uppercase',
                                  fontWeight: 600,
                                  marginRight: option.id === '7' ? 0 : 1,
                
                                  backgroundColor: this.props.form.days.includes(
                                    option.id
                                  )
                                    ? this.props.themecolors.themeLightColor
                                    : 'transparent',
                                  color: this.props.themecolors.textColor
                                }}
                              >
                                <label
                                  style={{
                                    padding: 5,
                                    minWidth: 30,
                                    cursor: 'pointer'
                                  }}
                                >
                                  <input
                                    type='checkbox'
                                    key={option.id}
                                    checked={this.props.form.days.includes(
                                      parseInt(option.id)
                                    )}
                                    onChange={e =>
                                      this.props.onDayChange(option)
                                    }
                                    style={{ display: 'none' }}
                                  />
                                  {this.props.translate(option.name)}
                                </label>
                              </div>
                            ))}
                          </div>) : null}
                          {this.props.form.period === 'weekly'
                            ? (<div
                            style={{
                              border: `1px solid ${this.props.themecolors.textColor}15`,
                              display: 'inline-flex'
                            }}
                          >
                            {day.map(option => (
                              <div
                                key={option.id}
                                style={{
                                  display: 'inline-flex',
                                  textTransform: 'uppercase',
                                  fontWeight: 600,
                                  marginRight: option.id === '7' ? 0 : 1,
                
                                  backgroundColor: parseInt(this.props.form.days) === parseInt(option.id)
                                    ? this.props.themecolors.themeLightColor
                                    : 'transparent',
                                  color: this.props.themecolors.textColor
                                }}
                              >
                                <label
                                  style={{
                                    padding: 5,
                                    minWidth: 30,
                                    cursor: 'pointer'
                                  }}
                                  onClick={e =>
                                    this.props.onDayChange2('days', option.id)
                                  }
                                >
                                  {this.props.translate(option.name)}
                                </label>
                              </div>
                            ))}
                          </div>) : null}
                          
                          {this.props.form.period === 'monthly' ? (
                            <TextField
                              type="number"
                              fullWidth
                              label={this.props.translate('date')}
                              value={this.props.form.days}
                              onChange={e =>
                                this.props.onDayChange2(
                                  'days',
                                  e.target.value
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position='start'
                                    disableTypography
                                  >
                                    {this.props.translate('day')}
                                  </InputAdornment>
                                )
                              }}
                            />
                          ) : null}
                        </Grid>
                      )}
                      {/* <h4 style={{ marginTop: 15, marginBottom: 10 }}>{this.props.translate('setEmail')}</h4>
                      <RadioGroup
                      aria-label='position'
                      name='position'
                      value={this.props.form.mailTo}
                      // onChange={e =>
                      //   this.props.handleChangeForEmails('mailTo', e)
                      // }
                      row
                    >
                      <FormControlLabel
                        classes={{
                          label: 'form-contol-label'
                        }}
                        value='user'
                        control={<Radio size='small' />}
                        label={this.props.translate('userEmail')}
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        classes={{
                          label: 'form-contol-label'
                        }}
                        value='custom'
                        control={<Radio size='small' />}
                        label={this.props.translate('customEmails')}
                        labelPlacement='end'
                      />
                    </RadioGroup>         */}
                  </Grid>

                  </Grid> 








                 {/* <Grid container spacing={1}>
                   <Grid item xs={12} sm={6} md={4} lg={3} style={{zIndex:99}}>
                    <h4 style={{ marginBottom: 30 }}>
                      {this.props.translate('reportBetweenTime')}
                    </h4>
                    <div style={{ paddingLeft: 20, paddingRight: 20,}}>
                      <Slider
                        style={{marginTop:"15px",  color: this.props.themecolors.success}}
                        value={this.props.controlTime}
                        onChange={this.props.handleTimeControl}
                        valueLabelDisplay='on'
                        aria-labelledby='range-slider'
                        valueLabelFormat={this.valuetext}
                        max={1439}
                        classes={{
                          root: 'theme-range-slider',
                          thumb: 'theme-range-slider-thumb',
                          track: 'theme-range-slider-track',
                          rail: 'theme-range-slider-rail',
                          valueLabel: 'theme-range-slider-label'
                        }}
                        step={5}
                      />
                    </div>
                    </Grid>
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={4} lg={3} style={{zIndex:99}}>
                  {/* <h4 style={{ marginTop: 10, marginBottom: 5 }}>
            {this.props.translate('reportForDays')}
                    </h4>
                    <div
                      style={{
                        border: `1px solid ${this.props.themecolors.textColor}15`,
                        display: 'inline-flex',
                        marginBottom: 10
                      }}
                    >
                      {day.map(option => (
                        <div
                          key={option.id}
                          style={{
                            display: 'inline-flex',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            marginRight: option.id === '7' ? 0 : 1,

                            backgroundColor: this.props.form.attributes.controlDays.includes(
                              parseInt(option.id)
                            )
                              ? this.props.themecolors.themeLightColor
                              : this.props.themecolors.backgroundColor,
                            color: this.props.form.attributes.controlDays.includes(
                              parseInt(option.id)
                            ) ? this.props.themecolors.themeLightInverse : this.props.themecolors.textColor
                          }}
                        >
                          <label
                            style={{
                              padding: 5,
                              minWidth: 30,
                              cursor: 'pointer'
                            }}
                          >
                            <input
                              type='checkbox'
                              key={option.id}
                              checked={this.props.form.attributes.controlDays.includes(
                                parseInt(option.id)
                              )}
                              onChange={e =>
                                this.props.onDayChange3(e.target.checked, option)
                              }
                              style={{ display: 'none' }}
                            />
                            {this.props.translate(option.name)}
                          </label>
                        </div>
                      ))}
                    </div> */}
                   </Grid>
             </Grid>
            </>
          }
        />
      </Fragment>
    );
  }
}

const AddAlertsModal = withStyles(styles);
export default AddAlertsModal((addAlerts));

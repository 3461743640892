import React, { Component } from "react";
import { connect } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, Typography} from "@mui/material";
import { withStyles} from "@mui/styles";

import instance from "../../axios";
import pieChartDefault from "../../Components/common/Charts/pieChartDefault";
import Select from "react-select";
import moment from "moment";
import Button from "../../Components/common/Button";
import Tooltip from "@mui/material/Tooltip";
import { nFormatter, replaceSpecialCh } from "../../Helpers";
import Skeleton from "@mui/material/Skeleton";
import axios from 'axios'
import { addTab10, addTab11, addTab13, addTab14, addTab1,addTab2,addTab3,addTab4,addTab5,addTab6,addTab7, addTab8, addTab9, addTab15 , addTab18, addTab17, addTab19} from "../../Actions/MaintDashboard";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';

// import { ConsoleWriter } from "istanbul-lib-report";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import { Scrollbar } from "react-scrollbars-custom";
import withRoutes from "../../HOC/HocRoutes";
 
const CancelToken = axios.CancelToken
let source
const FiltersOption = [
  { label: "MTD", value: 1 },
  { label: "Last Month", value: 2 },
  { label: "Last 3 months", value: 3 },
  { label: "YTD", value: 4 },
  { label: "Last Year", value: 5 },
  { label: "ALL", value: 6 },
];

const FiltersOptionWithoutMTD = [
  { label: "Last Month", value: 2 },
  { label: "Last 3 months", value: 3 },
  { label: "YTD", value: 4 },
  { label: "Last Year", value: 5 },
  { label: "ALL", value: 6 },
];

const textStyle = {
  marginLeft: 5,
  color: "#ffffff",
  textAlign: "left",
  fontSize: "18px",
  fontWeight: "700",
  fontStyle: "italic",
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden !important",
  // overflow: "hidden",
  textOverflow: "ellipsis",
  paddding: "0 2px 0 2px",
};

const styles = {
  customTooltip: {
    fontSize: '16px', // Adjust the font size as needed
  },
};

const CustomTooltip = withStyles(styles)(Tooltip);


const MaintenanceItemEmpty = React.memo(() => {
  return (
    <div className={"mainten-section mainten"}>
      <div className="mainten-section-header">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton className="skeleton-pulse" width={150} height={25} />{" "}
          {/* </span>
        <span> */}
          <Skeleton className="skeleton-pulse" width={150} height={25} />{" "}
        </span>
      </div>

      <div className="mainten-section-body">
        <div className="mainten-split-section">
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />{" "}
          </div>
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />{" "}
          </div>{" "}
          <div>
            <Skeleton className="skeleton-pulse" width={"100%"} height={25} />{" "}
          </div>
        </div>
        {/* <div>
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton className="skeleton-pulse" width={150} height={35} />
            <Skeleton className="skeleton-pulse" width={150} height={35} />
          </span>
        </div>
        <div>
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            <Skeleton className="skeleton-pulse" width={150} height={35} />
            <Skeleton className="skeleton-pulse" width={150} height={35} />
          </span>
        </div> */}
      </div>
    </div>
  );
});
class MaintenanceDashbd extends Component {
  constructor() {
    super();
    this.state = {
      totalCost: 1,
      totalOutStanding: "",
      vehicleCategories: "",
      maintRequest: "",
      maintRequestCategory: "",
      packageAccount: "",
      usedPart: "",
      usedService: "",
      usedPackages: "",
      vehicleCategoryCost: "",
      topVehicleModelCost: "",
      salesClients: {},
      salesClients1: {},
      salesClientsWise: {},
      salesClientsTab1: {},
      clientVehiclesVehicleWise: [],
      maintRequestClientWise: {},
      maintRequestClientWiseTab1: {},
      maintenanceRequestCount: {},
      collectionsAndOutStanding: {},
      salesDataMonthly: [],
      salesDataYearly: [],
      vehiclesCategories: [],
      totalProfitTab14: [],
      partsMaintRequest: 0,
      serviceMaintRequest: 0,
      packageMaintRequest: 0,
      monthlySaleNetAmount: {},
      topVehiles:[],
      topMaintenancePerDay:[],
      topMaintenancePerKm:[],
      cogs: [],
      tab1: "ALL",
      tab2: "ALL",
      tab3: "ALL",
      tab4: "ALL",
      tab5: "ALL",
      tab6: "ALL",
      tab7: "ALL",
      tab8: "ALL",
      tab9: "ALL",
      tab10: "ALL",
      tab11: "ALL",
      tab12: "ALL",
      tab13: "ALL",
      tab14: "ALL",
      tab15: "MTD",
      totalOurDue: 0,
      totalAccounts: "",
      selectedVehicle: {},
      openGraph: false,
      loader: false,
      loader1: false,
      loader2: false,
      loader3: false,
      loader4: false,
      loader5: false,
      loader6: false,
      loader7: false,
      loader8: false,
      loader9: false,
      loader10: false,
      loader11: false,
      loader12: false,
      loader13: false,
      loader14: false,
      loader15: false,
      loaderA1: false,
      loaderA2: false,
      loaderA3: false,
      tab1loader1:false,
      tab1loader2:false,
      tab1loader3:false,
      tab1loader4:false,
      tab1loader5:false,
      loader18: false,
      loader17: false,
      loader19: false,
      xs: window.innerWidth <= 600,
      sm: window.innerWidth < 960,
    };
    window.addEventListener('resize', this.handleResize);
  }

  componentWillMount() {
    let  timezone = "";
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }else{
      timezone = "Asia/Riyadh"
    }
      const now = moment().tz(timezone);

      // ======== this part is for dashboards/cms/16, stations part, start of year YTD=========

      const startOfYear = now.clone().startOf("year");
      const endOfDay = now.clone().endOf("day");

      // ======== this part is for dashboards/cms/16, stations part, start of month MTD=========

      const startOfMonth = now.clone().startOf("month");
      const endOfMonth = now.clone().endOf("month");

      // ===================================================================

      const lastYearStart = startOfYear.clone().subtract(1, "year");
      const lastYearEnd = endOfDay.clone().subtract(1, "year");
      // ===================================================================
     

    this.getDashboardData(startOfYear.toISOString(),endOfDay.toISOString(), startOfMonth.toISOString() , endOfMonth.toISOString(), lastYearStart.toISOString(), lastYearEnd.toISOString());
  }
  componentWillUnmount() {
    source = CancelToken.source()
 }
 componentWillUnmount() {
  window.removeEventListener('resize', this.handleResize);
}

handleResize = () => {
  this.setState({
    xs: window.innerWidth <= 600, 
    sm: window.innerWidth < 960,
  });
};

  getDashboardData = (from, to,startOfMonth , endOfMonth,  lastYearStart ,  lastYearEnd) => {
    let tab1Data ={}
    // this.getCMS16(endOfMonth,startOfMonth, true); // this is for dashboards/cms/16,   stations part,
    this.getCMS16(true); // this is for dashboards/cms/16,   stations part,
    this.setState({ loaderA1: true , tab1loader3:true}, () => {
      if(this.props.tab1 && this.props.tab1.initFetch){
        this.setState({
          // totalCost:this.props.tab1.totalCost,
          initFetch:true,
          loaderA1:false,
          loader1:false,
          tab1loader3:false
        })
      }
      else{
        // instance({ url: `/api/dashboards/fmd/1?to=${to}&from=${from}`, method: "GET" })
        //   .then((res) => {
        //     this.setState({ totalCost: res, loaderA1: false ,  tab1loader3:false});
        //     tab1Data={
        //       ...tab1Data,
        //       totalCost:res,
        //       initFetch:true,
             
        //     }
        //     this.props.dispatch(addTab1(tab1Data))
        //   })
        //   .catch((err) => {});
          
        }
      });
      this.setState({ loader10: true, tab1loader2:true }, () => {
        if(this.props.tab1 && this.props.tab1.initFetch){
          this.setState({
            // totalCost:this.props.tab1.totalCost,
            totalAccounts:this.props.tab1.totalAccounts,
            initFetch:true,
            loaderA1:false,
            loader1:false,
            tab1loader2:false
          })
        }
        else{
          // instance({ url: `/api/dashboards/cms/14?to=${lastYearEnd}&from=${lastYearStart}`, method: "GET" })
          instance({ url: `/api/dashboards/cms/14`, method: "GET" })
            .then((res) => {
              this.setState({ totalAccounts: res,  tab1loader2:false });
              tab1Data={
                ...tab1Data,
                totalAccounts:res,
                initFetch:true,
              

              }
              this.props.dispatch(addTab1(tab1Data))
  
            })
            .catch((err) => {
              // console.log("err 1====", err)
            });
        }
        })


    this.setState({ loader5: true }, () => {
      if(this.props.tab6 && this.props.tab6.initFetch){
        this.setState({
          loader5: false,
          totalFleetSize : this.props.tab6.totalFleetSize || 0,
          clientVehiclesVehicleWise: this.props.tab6.clientVehiclesVehicleWise || [],
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/2`, method: "GET" })
          .then((res) => {

            let makeData = [];
            let colors = [
              "#da5e5e",
              "#f50057",
              "#7b101b",
              "#00c179",
              "#d52f2f",
              "#ec1c91",
              "#28a788",
              "#3f52b5e6",
              "#594a86",
              "#864a73",
            ];
            let maxUsedCount = Math.max(
              ...res.data.map((item) => item.accessVehiclesCount)
            );
            let dtRes = res.data.reverse();
            let usedPartPercentages = dtRes.map((item) => {
              return {
                ...item,
                salesPercentage: (item.accessVehiclesCount / maxUsedCount) * 100,
              };
            });
            this.setState({
              loader5: false,
              totalFleetSize : res.totalFleetCount,
              clientVehiclesVehicleWise: usedPartPercentages,
            });
            let dt = {
              initFetch:true,
              totalFleetSize : res.totalFleetCount,
              clientVehiclesVehicleWise:usedPartPercentages
            }
             this.props.dispatch(addTab6(dt))
          })
          .catch((err) => {});

      }
    });

    this.setState({ loader7: true , tab1loader1:true}, () => {
      // cancelToken: source.token,
      if(this.props.tab8 && this.props.tab8.initFetch){
         this.setState({
          loader7: false,
          tab1loader1:false,
          maintRequestClientWise: {
            clientList: this.props.tab8.maintRequestClientWise.clientList,
            totalInvoicesCount: this.props.tab8.maintRequestClientWise?.totalInvoicesCount || 0,
          },
          maintRequestClientWiseTab1: this.props.tab8.maintRequestClientWiseTab1 || 1,
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/3`, method: "GET" })
        .then((res) => {
          let maxUsedCount = Math.max(
            ...res.clientList.map((item) => item.count)
          );
          let usedPartPercentages =
            res.clientList &&
            res.clientList.map((item) => {
              return {
                ...item,
                countPercentage: (item.count / maxUsedCount) * 100,
              };
            });


          this.setState({
            loader7: false,
            tab1loader1:false,
            maintRequestClientWise: {
              clientList: usedPartPercentages,
              totalInvoicesCount: res?.totalInvoicesCount || 0,
            },
            maintRequestClientWiseTab1: res?.totalDeliveryNotesCount || 1,
          });
          let dat = {
            initFetch:true,
            maintRequestClientWise: {
              clientList: usedPartPercentages,
              totalInvoicesCount: res?.totalInvoicesCount || 0,
            },
            maintRequestClientWiseTab1: res?.totalDeliveryNotesCount || 1,
          }
          this.props.dispatch(addTab8(dat)); 

        })
        .catch((err) => {
          console.log("err 3 ====", err);
        });
      }
    });

    this.setState({ loader4: true , tab1loader5:true}, () => {
      if(this.props.tab5 && this.props.tab5.initFetch){
        let res = this.props.tab5.res || {}
         let maxUsedCount = Math.max(
          ...res.clientList.map((item) => item.saleNetAmount)
        );
        let usedPartPercentages = res.clientList.map((item) => {
          return {
            ...item,
            salesPercentage: (item.saleNetAmount / maxUsedCount) * 100,
          };
        });
        this.setState({
          loader4: false,
          tab1loader5:false,
          salesClientsTab1: res?.totalSaleTotalAmount || 0,
          salesClients: {
            ...res,
            clientList: usedPartPercentages,
          },
          salesClients1: {
            ...res,
            clientList: usedPartPercentages,
          },
        })
      }
      else{
          instance({ url: `/api/dashboards/cms/4`, method: "GET" })
            .then((res) => {
              let maxUsedCount = Math.max(
                ...res.clientList.map((item) => item.saleNetAmount)
              );
              let usedPartPercentages = res.clientList.map((item) => {
                return {
                  ...item,
                  salesPercentage: (item.saleNetAmount / maxUsedCount) * 100,
                };
              });
              this.setState({
                loader4: false,
                tab1loader5:false,

                salesClientsTab1: res?.totalSaleTotalAmount || 0,
                salesClients: {
                  ...res,
                  clientList: usedPartPercentages,
                },
                salesClients1: {
                  ...res,
                  clientList: usedPartPercentages,
                },
              });
              let dat ={
                res:res,
                initFetch:true
              }
              this.props.dispatch(addTab5(dat)); 
            })
            .catch((err) => {})

      }
    })

    this.setState({ loader6: true, tab1loader4:true  }, () => {
      if(this.props.tab7 && this.props.tab7.initFetch){
         this.setState({
          loader6: false,
          tab1loader4:false,
          salesClientsWise: {
            ...this.props.tab7.salesClientsWise,
            clientList: this.props.tab7.salesClientsWise.clientList,
          },
          totalOurDue: this.props.tab7?.totalOurDue || 0,
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/5`, method: "GET" })
          .then((res1) => {
            let res = res1.clientList.reverse();
             let maxUsedCount = Math.max(
              ...res.map((item) => item.overDue)
            );
            let usedPartPercentages = res.map((item) => {
              return {
                ...item,
                ourDuePercentage:
                  (item.overDue / maxUsedCount) * 100,
              };
            });
  
            this.setState({
              loader6: false,
              tab1loader4:false,

              salesClientsWise: {
                ...res1,
                clientList: usedPartPercentages,
              },
              totalOurDue: res1?.totalOurDue || 0,
            });
            let dt = {
              initFetch:true,
              salesClientsWise: {
                ...res1,
                clientList: usedPartPercentages,
              },
              totalOurDue: res1?.totalOurDue || 0,
            }
            this.props.dispatch(addTab7(dt))
          })
          .catch((err) => {
            console.log("err 5====", err);
          });
      }
    });

    this.setState({ loader2: true }, () => {
      if(this.props.tab2 && this.props.tab2.initFetch){
         this.setState({
          loader2: false,
          maintenanceRequestCount: this.props.tab2?.maintenanceRequestCount|| {},
          partsMaintRequest: this.props.tab2?.partsMaintRequest || {},
          serviceMaintRequest: this.props.tab2?.serviceMaintRequest || {},
          packageMaintRequest: this.props.tab2?.packageMaintRequest || {}
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/6`, method: "GET" })
          .then((res) => {
            let dt = [];
            for (const [key, value] of Object.entries(res)) {
              dt.push({ name: key, value: value });
            }
            let maxUsedCount = Math.max(...dt.map((item) => item.value));
            let usedPartPercentages = dt.map((item) => {
              return {
                ...item,
                countPercentage: (item.value / maxUsedCount) * 100,
              };
            });
            const findItem = usedPartPercentages.find(
              (item) => item.name === "items"
            );
            const findService = usedPartPercentages.find(
              (item) => item.name === "services"
            );
            const findPackages = usedPartPercentages.find(
              (item) => item.name === "packages"
            );
            // partsMaintRequest
            this.setState({
              loader2: false,
              maintenanceRequestCount: res,
              partsMaintRequest: findItem,
              serviceMaintRequest: findService,
              packageMaintRequest: findPackages,
            });
  
            let dat ={
              maintenanceRequestCount:res,
              partsMaintRequest: findItem,
              serviceMaintRequest: findService,
              packageMaintRequest: findPackages,
              initFetch:true
            }
            this.props.dispatch(addTab2(dat)); 
          })
          .catch((err) => {
            console.log("err 5====", err);
          });

      }
    });

    this.setState({ loader10: true }, () => {
      if(this.props.tab13&&this.props.tab13.initFetch){
        this.setState({
          loader10: false,
          vehiclesCategories: this.props.tab13?.data||[],
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/7`, method: "GET" })
          .then((res) => {
            let dt = [];
            for (const [key, value] of Object.entries(res)) {
              dt.push({ name: key, value: value });
            }
            let maxUsedCount = Math.max(...dt.map((item) => item.value));
            let dtRes = dt.reverse();
            let usedPartPercentages = dtRes.map((item) => {
              return {
                ...item,
                countPercentage: (item.value / maxUsedCount) * 100,
              };
            });
            // partsMaintRequest
            this.setState({
              loader10: false,
              vehiclesCategories: usedPartPercentages,
            });
            let dat ={
              initFetch:true,
              data:usedPartPercentages
            }
            this.props.dispatch(addTab13(dat))
          })
          .catch((err) => {
            console.log("err 7====", err);
          });
        
      }
    });

    this.setState({ loader8: true }, () => {
      if(this.props.tab9 && this.props.tab9.initFetch){
         this.setState({ usedPart: this.props.tab9.data, loader8: false });
      }
      else{
        instance({ url: `/api/dashboards/cms/8`, method: "GET" })
          .then((res) => {
            let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
            let usedPartPercentages = res.map((item) => {
              return {
                ...item,
                usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
              };
            });
            this.setState({ usedPart: usedPartPercentages, loader8: false });
            let dt = {
              data:usedPartPercentages,
              initFetch:true
            }
            this.props.dispatch(addTab9(dt))
          })
          .catch((err) => {
            console.log("err 8====", err);
          });
      }
    });

    this.setState({ loader13: true }, () => {
      if(this.props.tab10 && this.props.tab10.initFetch){
         this.setState({ usedService: this.props.tab10.data, loader13: false });
     }
     else{
       instance({ url: `/api/dashboards/cms/9`, method: "GET" })
         .then((res) => {
           let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
           let usedPartPercentages = res.map((item) => {
             return {
               ...item,
               usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
             };
           });
 
           this.setState({ loader13: false, usedService: usedPartPercentages });
           let dt ={
             initFetch: true,
             data: usedPartPercentages
           }
           this.props.dispatch(addTab10(dt))
         })
         .catch((err) => {
           console.log("err 9====", err);
         });
     }
    });

    this.setState({ loader14: true }, () => {
      if(this.props.tab11 && this.props.tab11.initFetch){
        this.setState({ loader14: false, usedPackages: this.props.tab11.data });
      }
      else{
          instance({ url: `/api/dashboards/cms/10`, method: "GET" })
          .then((res) => {
            let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
            let usedPartPercentages = res.map((item) => {
              return {
                ...item,
                usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
              };
            });
            this.setState({ loader14: false, usedPackages: usedPartPercentages });
            let dt = {
              initFetch:true,
              data:usedPartPercentages
            }
            this.props.dispatch(addTab11(dt))
          })
          .catch((err) => {
            console.log("err 10====", err);
          });
      }
    });

    this.setState({ loader3: true }, () => {
      if(this.props.tab3 && this.props.tab3.initFetch){
        let res = this.props.tab3.collectionsAndOutStanding
        let balancePercentage =
              res.totalOutstandingBalance > res.totalPaid
                ? (res.totalPaid / res.totalSaleNetAmount) * 100
                : (res.totalPaid / res.totalSaleNetAmount) * 100;
        this.setState({
          loader3: false,
          collectionsAndOutStanding: {
            ...this.props.tab3.collectionsAndOutStanding,
            balancePercentage: balancePercentage,
          },
        });
      }
      else{
        // instance({url: `/api/dashboards/cms/12?to=${to}&from=${from}`, method: "GET" })
        instance({url: `/api/dashboards/cms/12`, method: "GET" })
          .then((res) => {
            let balancePercentage =
              res.totalOutstandingBalance > res.totalPaid
                ? (res.totalPaid / res.totalSaleNetAmount) * 100
                : (res.totalPaid / res.totalSaleNetAmount) * 100;
            this.setState({
              loader3: false,
              collectionsAndOutStanding: {
                ...res,
                balancePercentage: balancePercentage,
              },
            });
            let dt = {
              initFetch:true,
              collectionsAndOutStanding: {
                ...res,
                balancePercentage: balancePercentage,
              }
            }
            this.props.dispatch(addTab3(dt))
          })
          .catch((err) => {
            console.log("err 10====", err);
          });
      }
    });

// --------------------------------------------------------------------------  this.setState({ loader3: true }, () => {
  this.setState({ loader17: true }, () => {
    if(this.props.tab17 && this.props.tab17.initFetch){
       this.setState({
        loader17: false,
        topVehiles: this.props.tab17.data,
      });
    }
    else{
      instance({ url: `/api/dashboards/cms/17`, method: "GET" })
        .then((res) => {
          this.setState ({
            loader17: false,
            topVehiles: res || [],
          });
          let dt= {
            data: res || [],
            initFetch:true
          }
          this.props.dispatch(addTab17(dt))
        })
        .catch((err) => {
          console.log("err15====", err);
        });
    }
  });
  this.setState({ loader19: true }, () => {
    if(this.props.tab19 && this.props.tab19.initFetch){
       this.setState({
        loader19: false,
        topMaintenancePerDay: this.props.tab19.data,
      });
    }
    else{
      instance({ url: `/api/dashboards/cms/19`, method: "GET" })
        .then((res) => {
          this.setState ({
            loader19: false,
            topMaintenancePerDay: res.costPerDay || [],
            topMaintenancePerKm: res.costPerKm || []
          });
          let dt= {
            data: res || [],
            initFetch:true
          }
          this.props.dispatch(addTab19(dt))
        })
        .catch((err) => {
          console.log("err19====", err);
        });
    }
  });

// --------------------------------------------------------------------------  this.setState({ loader3: true }, () => {


    this.setState({ loader9: true }, () => {
      if(this.props.tab4 && this.props.tab4.initFetch){
         this.setState({
          loader9: false,
          salesDataYearly: this.props.tab4.salesDataYearly,
          allSaleData: this.props.tab4.allSaleData,
          salesDataMonthly: this.props.tab4.salesDataMonthly,
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/13`, method: "GET" })
          .then((res) => {
            let monthlyDt = [];
            let yealyDt = [];
            let colors = ["#28A745", "#EAEAEA"];
            let colors2 = ["#0288D1", "#EAEAEA"];
            let ind = 0;
            let ind2 = 0;
            for (const [key, value, index] of Object.entries(res)) {
              if (key === "monthlySale" || key === "monthlyTarget") {
                let  salePercentage =(res.monthlySale / res.monthlyTarget) * 100
                let salePrecentRes = salePercentage > 100 ? 100 : salePercentage

                monthlyDt.push({
                  name: key,
                  // y: value,
                  y: key === "monthlySale" ? salePrecentRes : 100 - salePrecentRes,

                  value: value,
                  formattedvalue: value,
                  color: colors[ind++],
                });
              } else {
         
                let  salePercentage =(res.yearlySale / res.yearlyTarget) * 100
                let salePrecentRes = salePercentage > 100 ? 100 : salePercentage
                
                yealyDt.push({
                  name: key,
                  y: key === "yearlySale" ? salePrecentRes : 100 - salePrecentRes,
                  value: value,
                  formattedvalue: value,
                  color: colors2[ind2++],
                });
              }
            }
            let makeDataMonthly = [{ data: monthlyDt }];
            let makeDataYearly = [{ data: yealyDt }];

            let yearlyList =  res.yearlyList || {}
            let monthlyList =  res.monthlyList || {}
            const formattedDataYearly = Object.keys(yearlyList).map((key) => ({
              name: key,
              y: yearlyList[key],
            }));
            const formattedDataMonthly= Object.keys(monthlyList).map((key) => ({
              name: key,
              y: monthlyList[key],
            }));
            // return formattedDataYearly;


            this.setState({
              loader9: false,
              salesDataYearly: formattedDataYearly,
              allSaleData: res,
              salesDataMonthly: formattedDataMonthly,
            });
            let dt= {
              salesDataYearly: formattedDataYearly,
              allSaleData: res,
              salesDataMonthly: formattedDataMonthly,
              initFetch:true
            }
            this.props.dispatch(addTab4(dt))
          })
          .catch((err) => {
            console.log("err 10====", err);
          });
      }
    });

   

    this.setState({ loader11: true }, () => {
      if(this.props.tab14 && this.props.tab14.initFetch){
         this.setState({
          loader11: false,
          totalProfitTab14: this.props.tab14.data,
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/15`, method: "GET" })
          .then((res) => {
            let maxUsedCount = Math.max(
              ...res.clientList.map((item) => item.profit)
            );
            let calculateProfitPercentage = res.clientList.map((item) => {
              return {
                ...item,
                countPercentage: (item.profit / maxUsedCount) * 100,
              };
            });
            this.setState({
              loader11: false,
              totalProfitTab14: calculateProfitPercentage,
            });
            let dt= {
              data: calculateProfitPercentage,
              initFetch:true
            }
            this.props.dispatch(addTab14(dt))
          })
          .catch((err) => {
            console.log("err15====", err);
          });
      }
    });





    this.setState({ loader18: true }, () => {
      if(this.props.tab18 && this.props.tab18.initFetch){
         this.setState({
          loader18: false,
          monthlySaleNetAmount: this.props.tab18.data,
        });
      }
      else{
        instance({ url: `/api/dashboards/cms/18`, method: "GET" })
          .then((res) => {

            this.setState({
              loader18: false,
              monthlySaleNetAmount: res || {},
            });
            let dt= {
              data: res || {},
              initFetch:true
            }
            this.props.dispatch(addTab18(dt))
          })
          .catch((err) => {
            console.log("err15====", err);
          });
      }
    });
  };
  generateRandomColor = () => {
    let colors = [
      "#f38686",
      "#91e064",
      "#e86493",
      "#f6cc4e",
      "#d52f2f",
      "#5d2c2c",
      "#45e6aa",
      "#427ace",
      "#477ac7",
      "#c747b5",
    ];
    let randomColor = colors[Math.floor(Math.random() * colors.length)];
    return randomColor;
  };

  pickUniqueColor = () => {
    let colors = [
      "#f38686",
      "#91e064",
      "#e86493",
      "#f6cc4e",
      "#d52f2f",
      "#5d2c2c",
      "#45e6aa",
      "#427ace",
      "#477ac7",
      "#c747b5",
    ];

    const randomIndex = Math.floor(Math.random() * colors.length);
    const pickedColor = colors[randomIndex];
    colors.splice(randomIndex, 1);
    return pickedColor;
  };

  getCMS16 = (to, from, chk) => {
     this.setState({ loader12: true }, () => {
     if(this.props.tab15 && this.props.tab15.initFetch && !to && !from && !chk ){

      this.setState({ cogs: this.props.tab15?.data ||[], loader12: false });
    }
    else{
       let url =
        to && from && chk
          ? `/api/dashboards/cms/16?to=${to}&from=${from}`
          : `/api/dashboards/cms/16`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let dt = [];
          for (const [key, value] of Object.entries(res)) {
            let name = key;
            dt.push({ ...value, name: name });
          }
          let maxUsedCount = Math.max(...dt.map((item) => item.invoicesCount));
           if(this.props.ServerSetting.attributes.c_garageTarget){
            let dtRes = dt.reverse();
            let types = this.props.ServerSetting.attributes.c_garageTarget
 
            let usedPartPercentages = dtRes.map((item) => { 
              let re = item.name
              let count = types[re]
    
              // let targetValue = item.target[item.name];
           // totalCost
              return {
                ...item,
                countPercentage: item.totalCost > count ? 100 : (item.totalCost / count) * 100,
                // countPercentage: item.invoicesCount > count ? 100 : (item.invoicesCount / count) * 100,
                // target:targetValue
                target: types[item.name] 
              };

          })
          //                 countPercentage: item.invoicesCount > count ? 100  : (item.invoicesCount / count) * 100,

          this.setState({ cogs: usedPartPercentages, loader12: false });
          let dat = {
            initFetch:true,
            data:usedPartPercentages
          }
          this.props.dispatch(addTab15(dat))
          }
        else{
          let maxUsedCount = Math.max(
            ...dt.map((item) => item.invoicesCount)
          );
          let dtRes = dt.reverse();

          let calculateProfitPercentage = dtRes.map((item) => {
            return {
              ...item,
              
              countPercentage: (item.invoicesCount / maxUsedCount) * 100,
            };
          });
          this.setState({ cogs: calculateProfitPercentage, loader12: false });

          let dat = {
            initFetch:true,
            data:calculateProfitPercentage
          }
          this.props.dispatch(addTab15(dat))
          
        }
          // });
        })
        .catch((err) => {})
      

      }
    });
  };

  getCMS1 = (to, from) => {
    this.setState({ loader5: true }, () => {
      instance({
        url: `/api/dashboards/cms/1?to=${to}&from=${from}`,
        method: "GET",
      })
        .then((res) => {
          let makeData = [];
          let colors = [
            "#da5e5e",
            "#f50057",
            "#7b101b",
            "#00c179",
            "#d52f2f",
            "#ec1c91",
            "#28a788",
            "#3f52b5e6",
            "#594a86",
            "#864a73",
          ];

          res &&
            res.map((item, index) => {
              makeData.push({
                name: item.contractNumber,
                key: item.accessVehiclesCount,
                y: item.accessVehiclesCount,
                color: colors[index],
              });
            });
          // let makeData2 = [{data: makeData}];
          this.setState({
            clientVehiclesVehicleWise: makeData,
            loader5: false,
          });
        })
        .catch((err) => {});
    });
  };

  getCMS2 = (to, from) => {
    this.setState({ loader5: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/2?to=${to}&from=${from}`
          : `/api/dashboards/cms/2`;
       instance({ url: url, method: "GET" })
        .then((res) => {
          let makeData = [];
          let colors = [
            "#da5e5e",
            "#f50057",
            "#7b101b",
            "#00c179",
            "#d52f2f",
            "#ec1c91",
            "#28a788",
            "#3f52b5e6",
            "#594a86",
            "#864a73",
          ];

          res &&
            res.data.map((item, index) => {
              makeData.push({
                name: item.contractNumber,
                key: item.accessVehiclesCount,
                y: item.accessVehiclesCount,
                color: colors[index],
              });
            });
          let makeData2 = [{ data: makeData }];
          this.setState({totalFleetSize : res.totalFleetCount, clientVehiclesVehicleWise: res.data, loader5: false });
          let dt = {
            initFetch:true,
            clientVehiclesVehicleWise:res.data,
            totalFleetSize : res.totalFleetCount
          }
           this.props.dispatch(addTab6(dt))
        })
        .catch((err) => {});
    });
  };

  getCMS3 = (to, from, chk) => {
    this.setState({ loader7: chk ? false : true, tab1loader1:chk ? true : false, }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/3?to=${to}&from=${from}`
          : `/api/dashboards/cms/3`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          if (chk) {
            this.setState({
              maintRequestClientWiseTab1: res?.totalDeliveryNotesCount || 1,
              loader7: false,
              tab1loader1:false 
            });
          } else {
            let maxUsedCount = Math.max(
              ...res.clientList.map((item) => item.count)
            );
            let usedPartPercentages =
              res.clientList &&
              res.clientList.map((item) => {
                return {
                  ...item,
                  countPercentage: (item.count / maxUsedCount) * 100,
                };
              });

            this.setState({
              loader7: false,
              tab1loader1:false ,

              maintRequestClientWise: {
                clientList: usedPartPercentages,
                totalInvoicesCount: res?.totalInvoicesCount || 0,
              },
            });
            let dat = {
              initFetch:true,
              maintRequestClientWise: {
                clientList: usedPartPercentages,
                totalInvoicesCount: res?.totalInvoicesCount || 0,
              },
              maintRequestClientWiseTab1: res?.totalDeliveryNotesCount || 1,
            }
            // this.props.dispatch(addTab8(dat));
          }
        })
        .catch((err) => {
          console.log("err 3 ====", err);
        });
    });
  };

  getCMS4 = (to, from, tab1) => {
    this.setState({ loader4: tab1 ? false : true, tab1loader5:tab1 ? true :false }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/4?to=${to}&from=${from}`
          : `/api/dashboards/cms/4`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let maxUsedCount = Math.max(
            ...res.clientList.map((item) => item.saleNetAmount)
          );
          let usedPartPercentages = res.clientList.map((item) => {
            return {
              ...item,
              salesPercentage: (item.saleNetAmount / maxUsedCount) * 100,
            };
          });

          if (tab1) {
            this.setState({
              loader4: false,
              tab1loader5:false ,
              salesClientsTab1: res.totalSaleTotalAmount,
              salesClients1: {
                ...res,
                clientList: usedPartPercentages,
              },
            });
          } else {
            this.setState({
              tab1loader5:false ,

              salesClients: {
                ...res,
                clientList: usedPartPercentages,
              },
              loader4: false,
            });
          }
          let dat ={
            res:res,
            initFetch:true,
            
          }
          // this.props.dispatch(addTab5(dat)); 

        })
        .catch((err) => {});
    });
  };

  getCMS5 = (to, from, chk) => {
    this.setState({ loader6: chk ? false : true, tab1loader4:chk ? true : false, }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/5?to=${to}&from=${from}`
          : `/api/dashboards/cms/5`;
      instance({ url: url, method: "GET" })
        .then((res1) => {
          if (chk) {
            this.setState({
              totalOurDue: res1?.totalOurDue,
              loader6: false,
              tab1loader4:false
            });
          } else {
            let res = res1.clientList.reverse();
            
            // let maxUsedCount = Math.max(...res.clientList?.map(item => item.outstandingBalance));
            let maxUsedCount = Math.max(
              ...res?.map((item) => item.overDue)
              );
             // let usedPartPercentages = res.clientList.map(item => {
            //   return {  ...item, ourDuePercentage: (item.overDue / maxUsedCount) * 100
            //   }
            // })
             let usedPartPercentages = res?.map((item) => {
              return {
                ...item,
                ourDuePercentage:
                  (item.overDue / maxUsedCount) * 100,
              };
            });

            this.setState({
              salesClientsWise: {
                ...res,
                clientList: usedPartPercentages,
              },
              loader6: false,
              tab1loader4:false

            });
            let dt = {
              initFetch:true,
              salesClientsWise: {
                ...res,
                clientList: usedPartPercentages,
              },
              totalOurDue: res?.totalOurDue || 0,
            }
            // this.props.dispatch(addTab7(dt))
          }
        })
        .catch((err) => {
          console.log("err 5====", err);
        });
    });
  };

  getCMS6 = (to, from) => {
     this.setState({ loader2: true }, () => {
      this.setState({
        partsMaintRequest: 0,
        serviceMaintRequest: 0,
        packageMaintRequest: 0,
      });
      let url =
        to && from
          ? `/api/dashboards/cms/6?to=${to}&from=${from}`
          : `/api/dashboards/cms/6`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let dt = [];
          for (const [key, value] of Object.entries(res)) {
            dt.push({ name: key, value: value });
          }
          let maxUsedCount = Math.max(...dt.map((item) => item.value));
          let usedPartPercentages = dt.map((item) => {
            return {
              ...item,
              countPercentage: (item.value / maxUsedCount) * 100,
            };
          });
          const findItem = usedPartPercentages.find(
            (item) => item.name === "items"
          );
          const findService = usedPartPercentages.find(
            (item) => item.name === "services"
          );
          const findPackages = usedPartPercentages.find(
            (item) => item.name === "packages"
          );
          // partsMaintRequest
          this.setState({
            loader2: false,
            maintenanceRequestCount: res,
            partsMaintRequest: findItem,
            serviceMaintRequest: findService,
            packageMaintRequest: findPackages,
          });
          let dat ={
            maintenanceRequestCount:res,
            partsMaintRequest: findItem,
            serviceMaintRequest: findService,
            packageMaintRequest: findPackages,
          }
          this.props.dispatch(addTab2(dat));
        })
        .catch((err) => {
          console.log("err 5====", err);
        });
    });
  };

  getCMS7 = (to, from) => {
    this.setState({ loader10: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/7?to=${to}&from=${from}`
          : `/api/dashboards/cms/7`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let dt = [];
          for (const [key, value] of Object.entries(res)) {
            dt.push({ name: key, value: value });
          }
          let maxUsedCount = Math.max(...dt.map((item) => item.value));
          let dtRes = dt.reverse();
          let usedPartPercentages = dtRes.map((item) => {
            return {
              ...item,
              countPercentage: (item.value / maxUsedCount) * 100,
            };
          });

          // partsMaintRequest
          this.setState({
            vehiclesCategories: usedPartPercentages,
            loader10: false,
          });
          let dat ={
            initFetch:true,
            data:usedPartPercentages
          }
          this.props.dispatch(addTab13(dat))
        })
        .catch((err) => {
          console.log("err 7====", err);
        });
    });
  };

  getCMS8 = (to, from) => {
    this.setState({ loader8: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/8?to=${to}&from=${from}`
          : `/api/dashboards/cms/8`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
          let usedPartPercentages = res.map((item) => {
            return {
              ...item,
              usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
            };
          });
          this.setState({ usedPart: usedPartPercentages, loader8: false });
          let dt = {
            data:usedPartPercentages,
            initFetch:true
          }
          this.props.dispatch(addTab9(dt))
        })
        .catch((err) => {
          console.log("err 8====", err);
        });
    });
  };

  getCMS9 = (to, from) => {
    this.setState({ loader13: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/9?to=${to}&from=${from}`
          : `/api/dashboards/cms/9`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let maxUsedCount = Math.max(...res.map((item) => item.usedCount));
          let usedPartPercentages = res.map((item) => {
            return {
              ...item,
              usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
            };
          });

          this.setState({ usedService: usedPartPercentages, loader13: false });
          let dt ={
            initFetch: true,
            data: usedPartPercentages
          }
          this.props.dispatch(addTab10(dt))
        })
        .catch((err) => {
          console.log("err 9====", err);
        });
    });
  };

  getCMS10 = (to, from) => {
    this.setState({ loader14: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/10?to=${to}&from=${from}`
          : `/api/dashboards/cms/10`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          // let res = res1.clientList;
          let maxUsedCount = Math.max(...res?.map((item) => item.usedCount));
          let usedPartPercentages = res.map((item) => {
            return {
              ...item,
              usedCountPercentage: (item.usedCount / maxUsedCount) * 100,
            };
          });
          this.setState({ usedPackages: usedPartPercentages, loader14: false });
          let dt = {
            initFetch:true,
            data:usedPartPercentages
          }
          this.props.dispatch(addTab11(dt))
        })
        .catch((err) => {
          console.log("err 10====", err);
        });
    });
  };

  getCMSTab12 = (to, from) => {
    this.setState({ loader9: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/13?to=${to}&from=${from}`
          : `/api/dashboards/cms/13`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let yealyDt,
            monthlyDt = [];
          let colors = ["#28A745", "#EAEAEA"];
          let colors2 = ["#0288D1", "#EAEAEA"];
          let ind = 0;
          let ind2 = 0;
          for (const [key, value, index] of Object.entries(res)) {
            if (key === "monthlySale" || key === "monthlyTarget") {
              monthlyDt.push({
                name: key,
                y: value,
                value: value,
                formattedvalue: value,
                color: colors[ind++],
              });
            } else {
              yealyDt.push({
                name: key,
                y: value,
                value: value,
                formattedvalue: value,
                color: colors2[ind2++],
              });
            }
          }
          let makeDataMonthly = [{ data: monthlyDt }];
          let makeDataYearly = [{ data: yealyDt }];
          this.setState({
            salesDataYearly: makeDataYearly,
            loader9: false,
            allSaleData: res,
          });
          let dt= {
            salesDataYearly: makeDataYearly,
            allSaleData: res,
            initFetch:true
          }
          this.props.dispatch(addTab4(dt))
        })
        .catch((err) => {
          console.log("err 10====", err);
        });
    });
  };

  getCMS12 = (to, from) => {
    this.setState({ loader3: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/12?to=${to}&from=${from}`
          : `/api/dashboards/cms/12`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let maxUsedCount =
            res.totalOutstandingBalance > res.totalPaid
              ? res.totalOutstandingBalance
              : res.totalPaid;
          let balancePercentage =
            res.totalOutstandingBalance > res.totalPaid
              ? (res.totalPaid / res.totalSaleNetAmount) * 100
              : (res.totalPaid / res.totalSaleNetAmount) * 100;
          this.setState({
            loader3: false,
            collectionsAndOutStanding: {
              ...res,
              balancePercentage: balancePercentage,
            },
          });
        })
        .catch((err) => {
          console.log("err 10====", err);
        });
    });
  };

  getCMS13 = (to, from) => {
    this.setState({ loader: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/13?to=${to}&from=${from}`
          : `/api/dashboards/cms/13`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let dt = [];
          let colors = [
            "#28A745",
            "#e86493",
            "#EAEAEA",
            "#f6cc4e",
            "#d52f2f",
            "#5d2c2c",
            "#45e6aa",
            "#427ace",
            "#477ac7",
            "#c747b5",
          ];
          let ind = 0;
          for (const [key, value, index] of Object.entries(res)) {
            dt.push({
              name: key,
              y: value,
              value: value,
              formattedvalue: value,
              color: colors[ind++],
            });
          }

          let makeData = [{ data: dt }];

          this.setState({
            loader: false,
            salesData: makeData,
            allSaleData: res,
          });
        })
        .catch((err) => {});
    });
  };
  getCMS14 = (to, from) => {
    this.setState({ loader1: true , tab1loader2:true}, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/14?to=${to}&from=${from}`
          : `/api/dashboards/cms/14`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          this.setState({ totalAccounts: res, loader1: false , tab1loader2:false});
        })
        .catch((err) => {});
    });
  };
  getCMS15 = (to, from) => {
    this.setState({ loader11: true }, () => {
      let url =
        to && from
          ? `/api/dashboards/cms/15?to=${to}&from=${from}`
          : `/api/dashboards/cms/15`;
      instance({ url: url, method: "GET" })
        .then((res) => {
          let maxUsedCount = Math.max(
            ...res.clientList.map((item) => item.profit)
          );
          let calculateProfitPercentage = res.clientList.map((item) => {
            return {
              ...item,
              countPercentage: (item.profit / maxUsedCount) * 100,
            };
          });
          this.setState({
            totalProfitTab14: calculateProfitPercentage,
            loader11: false,
          });
          let dt= {
            data: calculateProfitPercentage,
            initFetch:true
          }
          this.props.dispatch(addTab14(dt))
        })
        .catch((err) => {
          console.log("err15====", err);
        });
    });
  };
  // getFMD1 = (to, from) => {
  //   this.setState({ loaderA1: true , tab1loader3:true}, () => {
  //     let url =
  //       to && from
  //         ? `/api/dashboards/fmd/1?to=${to}&from=${from}`
  //         : `/api/dashboards/fmd/1`;
  //     instance({ url: url, method: "GET" })
  //       .then((res) => {
  //         this.setState({ totalCost: res, loaderA1: false,  tab1loader3:false });
          
  //       })
  //       .catch((err) => {});
  //   });
  // };

  // getToFrom = (value) => {
  //   let timezone = ''
  //   if (
  //     this.props.logInUser &&
  //     this.props.logInUser.attributes &&
  //     this.props.logInUser.attributes.timezone
  //   ) {
  //     timezone = this.props.logInUser.attributes.timezone
  //   }
  //   moment.tz.setDefault(timezone)
    
   //   if (value === 1) {
  //     let value = new Date();
  //     let val = moment.utc(value).startOf("month");
  //     let to = val._i.toISOString();
  //     let from = val._d.toISOString();
  //     let valFrom = moment(from).startOf("month").startOf("start").utc().format("YYYY-MM-DDTHH:mm:59.59") + "Z";
  //     let res = {
  //       to: to,
  //       from: from,
  //     };
  //     return res;
  //   } else if (value === 2) {
  //     let date = new Date();
  //     let from = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - 1, 1)).toISOString();
  //     let fromVal = moment(from).startOf("month").startOf("start").utc().format("YYYY-MM-DDTHH:mm:00.00") + "Z";
  //     let date2 = new Date();
  //     let to = new Date(date2.getUTCFullYear(), date2.getUTCMonth(),1).toISOString();
  //     let chnTo = moment(to).endOf('day').utc().format("YYYY-MM-DDTHH:mm:59.59") + "Z"
     
  //     let res = {
  //       to: chnTo,
  //       from: fromVal,
  //     };
  //     return res;
  //   } else if (value === 3) {
  //     let today = new Date();
  //     let lastMonth = new Date();
  //     lastMonth.setMonth(today.getMonth() - 3);

  //     let from = new Date(
  //       lastMonth.getFullYear(),
  //       lastMonth.getUTCMonth() - 1,
  //       1
  //     );
  //     let to = new Date(today.getFullYear(), today.getMonth(), 0);
  //     let res = {
  //       to: to.toISOString(),
  //       from: from.toISOString(),
  //     };
  //     return res;
  //   } else if (value === 4) {
  //     let from = new Date(new Date().getFullYear(), 0, 1).toISOString();
  //     let to = new Date().toISOString();
  //     let res = {
  //       to: to,
  //       from: from,
  //     };
  //     return res;
  //   } else if (value === 6) {
  //     let res = {
  //       to: false,
  //       from: false,
  //     };
  //     return res;
  //   } else {
  //     let from = new Date(new Date().getFullYear() - 1, 0, 1).toISOString();
  //     let to = new Date(new Date().getFullYear() - 1, 11, 31).toISOString();
  //     let res = {
  //       to: to,
  //       from: from,
  //     };
  //     return res;
  //   }
  // };


  getToFrom = (value) => {
    let  timezone = "";
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }else{
      timezone = "Asia/Riyadh"
    }
     
    const now = moment().tz(timezone);

    if (value === 1) {
      const startOfMonth = now.clone().startOf("month");
      const endOfMonth = now.clone().endOf("month");
  
      return {
        from: startOfMonth.toISOString(),
        to: endOfMonth.toISOString(),
      };
    } else if (value === 2) {
      const lastMonthStart = now.clone().subtract(1, "month").startOf("month");
      const lastMonthEnd = now.clone().subtract(1, "month").endOf("month");
  
      return {
        from: lastMonthStart.toISOString(),
        to: lastMonthEnd.toISOString(),
      };
    } else if (value === 3) {
      const threeMonthsAgo = now.clone().subtract(3, "months");
      const startOfThreeMonthsAgo = threeMonthsAgo.clone().startOf("month");
      const endOfLastMonth = now.clone().subtract(1, "month").endOf("month");
  
      return {
        from: startOfThreeMonthsAgo.toISOString(),
        to: endOfLastMonth.toISOString(),
      };
    } else if (value === 4) {
      const startOfYear = now.clone().startOf("year");
  
      return {
        from: startOfYear.toISOString(),
        to: now.toISOString(),
      };
    } else if (value === 6) {
      return {
        from: false,
        to: false,
      };
    } else {
      const lastYearStart = now.clone().subtract(1, "year").startOf("year");
      const lastYearEnd = now.clone().subtract(1, "year").endOf("year");
  
      return {
        from: lastYearStart.toISOString(),
        to: lastYearEnd.toISOString(),
      };
    }
  };


  handleChange = (name, e) => {
    if (name === "tab1") {
      this.setState({ loader1: true });
    }
    this.setState({ [name]: e.label }, async () => {
      const res = await this.getToFrom(e.value);
      if (name === "tab1") {
        await Promise.all([
          // this.getFMD1(res.to, res.from),                    // in tab5 total is showing form cms/4 api
          this.getCMS14(res.to, res.from),
          this.getCMS5(res.to, res.from, true),
          this.getCMS4(res.to, res.from, true),
          this.getCMS3(res.to, res.from, true),
        ]).then(() => {
          this.setState({ loader1: false });
        });
      } else if (name === "tab2") {
         this.getCMS6(res.to, res.from);
      } else if (name === "tab3") {
        this.getCMS12(res.to, res.from);
      } else if (name === "tab4") {
        this.getCMS13(res.to, res.from);
      } else if (name === "tab5") {
        this.getCMS4(res.to, res.from);
      } else if (name === "tab6") {
        this.getCMS2(res.to, res.from);
      } else if (name === "tab7") {
        this.getCMS5(res.to, res.from);
      } else if (name === "tab8") {
        this.getCMS3(res.to, res.from);
      } else if (name === "tab9") {
        this.getCMS8(res.to, res.from);
      } else if (name === "tab10") {
        this.getCMS9(res.to, res.from);
      } else if (name === "tab11") {
        this.getCMS10(res.to, res.from);
      } else if (name === "tab12") {
        this.getCMSTab12(res.to, res.from);
      } else if (name === "tab13") {
        this.getCMS7(res.to, res.from);
      } else if (name === "tab14") {
        this.getCMS15(res.to, res.from);
      } else if (name === "tab15") {

        this.getCMS16(res.to, res.from, true);
      } else {
        this.getCMS10(res.to, res.from);
      }
    });
  };

  openVehicle = (item) => {
    let dt = [];
    let ind = 0;
    let colors = [
      "#28A745",
      "#e86493",
      "#f6cc4e",
      "#EAEAEA",
      "#d52f2f",
      "#5d2c2c",
      "#45e6aa",
      "#427ace",
      "#477ac7",
      "#c747b5",
    ];
    for (const [key, value] of Object.entries(item.categoryCount)) {
      dt.push({
        name: key,
        y: value,
        value: value,
        formattedvalue: value,
        color: colors[ind++],
      });
    }
    let selectedData = [{ data: dt }];
    this.setState({ openGraph: true, selectedVehicle: selectedData });
  };
  CloseGraph = () => {
    this.setState({ openGraph: false, selectedVehicle: {} });
  };

  render() {  
    const { xs,sm } = this.state;

    let dt = [];
    let fmd9 = [];
    let fmd10 = [];
    for (const [key, value] of Object.entries(this.state.vehicleCategories)) {
      dt.push({
        name: key,
        y: value,
        value: value,
        formattedvalue: value,
        color: this.generateRandomColor(),
      });
    }
    for (const [key, value] of Object.entries(this.state.vehicleCategoryCost)) {
      fmd9.push({ name: key, cost: value });
    }
    for (const [key, value] of Object.entries(this.state.topVehicleModelCost)) {
      fmd10.push({ name: key, cost: value });
    }
    // const data = {
    //   data: [
    //       { name: 'Scheduled', value: 65540, color: '#0288D1', formattedvalue: '65,540' },
    //       { name: 'Approved', value: 65540, color: '#28A745', formattedvalue: '65,540' },
    //       { name: 'Rejected', value: 65540, color: '#DC3545', formattedvalue: '65,540' },
    //   ],
    //   total: { name: 'Bookings', value: 65540 * 3, formattedvalue: '125,000' }
    // };
    const data = [{ data: dt }];
    const { packages, items, services } = this.state.maintenanceRequestCount;
    let pa = packages ? packages : 0;
    let it = items ? items : 0;
    let se = services ? services : 0;

    let totaMainReqCate = pa + it + se
    let ownChart = {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          height: "310px",
        },
        // title: {
        //   text: "table.head"
        // },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              format: "{point.name}: {point.y:.1f}",
              style: {
                fontWeight: "bold",
                color: "currentColor",
              },
            },
          },
        },
        series: data,
        legend: {
          enabled: true,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              this.y.toFixed(2) +
              "</strong>"
            );
          },
        },
      }),
      show: true,
    };

    let clientVehiclesVehicleWiseChart = {
      config: pieChartDefault().initializeWithOptions({
        chart: {
          height: "310px",
        },
        // title: {
        //   text: "table.head"
        // },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              format: "{point.name}: {point.y:.1f}",
              style: {
                fontWeight: "bold",
                color: "currentColor",
              },
            },
          },
        },
        series: this.state.selectedVehicle,
        legend: {
          enabled: true,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              this.y.toFixed(2) +
              "</strong>"
            );
          },
        },
      }),
      show: true,
    };

    // let salesChart = {
    //   // config: pieChartDefault().initializeWithOptions({
    //     chart: {
    //       type: 'pie',
    //       height: '145px',
    //     },
    //     title: {
    //       text: ' ',
    //     },
    //     plotOptions: {
    //       pie: {
    //         innerSize: '50%', // Set the inner size to create the donut effect
    //         dataLabels: {
    //           enabled: false,
    //           format: '{point.name}: {point.percentage:.2f}%',
    //         },
    //         startAngle: -90, // Start angle for the semicircle (default: 0)
    //         endAngle: 90,   // End angle for the semicircle (default: 360)
    //       },
    //     },
    //     tooltip: {
    //       pointFormat: '{series.name}: <b>{point.y:.2f}</b>',
    //     },
    //     series: [
    //       {
    //         name: 'Monthly Data',
    //         innerSize: "50%",
    //         data: this.state.salesDataMonthly
    //       },
    //     ],
    //     legend: {
    //       enabled: false,
    //     },
    //     yAxis: {
    //       gridLineWidth: 0,
    //     },
    //     credits: {
    //       enabled: false, // Set to false to hide the credits label
    //     },
    //   // }),
    //   show: true,
    // };
    


    let topMaintenancePerDayChart = {
      chart: {
        type: 'pie',
        // width: 398,
        height: '280px',
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          innerSize: '50%', // Set the inner size to create the donut effect
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f}%',
            padding: 0,
            distance: 20
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            'Vehicle License Plate : ' + '</b>' +
            this.point.name +
            '<br/>' +
            '<b>' +'Cost Per Day : ' + '</b>' +
            Number(this.point.y).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) 
          );
        },
      },
      
      series: [
        {
          innerSize: '50%',
          data: Object.keys(this.state.topMaintenancePerDay).map((key) => ({
            name: key,
            y: this.state.topMaintenancePerDay[key]
          }))
        },
      ],
      legend: {
        enabled: false,
      },
      yAxis: {
        gridLineWidth: 0,
      },
      credits: {
        enabled: false,
      },
    };
    let topMaintenancePerKmChart = {
      chart: {
        type: 'pie',
        // width: 398,
        height: '280px',
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          innerSize: '50%', // Set the inner size to create the donut effect
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f}%',
            padding: 0,
            distance: 20
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            'Vehicle License Plate : ' + '</b>' +
            this.point.name +
            '<br/>' +
            '<b>' +'Cost Per KM : ' + '</b>' +
            Number(this.point.y).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) 
          );
        },
      },
      
      series: [
        {
          innerSize: '50%',
          data: Object.keys(this.state.topMaintenancePerKm).map((key) => ({
            name: key,
            y: this.state.topMaintenancePerKm[key]
          }))
        },
      ],
      legend: {
        enabled: false,
      },
      yAxis: {
        gridLineWidth: 0,
      },
      credits: {
        enabled: false,
      },
    };
    let topVehilesChart = {
      chart: {
        type: 'pie',
        width: 398,
        height: '280px',
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          innerSize: '50%', // Set the inner size to create the donut effect
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f}%',
            padding: 0,
            distance: 20
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            'Vehicle : ' + '</b>' +
            this.point.name +
            '<br/>' +
            '<b>' +'Total Maint Cost : ' +  '</b>' +
            Number(this.point.y).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + ' SAR' +
            '<br/>' + '<b>' +
            'Maint Count : ' + '</b>' +
            this.point.count 
          );
        },
      },
      
      series: [
        {
          name: 'Monthly Data',
          innerSize: '50%',
          data: this.state.topVehiles?.map(item => ({ name: item.plateNumber, y: item.SaleNetAmount, count:item.Count })),
        },
      ],
      legend: {
        enabled: false,
      },
      yAxis: {
        gridLineWidth: 0,
      },
      credits: {
        enabled: false,
      },
    };




    let salesChart = {
      // config: pieChartDefault().initializeWithOptions({
        chart: {
          type: 'pie',
          height: '110px',
          // width: 250,
        },
        title: {
          text: '',
        },
        plotOptions: {
          pie: {
            innerSize: '50%', // Set the inner size to create the donut effect
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.1f}%',
            },
            // startAngle: -90, // Start angle for the semicircle (default: 0)
            // endAngle: 90,   // End angle for the semicircle (default: 360)
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.2f}</b>',
          formatter: function() {
            return this.series.name + ": <b>" + this.y.toLocaleString({ minimumFractionDigits: 2 }) + "</b>";
          }
        },
        series: [
          {
            name: 'Monthly Data',
            innerSize: "50%",
            data: this.state.salesDataMonthly
          },
        ],
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
                enabled: false, // Set to false to hide the credits label
              },
      // }),
      show: true,
    };
    
    
    let salesChartYearly = {
      // config: pieChartDefault().initializeWithOptions({
        chart: {
          type: 'pie',
          width: 350,
          height: '270px',
          //  width: '200px'
        },
        title: {
          text: '',
        },
        plotOptions: {
          pie: {
            innerSize: '50%', // Set the inner size to create the donut effect
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.2f}%',
            },
            // startAngle: -90, // Start angle for the semicircle (default: 0)
            // endAngle: 90,   // End angle for the semicircle (default: 360)
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y:.2f}</b>',
          formatter: function() {
            return this.series.name + ": <b>" + this.y.toLocaleString({ minimumFractionDigits: 2 }) + "</b>";
          }
        },
        series: [
          {
            name: 'Yearly Data',
            innerSize: "50%",
            data: this.state.salesDataYearly,
          },
        ],
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
        },
        credits: {
          enabled: false, // Set to false to hide the credits label
        },
      // }),
      show: true,
    };
    
      let monthlyNetAmountChart = {
        chart: {
          type: "column",
          height: '400px',

        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: "",
          },
          categories: Object.keys(this.state.monthlySaleNetAmount).reverse(),
        },
        yAxis: {
          title: {
            text: "",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          formatter: function () {
            return (
              "<strong>" +
              this.key +
              "</strong>" +
              " <br>" +
              "<strong>" +
              Number(this.y).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SAR "+
              "</strong>"
            );
          },
        },
        plotOptions: {
          column: {
             colorByPoint: true, // Use different colors for each bar
             borderWidth: 0, // Remove the border around bars

            grouping: true,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return nFormatter(this.y, 2);
              },
            },
            // pointWidth: 10,
            pointPadding: 0.1,
            groupPadding: 0.1,
            events: {
              // click: function (event) {
              //   const pointData = event.point;
              //   if (!check) {
              //     handleBarClick(pointData);
              //   }
              // },
            },
          },
        },
        
        series: [
          {
            name: '',
            showInLegend: false,
            colors: ['#2c3e50', '#3498db', '#e74c3c', '#2ecc71', '#f39c12', '#9b59b6', '#1abc9c', '#e67e22', '#27ae60', '#c0392b', '#34495e', '#e74c3c'],
            data: Object.values(this.state.monthlySaleNetAmount).reverse(),
          },
        ],
        credits: {
          enabled: false,
        },
      }
    return (
      <div style={{ position: "relative" }}>
        <Grid container>
          <Grid
          container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              marginBottom: 10,
              borderBottomWidth: 2,
              borderBottomColor: "#707070",
              borderBottomStyle: "dashed",
            }}
          >
            <Grid item xs={12} sm={6} md={3} lg={3}  
            style={{ 
            padding: "0px 20px 0px",
            borderBottomWidth: sm ? 2 : 0,
            borderBottomColor: sm ? "#707070" : "none",
            borderBottomStyle: sm ? "dashed" : "none", 
          }}>
              {
              //  this.state.loader1 || this.state.loaderA1||

               this.state.tab1loader1 || 
               this.state.tab1loader2 || 
              //  this.state.tab1loader3  ||
               this.state.tab1loader4 || 
               this.state.tab1loader5 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:"center",
                        padding: "10",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                          alignItems: "center",
                        }}
                      >
                        {this.props.translate("totalSalesandOverdue")}
                  
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 125,
                          marginLeft: "5px"
                        }}
                      >
                        <Select
                          options={FiltersOptionWithoutMTD}
                          // value={"this.state.tab1"}
                          value={{
                            label: this.state.tab1 ? this.state.tab1 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab1", e)}
                        />
                      </p>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        background: "#28A745",
                        borderRadius: 12,
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          color: "#ffffff",
                          textAlign: "left",
                          marginTop: "15px",
                          fontSize: 14,
                          fontWeight: 700,
                          paddingLeft: 10,
                        }}
                      >
                        {this.props.translate("totalSales")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}(w/o VAT)</span>
                      </p>
                      <div
                        style={{
                          // display: "inline-flex",
                          padding: "10px 0 10px 0",
                        }}
                      >
                        <span
                          style={{
                            padding: "0 5px 0 10px",
                            color: "#ffffff",
                            marginTop: 5,
                          }}
                        >
                          SAR
                        </span>
                        <Tooltip
                              title={
                                <Typography style={{fontSize:"14px",}}>
                                {this.state?.salesClients1?.totalSaleTotalAmount?.toLocaleString() || ""}
                                </Typography>
                                }
                              placement="bottom-start"
                              enterDelay={300}
                              classes={{ tooltip: 'customTooltip' }}
                          >
                         <span style={textStyle}>
                          {" "}
                            {this.state.salesClients1&& this.state.salesClients1.totalSaleTotalAmount ?  
                            nFormatter(this.state.salesClients1.totalSaleTotalAmount,2)
                           : 0}
                        </span>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        background: "#222C3C",
                        borderRadius: 12,
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          color: "#ffffff",
                          textAlign: "left",
                          marginTop: "15px",
                          fontSize: 14,
                          fontWeight: 700,
                          paddingLeft: 10,
                        }}
                      >
                        {this.props.translate("totalAccounts")}
                      </p>
                      <div
                        style={{
                          // display: "inline-flex",
                          padding: "10px 0 10px 0",
                        }}
                      >
                          <Tooltip
                              title={
                                <Typography style={{fontSize:"14px",}}>
                                {this.state?.totalAccounts?.totalAccounts?.toLocaleString() || ""}
                                </Typography>
                                }
                              placement="bottom-start"
                              enterDelay={300}
                              classes={{ tooltip: 'customTooltip' }}
                          >
                        <span style={textStyle}>
                          {" "}
                          {parseInt(this.state?.totalAccounts?.totalAccounts) ||
                            ""}
                        </span>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        background: "#DC3545",
                        borderRadius: 12,
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          color: "#ffffff",
                          textAlign: "left",
                          marginTop: "15px",
                          fontSize: 14,
                          fontWeight: 700,
                          paddingLeft: 10,
                        }}
                      >
                        {this.props.translate("overdue")}
                      </p>
                      <div
                        style={{
                          // display: "inline-flex",
                          padding: "10px 0 10px 0",
                        }}
                      >
                        <span
                          style={{
                            padding: "0 5px 0 10px",
                            color: "#ffffff",
                            marginTop: 5,
                          }}
                        >
                          SAR
                        </span>{" "}
                           <Tooltip
                           title={
                            <Typography style={{fontSize:"14px",}}>
                            {this.state?.totalOurDue?.toLocaleString()  || ""}
                              </Typography>
                                }
                           placement={"bottom-start"}
                           enterDelay={300}
                           > 
                        {/* <span style={textStyle}> */}

                           <span style={textStyle}>
                            {this.state?.totalOurDue ? nFormatter(this.state?.totalOurDue,2)
                            : 0
                             }
                             </span>
                        {/* </span> */}
                             </Tooltip>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        background: "#0288D1",
                        borderRadius: 12,
                        marginRight: 5,
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          color: "#ffffff",
                          textAlign: "left",
                          marginTop: "15px",
                          fontSize: 14,
                          fontWeight: 700,
                          paddingLeft: 10,
                        }}
                      >
                        {this.props.translate("averageTicketPrice")}
                      </p>
                      <div
                        style={{
                          // display: "inline-flex",
                          padding: "10px 0 10px 0",
                        }}
                      >
                        <span
                          style={{
                            padding: "0 5px 0 10px",
                            color: "#ffffff",
                            marginTop: 5,
                          }}
                        >
                          SAR
                        </span>{" "}
                        <Tooltip
                              title={
                                <Typography style={{fontSize:"14px",}}>
                                {
                                  this.state?.salesClientsTab1 &&
                                    this.state.maintRequestClientWiseTab1
                                    ? (this.state?.salesClientsTab1 /
                                        this.state.maintRequestClientWiseTab1)?.toLocaleString() 
                                    : ""
                                || ""} 
                                </Typography>
                                }
                              placement="bottom-start"
                              enterDelay={300}
                              classes={{ tooltip: 'customTooltip' }}
                          >
                        <span style={textStyle}>
                 
                          {this.state?.salesClientsTab1 &&
                              this.state.maintRequestClientWiseTab1 ?  
                            nFormatter( this.state?.salesClientsTab1 /
                            this.state.maintRequestClientWiseTab1,2)
                           : 0}
                          {/* {parseInt(
                            this.state?.salesClientsTab1 &&
                              this.state.maintRequestClientWiseTab1
                              ? this.state?.salesClientsTab1 /
                                  this.state.maintRequestClientWiseTab1
                              : ""
                          ) || ""} */}
                        </span>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12} sm={6} md={3} lg={3}
              style={{
                padding: "0px 20px 0px",
                borderLeftWidth: "1px",
                borderRightWidth:sm?"none":"1px",
                borderRightColor:sm?"none":"#707070",
                borderLeftColor: "#707070",
                borderLeftStyle: "dashed",
                borderRightStyle:sm?"none":"dashed",
                borderBottomWidth: sm ? 2 : 0,
                borderBottomColor: sm ? "#707070" : "none",
                borderBottomStyle: sm ? "dashed" : "none", 
              }}
            >
              {this.state.loader2 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  {/* {!this.state.loader2  || (this.state.partsMaintRequest && this.state.serviceMaintRequest && this.state.packageMaintRequest)  ?  */}

                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems:"center"
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                          alignItems: "center",
                        }}
                      >
                        {this.props.translate("unitSalesbyCategory")}
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          value={{
                            label: this.state.tab2 ? this.state.tab2 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab2", e)}
                        />
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="row-label"
                      style={{
                        textAlign: "left",
                        marginTop: 12,
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {this.props.translate("totalMaintenanceRequestCount")}
                    </span>
                    <Tooltip
                           title={
                            <Typography style={{fontSize:"14px",}}>
                           { totaMainReqCate?.toLocaleString()  || ''}
                            </Typography>  }
                           placement={"bottom-start"}
                           enterDelay={300}
                           > 
                    <span
                      className="row-label"
                      style={{
                        fontSize: "28px",
                        fontWeight: "600",
                        fontStyle: "italic",
                        paddingLeft: 10,
                      }}
                    >
                      {totaMainReqCate ? nFormatter(totaMainReqCate,2): ""}
                    </span>
                    </Tooltip>
                  </Grid>
                  
                  <Grid item xs={12} md={12} style={{ marginTop: 6 }}>
                    <div style={{ marginTop: 5 }}>
                      <span className="row-label"  style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // padding: "10",
                        alignItems:"center"
                      }}>
                        <p>{this.props.translate("parts")}</p> 
                        <strong>{items?.toLocaleString() || ""}</strong>
                      </span>
                      <LinearProgress
                        style={{ height: 20 }}
                        className="success"
                        variant="determinate"
                        value={
                          this.state.partsMaintRequest?.countPercentage || 0
                        }
                      />
                    </div>

                    <div style={{ marginTop: 6 }}>
                      <span className="row-label" style={{ margin: "10px 0",  display: "flex",
                        justifyContent: "space-between",
                        alignItems:"center", }}>
                        <p>{this.props.translate("services")}</p>
                        <strong>{services?.toLocaleString() || ""}</strong>
                      </span>
                      <LinearProgress
                        style={{ height: 20 }}
                        className="danger"
                        variant="determinate"
                        value={parseInt(
                          this.state.serviceMaintRequest?.countPercentage || 0
                        )}
                      />
                    </div>

                    <div style={{ margin: "6px 0px 6px 0px" }}>
                      <span className="row-label" style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // padding: "10",
                        alignItems:"center"
                      }}>
                        <p>{this.props.translate("packages")}</p> 
                        <strong>{packages?.toLocaleString() || ""}</strong>
                      </span>
                      <LinearProgress
                        style={{ height: 20 }}
                        className="light-blue"
                        variant="determinate"
                        value={parseInt(
                          this.state.packageMaintRequest?.countPercentage || 0
                        )}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12} sm={6} md={3} lg={3}
              style={{
                padding: "10px 12px 0px 20px",
                borderRightWidth:xs?"none": "1px",
                borderRightColor:xs?"none" :"#707070",
                borderRightStyle:xs?"none": "dashed",
                borderBottomWidth: xs ? 2 : 0,
                borderBottomColor: xs ? "#707070" : "none",
                borderBottomStyle: xs ? "dashed" : "none", 
              }}
            >
              {this.state.loader3 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                <Grid item xs={12} md={12}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems:"center",
                          padding: "10",
                        }}
                      >
                        <p
                          className="row-label"
                          style={{
                            textAlign: "left",
                            fontWeight: 700,
                            fontSize: 14,
                            alignItems: "center",
                          }}
                        >
                          {this.props.translate("collectionsAndOutstanding")}
                          {/* <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}(YTD)</span> */}
                        </p>
                        {/* <p
                          className="row-label"
                          style={{
                            textAlign: "left",
                            fontSize: 12,
                            fontWeight: 500,
                            width: 150,
                          }}
                        >
                          <Select
                            options={FiltersOption}
                            value={{
                              label: this.state.tab3 ? this.state.tab3 : "Select",
                            }}
                            onChange={(e) => this.handleChange("tab3", e)}
                          />
                        </p> */}
                      </Grid>
                    </Grid>
                   
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        // display: "flex",
                        // padding: "10",
                        // justifyContent: "space-between",
                        marginTop: 30,
                      }}
                    >
                   

                    
                      
                        <div style={{ 
                        display: 'flex', justifyContent: 'space-between',
                        fontSize:14, fontWeight:"bold",
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div 
                      style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{this.props.translate("totalOverdue")}</strong>
                        </div>
                        <div 
                        style={{display:"flex" ,justifyContent:'end'}}>
                       
                            <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = this.state.collectionsAndOutStanding?.totalOverDue;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                         
                            </strong>
                          {/* </Tooltip> */}
                        </div>
                      </div>

                      <div style={{ 
                             fontSize:14, fontWeight:"bold",
                        display: 'flex', justifyContent: 'space-between',
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{this.props.translate("totalCollections")}</strong>
                        </div>
                        <div 
                        style={{display:"flex" ,justifyContent:'end'}}
                        >
                             <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = this.state.collectionsAndOutStanding?.totalPaid;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                      // return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                            </strong>
                           
                        </div>
                      </div>
                      
                      <div style={{ 
                             fontSize:14, fontWeight:"bold",
                        display: 'flex', justifyContent: 'space-between',
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{this.props.translate("paymentBalance")}</strong>
                        </div>
                        <div style={{display:"flex" ,justifyContent:'end'}}>
                            <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = this.state.collectionsAndOutStanding?.amountCredited;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      // return formattedAmount;
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                            </strong>
                        </div>
                      </div>
                      <div style={{ 
                             fontSize:14, fontWeight:"bold",
                        display: 'flex', justifyContent: 'space-between',
                        background:"#00c179",borderRadius:4, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' , margin:"10px 0 10px 0",minHeight:24}}>
                      <div style={{display:"flex",alignItems:'center'}}>
                          <strong style={{ margin:"5px", color:"#ffffff" }}>{this.props.translate("totalOutstanding")}</strong>
                        </div>
                        <div style={{display:"flex" ,justifyContent:'end'}}>
                            <strong style={{margin:"5px",color:"#ffffff"}}>
                            {(() => {
                                    try
                                     {
                                      const saleNetAmount = this.state.collectionsAndOutStanding?.totalOutstanding;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      const formattedAmount1 =  `${formattedAmount} SAR`;
                                      return formattedAmount1;
                                      // return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                            </strong>
                        </div>
                      </div>

                    </Grid>
                    
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: "10px 20px 0px 20px"}}>
              {this.state.allSaleData && this.state.allSaleData ? (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:"center",
                        padding: "10",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                          alignItems: "center",
                        }}
                      >
                        {this.props.translate("salesTargetMonthly")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}(w/o VAT)</span>
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                  <Tooltip
                           title={ <Typography style={{fontSize:"14px",}}>
                            {this.state.allSaleData?.monthlyTarget?.toLocaleString()   || " " }
                            </Typography>}
                           placement={"bottom-start"}
                           enterDelay={300}
                           > 
                    <span style={{ fontSize: 28, fontWeight: "700" }}>
                        {this.state.allSaleData?.monthlyTarget  ? nFormatter(this.state.allSaleData?.monthlyTarget,2): ""}
                    </span>
                    </Tooltip>
                    <span style={{ fontSize: 12 }}> SAR</span>
                  </Grid>
                  <Grid item xs={12} md={12} style={{}}>
                  <HighchartsReact highcharts={Highcharts} options={salesChart} />

                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      // marginTop: 5,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, alignSelf: "center" }}>
                      {" "}
                      Target Reach
                    </span>
                    <Tooltip
                           title={
                            <Typography style={{fontSize:"14px",}}>
                           { this.state.allSaleData.monthlySale?.toLocaleString()  || 0 }
                            </Typography>}
                           placement={"bottom-start"}
                           enterDelay={300}
                           > 
                    <span
                      style={{
                        fontSize: 28,
                        fontWeight: "700",
                        padding: "0 5px",
                      }}
                    >
                      {this.state.allSaleData?.monthlySale ? nFormatter(this.state.allSaleData?.monthlySale,2) : 0 }
                      
                    </span>
                    </Tooltip>
                    <span style={{ alignSelf: "center",fontSize:12 }}>SAR</span>
                  </Grid>
                </>
              ) : (
                <MaintenanceItemEmpty />
              )}
            </Grid>
          </Grid>

          <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
            style={{
              display: "flex",
              marginBottom: 10,
              borderBottomWidth: 2,
              borderBottomColor: "#707070",
              borderBottomStyle: "dashed",
            }}
          >
            <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: "10px 20px 0px 20px"  }}>
              {this.state.loader4 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                          alignItems: "center",
                        }}
                      >
                        {this.props.translate("clientSales")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}SAR</span>
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                          options={FiltersOptionWithoutMTD}
                          value={{
                            label: this.state.tab5 ? this.state.tab5 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab5", e)}
                        />
                      </p>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        marginTop: 10,
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        {this.props.translate("total")}{" "}
                        {parseInt(
                          this.state.salesClients.totalSaleNetAmount
                        )}{" "}
                        SAR
                      </p>
                    </Grid> */}
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.salesClients &&
                        this.state.salesClients.clientList &&
                        this.state.salesClients.clientList.map((d, index) => (
                          <li>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingRight: "5px",
                                height: 17,
                                overflow: "hidden",
                              }}
                            >
                                  <Tooltip
                                  title={<Typography style={{fontSize:"12px",}}>{d.contractName}</Typography>}
                                  // placement={ "bottom-end" : "bottom-start"}
                                  enterDelay={300}
                                >
                              <span
                               style={{
                                maxWidth: "170px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                              >{d.contractName}</span>
                              </Tooltip>
                                {" "}
                                {(() => {
                                    try
                                     {
                                      const saleNetAmount = d.saleNetAmount;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                                {/* <span>{ d.saleNetAmount?.toLocaleString() || ""}</span>{" "} */}
                            </div>
                            <LinearProgress
                              style={{ height: 11 }}
                              className="success"
                              variant="determinate"
                              value={parseInt(d.salesPercentage)}
                            />
                          </li>
                        ))}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12} sm={6} md={3} lg={3}
              style={{
                padding: "10px 20px 0px 20px",
                borderLeftWidth: "1px",
                borderLeftColor: "#707070",
                borderLeftStyle: "dashed",
                borderTopWidth: xs ? 2 : 0,
                borderTopColor: xs ? "#707070" : "none",
                borderTopStyle: xs ? "dashed" : "none",
              }}
            >
              {this.state.loader5 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          marginTop: 10 ,
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        <span>{this.props.translate("clientVehiclesCount")}</span>
                        {/* <span style={{ fontSize: 12, fontWeight: 500 }}>
                          (Category) 
                        </span> */}
                      </p>
                      {/* <p
                        className="row-label"
                        style={{
                          fontSize: "22px",
                          fontWeight: "600",
                          fontStyle: "italic",
                          paddingLeft: 10,
                        }}
                      > */}
                        
                        <p>
                        {/* <span  
                        className="row-label"
                            style={{
                              textAlign: "left",
                              marginTop: 12,
                              fontSize: 12,
                              fontWeight: 500,
                            }}>
                      {" "}
                        
                      {this.props.translate("total")}
                    </span> */}

                        <Tooltip
                              title={
                                <Typography style={{fontSize:"14px",}}>
                                {this.state?.totalFleetSize?.toLocaleString() || 0}
                                </Typography>
                                }
                              placement="bottom-start"
                              enterDelay={300}
                              classes={{ tooltip: 'customTooltip' }}
                          >
                          <span
                        className="row-label"
                        style={{
                          fontSize: "22px",
                          fontWeight: "600",
                          fontStyle: "italic",
                          paddingLeft: 10,
                        }}
                      >
                          {" "}
                            {this.state && this.state.totalFleetSize ?  
                            nFormatter(this.state.totalFleetSize,2)
                           : 0}
                        </span>
                        </Tooltip>

                        </p>
                        {/* {this.state.totalFleetSize} */}
                        {/* <Select
                          options={FiltersOption}
                          value={{
                            label: this.state.tab6 ? this.state.tab6 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab6", e)}
                        /> */}
                      {/* </p> */}
                    {/* </Grid>
                       <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        padding: "10",
                        alignItems: "center",

                        marginTop: 10,
                      }}
                    >
                    <span
                      className="row-label"
                      style={{
                        textAlign: "left",
                        marginTop: 12,
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {this.props.translate("totalFleetSize")}{" "}
                    </span>
                      <span
                        className="row-label"
                        style={{
                          fontSize: "22px",
                          fontWeight: "600",
                          fontStyle: "italic",
                          paddingLeft: 10,
                        }}
                      >
                    
                        {parseInt(
                          this.state.totalFleetSize
                        )}
                      </span>*/}
                    </Grid> 
                  </Grid>

                  {!this.state.openGraph ? (
                    <Grid item xs={12} md={12} style={{ marginTop: 25 }}>
                       <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                      <ul className="md-bar-graphlist">
                        {this.state.clientVehiclesVehicleWise &&
                          this.state.clientVehiclesVehicleWise.map(
                            (d, index) => (
                              <li
                                onClick={() => this.openVehicle(d)}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: 17,
                                    overflow: "hidden",
                                  }}
                                >
                                     <Tooltip
                                   title={<Typography style={{fontSize:"12px",}}>{d.contractName}</Typography>}
                                  // placement={ "bottom-end" : "bottom-start"}
                                  enterDelay={300}
                                >
                                  <span 
                                      style={{
                                        maxWidth: "170px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                      }}
                                  >{d.contractName}</span>
                                  </Tooltip>
                                  <span>
                                  {(() => {
                                    try
                                     {
                                      const saleNetAmount = d.accessVehiclesCount;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                                  </span> 
                                  {/* <span>{d.accessVehiclesCount?.toLocaleString() || ""}</span> */}
                                </div>
                                <LinearProgress
                                  style={{ height: 11 }}
                                  className="light-green"
                                  variant="determinate"
                                  value={parseInt(d.salesPercentage)}
                                />
                              </li>
                            )
                          )}
                      </ul>
                      </Scrollbar>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ textAlign: "end", marginTop: 5 }}
                        >
                          <Button
                            aria-label="back"
                            size="small"
                            color="inherit"
                            onClick={() => this.CloseGraph()}
                            id="backButton"
                          >
                            {this.props.translate("sharedBack")}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                          {/* <ReactHighcharts
                            domProps={{ id: "clientVehiclesVehicleWise" }}
                            config={clientVehiclesVehicleWiseChart.config}
                          /> */}
                  <HighchartsReact highcharts={Highcharts} options={clientVehiclesVehicleWiseChart.config} />

                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} 
            style={{ 
            padding: "10px 20px 0px 20px",
            borderTopWidth: xs ? 2 : 0,
            borderTopColor: xs ? "#707070" : "none",
            borderTopStyle: xs ? "dashed" : "none",
            borderLeftWidth: "1px",
            borderLeftColor: "#707070",
            borderLeftStyle: "dashed",
            
            }}>
              {this.state.loader6 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("clientOutstanding")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}SAR</span>
                      </p>

                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                        isDisabled={true}
                          options={FiltersOption}
                          value={{
                            label: this.state.tab7 ? this.state.tab7 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab7", e)}
                        />
                      </p>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        marginTop: 10,
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("clients")}
                        {this.state.salesClientsWise.totalCount}
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        Total{" "}
                        {parseInt(this.state.salesClientsWise?.totalOurDue ||  0 )}{" "} {"SAR"}
                      </p>
                    </Grid> */}
                  </Grid>
                   <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                   <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.salesClientsWise &&
                        this.state.salesClientsWise.clientList &&
                        this.state.salesClientsWise.clientList.map(
                          (d, index) => (
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: 17,
                                  overflow: "hidden",
                                }}
                              >
                                   <Tooltip
                                  title={<Typography style={{fontSize:"12px",}}>{d.contractName}</Typography>}
                                  // placement={ "bottom-end" : "bottom-start"}
                                  enterDelay={300}
                                >
                                <span    
                                  style={{
                                maxWidth: "170px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                              >
                                {d.contractName}</span>
                                </Tooltip>
                                {/* <span>{parseInt(d.overDue?.toLocaleString())|| ""}</span> */}
                                {/* <span>{d.overDue?.toLocaleString()|| ""}</span> */}
                                <span>
                                  {(() => {
                                    try
                                     {
                                      const saleNetAmount = d.overDue;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                                  </span> 
                              </div>
                              <LinearProgress
                                style={{ height: 11 }}
                                className="purple"
                                variant="determinate"
                                value={parseInt(d.ourDuePercentage)}
                              />
                            </li>
                          )
                        )}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12} sm={6} md={3} lg={3}
              style={{
                padding: "10px 20px 0px 20px",
                borderLeftWidth: "1px",
                borderLeftColor: "#707070",
                borderLeftStyle: "dashed",
                borderTopWidth: xs ? 2 : 0,
                borderTopColor: xs ? "#707070" : "none",
                borderTopStyle: xs ? "dashed" : "none",
                borderTopWidth: sm ? 2 : 0,
                borderTopColor: sm ? "#707070" : "none",
                borderTopStyle: sm ? "dashed" : "none",
              }}
            >

              {this.state.loader7 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("clientMaint.InvoiceCount")}
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          value={{
                            label: this.state.tab8 ? this.state.tab8 : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab8", e)}
                        />
                      </p>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}  style={{ marginTop: 10 }}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.maintRequestClientWise &&
                        this.state.maintRequestClientWise.clientList &&
                        this.state.maintRequestClientWise.clientList.map(
                          (d, index) => (
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: 17,
                                  overflow: "hidden",
                                }}
                              >
                                 <Tooltip
                                   title={<Typography style={{fontSize:"12px",}}>{d.contractName}</Typography>}
                                  // placement={ "bottom-end" : "bottom-start"}
                                  enterDelay={300}
                                >
                                <span
                                    style={{
                                      maxWidth: "170px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap"
                                    }}
                                >{d.contractName}</span>
                                </Tooltip>
                                {(() => {
                                    try
                                     {
                                      const saleNetAmount = d.count;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}
                                {/* <span>{d.count?.toLocaleString() || ""}</span> */}
                              </div>
                              <LinearProgress
                                style={{ height: 11 }}
                                className="danger"
                                variant="determinate"
                                value={parseInt(d.countPercentage)}
                              />
                            </li>
                          )
                        )}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
            style={{
              display: "flex",
              marginBottom: 10,
              borderBottomWidth: 2,
              borderBottomColor: "#707070",
              borderBottomStyle: "dashed",
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderRightColor:xs?"none": "#707070",
                borderRightStyle:xs?"none": "dashed",
                borderRightWidth:xs?"none": 2,
              }}
            >
              {this.state.loader8 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                    >
                      {this.props.translate("topParts")}
                    </p>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: 12,
                        fontWeight: 500,
                        width: 130,
                      }}
                    >
                      <Select
                        options={FiltersOption}
                        value={{
                          label: this.state.tab9 ? this.state.tab9 : "Select",
                        }}
                        onChange={(e) => this.handleChange("tab9", e)}
                      />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.usedPart &&
                        this.state.usedPart.map((item) => {
                          return (
                            <li>
                               <span style={{display:"flex", justifyContent:"space-between"}} className="row-label">
                                <em>
                                  {item?.itemName || ""}
                                  {" - "}
                                  {item?.itemCode || ""}
                                </em>{" "}
                                 <strong>{item?.usedCount?.toLocaleString() || ""}</strong>
                                 
                              </span>
                              <LinearProgress
                                style={{ height: 5 }}
                                className="light-yellow"
                                variant="determinate"
                                value={item.usedCountPercentage}
                              />
                            </li>
                          );
                        })}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderRightColor:xs?"none": "#707070",
                borderRightStyle:xs?"none": "dashed",
                borderRightWidth:xs?"none": 2,
                borderTopWidth: xs ? 2 : 0,
                borderTopColor: xs ? "#707070" : "none",
                borderTopStyle: xs ? "dashed" : "none",
              }}
            >
              {this.state.loader13 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                       style={{
                        textAlign: "left",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {this.props.translate("topServices")}
                    </p>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: 12,
                        fontWeight: 500,
                        width: 130,
                      }}
                    >
                      <Select
                        options={FiltersOption}
                        value={{
                          label: this.state.tab10 ? this.state.tab10 : "Select",
                        }}
                        onChange={(e) => this.handleChange("tab10", e)}
                      />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.usedService &&
                        this.state.usedService.map((item) => {
                          return (
                            <li>
                              <span style={{display:"flex", justifyContent:"space-between"}} className="row-label">
                                <em>
                                  {item?.itemName || ""}
                                  {" - "}
                                  {item?.itemCode || ""}
                                </em>{" "}
                               <strong>{item?.usedCount?.toLocaleString() || ""}</strong>
                              </span>
                              <LinearProgress
                                style={{ height: 5 }}
                                className="light-red"
                                variant="determinate"
                                value={item.usedCountPercentage}
                              />
                            </li>
                          );
                        })}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderTopWidth: xs ? 2 : 0,
                borderTopColor: xs ? "#707070" : "none",
                borderTopStyle: xs ? "dashed" : "none",
              }}
            >
              {this.state.loader14 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                    >
                      {this.props.translate("topPackages")}
                    </p>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: 12,
                        fontWeight: 500,
                        width: 130,
                      }}
                    >
                      <Select
                        options={FiltersOption}
                        value={{
                          label: this.state.tab11 ? this.state.tab11 : "Select",
                        }}
                        onChange={(e) => this.handleChange("tab11", e)}
                      />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">

                      {this.state.usedPackages &&
                        this.state.usedPackages.map((item) => {
                          return (
                            <li>
                              {/* <span className="row-label"> */}
                              <span style={{display:"flex", justifyContent:"space-between"}} className="row-label">
                                <em>
                                  {item?.itemName || item?.name || ""}
                                  {" - "}
                                  {item?.itemCode || ""}
                                </em>{" "}
                                 <strong>{item?.usedCount?.toLocaleString() || ""}</strong>
                              </span>
                              <LinearProgress
                                style={{ height: 5 }}
                                className="light-green"
                                variant="determinate"
                                value={item.usedCountPercentage}
                              />
                            </li>
                          );
                        })}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12} 
          style={{ display: "flex", marginTop: 5, marginBottom: 10,
              borderBottomWidth: 2,
              borderBottomColor: "#707070",
              borderBottomStyle: "dashed", }}>
          

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderRightColor:xs?"none": "#707070",
                borderRightStyle:xs?"none": "dashed",
                borderRightWidth:xs?"none": 2,
              }}
            >
              {this.state.loader10 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("vehiclesCategoriesCount")}
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          value={{
                            label: this.state.tab13
                              ? this.state.tab13
                              : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab13", e)}
                        />
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{marginBottom:"5px"}}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >            
                      <ul className="md-bar-graphlist">
                        {this.state.vehiclesCategories &&
                          this.state.vehiclesCategories.map((d, index) => (
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                  {/* <span>
                                    {d.name.charAt(0).toUpperCase() + d.name.slice(1)}
                                  </span> */}
                                <span>{d.name ==="car" ? "SEDAN" : d.name?.toUpperCase()}</span>
                                <span>
                                {(() => {
                                      try
                                      {
                                        const saleNetAmount = d.value;
                                        const integerAmount = Math.floor(saleNetAmount);
                                        const formattedAmount = integerAmount.toLocaleString();
                                        return formattedAmount;
                                      } catch (error) {
                                        console.error('Error:', error);
                                        return "Error";
                                      }
                                    })()}
                                  </span>



                                {/* <span>{d.value?.toLocaleString()}</span> */}
                              </div>
                              <LinearProgress
                                style={{ height: 11 }}
                                className="primary"
                                variant="determinate"
                                value={parseInt(d.countPercentage)}
                              />
                            </li>
                          ))}
                      </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderRightColor:xs?"none": "#707070",
                borderRightStyle:xs?"none": "dashed",
                borderRightWidth:xs?"none": 2,
                borderTopWidth: xs ? 2 : 0,
                borderTopColor: xs ? "#707070" : "none",
                borderTopStyle: xs ? "dashed" : "none",
              }}
            >
              {this.state.loader11 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("profitsSAR")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}SAR</span>
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          value={{
                            label: this.state.tab14
                              ? this.state.tab14
                              : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab14", e)}
                        />
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{marginBottom:"5px"}}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.totalProfitTab14 &&
                        this.state.totalProfitTab14.map((d, index) => (
                          <li>
                            {/* const formattedCompanyName = data?.attributes?.c_accountName.replace(/:amp;/g, '&'); */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{replaceSpecialCh(d.contractName)}</span>
                              <span>        {(() => {
                                    try
                                     {
                                      const saleNetAmount = d.profit;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}</span>
                              {/* <span>{d.profit?.toLocaleString()}</span> */}
                            </div>
                            <LinearProgress
                              style={{ height: 11 }}
                              className="light-yellow"
                              variant="determinate"
                              value={parseInt(d.countPercentage)}
                            />
                          </li>
                        ))}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderTopWidth: xs ? 2 : 0,
                borderTopColor: xs ? "#707070" : "none",
                borderTopStyle: xs ? "dashed" : "none",
              }}
            >
              {this.state.loader12 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("stations")}
                      </p>
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontSize: 12,
                          fontWeight: 500,
                          width: 130,
                        }}
                      >
                        <Select
                          options={FiltersOption}
                          value={{
                            label: this.state.tab15
                              ? this.state.tab15
                              : "Select",
                          }}
                          onChange={(e) => this.handleChange("tab15", e)}
                        />
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{marginBottom:"5px"}}>
                  <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height:
                        "320px",
                        
                      }}
                      scrollerProps={{
                        renderer: (props) => {
                          const { elementRef, ...restProps } = props;
                          return (
                            <div {...restProps} ref={elementRef} id="scrollableDiv" />
                          );
                        },
                      }}
                    >
                    <ul className="md-bar-graphlist">
                      {this.state.cogs &&
                        this.state.cogs.map((d, index) => {
                     
                          return (
                            <Tooltip
                              title={
                                <Typography key={d.invoicesCount}>
                                  {"Average Ticket"}:{" "}
                                  {parseInt(d.averageTicket)} <br />
                                  {"COGS"}: {parseInt(d.totalCost)}
                                  <br />
                                  {"Gross Profit"}: {parseInt(d.totalProfit)}
                                  <br />
                                  {"Net Sales"}:{parseInt(d.totalSaleNetAmount)}
                                  <br />
                                  {"Target"}:{d?.target?.toLocaleString()}
                                  <br />
                                </Typography>
                              }
                              placement={"bottom-start"}
                              enterDelay={300}
                              // placement='right-end'
                            >

                              <li>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                          
                                   <span>{d.name}</span>
                                  {/* <span>{d.invoicesCount?.toLocaleString()}</span> */}
                                  {/* <span>{parseInt(d.invoicesCount?.toLocaleString())}</span> */}
                                  <span>    <span>        {(() => {
                                    try
                                     {
                                      const saleNetAmount = d.invoicesCount;
                                      const integerAmount = Math.floor(saleNetAmount);
                                      const formattedAmount = integerAmount.toLocaleString();
                                      return formattedAmount;
                                    } catch (error) {
                                      console.error('Error:', error);
                                      return "Error";
                                    }
                                  })()}</span></span>
                                </div>
                                <LinearProgress
                                  style={{ height: 11 }}
                                  className="success"
                                  variant="determinate"
                                  value={parseInt(d.countPercentage)}
                                />
                              </li>
                            </Tooltip>
                          );
                        })}
                    </ul>
              </Scrollbar>

                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid 
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{   display: "flex",
                      // justifyContent: "space-between",
                      // marginTop: 10,
                      marginBottom: 10, }}>
           
          <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderRightColor:xs?"none": "#707070",
                borderRightStyle:xs?"none": "dashed",
                borderRightWidth:xs?"none": 2,
                // borderTop:  "2px dashed #707070",

              }}
            >
              {this.state.loader9 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("salesTargetYearly")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}(w/o VAT)</span>
                      </p>
                      {/* <p className="row-label" style={{textAlign:"left",fontSize:12, fontWeight:500, width:130 }}>
                            <Select options={FiltersOption}  value={{label : this.state.tab12 ? this.state.tab12 : "Select"}} onChange={e=>this.handleChange("tab12",e)}/>
                            </p> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: 5 }}>
                   
                  <Tooltip
                          title={
                            <Typography style={{fontSize:"14px",}}>
                            {this.state.allSaleData?.yearlyTarget?.toLocaleString()  || " " }
                            </Typography>}
                          placement={"bottom-start"}
                          enterDelay={300}
                          > 
                    <span style={{ fontSize: 28, fontWeight: "700" }}>
                        {this.state.allSaleData?.yearlyTarget ? nFormatter(this.state.allSaleData?.yearlyTarget,2) : ""}
                    </span>
                    </Tooltip>
                    <span style={{ fontSize: 12 }}> SAR</span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* <ReactHighcharts
                      domProps={{ id: "salesChartYearly" }}
                      config={salesChartYearly.config}
                    /> */}
                  <HighchartsReact highcharts={Highcharts} options={salesChartYearly} />

                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      // marginTop: 5,
                      display: "flex",
                      // marginTop:"20px",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, alignSelf: "center" }}>
                      {" "}
                      Target Reach
                    </span>
                    <Tooltip
                          title={
                            <Typography style={{fontSize:"14px",}}>
                         {   this.state.allSaleData?.yearlySale?.toLocaleString()  || " " }
                            </Typography>}
                          placement={"bottom-start"}
                          enterDelay={300}
                          > 
                          <span
                            style={{
                              fontSize: 28,
                              fontWeight: "700",
                              padding: "0 5px",
                            }}
                          >
                          {this.state.allSaleData?.yearlySale ? nFormatter(this.state.allSaleData?.yearlySale,2) : "" }
                          </span>
                    </Tooltip>
                    <span style={{ alignSelf: "center", fontSize:12 }}> SAR</span>
                  </Grid>
                </>
              )}
            </Grid>
            {/* <Grid 
              item  
              xs={12}
              sm={12}
              md={4}
              lg={4}
                style={{ 
                  padding: "0px 10px  0px",
                  borderRight:xs?"": "2px dashed#707070",
                  // borderRightStyle:xs?"none": "dashed",
                  // borderRightWidth:xs?"none": 2,
                // borderTop:  "2px dashed #707070",

                  }}
              >
                {this.state.loader9 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                <Grid item xs={12} md={12}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="row-label"
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {this.props.translate("actualVsPotentialBusiness")}
                    </p>
                   
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} 
                     style={{ 
                      margin: "50px 0px  0px 0px "
                     }}
                     >
                    
                  </Grid>
                  </>
                  )}
           
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{
                padding: "0 10px 0 10px",
                // borderTopWidth: xs ? 2 : 0,
                // borderTop: xs ? "2px dashed #707070" : "none",
                // borderTop:  "2px dashed #707070",
                // borderTopStyle: xs ? "dashed" : "none",
              }}
            >
          {this.state.loader18 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="row-label"
                        style={{
                          textAlign: "left",
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        {this.props.translate("monthlySalesNetAmount")}
                        <span  style={{
                          textAlign: "left",
                          fontWeight: 500,
                          fontSize: 12,
                          alignItems: "center",
                        }} >{" "}({this.props.translate("Last 12 Months")})</span>
                      </span>
                    </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
          <HighchartsReact highcharts={Highcharts} options={monthlyNetAmountChart} />
          </Grid> </> )}
        </Grid>
       
          </Grid>
          <Grid 
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{   display: "flex",
                      borderTop: "2px dashed #707070",
                      paddingTop: 10,
                      marginBottom: 10, }}>
           
          <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
                borderRightColor:xs?"none": "#707070",
                borderRightStyle:xs?"none": "dashed",
                borderRightWidth:xs?"none": 2,
                // borderTop:  "2px dashed #707070",

              }}
            >
              {this.state.loader9 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                <Grid item xs={12} md={12}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="row-label"
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {this.props.translate("top10VehiclesbyMaint")}
                      {/* <span  style={{
                        textAlign: "left",
                        fontWeight: 500,
                        fontSize: 12,
                        alignItems: "center",
                      }} >{" "}(w/o VAT)</span> */}
                    </p>
                   
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} 
                     style={{ 
                      margin: "10px 0px  0px 0px "
                     }}
                     >
                  <HighchartsReact highcharts={Highcharts} options={topVehilesChart } />
                  </Grid>
                  </>
                  )}
            </Grid>
            <Grid 
              item  
              xs={12}
              sm={12}
              md={4}
              lg={4}
                style={{ 
                  padding: "0px 10px  0px",
                  borderRight:xs?"": "2px dashed#707070",
                  }}
              >
                {this.state.loader19 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                <Grid item xs={12} md={12}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="row-label"
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {this.props.translate("top10VehiclesbyMaint")} {' '} {this.props.translate("perDay")}
                    </p>
                   
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} 
                     style={{ 
                      margin: "10px 0px  0px 0px "
                     }}
                     >
                    <HighchartsReact highcharts={Highcharts} options={topMaintenancePerDayChart} />
                  </Grid>
                  </>
                  )}
           
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{
                padding: "0 10px 0 10px",
              }}
            >
          {this.state.loader9 ? (
                <MaintenanceItemEmpty />
              ) : (
                <>
                <Grid item xs={12} md={12}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="row-label"
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {this.props.translate("top10VehiclesbyMaint")} {' '} {this.props.translate("perKM")}
                    </p>
                   
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} 
                     style={{ 
                      margin: "10px 0px  0px 0px "
                     }}
                     >
                    <HighchartsReact highcharts={Highcharts} options={topMaintenancePerKmChart} />
                  </Grid>
                  </>
                  )}
        </Grid>
       
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapState = (state,props) => { 
  return {
    // currentId: props.match?.params?.id,
    logInUsers: state.logInUsers,
    themecolors: state.themeColors,
    tab1: state.tab1,
    tab2: state.tab2,
    tab3: state.tab3,
    tab4: state.tab4,
    tab5: state.tab5,
    tab6: state.tab6,
    tab7: state.tab7,
    tab8: state.tab8,
    tab9: state.tab9,
    tab10: state.tab10,
    tab11: state.tab11,
    tab12: state.tab12,
    tab13: state.tab13,
    tab14: state.tab14,
    tab15: state.tab15,
    tab18:state.tab18,
    tab17:state.tab17,
    tab19:state.tab19
  };
};
const mapStateToProps = connect(mapState);
export const MaintenanceDashboard = mapStateToProps(withRoutes(MaintenanceDashbd));

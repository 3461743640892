export const getUsers = users => ({
  type: 'GET_USERS',
  users
})
export const addUser = user => ({
  type: 'ADD_USER',
  user
})
export const updateUser = user => ({
  type: 'UPDATE_USER',
  user
})
export const removeUser = user => ({
  type: 'REMOVE_USER',
  user
})
export const sortUsers = users => ({
  type: 'SORT_USERS',
  users
})
export const parentUsersLimit = limit => ({
  type: 'USER_LIMIT',
  limit
})
export const vehicleLimit = limit => ({
  type: 'VEHICLES_LIMIT',
  limit
})

export const searchUser = user => ({
  type: 'SEARCH_USER',
  payload: user.user
})

export const unitBaseUser = user => ({
  type: 'UNIT_BASE_USER',
  user
})

export const logInUserInfo = user => ({
  type: 'LOGIN_USER_INFO',
  user
})

export const logout = user => ({
  type: 'LOGOUT_USER',
  user
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from './Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Scrollbar from 'react-scrollbars-custom'
import instance from '../../axios'
class AreaSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      search: '',
      isChecked: false,
      vehicles:[]
    }
  }
  componentWillMount () {
    let list = {}
    if (this.props.value && this.props.value.length) {
      this.props.value.map(d => (list[d] = true))
      this.setState({ list })
    }
    this.getVehicles()
  }

  componentWillReceiveProps (nextprops) {
    let list = {}
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map(d => (list[d] = true))
    }
    this.setState({ list })
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      isChecked: false
    })
  }

  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list),'area')
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    this.setState(
      {
        list,
        isChecked: this.props.vehicles?.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }
  searchItem = text => {
    this.setState({ search: text.target.value },()=>{this.getVehicles()})
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      this.state.vehicles.map(d => (list[d.id] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  
  getVehicles =  () => {
    let vehcilesList = []
     if(this.props.logInUser&&this.props.logInUser.id){
       instance({
        method: 'GET',
        // http://localhost:3000/api/areas/get?userId=6&all=true&page=1&limit=50&search=
        url: `api/areas/get?userId=${this.props.logInUser.id}&all=true&limit=20&page=1&search=${this.state.search}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          // vehcilesList = (res.data).map(item => {
          //   return {
          //     id:item.id,
          //     key:item.id,
          //     name:item.label,
          //     label:item.label,
          //     uniqueId:item.id,
          //     valueType:'string'
          //   }
          // })

          // // })
          this.setState({vehicles:res.data})
          // console.log("aresa",this.state.vehicles);


      })
      .catch(error => {
        // errorHandler(error, this.props.dispatch)
      })
     }
  }

  render () {
    // let list = this.props.vehicles.filter(
    //   item => item.label && item.label.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    // )
    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
         
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
        .selector-list {
            padding: 0;
            margin: 0;
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}
        <div>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={this.props.translate('searchArea')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {/* <li style={{ paddingRight: 0, paddingTop: 3 }}>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate('selectAll')}
                />
              </li> */}
            </ul>
          </div>
          <div className='selector-count'>
            {Object.keys(this.state.list).length}{' '}
            {this.props.translate('AreasSelected')}
          </div>
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 35) *
                (this.props.rows || 5)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div {...restProps} ref={elementRef} id='scrollableDiv' />
                )
              }
            }}
          >
            <ul className='selector-list'>
              {this.state.vehicles && this.state.vehicles.map(row => (
                <li key={row.id}>
                  <label className={this.state.vehicles[row.id] ? 'active' : null}>
                    <span className='selector-check'>
                      <Checkbox
                        // checked={this.state.vehicles[row.id]}
                        value={row}
                        onChange={this.onChange}
                      />
                    </span>
                    <span className='selector-label'>{row.name}</span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  vehicles: state.vehicles,
  themecolors: state.themeColors,
  logInUser:state.logInUsers

})
const mapStateToProps = connect(mapState)
export default mapStateToProps((AreaSelector))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../wrapper'
import { MaintenanceDashboard } from './MaintenanceDashboard'
import { logInUserInfo } from '../../Actions/Users'
import withRoutes from '../../HOC/HocRoutes';

class MaintDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
    }
  }

  render () {
      if (this.props.logInUser ) {
        return (
          <Layout
            {...this.props}
            MaintenanceDashboard
          >
            <MaintenanceDashboard {...this.props} />
          </Layout>
        )
      } else  {
        fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
          headers: { Accept: "application/json; odata=verbose" },
        })
          .then((response) => {
            if (response.ok) {
              response.json().then(res => {
                if(res.status ==='success'){  
                  let userInfo = res.data
                  this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                  this.props.dispatch(logInUserInfo(userInfo))
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                
                    toast.error(
                     this.props.translate(err[1])
                  )
                  this.props.navigate('/login')
                }
              })
            } else {
              this.props.navigate('/login')
              throw response
            }
          })
          .catch((e) => {
           
              toast.error(
                "somethingWentWrong"
            );
          });
        return null;
      }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withRoutes(MaintDashboard))

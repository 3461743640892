import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddRoleModal from './addRole'
import isEqual from 'react-fast-compare'
import { Checkbox } from '@mui/material'
// import Loader from '../../Layout/Loader'
import { addRole, updateRole, removeRole } from '../../Actions/Roles'
import Tooltip from '@mui/material/Tooltip'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ConfirmDialoag from '../common/ConfirmDialoag'
import { checkPrivileges } from '../../Helpers'

const styles = theme => ({
  root: {
    color: '#00B06E',
    '&$checked': {
      color: '#57c279'
    }
  },
  checked: {},
  disabled: {
    opacity: 0.5
  }
})
const formDefault = {
  name: '',
  description: '',
  id: '',
  // reportInterval:'',
  // historyInterval:''
}
class roleModal extends Component {
  constructor () {
    super()
    this.state = {
      // all fields of form
      loader: false,
      addOption: false,
      editOption: false,
      showDetails: false,
      addO:false,
      selectedItem: '',
      isVisableRoleBtn: false,
      allPrivilagesGroups: '',
      onDeleteConfirmation: false,
      open: {},
      form: {...formDefault}
    }
    this.checkHandelChanges = this.checkHandelChanges.bind(this)
  }
  componentWillMount () {
    if (this.props.activeOperation === 'add') {
      this.setState({
        addOption: true,
        editOption: false,
        showDetails: false,
        loader: false,
        selectedItem: '',
        form:{...formDefault},
        // form:{...formDefault,attributes:{}}
      })
    }
    if (this.props.activeOperation === 'edit') {
      this.setState({
        addOption: false,
        editOption: true,
        showDetails: false,
        loader: false,
        selectedItem: this.props.selectedItem,
        form: { ...this.props.selectedItem }
      })
    }
    if (this.props.activeOperation === 'remove') {
      this.isDeleteRole(this.props.selectedItem)
    }
    if (this.props.activeOperation === 'showDetails') {
      this.preparePrivilagesGroups()
      this.setState({
        addOption: false,
        editOption: false,
        showDetails: true,
        selectedItem: this.props.selectedItem
      })
    }
  }
  componentWillUnmount () {
    // this.props.dispatch(toast.removeAll())
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.activeOperation === 'add') {
      this.setState({
        addOption: true,
        editOption: false,
        showDetails: false,
        loader: false,
        selectedItem: '',
        form: {...formDefault},
        // form: {...formDefault,attributes:{}}
      })
    }
    if (nextProps.activeOperation === 'edit') {
      this.setState({
        addOption: false,
        editOption: true,
        showDetails: false,
        loader: false,
        selectedItem: nextProps.selectedItem,
        form: { ...nextProps.selectedItem }
      })
    }
    if (nextProps.activeOperation === 'remove') {
      this.isDeleteRole(nextProps.selectedItem)
    }
    if (nextProps.activeOperation === 'showDetails') {
      this.setState({
        addOption: false,
        editOption: false,
        showDetails: true,
        selectedItem: nextProps.selectedItem
      })
    }
  }
  preparePrivilagesGroups = () => {
    let test = {},
      result = [],
      perants = {}
      this.props.privileges.map(a => {
      let checked = false
      if (this.props.itemPrivileges.length) {
        let value = this.props.itemPrivileges.find(dec => dec.id === a.id)
        if (value) {
          checked = true
        }
      }
      a['checked'] = checked
      if (a.parentId === 0) {
        a['checkedAll'] = true
        perants = { ...perants, [a.key]: true }
        test[a.id] = { ...a, children: a.children || [] }
        test[a.id].children.push(a)
      } else {
        test[a.parentId] = test[a.parentId] || { data: {}, children: [] }
        test[a.parentId].children.push(a)
      }
    })
    if (test) {
      result = Object.keys(test).map(function (key) {
        return test[key]
      })
      if (result) {
        result.map(item => {
          delete item.children[0].checkedAll
          if (item.checked) {
            let value = item.children.find(child => child.checked === false)
            if (value) {
              item.checkedAll = false
            }
          } else {
            item.checkedAll = false
          }
        })
      }
    }
    this.setState({
      allPrivilagesGroups: result,
      open: perants
    })
  }
  addSubmit = () => {
    let { form } = this.state
    // form['reportInterval']=form.reportInterval*24*60*60
    // form.attributes['dataHistory']=form.timeInterval*24*60*60
    if (form.description.trim().length > 0) {
      const obj = { ...form }
      fetch('/api/roles/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){
                let role=res.data
                this.setState({
                  addOption: false,
                  isVisableRoleBtn: false,
                  form: ''
                })
                this.props.dispatch(addRole(role))
                if (this.props.onCloseModal) {
                  this.props.onCloseModal()
                }
              
                  toast.success(
                     this.props.translate('roleIsAdded')
                )
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                
                  toast.error(
                    this.props.translate(err[1])
                )
              }
            })
          } else {
            throw response
          }
        })
        .catch(e => {
          
            toast.error(
              'Something went wrong'
          )
        })
    } else {

        toast.error(
          this.props.translate('descriptionIsEmpty')
      )
    }
  }
  editSubmit = () => {
    let { form,addO } = this.state
    // if(addO){
    //   form['timeInterval']=form.timeInterval*24*60*60
    // }
    
    if (form.description.trim().length > 0) {
      const obj = { ...form }
      // if(addO){
      //   obj['reportInterval']=obj.reportInterval*24*60*60
      // }
      fetch(`/api/roles/${form.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){
                this.props.dispatch(updateRole(res.data))
                if (this.props.onCloseModal) {
                  this.props.onCloseModal()
                }
   
                  toast.success(
                    this.props.translate('roleIsUpdated')             
                )
                this.setState({
                  editOption: false,
                  isVisableRoleBtn: false,
                  form: ''
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
              
                  toast.error(
                 this.props.translate(err[1])
                )
              }
            })
          } else {
            throw response
          }
        })
        .catch(e => {
          // if(e && e.text){
          //   e.text().then(err => {
          //   var array = err&&err.split("-")
          //   this.props.dispatch(toast.error({
          //         message: this.props.translate(array[0]),
          //         autoDismiss: 5
          //      }))
          //   })
          // }
          // else{
            toast.error(
           this.props.translate('somthingWentWrongMessage')
            )
          // }
        })
      this.setState({
        isVisableRoleBtn: false
      })
    } else {
    
        toast.error(
         this.props.translate('descriptionIsEmpty')
      )
    }
  }
  isDeleteRole = item => {
    this.setState({
      addOption: false,
      editOption: false,
      showDetails: false,
      selectedItem: item,
      onDeleteConfirmation: true
    })
  }
  deleteRole = () => {
    const { selectedItem } = this.state
    if (selectedItem.id) {
      fetch(`/api/roles/${selectedItem.id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...selectedItem
        })
      })
        .then(response => {
            response.json().then(res=>{
              if(res.status === "success"){
                this.props.dispatch(removeRole(selectedItem))
                if (this.props.onCloseModal) {
                  this.props.onCloseModal()
                }
   
                  toast.success(
                     this.props.translate('roleIsDeleted')
                )
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
             
                  toast.error(
                    this.props.translate(err[1])
                )
              }
              else {
                throw response
              }
            })
        })
        .catch(e => {
 
            toast.error(
              'something went wrong'
          )
        })
      this.setState({
        selectedItem: '',
        addOption: false,
        editOption: false,
        attributeChangesMessage: '',
        onDeleteConfirmation: false
      })
    }
  }

  handleChange = name => event => {
    const { target } = event
    if (target.type === 'checkbox') {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.checked
          }
        }),
        () => this.checkRequiredFields()
      )
    }else if(name ==='reportInterval') {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.value
          },
        }),
        () =>{
          this.setState({addO:true})
          this.checkRequiredFields()}
      )
    }
    else {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.value
          }
        }),
        () =>{
          this.checkRequiredFields()}
      )
    }
  }
  checkRequiredFields = () => {
    let { name, description } = this.state.form
    let value = !isEqual(this.state.form, this.state.selectedItem)
    if (name&&name.trim().length > 0 && description && value) {
      this.setState({
        isVisableRoleBtn: true
      })
    } else {
      this.setState({
        isVisableRoleBtn: false
      })
    }
  }
  modalControle = () => {
    this.setState({
      addOption: false,
      editOption: false
    })
    this.props.onCloseModal()
  }
  checkHandelChanges = (event, type, data) => {
    let checkPivilge= false //check privilges to assign/unassign role 
    if(event.target.checked){ if(checkPrivileges('roleLinkPrivilege')) { checkPivilge=true }  }   // to check priviliges for assigning and un-assigning
    else { if(checkPrivileges('roleUnlinkPrivilege')) { checkPivilge=true }  }
    if(checkPivilge){
    let { target } = event
    this.setState(
      {
        loader: true
      },
      () => {
        let allPrivilages = JSON.parse(
          JSON.stringify(this.state.allPrivilagesGroups)
        )
        if (type === 'parent') {
            if(this.props.logInUser.userType !== -1 && this.state.selectedItem.id < 8){  // to handle assigning and unassigning default roles (other than owner acces)  
              
                toast.error(
                  this.props.translate('ownerAccessDenied')
              )
            }
            else {
              allPrivilages.map(prvilg => {
                if (prvilg.id === data.id) {
                  prvilg.checked = target.checked
                  prvilg.checkedAll = target.checked
                  let option = ''
                  //this.multiPrivilageHandel(data.id, target.checked)
                  prvilg.children.map(child => {
                    child.checked = target.checked
                    option = this.multiPrivilageHandel(child.id, target.checked)
                  })
                
                  if (prvilg.checkedAll) {
  
                      toast.success(
                         this.props.translate(
                          'groupsPrivilageAssignedSuccessfully'
                        )
                    )
                  } else {
      
                      toast.success(
                      this.props.translate('groupsPrivilageRemoved')
                    )
                  }
                  this.setState({
                    loader: false
                  })
                }
              })
              this.setState({
                allPrivilagesGroups: allPrivilages
              })
            }
          //let allPrivilages =JSON.parse(JSON.stringify(this.state.allPrivilagesGroups))
        }
         else if (type === 'child') {
          if(this.props.logInUser.userType !== -1 && this.state.selectedItem.id < 8){ // to handle assigning and unassigning default roles (other than owner acces) 

              toast.error(
            this.props.translate('ownerAccessDenied')
            )
          }
          else{
            allPrivilages.map(prvilg => {
              if (data.parentId === 0) {
                if (prvilg.id === data.id) {
                  prvilg.children.map(child => {
                    if (child.id === data.id) {
                      child.checked = target.checked
                    }
                  })
                }
              } else {
                if (prvilg.id === data.parentId) {
                  prvilg.children.map(child => {
                    if (child.id === data.id) {
                      child.checked = target.checked
                    }
                  })
                }
              }
            })
            this.setState({
              allPrivilagesGroups: allPrivilages
            })
            //let all_Privilages =JSON.parse(JSON.stringify(this.state.allPrivilagesGroups))
            let option = 'POST'
            if (data) {
              let obj = {
                roleId: this.state.selectedItem.id,
                privilegeId: data.id
              }
              if (target.checked === false) {
                option = 'DELETE'
              }
  
              fetch(`/api/permissions`, {
                method: `${option}`,
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  ...obj
                })
              })
                .then(response => {
                  response.json().then(res =>{
                   if (res.status === "success") {
                    this.setState({
                      loader: false
                    })
                    if (option === 'POST') {
          
                        toast.success(
                          this.props.translate(
                            'privilageAssignedSuccessfully'
                          )
                      )
                    } else {
     
                        toast.success(
                        this.props.translate('privilageRemoved')
                      )
                    }
                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
             
                      toast.error(
                        this.props.translate(err[1])
                           )
                  }
                  else {
                    throw response
                  }
                })
              })
                .catch(e => {
                  // if(e && e.text){
                  //   e.text().then(err => {
                  //   var array = err&&err.split("-")
                  //   this.props.dispatch(toast.error({
                  //         message: this.props.translate(array[0]),
                  //         autoDismiss: 5
                  //      }))
                  //   })
                  // }
                  // else{
                    toast.error(
                       this.props.translate('somthingWentWrongMessage')
                )
                  // }
                  // console.log('e =', e.text().PromiseResult)
                  // console.log('error.responsee =', e.Response)
                  // this.props.dispatch(
                    // toast.error({
                    //   message: this.props.translate('somthingWentWrongMessage'),
                    //   autoDismiss: 5,
                    //   location: this.props.location
                    // })
                  // )
                  //window.location.pathname = '/roleManagement'
                })
            }
          }
          
        } //close
      }
    )
  }
    else {
    
        toast.error(
      this.props.translate('lackOfPrivileges')
      )
    }
  }
  multiPrivilageHandel = (id, checked) => {
    let option = 'POST'
    if (id) {
      let obj = {
        roleId: this.state.selectedItem.id,
        privilegeId: id
      }
      if (checked === false) {
        option = 'DELETE'
      }
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
            response.json().then(res =>{              
            if (res.status === "success") {
              if (option === 'POST') {
                return option
              } else {
                return option
              }
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              console.log('res =', res?.message)
            }
            // else if(res.statusCode){
            //   var err = res.message.split(':')
            //   err[1] =err[1].replace(')', "")
            //   this.props.dispatch(
            //     toast.error({
            //       message: this.props.translate(err[1]),
            //       autoDismiss: 5,
            //       location: this.props.location
            //     })
            //   )
            // } 
            else {
              throw response
            }
          })
        })
        .catch(e => {
         
            toast.error(
              this.props.translate('somthingWentWrongMessage')
          )
          //window.location.pathname = '/roleManagement'
        })
    }
  }

  handleClick = item => {
    let coll = this.state.open[item]
    this.setState({
      open: { ...this.state.open, [item]: !coll }
    })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
    if (this.props.onCloseModal) {
      this.props.onCloseModal()
    }
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  // }
  render () {
    let listData = ''
    if (
      this.state.allPrivilagesGroups &&
      this.state.allPrivilagesGroups.length && this.state.showDetails
    ) {

      listData = this.state.allPrivilagesGroups?.map(privileges => (
        <PrivilagesTree
          key={privileges.id}
          data={privileges}
          handleChange={this.checkHandelChanges}
          handleClick={this.handleClick}
          {...this.props}
          {...this.state}
        />
      ))
    }
    return (
      <Fragment>
        {this.state.addOption && (
          <AddRoleModal
            translate={this.props.translate}
            formSubmit={this.addSubmit}
            handleChange={this.handleChange}
            form={this.state.form}
            addOption={true}
            modalControle={this.modalControle}
            buttonText={this.props.translate('sharedCreate')}
            isVisableRoleBtn={this.state.isVisableRoleBtn}
          />
        )}
        {this.state.editOption && (
          <AddRoleModal
            translate={this.props.translate}
            formSubmit={this.editSubmit}
            handleChange={this.handleChange}
            form={this.state.form}
            addOption={this.state.addO}
            modalControle={this.modalControle}
            buttonText={this.props.translate('update')}
            isVisableRoleBtn={this.state.isVisableRoleBtn}
          />
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
          translate={this.props.translate}
            onCancel={this.onCancel}
            onOk={this.deleteRole}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.selectedItem.name}
          />
        )}
        {this.state.showDetails && listData && !this.state.loader ? (
          <div className='individual-view'>
            <ul style={{ listStyle: 'none' }}>{listData}</ul>
          </div>
        ) : (
          <>
            {
              <div className='individual-view'>
                <ul style={{ listStyle: 'none' }}>{listData}</ul>
              </div>
            }
          </>
        )}
      </Fragment>
    )
  }
}

export class PrivilagesTree extends Component {
  render () {
    const { classes } = this.props
    if (this.props.data) {
      return (
        <ul style={ListStyle}>
          <li key={this.props.data.id}>
            <Tooltip
              className={this.props.data.id}
              title={this.props.data.description}
            >
              <span>
             {this.props?.data?.key ? 
                this.props.translate(
                  this.props?.data?.key === 'poi'
                    ? this.props.data.key + 'H'
                    : this.props.data.key
                ): " "}
              </span>
            </Tooltip>
            <span
              style={{ verticalAlign: 'middle', display: 'inline-flex' }}
              onClick={() => this.props.handleClick(this.props.data.key)}
            >
              {this.props.open[this.props.data.key] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </span>
            <label style={{ paddingRight: 14, paddingLeft: 25 }}>
              {this.props.translate('selectAll')}
            </label>
            <Checkbox
              checked={this.props.data.checkedAll || false}
              onChange={e =>
                this.props.handleChange(e, 'parent', this.props.data)
              }
              classes={{
                root: classes.root,
                checked: classes.checked
              }}
            />
          </li>
          <Collapse
            in={this.props.open[this.props.data.key]}
            timeout='auto'
            unmountOnExit
          >
            {this.props.data.children.map(item => (
              <ListRow key={item.id} childData={item} {...this.props} />
            ))}
          </Collapse>
        </ul>
      )
    } else {
      return ''
    }
  }
}
export class ListRow extends Component {
  render () {
    const { checked } = this.props.childData
    const { classes } = this.props
    return (
      <li key={this.props.childData.id} style={ListStyleOfChildren}>
        <Checkbox
          checked={checked || false}
          onChange={e =>
            this.props.handleChange(e, 'child', this.props.childData)
          }
          disabled={this.props.childData.key === 'account' ? true : false}
          classes={{
            root: classes.root,
            checked: classes.checked,
            disabled: classes.disabled
          }}
        />
        <Tooltip title={this.props.childData.description}>
          <span>
            {this.props.translate(
              this.props.childData.key === 'position'
                ? 'unit' + this.props.childData.key
                : this.props.childData.key
            )}
          </span>
        </Tooltip>
      </li>
    )
  }
}

const mapState = state => ({
  privileges: state.privileges,
  logInUser: state.logInUsers,
  themecolors: state.themecolors
  // roles: state.roles
})
const mapStateToProps = connect(mapState)
export const RoleModal = mapStateToProps(
  withStyles(styles)((roleModal))
)

// style
const ListStyle = {
  listStyle: 'none',
  borderBottom: '2px dashed #00b974',
  padding: 0,
  paddingBottom: 10,
  paddingTop: 10
}
const ListStyleOfChildren = {
  float: 'left',
  width: '33.33%',
  boxSizing: 'border-box'
}

import React, { Component } from "react";
import { connect } from "react-redux";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import axios from "axios";
import { ItemsList } from "../../Components/MissingItems";
import withRoutes from "../../HOC/HocRoutes";

let source;
class MissingItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      to: "",
      from: "",
      selectedUser: "",
      expenseType:"",
      accType:"",
      page: 1,
      pageSize: 20,
      searchItem:"",
      value: "maintenance",
      invoiceData: "",
      searchInvoiceText: "",
      selectedAccount: "",
      selectSingleInvoice: false,
      itemsData: "",
      leasing: false,
      showPayments: false,
      recievingPartyAccounts: [],
      contractType:1,
      loader1:false,
      loader2:false,
      selectSingleAccount:false,
      itemsData:[],
      checkUser:true
    };
  }
  componentWillMount() {
      this.getMissingItems()
      this.getContractsData();

  }

  componentWillReceiveProps (n) {
    if(this.props.logInUser && this.state.checkUser){
        this.getContractsData();
      }
  }
  

  searchInvoiceText = (e) => {
    source.cancel();
    this.setState(
      {
        searchInvoiceText: e.target.value,
      },
      () => {
        this.getContractsData();
      }
    );
  };
 

  setPagination = () => {
    let nAcc;
    // if (this.state.selectSingleAccount) {
      nAcc = { ...this.state.itemsData }; // for   Missing Items 
    // } else {
    //   nAcc = { ...this.state.invoiceData }; // for contract All accounts
    // }
    let lastPage = nAcc.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nAcc.data;
    nAcc.pageSize = this.state.pageSize;
    nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nAcc;
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangePage = (value) => {
    // if (this.state.selectSingleAccount) {
      this.setState({   
        to: this.state.to,
        from: this.state.from,
        selectedUser: this.state.selectedUser,
        expenseType:this.state.expenseType,
        accType:this.state.accType,
         page: value
         }, () =>
        this.getMissingItems()
      );
    // } else {
    //   this.setState({ page: value }, () => this.getContractsData()); // for contract All accounts
    // }
  };

  handleChangeRowsPerPage = (value) => {
    // if (this.state.selectSingleAccount) {
      this.setState({     
          to: this.state.to,
          from: this.state.from,
          selectedUser: this.state.selectedUser,
          expenseType:this.state.expenseType, 
          accType:this.state.accType,
          pageSize: value ,
          page:1
        }, () =>
          this.getMissingItems()
      );
    // } else {
    //   this.setState({ pageSize: value }, () => this.getContractsData()); // for contract All accounts
    // }
  };


  
  handleChangeTabs = (e) => {
    if (e === "leasing") {
      this.setState(
        {
          value: e,
          leasing: true,
          contractType:2
        },
        () => {
          this.getContractsData();
        }
      );
    } else if (e === "maintenance") {
      this.setState(
        {
          value: e,
          leasing: false,
          contractType:1
        },
        () => {
          this.getContractsData();
        }
      );
    }
  };



    getContractsData = () => {
    let list = {};
    let usersId = {};
   
    let apiUrl = `/api/accounts/list?all=true&contractType=1&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;
    source = axios.CancelToken.source();
            this.setState({
        accounts: [],
        loader1:false,
      }, () => {
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        let dataRes = response?.data?.data?.data || [];
        dataRes &&
          dataRes.map(
            (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
          );
        this.setState({
          accounts: response?.data?.data || {},
          makingAccounts: list,
          makingUsers: usersId,
          loader1: false,
          checkUser: false
        // loaderR:true,

        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
    })
  };



  // getContractsData = async () => {
  //   while (!this.props.logInUser.id) {
  //     await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100 milliseconds before checking again
  //   }
  
  //   let list = {};
  //   let usersId = {};
  
  //   let apiUrl = `/api/accounts/list?all=true&contractType=1&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;
  
  //   source = axios.CancelToken.source();
  //   this.setState({
  //     accounts: [],
  //     loader1: false,
  //   }, async () => {
  //     try {
  //       const response = await axios.get(apiUrl, {
  //         cancelToken: source.token,
  //       });
  
  //       let dataRes = response?.data?.data?.data || [];
  //       dataRes &&
  //         dataRes.map(
  //           (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
  //         );
  //       this.setState({
  //         accounts: response?.data?.data || {},
  //         makingAccounts: list,
  //         makingUsers: usersId,
  //         loader1: false,
  //       });
  //     } catch (e) {
  //       console.log("e ===", e);
  //     }
  //   });
  // };
  invoiceSelection = (item) => {
    this.setState(
      {
        selectedAccount:item,
        selectSingleAccount: true,
      },
      () => {
        this.getMissingItems(item.contractNumber)
      }
    );
  };

  goBack = () => {
    this.setState(
      {
        selectSingleAccount: false,
      },
      () => {
        this.getContractsData();
      }
    );
  };

  getItems = (userId, to, from, expenseType,accType) => {
    this.setState(
      {
        to: to,
        from: from,
        selectedUser: userId,
        expenseType:expenseType,
        accType:accType,
      }, () => {this.getMissingItems()})

  }


  getMissingItems=()=>{
    let dateto = this.state.to || "";
    let datefrom =  this.state.from || "";
    let userId =  this.state.selectedUser || "";
    let name =  this.state.expenseType || "";
    let contract = this.state.accType === "cash" ? 3 : this.state.accType === "maintenance" ? 1 : null;
    let { page, pageSize, searchItem } = this.state;
    let apiUrl;
    apiUrl = `/api/deliverynotes/items/notincontract?page=${page}&limit=${pageSize}&itemCode=${searchItem}`;
    
    // Add the parameters to the apiUrl only if they have values
    if (dateto) apiUrl += `&to=${dateto}`;
    if (datefrom) apiUrl += `&from=${datefrom}`;
    if (userId) apiUrl += `&contractNumber=${userId}`;
    if (name) apiUrl += `&name=${name}`;
    if (contract) apiUrl += `&contractType=${contract}`

    source = axios.CancelToken.source();
    this.setState(
      {
        loader2: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            // let allItems =  [];
            // const { packages, services, items } = response?.data?.data || [];
            // allItems.push(...packages, ...services, ...items);
            this.setState({
              loader2: false,
              itemsData: response?.data?.data|| [],
            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  }
  searchItems = (e) => {
    source.cancel();
    // console.log("e====", e,   this.state.selectedUser,);
    this.setState(
      {
        to: this.state.to,
        from: this.state.from,
        selectedUser: this.state.selectedUser,
        expenseType:this.state.expenseType,
        accType:this.state.accType,
        searchItem:e.target.value,
      }, () => {this.getMissingItems()})

  }

    render() {
    if (this.props.logInUser) {
      const { value } = this.state;

      return (
        <Layout
          {...this.state}
          {...this.props}
          getMissingItems={this.getItems}
          // noSidebar={
          //   this.state.selectSingleAccount
          //     ? ""
          //     : "noSidebar"
          // }
        >
          {/* {!this.state.selectSingleAccount ?
            <>
              <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                <Grid item xs={4} sm={4} style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleChangeTabs("maintenance")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "maintenance" ? "#00C179" : "#ffff",
                      color: value === "maintenance" ? "#ffff" : "#00C179",
                    }}
                  >
                    {this.props.translate("maintenance")}
                  </Button>

                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={() => this.handleChangeTabs("leasing")}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                        value === "leasing" ? "#00C179" : "#ffff",
                      color: value === "leasing" ? "#ffff" : "#00C179",
                    }}
                  >
                    {" "}
                    {this.props.translate("leasing")}
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sm={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("search")}
                      value={this.state.searchInvoiceText}
                      onChange={(e) => this.searchInvoiceText(e)}
                    />
                  </div>
                </Grid>
              </Grid>
             {this.state.loader1 ? <Loader/> : 
                  <Table
                    rows={
                      (this.state.invoiceData && this.state.invoiceData.data) || []
                    }
                    pagination={this.setPagination()}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    // SearchItem={this.SearchItem}
                    onClick={this.invoiceSelection}
                    btnName1={"accounts"}
                    rowsPerPage={15}
                    isEditable={true}
                    themecolors={this.props.themecolors}
                    filterDropdown
                    // setSerialNo
                    isCursoPointer
                    rowDefinition={[
                      {
                        id: "accountName",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("accountName"),
                      },
                      {
                        id: "contractName",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("contractName"),
                      },
                      {
                        id: "contractNumber",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("contractNumber"),
                      },
                      {
                        id: "contractStartDate",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("contractStartDate"),
                      },
                      {
                        id: "contractEndDate",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("contractEndDate"),
                      },
                      {
                        id: "totalBalance",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("totalBalance"),
                      },
                      {
                        id: "totalPaid",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("totalPaid"),
                      },
                      {
                        id: "totalRevenue",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("totalRevenue"),
                      },
                    ]}
                  />
            }
            </>
            :null} */}
            {/* {this.state.selectSingleAccount ? */}
             <ItemsList 
              {...this.state}
              getMissingItems={this.getMissingItems}
              {...this.props}
              searchItems={this.searchItems}
              itemsData={this.state.itemsData.data || [] }
              setPagination={this.setPagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              goBack={this.goBack}
              translate={this.props.translate}
             />
            {/* :""} */}
        </Layout>
      );
    } else {
      fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
               
                  toast.error(
                     this.props.translate(err[1])
                )
                this.props.navigate('/login')
              }
            })
          } else {
            this.props.navigate('/login')
            throw response
          }
        })
        .catch((e) => {
          this.props.navigate('/login')
         
            toast.error(
             "somethingWentWrong"
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(MissingItems));

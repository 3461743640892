import React, { Component, Fragment } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Button from './Button'

let themecolors
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors
  return {
    themecolors: state.themecolors
  }
}

const modalCloseBtn = {
  position: 'absolute',
  right: 15,
  top: 15
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    // padding: theme.spacing(1) * 2,
    // color: theme.palette.text.secondary
  },
  button: {
    // backgroundColor: themecolors['500'],
    // color: themecolors['themeInverse'],
    '&:hover': {
      // backgroundColor: themecolors['600']
    }
  }
})

class SimpleModal extends Component {
  constructor (props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      open: this.props.visable
    }
  }

  handleClose () {
    this.setState({ open: false })
    this.props.modalControle()
  }

  render () {
    const { classes } = this.props
    return (
      <div style={{ display: this.state.open ? 'block' : 'none' }}>
        <Paper
          {...this.props}
          style={{
            boxShadow: this.props.notVisableEnternalAddBtn && 'none',
            padding: this.props.padding ? 0 : 16,
            color: 'rgba(0,0,0,0.54)',
            position: 'relative'
          }}
        >
          {!this.props.notVisableEnternalAddBtn && (
            <h3 style={{ margin: '5px 5px 15px 0' }}>
              {this.props.translate(this.props.title)}
            </h3>
          )}
          {!this.props.notVisableBackbtn && (
            <Button
              style={modalCloseBtn}
              size='small'
              // variant='outlined'
              onClick={this.handleClose}
            >
              {this.props.translate(this.props.btnclosetext) || 'X'}
            </Button>
          )}
          <div>{this.props.content}</div>
          {this.props.isButtonVisable ? (
            <div
              className='clearfix'
              style={{
                borderTop: !this.props.notVisableEnternalAddBtn && '2px outset',
                paddingTop: 16
              }}
            >
              {this.props.attBtnNotshow ? (
                <div>
                  <Button
                    variant='contained'
                    className={classes.button}
                    disabled={!this.props.isVisableBtn}
                    onClick={() => this.props.formSubmit()}
                  >
                    {' '}
                    {this.props.translate(this.props.buttonText)}
                  </Button>
                </div>
              ) : (
                <Fragment>
                  {this.props.title === 'Attributes' ? null : (
                    <div style={{ display: 'inline' }}>
                      {/* {!this.props.isNotShowAttributesBtn && (
                        <Button
                          variant='contained'
                          className={
                            classes.button +
                            (this.props.attributeChangesMessage ? ' alter' : '')
                          }
                          onClick={() => this.props.addAttributes()}
                        >
                          {this.props.translate('sharedAttribute')}
                        </Button>
                      )} */}
                      <span> {this.props.attributeChangesMessage}</span>
                    </div>
                  )}
                  {this.props.showPort && (
                    <Button
                      onClick={() => this.props.showPortList()}
                      style={{ marginLeft: 20 }}
                    >
                      {this.props.translate('deviceModel')}
                    </Button>
                  )}
                  {!this.props.notVisibleExternalAddBtn && (
                    <Button
                      id="formSubmit"
                      variant='contained'
                      style={{ float: 'right' }}
                      className={classes.button}
                      disabled={!this.props.isVisableBtn}
                      onClick={() => this.props.formSubmit()}
                    >
                      {' '}
                      {this.props.translate(this.props.buttonText)}
                    </Button>
                  )}
                </Fragment>
              )}
            </div>
          ) : null}
        </Paper>
      </div>
    )
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired
}

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = (withStyles(styles)(SimpleModal))

export default connect(mapStateToProps)(SimpleModalWrapped)

import React, { Component } from "react";
import TextField from "./TextField";
import Grid from "@mui/material/Grid";
import { CSVLink } from "react-csv";
import axios from "axios";
import Button from "./Button";
import CustomDialog from "./Dialog";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from "./MenuItem";
import { Tooltip } from "@mui/material";
import { GetApp } from "@mui/icons-material";

class ExportFile extends Component {
  state = {
    exportFileName: "",
    exportOption: false,
  };

  handleChange = (e) => {
    this.setState({ exportFileName: e.target.value });
  };
  closeModel = () => {
    this.setState({ exportOption: false });
  };
  downloadExcel = () => {
    if (this.props.downloadType && this.props.downloadType === "accounts") {
      this.setState({ exportOption: true });
    } else {
      let api,
        Accept,
        smp = this.props.sample ? true : false;
      let expensetypeId = this.props.services ? this.props.expensetypeId : null;
      api = expensetypeId
        ? `/api/${this.props.downloadType}/export?sample=${smp}&expensetypeId=${expensetypeId}`
        : this.props.fileName==="vehicles" && 
         (this.props.title === "Download FuelTypes" || this.props.title === "Download Categories" || this.props.title === "Download MakeModel")
        ? (api = `/api/${this.props.downloadType}/export`)
        : this.props.fileName==="vehicles" && this.props.reportType === "carbonfootprint"
        ? (api = `api/vehicles/export/${this.props.downloadType}`)
        : this.props.fileName==="vehicles" && this.props.reportType === "entitlement"
        ? (api = `api/vehicles/${this.props.downloadType}/export`)
        : this.props.fileName==="vehicles" && this.props.reportType === "vehicle-utilization"
        ? (api = `api/vehicles/export/${this.props.downloadType}`)
        : this.props.fileName==="vehicles" && this.props.downloadType === "vehicles/status"
        ? (api = `/api/vehicles/status/export?sample=true`)
        : this.props.fileName === "vehicles" && !this.props.vehicleMaintenance 
        ? (api = `/api/${this.props.downloadType}/export?sample=${smp}&userId=${this.props.logInUser.id}&all=true`)
        : this.props.fileName === "vehicles" && this.props.vehicleMaintenance
        ? (api = `/api/${this.props.downloadType}/export?sample=${smp}&userId=${this.props.logInUser.id}&maintenanceDetails=true&all=true`)
        : this.props.fileName === "category" && this.props.downloadType === "reportV2"
        ? (api = `/api/expensetypes/export/v2`)
        : `/api/${this.props.downloadType}/export?sample=${smp}`;
      // Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (this.props.type === "PDF") {
        Accept = "application/pdf";
      } else {
        Accept =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }

      axios({
        method: "GET",
        url: api,
        headers: {
          Accept: Accept,
        },
        responseType: "blob",
      })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data]));

          if (this.props.type === "PDF") {
            a.setAttribute("download", `${this.props.downloadType}.pdf`);
          } else {
            a.setAttribute("download", `${this.props.downloadType}.xlsx`);
          }

          // a.setAttribute('download', `${this.props.downloadType}.xlsx`);
          document.body.appendChild(a);
          a.href = url;
          a.click();
        })
        .catch((error) => {
          console.log("er =", error);
 
            toast.error( this.props.translate("somethingWentWrong")
          );
        });
      // if(this.props.closedModel()){
      //     this.props.closedModel()
      // }
    }
  };
  render() {
    const { title, downloadType } = this.props;
    return (
      <>
        {this.props.services ? (
          <>
            <Tooltip title={this.props.translate(title)}>
              <MenuItem
                style={{ minWidth: "inherit" }}
                component="label"
                onClick={this.downloadExcel}
              >
                {this.props.translate(title)}
              </MenuItem>
            </Tooltip>
          </>
        ) : (
          <Button
            disabled={!this.props.dataLength}
            color="inherit"
            size="small"
            style={{ marginRight: "20px" }}
            onClick={this.downloadExcel}
          >
            <GetApp />
            {title}
          </Button>
        )}
        {this.state.exportOption && (
          <CustomDialog
            themeColors={this.props.themecolors}
            visable={false}
            maxWidth={"sm"}
            fullWidth
            title={this.props.translate("exportOption")}
            onClose={this.closeModel}
            isButtonVisable={false}
            translate={this.props.translate}
            draggable={true}
            bodyPadding={0}
          >
            <div>
              <Grid container spacing={4}>
                <Grid item md={6} sm={6} xs={12} style={{ marginLeft: 12 }}>
                  <TextField
                    id="name"
                    label={this.props.translate("exportFileName")}
                    type="text"
                    placeholder=""
                    value={this.state.exportFileName}
                    onChange={(e) => this.handleChange(e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <CSVLink
                data={this.props.data}
                filename={
                  this.state.exportFileName
                    ? this.state.exportFileName + ".csv"
                    : this.props.downloadType
                    ? this.props.downloadType + ".csv"
                    : "my-file.csv"
                }
                className="btn btn-primary"
                style={{
                  marginLeft: 20,
                  marginRight: "10%",
                  textDecoration: "underline",
                }}
                target="_blank"
                onClick={this.props && this.props.closedModel}
              >
                {this.props.translate("downloadInCsv")}
              </CSVLink>
            </div>
          </CustomDialog>
        )}
      </>
    );
  }
}
export default ExportFile;

import React, { Component } from "react";
import { checkPrivileges } from "../../Helpers";
// import CustomDialog from './Dialog';
import Stepper from "./Stepper";
import moment from "moment";
import DateRangePicker from "./DateRangePicker";
// import TextField from './TextField';
import { Grid, MenuItem } from "@mui/material";
import Loader from "../../Layout/Loader";
// import Button from './Button';
import VehicleSelector from "./VehicleSelector";
import AccountSelector from "./AccountSelector";
// // // import Menu from '../Menu/CommonMenu'
import AreaSelector from "./AreaSelector";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import TextField from "../common/TextField";

export default class invoiceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilterModal: false,
      from: "",
      to: "",
      selectedDate: "",
      vehicleId: [],
      contractNumber: [],
      areaId: [],
      // loader: false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId: [],
      selectedaccountId: [],
      selectedAreaId: [],
      steps: [
        { id: 1, title: "selectServiceTimeControl" },
        { id: 2, title: "selectAccount" },
        { id: 3, title: "selectVehicles" },
        { id: 4, title: "selectAreaIds" },
      ],
      stepsforSingle: [
        { id: 1, title: "selectServiceTimeControl" },
        { id: 2, title: "selectVehicles" },
        { id: 3, title: "selectAreaIds" },
      ],
      activeStep: 0,
      reportType: 0,
      isSubmitBtn: false,
      invoicesList: [],
      invoiceStatus: 1,
    };
  }

  handleChangeStatus = (event) => {
    // console.log("e===", event.target.value);
    this.setState({
      invoiceStatus: event.target.value,
    });
  };

  onCloseDueMaintenanceModel = () => {
    this.setState({
      openFilterModal: false,
      to: "",
      from: "",
      isSubmitBtn: false,
      selectedDate: "",
      vehicleId: [],
      areaId: [],
      contractNumber: [],
      countChecked: 0,
      anchorEl: null,
      activeStep: 0,
    });
  };

  showDates = (e, date) => {
    // console.log("e, data=====", e, date);

    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate
      .clone()
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();

    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };

    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }

    // Update state with selected date range and formatted dates
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  handleChange = (name, e) => {
    if (e === "area") {
      this.setState({
        areaId: name,
      });
      this.setState({ areaId: name }, () => {
        let filter = "";
        Object.entries(this.state.areaId).map(([key, value]) => {
          if (value) {
            filter += "&areaId=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedAreaId: filter,
        });
      });
    } else if (e === "vehicle") {
      this.setState({
        vehicleId: name,
      });
      this.setState({ vehicleId: name }, () => {
        let filter = "";
        Object.entries(this.state.vehicleId).map(([key, value]) => {
          if (value) {
            filter += "&vehicleId=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedvehicleId: filter,
        });
      });
    } else {
      //   if(this.props.selectSingleInvoice){

      //   }
      //   else
      //  {
      this.setState({
        contractNumber: name,
      });
      this.setState({ contractNumber: name }, () => {
        let filter = "";
        Object.entries(this.state.contractNumber).map(([key, value]) => {
          if (value) {
            filter += "&contractNumber=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedaccountId: filter,
        });
      });
      // }
    }
  };

  goToNextStep = (step, type, index) => {
    if (this.props.selectSingleInvoice) {
      let filter = "";
      filter = "&contractNumber=" + this.props.selectedAccount?.contractNumber;
      this.setState({
        selectedaccountId: filter,
      });

      if (step === 3) {
        this.setState(
          {
            //   openFilterModal:false,
          },
          () => {
            // onClick={() => this.props.selecteItem(this.props.data)}

            if (type === "downloadExcel" || type === "downloadPdf") {
              this.props.downloadInvoices(
                this.state.to,
                this.state.from,
                this.state.selectedaccountId,
                this.state.selectedvehicleId,
                this.state.selectedAreaId,
                type,
                this.state.invoiceStatus
              );
            } else {
              this.props.selecteFilters(
                this.state.to,
                this.state.from,
                this.state.selectedaccountId,
                this.state.selectedvehicleId,
                this.state.selectedAreaId
              );
            }
          }
        );
      } else {
        this.validatedCurrentStep(step, type, index);
      }
    } else {
      if (step === 4) {
        this.setState(
          {
            //   openFilterModal:false,
          },
          () => {
            // onClick={() => this.props.selecteItem(this.props.data)}

            if (type === "downloadExcel" || type === "downloadPdf") {
              this.props.downloadInvoices(
                this.state.to,
                this.state.from,
                this.state.selectedaccountId,
                this.state.selectedvehicleId,
                this.state.selectedAreaId,
                type,
                this.state.invoiceStatus
              );
            } else {
              this.props.selecteFilters(
                this.state.to,
                this.state.from,
                this.state.selectedaccountId,
                this.state.selectedvehicleId,
                this.state.selectedAreaId
              );
            }
          }
        );
      } else {
        this.validatedCurrentStep(step, type, index);
      }
    }
  };
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
    this.setState({ activeStep: currentStep - 1 });
  };

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if (step === 1) {
      if (this.state.to && this.state.from) {
        validated = true;
        errorMsg = null;
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">Please select 'Date ' from above</span>
        );
      }
    } else if (step === 2) {
      if (!this.props.selectSingleInvoice) {
        if (this.state.selectedaccountId.length > 0) {
          validated = true;
          errorMsg = null;
        } else {
          validated = false;
          errorMsg = (
            <span className="text-warning">
              Please select atleast one account
            </span>
          );
        }
      } else {
        validated = true;
      }
    } else if (step === 3) {
      validated = true;
    }

    if (validated === true) {
      if (this.props.selectSingleInvoice) {
        this.setState({ activeStep: index + 1 }, () => {
          if (this.state.activeStep === 2) {
            this.setState({ isSubmitBtn: true });
          }
        });
      } else {
        this.setState({ activeStep: index + 1 }, () => {
          if (this.state.activeStep === 3) {
            this.setState({ isSubmitBtn: true });
          }
        });
      }
    }

    if (this.props.selectSingleInvoice) {
      this.state.stepsforSingle.map((s) => {
        if (s.id === step) {
          steps.push({ ...s, errorMsg });
        } else steps.push(s);
        return null;
      });
      this.setState({ steps });
    } else {
      this.state.steps.map((s) => {
        if (s.id === step) {
          steps.push({ ...s, errorMsg });
        } else steps.push(s);
        return null;
      });
      this.setState({ steps });
    }
  };

  getStepContent = (id) => {
    if (this.props.selectSingleInvoice) {
      switch (id) {
        case 1:
          return (
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12}>
                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid container className="form-group">
              <Grid item xs={12}>
                <VehicleSelector
                  api="vehicle"
                  fill
                  translate={this.props.translate}
                  hideIcons
                  isClearable
                  placeholder={this.props.translate("searchVehicle")}
                  onChange={this.handleChange}
                  value={this.state?.vehicleId || []}
                />
              </Grid>
            </Grid>
          );
        case 3:
          return (
            <Grid container className="form-group">
              <Grid item xs={12}>
                <AreaSelector
                  api="area"
                  fill
                  hideIcons
                  translate={this.props.translate}
                  isClearable
                  placeholder={this.props.translate("searchArea")}
                  onChange={this.handleChange}
                  value={this.state?.areaId || []}
                />
              </Grid>

              {checkPrivileges("invoiceInvalidView") &&
                this.props.value === "maintenance" && (
                  <Grid item xs={12}>
                    <TextField
                      id="invoiceStatus"
                      label={this.props.translate("invoiceStatus")}
                      type="text"
                      value={this.state.invoiceStatus || ""}
                      onChange={this.handleChangeStatus}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      select
                    >
                      <MenuItem value={1}>
                        {this.props.translate("valid")}
                      </MenuItem>
                      <MenuItem value={2}>
                        {this.props.translate("inValid")}
                      </MenuItem>
                      <MenuItem value={3}>
                        {" "}
                        {this.props.translate("both")}{" "}
                      </MenuItem>
                    </TextField>
                  </Grid>
                )}
            </Grid>
          );
        default:
          return null;
      }
    } else {
      switch (id) {
        case 1:
          return (
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12}>
                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid container className="form-group">
              <Grid item xs={12}>
                {/* {this.props.loader ?   <Loader component="filter" /> :  */}
                <AccountSelector
                  selecteItem={this.props.selecteItem}
                  api="accounts"
                  type="invoices"
                  fill
                  translate={this.props.translate}
                  contractType={this.props.contractType}
                  // type="invoices"
                  hideIcons
                  invoices
                  // SearchItem={this.props.SearchItem}
                  // getContractsData={this.props.getContractsData}
                  // accounts={this.props.accounts || []}
                  isClearable
                  placeholder={this.props.translate("searchAccount")}
                  onChange={this.handleChange}
                  value={this.state?.contractNumber || []}
                />
                {/* } */}
              </Grid>
            </Grid>
          );
        case 3:
          return (
            <Grid container className="form-group">
              <Grid item xs={12}>
                <VehicleSelector
                  api="vehicle"
                  fill
                  translate={this.props.translate}
                  hideIcons
                  isClearable
                  placeholder={this.props.translate("searchVehicle")}
                  onChange={this.handleChange}
                  value={this.state?.vehicleId || []}
                />
              </Grid>
            </Grid>
          );
        case 4:
          return (
            <Grid container className="form-group">
              <Grid item xs={12}>
                <AreaSelector
                  api="area"
                  fill
                  translate={this.props.translate}
                  hideIcons
                  isClearable
                  placeholder={this.props.translate("searchArea")}
                  onChange={this.handleChange}
                  value={this.state?.areaId || []}
                />
              </Grid>

              {checkPrivileges("invoiceInvalidView") &&
                this.props.value === "maintenance" && (
                  <Grid item xs={12}>
                    <TextField
                      id="invoiceStatus"
                      label={this.props.translate("invoiceStatus")}
                      type="text"
                      value={this.state.invoiceStatus || ""}
                      onChange={this.handleChangeStatus}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      select
                    >
                      <MenuItem value={1}>
                        {this.props.translate("valid")}
                      </MenuItem>
                      <MenuItem value={2}>
                        {this.props.translate("inValid")}
                      </MenuItem>
                      <MenuItem value={3}>
                        {" "}
                        {this.props.translate("both")}{" "}
                      </MenuItem>
                    </TextField>
                  </Grid>
                )}
            </Grid>
          );
        default:
          return null;
      }
    }
  };

  validatedForm = (step, type, index) => {
    let validated = false;
    let errorMsg = null;

    if (this.state.to && this.state.from) {
      validated = true;
      errorMsg = null;
      this.setState({ isSubmitBtn: true });
    } else {
      validated = false;
      errorMsg = (
        <span className="text-warning">
          Please select 'Date and Maintenance Status' from above
        </span>
      );
    }

    if (this.props.selectSingleInvoice) {
      validated = true;
    } else {
      if (this.state.selectedaccountId.length > 0) {
        validated = true;
        errorMsg = null;
        this.setState({ isSubmitBtn: true });
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">
            Please select atleast one account
          </span>
        );
      }
    }
    if (validated === true) {
      this.setState({}, () => this.handleNext(step, type, index));
    } else {
      validated = false;
      errorMsg = (
        <span className="text-warning">
          Please select 'Date and Maintenance Status' from above
        </span>
      );
    }
  };

  handleNext = (step, type, index) => {
    // if (validated === true) {

    if (this.props.selectSingleInvoice) {
      let filter = "";
      filter = "&contractNumber=" + this.props.selectedAccount?.contractNumber;

      this.setState({ selectedaccountId: filter }, () => {
        this.props.selecteFilters(
          this.state.to,
          this.state.from,
          this.state.selectedaccountId,
          this.state.selectedvehicleId,
          this.state.selectedAreaId
        );
        // }
      });
    } else {
      this.setState({}, () => {
        this.props.selecteFilters(
          this.state.to,
          this.state.from,
          this.state.selectedaccountId,
          this.state.selectedvehicleId,
          this.state.selectedAreaId
        );
        // }
      });
    }
  };
  render() {
    // console.log("===inccc",this.props.value)
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            {this.props.value === "cashCustomer" ? (
              <>
                <Grid container className="form-group sidebar-bg-layer">
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <DateRangePicker
                      fill
                      error={this.props.timeError || false}
                      selectedDate={this.state.selectedDate}
                      label={
                        this.props.translate("reportFrom") +
                        " " +
                        this.props.translate("reportTo")
                      }
                      onEvent={this.showDates}
                    />
                  </Grid>
                </Grid>
                {this.props.selectSingleInvoice ? (
                  ""
                ) : (
                  <Grid container className="form-group sidebar-bg-layer">
                    <Grid item xs={12} style={{ padding: 10 }}>
                      {/* {this.props.loader ?   <Loader component="filter" /> :  */}
                      <AccountSelector
                        selecteItem={this.props.selecteItem}
                        api="accounts"
                        fill
                        invoices
                        translate={this.props.translate}
                        contractType={this.props.contractType}
                        // type="invoices"
                        hideIcons
                        // getContractsData={this.props.getContractsData}
                        // accounts={this.props.accounts || []}
                        isClearable
                        placeholder={this.props.translate("searchAccount")}
                        onChange={this.handleChange}
                        value={this.state?.contractNumber || []}
                      />
                      {/* } */}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} style={{ padding: 10 }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginRight: 10,
                      }}
                    >
                      <Button
                        onClick={(e) => this.validatedForm("", "", "")}
                        // onClick={(e) => this.props.selecteFilters( this.state.to, this.state.from,  this.state.selectedaccountId,"","")}
                        style={{
                          margin: 5,
                          height: "30px",
                          width: "60px",
                        }}
                        //  disabled={this.state.isSubmitBtn ? "" : "disabled"}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Grid>
              </>
            ) : (
              <Stepper
                {...this.props}
                downloadInvoices={this.props.downloadInvoices}
                invoicesData={this.props.invoicesData}
                translate={this.props.translate}
                activeStep={this.state.activeStep}
                reportType={this.state.reportType}
                getStepContent={this.getStepContent}
                steps={
                  this.props.selectSingleInvoice
                    ? this.state.stepsforSingle
                    : this.state.steps
                }
                handleNext={this.goToNextStep}
                handleBack={this.goToBackStep}
                isSubmitBtn={this.state.isSubmitBtn}
                selecteItem={this.props.selecteItem}
                selectSingleInvoice={this.props.selectSingleInvoice}
                // downloadInvoice={e=>this.props.downloadInvoice(this.state,e)}
                data="invoices"
              />
            )}
          </>
        }
      </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}

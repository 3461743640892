/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Fragment } from "react";
import Select from "react-select";
import AsyncSelect from "react-select";

const customStyles = {
  container: (provided) => ({
    ...provided,
    marginTop: 8,
    marginBottom: 4,
    zIndex: 9,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 28,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    marginTop: 4,
    marginBottom: 4,
  }),
};
class SingleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    let index = this.props.index || 0;
    let name = this.props.selectName || "single";
    if (name === "parentId") {
      this.setState(
        {
          [name]: value.id,
        },
        () => {
          if (this.props.handleChange) {
            this.props.handleChange(name, value, index);
          }
        }
      );
    } else if (name === "invoiceNumber") {
      if (value) {
        this.setState(
          {
            [name]: value.invoiceNumber,
          },
          () => {
            if (this.props.handleChange) {
              this.props.handleChange(name, value, index);
            }
          }
        );
      } else {
        if (this.props.handleChange) {
          this.props.handleChange(name, value, index);
        }
      }
    } else {
      if (value) {
        if (value.id && this.props.canAssign) {
          this.setState(
            {
              [name]: value,
            },
            () => {
              if (this.props.handleChange) {
                this.props.handleChange(name, value, index);
              }
              if (this.props.returnSelected) {
                this.props.returnSelected(value, "POST");
              }
            }
          );
        } else {
          if (name === "timezone" || name === "decoder.timezone") {
            this.setState(
              {
                [name]: value.id,
              },
              () => {
                if (this.props.handleChange) {
                  this.props.handleChange(name, value, index);
                }
              }
            );
          }
        }
      } else {
        this.props.handleChange(name, "", index);
      }
    }
  }
  UNSAFE_componentWillReceiveProps(n) {
    this.setState({ [n.selectName]: n.value });
  }

  render() {
    const { classes, theme, array } = this.props;
    const suggessions = array.map((v) => {
      // console.log("v==",v);
      return {
        ...v,
        id: v.key || v.id,
        label:
          this.props.selectName === "invoiceNumber"
            ? v.invoiceNumber
            : v.name || v.key || v.invoiceNumber || v.label || v.accountName || v.modelName || v.id,
      };
    });

    if (
      this.state[this.props.selectName] === undefined &&
      this.props.value !== undefined
    ) {
      this.setState({ [this.props.selectName]: this.props.value });
    }

    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
      defaultValue,
    } = this.props;
    return (
      <Fragment>
        {!this.props.async && (
          <Select
            {...this.props}
            styles={customStyles}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            placeholder={null}
            defaultValue={defaultValue}
            isRtl={isRtl}
            value={this.state[this.props.selectName] || ""}
            //components={components}
            // components={{ Control }}
            isSearchable={isSearchable}
            options={suggessions}
            onChange={(e) => this.handleChange(e)}
            placeholer={this.props.label || "Select"}
          />
        )}
        {this.props.async && (
          <AsyncSelect
            {...this.props}
            styles={customStyles}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            // placeholder={null}
            defaultValue={defaultValue}
            isRtl={isRtl}
            isMulti={this.props.isMulti ? "isMulti" : ""}
            value={this.state[this.props.selectName] || ""}
            //components={components}
            // components={{ Control }}
            isSearchable={isSearchable}
            onChange={this.handleChange}
            options={suggessions}
            placeholder={this.props.label || "Select"}
          />
        )}
      </Fragment>
    );
  }
}

export default SingleSelect;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddAttributes from './addAttributes'
import Table from './../../Components/common/Table'
import Button from '../../Components/common/Button'
import CustomDialog from './../../Components/common/Dialog'
import {
  groupAttributesTypes,
  simpleAttributesTypes,
  volumeUnit,
  speedUnit,
  distanceUnit
} from './AttributesArrays'
import { timeZonesTypes } from './timezone'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themecolors
  }
}

class Attributes extends Component {
  constructor () {
    super()
    this.state = {
      allAttriubtes: '',
      addOption: false,
      editOption: false,
      formData: '',
      selectedType: '',
      selectedItem: '',
      htmlTag: '',
      selectTagData: '',
      isVisableUserBtn: false,
      onDeleteConfirmation: false,
      form: {
        value: '',
        name: ''
      }
    }
    this.ad_submit = this.ad_submit.bind(this)
    this.ud_submit = this.ud_submit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateAttriubtes = this.updateAttriubtes.bind(this)
    this.deleteAttriubtes = this.deleteAttriubtes.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }

  componentWillMount () {
    // if (this.props.componentType === 'user') {
    //     let newAttributes = {}
    //     let value = Object.keys(this.props.itemAttributes).reduce((array, key) => {
    //         return [...array, { 'name': key, 'value': this.props.itemAttributes[key] }]

    //     }, [])

    //     if (value.length) {
    //         value.map(k => {
    //             if (k.name !== 'serviceTitle' && k.name !== 'footerText' && k.name !== 'privacyPolicyLink' &&
    //                 k.name !== 'termsLink' && k !== "notificationSettings") {
    //                 let name = k.name, value = k.value
    //                 newAttributes = { ...newAttributes, [name]: value }
    //             }
    //         })
    //         this.setState({
    //             formData: userAttributesTypes,
    //             allAttriubtes: newAttributes,
    //         })
    //     } else {
    //         this.setState({
    //             formData: userAttributesTypes,
    //             allAttriubtes: newAttributes,
    //         })
    //     }
    // }
    if (this.props.componentType === 'group') {
      this.setState({
        formData: groupAttributesTypes,
        allAttriubtes: JSON.parse(JSON.stringify(this.props.itemAttributes))
      })
    } else if (this.props.componentType === 'account') {
      this.setState({
        formData: simpleAttributesTypes,
        allAttriubtes: JSON.parse(JSON.stringify(this.props.itemAttributes))
      })
    }
    // else if (this.props.componentType === 'device') {
    //     let newAttributes = {}
    //     let value = Object.keys(this.props.itemAttributes).reduce((array, key) => {
    //         return [...array, { 'name': key, 'value': this.props.itemAttributes[key] }]

    //     }, [])

    //     if (value.length) {
    //         value.map(k => {
    //             if (k.name !== 'parking.minIdleTime' && k.name !== 'parking.maxIdleSpeed' && k.name !== 'port') {
    //                 let name = k.name, value = k.value
    //                 newAttributes = { ...newAttributes, [name]: value }
    //             }
    //         })
    //         this.setState({
    //             formData: deviceAttributesTypes,
    //             allAttriubtes: newAttributes,
    //         })
    //     } else {
    //         this.setState({
    //             formData: deviceAttributesTypes,
    //             allAttriubtes: newAttributes,
    //         })
    //     }
    // }
  }
  onCloseModal () {
    this.setState({
      addOption: false,
      editOption: false
    })
  }
  handleChange (key, event) {
    if (key === 'timezone' || key === 'decoder.timezone') {
      if (event.id === 'None') {
        if (this.state.form['value']) {
          delete this.state.form['value']
        }
        this.setState(
          {
            form: {
              ...this.state.form
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              value: event.id
            }
          },
          () => this.checkRequiredFields()
        )
      }
    } else {
      if (event) {
        const { target } = event
        if (target) {
          let value = target.type === 'checkbox' ? target.checked : target.value

          if (key === 'name') {
            if (value === '' || value === undefined) {
              this.setState({
                form: {
                  ...this.state.form,
                  [key]: value
                },
                isVisableUserBtn: false
              })
            } else {
              if (this.state.formData[0].key === 'simpleAttributes') {
                this.setState(
                  {
                    form: {
                      ...this.state.form,
                      [key]: value,
                      value: ''
                    }
                  },
                  () => this.checkRequiredFields()
                )
              } else {
                let result = this.state.formData.filter(obj => {
                  return obj.key === value
                })
                if (result[0].htmlTag === 'select') {
                  if (result[0].dataType === 'timezone') {
                    // for timezone and others selected object:
                    this.setState(
                      {
                        selectTagData: timeZonesTypes
                      },
                      () => this.checkRequiredFields()
                    )
                  } else if (result[0].key === 'volumeUnit') {
                    // for volume
                    this.setState(
                      {
                        selectTagData: volumeUnit
                      },
                      () => this.checkRequiredFields()
                    )
                  } else if (result[0].key === 'speedUnit') {
                    // for speed
                    this.setState(
                      {
                        selectTagData: speedUnit
                      },
                      () => this.checkRequiredFields()
                    )
                  } else if (result[0].key === 'distanceUnit') {
                    // for timezone and others selected object:
                    this.setState(
                      {
                        selectTagData: distanceUnit
                      },
                      () => this.checkRequiredFields()
                    )
                  }
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        [key]: value,
                        value: ''
                      }
                    },
                    () => this.checkRequiredFields()
                  )

                  this.setState(
                    {
                      selectedType: result[0].valueType,
                      htmlTag: result[0].htmlTag
                    },
                    () => this.checkRequiredFields()
                  )
                }
                this.setState(
                  {
                    form: {
                      ...this.state.form,
                      [key]: value,
                      value: ''
                    }
                  },
                  () => this.checkRequiredFields()
                )
                this.setState(
                  {
                    selectedType: result[0].valueType,
                    htmlTag: result[0].htmlTag
                  },
                  () => this.checkRequiredFields()
                )
              }
            }
          } else {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [key]: value
                }
              },
              () => this.checkRequiredFields()
            )
          }
        } else {
          this.setState(
            {
              form: {
                ...this.state.form,
                [key]: event.hex
              }
            },
            () => this.checkRequiredFields()
          )
        }
      }
    }
  }
  checkRequiredFields () {
    let { name } = this.state.form
    if (name) {
      this.setState({
        isVisableUserBtn: true
      })
    } else {
      this.setState({
        isVisableUserBtn: false
      })
    }
  }

  ad_submit () {
    this.props.changeAttribute(this.state.form.name, this.state.form.value)
    this.setState({
      allAttriubtes: {
        ...this.state.allAttriubtes,
        [this.state.form.name]: this.state.form.value
      },
      addOption: false,
      editOption: false,
      selectedType: '',
      htmlTag: '',
      form: {
        name: '',
        value: ''
      }
    })
  }
  ud_submit () {
    // for attruibates update
    let allAttributes = {},
      otherForParen = {}
    let othersAllAttbs = JSON.parse(JSON.stringify(this.props.itemAttributes))
    const OtherAtt = Object.keys(othersAllAttbs)
    let allAtt = this.state.allAttriubtes
    const DrAtt = Object.keys(this.state.allAttriubtes)
    DrAtt.map(dr => {
      if (dr !== this.state.selectedItem.name) {
        allAttributes[dr] = allAtt[dr]
      }
      return null
    })
    OtherAtt.map(otherdr => {
      if (otherdr !== this.state.selectedItem.name) {
        otherForParen[otherdr] = othersAllAttbs[otherdr]
      }
      return null
    })
    otherForParen = {
      ...otherForParen,
      [this.state.form.name]: this.state.form.value
    }
    this.setState(
      {
        allAttriubtes: {
          ...allAttributes,
          [this.state.form.name]: this.state.form.value
        },
        editOption: false,
        addOption: false,
        selectedType: '',
        htmlTag: '',
        selectedItem: ''
      },
      () =>
        this.props.changeAttribute(
          this.state.form.name,
          this.state.form.value,
          'updateAtt',
          otherForParen
        )
    )
    this.setState({
      form: {
        name: '',
        value: ''
      }
    })
  }
  addAttriubtes () {
    this.setState({
      selectedType: '',
      isVisableUserBtn: false,
      htmlTag: '',
      addOption: true,
      form: {
        name: '',
        value: ''
      }
    })
  }
  updateAttriubtes (item) {
    const itemType = this.state.formData.filter(
      elmt => elmt.key === item[0].name
    )
    if (itemType[0].dataType === 'timezone') {
      // for timezone and others selected object:
      this.setState({
        selectTagData: timeZonesTypes
      })
    }
    if (itemType[0].key === 'volumeUnit') {
      // for volume
      this.setState({
        selectTagData: volumeUnit
      })
    }
    if (itemType[0].key === 'speedUnit') {
      // for speed
      this.setState({
        selectTagData: speedUnit
      })
    }
    if (itemType[0].key === 'distanceUnit') {
      // for timezone and others selected object:
      this.setState({
        selectTagData: distanceUnit
      })
    }

    this.setState({
      selectedType: itemType[0].valueType,
      selectedItem: item[0],
      htmlTag: itemType[0].htmlTag,
      editOption: true,
      form: { ...item[0] }
    })
  }
  deleteAttriubtes () {
    let allAttributes = {},
      otherForParen = {}
    let othersAllAttbs = JSON.parse(JSON.stringify(this.props.itemAttributes))
    let allAtt = this.state.allAttriubtes
    const OtherAtt = Object.keys(othersAllAttbs)
    const DrAtt = Object.keys(this.state.allAttriubtes)
    DrAtt.map(dr => {
      if (dr !== this.state.selectedItem.name) {
        allAttributes[dr] = allAtt[dr]
      }
      return null
    })
    OtherAtt.map(otherdr => {
      if (otherdr !== this.state.selectedItem.name) {
        otherForParen[otherdr] = othersAllAttbs[otherdr]
      }
      return null
    })
    this.setState(
      {
        allAttriubtes: { ...allAttributes },
        selectedType: '',
        htmlTag: '',
        onDeleteConfirmation: false
      },
      () =>
        this.props.changeAttribute(
          this.state.form.name,
          this.state.form.value,
          'deleteAtt',
          otherForParen
        )
    )
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  attriubtesOnDelete = item => {
    this.setState({
      selectedItem: item[0],
      onDeleteConfirmation: true
    })
  }
  render () {
    const { allAttriubtes, formData } = this.state
    let value = Object.keys(allAttriubtes).reduce((array, key) => {
      if (formData[0].key === 'simpleAttributes') {
        return [
          ...array,
          {
            name: key,
            value: allAttriubtes[key],
            view: key,
            valueType: formData[0].valueType
          }
        ]
      } else {
        let selectedRow = formData.filter(r => r.key === key)
        if (selectedRow[0]) {
          return [
            ...array,
            {
              name: key,
              value: allAttriubtes[key],
              view: this.props.translate(selectedRow[0].name),
              valueType: selectedRow[0].valueType
            }
          ]
        }
      }
      return null
    }, [])

    return (
      <div>
        <CustomDialog
          themecolors={this.props.themecolors}
          visable={true}
          onClose={this.props.onCloseModal}
          isButtonVisable={true}
          draggable={true}
          CancelText={this.props.translate('sharedCancel')}
          fullWidth
          headerActions={
            this.props.hasAccessOfCreate && (
              <Button
                size='small'
                variant='contained'
                onClick={() => this.addAttriubtes()}
              >
                {' '}
                {this.props.translate('sharedAdd')}
              </Button>
            )
          }
          bodyPadding={0}
        >
          <Table
            insideDialog={true}
            title={this.props.translate('sharedAttributes')}
            rows={value}
            onEdit={this.updateAttriubtes}
            onDelete={this.attriubtesOnDelete}
            rowsPerPage={10}
            isEditable={true}
            searchable={true}
            translate={this.props.translate}
            themecolors={this.props.themecolors}
            hasAccessOfUpdate={this.props.hasAccessOfUpdate}
            hasAccessOfDelete={this.props.hasAccessOfDelete}
            rowDefinition={[
              {
                id: 'view',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedName')
              },
              {
                id: 'value',
                type: 'any',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedValue')
              }
            ]}
          />
        </CustomDialog>
        {this.state.addOption && (
          <AddAttributes
            themecolors={this.props.themecolors}
            isVisableBtn={this.state.isVisableUserBtn}
            handleSubmit={this.ad_submit}
            handleChange={this.handleChange}
            selectedType={this.state.selectedType}
            htmlTag={this.state.htmlTag}
            formData={this.state.formData}
            form={this.state.form}
            selectTagData={this.state.selectTagData}
            onCloseModal={this.onCloseModal}
          />
        )}
        {this.state.editOption && (
          <AddAttributes
            themecolors={this.props.themecolors}
            isVisableBtn={this.state.isVisableUserBtn}
            onChange={this.onChange}
            handleSubmit={this.ud_submit}
            handleChange={this.handleChange}
            selectedType={this.state.selectedType}
            htmlTag={this.state.htmlTag}
            formData={this.state.formData}
            form={this.state.form}
            selectTagData={this.state.selectTagData}
            onCloseModal={this.onCloseModal}
          />
        )}

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteAttriubtes}
            title={'areYouWantToDelete'}
            children={this.state.selectedItem.name}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps)((Attributes))

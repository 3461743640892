import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "redux";

import { withStyles } from "@mui/styles";
import Table from "../../../common/tableWithBackEndPagination";
import { PaginationConfig } from "../../../../Helpers";
import Loader from "../../../../Layout/Loader";
import { Grid } from "@mui/material";
import TextField from "../../../common/TextField";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
let source;
class Invoices extends Component {
  constructor() {
    super();
    this.state = {
      invoicesReceived: false,
      selectedInvoiceList: [],
      pagination: { ...PaginationConfig, hasNext: "", pageSize: 10 },
      total: "",
      lastPage: "",
      noRecords: false,
      rowsPerPage: 10,
      pageSize: 10,
      page: 1,
      itemSearch: "",
    };
  }
  componentWillMount() {
    this.getMoreInvoices();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  getMoreInvoices = () => {
    const { itemSearch } = this.state.pagination;
    let api = `api/payments/get?cn=${this.props.selectedAccount.contractNumber}&page=${this.state.page}&limit=${this.state.pageSize}&refNumber=${itemSearch}`;
    // let api = `/api/users/get?accountId=${this.props.selectItemId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`
    source = axios.CancelToken.source();
    axios
      .get(api, {
        cancelToken: source.token,
      })
      .then((res) => {
        let response = res.data && res.data.data;
        this.setState({
          selectedInvoiceList: response,
          invoicesReceived: true,
        });
      })
      .catch((e) => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      });
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ pageSize: rowsPerPage, page: 1 }, () => {
      this.getMoreInvoices();
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      this.getMoreInvoices();
    });
  };

  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue,
        },
      },
      () => this.getMoreInvoices()
    );
  };

  setPagination = (user) => {
    if (this.state.selectedInvoiceList.data.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  render() {
    return (
      <Fragment>
        <Grid container className="breadcrumb-row">
          <Grid item xs={12} sm={3}>
            <h3 style={{ margin: 0 }}>{this.props.translate("Payment")}</h3>
          </Grid>
          <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
            <div style={{ marginRight: "20px", width: "25%" }}>
              <TextField
                fullWidth
                label={this.props.translate("search")}
                onChange={(e) => this.SearchItem(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        {this.state && this.state.invoicesReceived && !this.state.noRecords ? (
          <>
            <Table
              rows={this.state.selectedInvoiceList.data || []}
              translate={this.props.translate}
              pagination={this.setPagination(this.state.selectedInvoiceList)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              rowsPerPage={this.state?.rowsPerPage}
              ServerSetting={this.props.ServerSetting}
              isEditable={true}
              themecolors={this.props.themecolors}
              rowDefinition={rowDefinitionR(this.props.translate)}
            />
          </>
        ) : !this.state.invoicesReceived && !this.state.noRecords ? (
          <div style={{ textAlign: "center" }}>
            <Loader defaultStyle />
          </div>
        ) : null}
        {this.state.noRecords && (
          <div style={{ textAlign: "center" }}>
            <h4> {this.props.translate("noInvoiceFound")}</h4>
          </div>
        )}
      </Fragment>
    );
  }
}

const enhance = compose(withStyles(styles), connect());

export default enhance(Invoices);

const rowDefinitionR = (translate) => {
  return [
    {
      id: "refNum",
      numeric: false,
      disablePadding: false,
      label: translate("refNum"),
    },
    {
      id: "paymentMode",
      numeric: false,
      disablePadding: false,
      label: translate("paymentMode"),
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: translate("amount"),
    },
    {
      id: "paymentDate",
      numeric: false,
      disablePadding: false,
      label: translate("paymentDate"),
    },

    {
      id: "adjustmentType",
      numeric: false,
      disablePadding: false,
      label: translate("adjustmentType"),
    },

    // {
    //   id: "receipt",
    //   numeric: false,
    //   disablePadding: false,
    //   label: translate("receipt"),
    //   hide: false,
    // },
  ];
};

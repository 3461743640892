import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getRoles } from "./../../Actions/Roles";
import MaterialIcon from "material-icons-react";
import { checkPrivileges } from "../../Helpers";
import { ReactComponent as RoleManageIcon } from "./../../assets/nav/role-manage.svg";
import { ReactComponent as ContractMgmtIcon } from "./../../assets/nav/contractMgmt.svg";
import { Scrollbar } from "react-scrollbars-custom";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

let source;

class Contract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callPending: true,
      page: 1,
      pageSize: 20,
      itemSearch: this.props.searchAccount ? this.props.searchAccount : "",
      accounts: {},
    };
  }
  componentWillMount() {
    this.fetchAccount();
  }

  fetchAccount = () => {
    let { page, pageSize, itemSearch } = this.state;
    let apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&all=true&page=${page}&limit=${pageSize}&search=${this.state.itemSearch}`;
    source = axios.CancelToken.source();
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        this.setState({
          accounts: response.data.data,
        });
      })
      .catch((e) => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      });
  };
  componentWillReceiveProps(n) {
    this.setState({ itemSearch: n.searchAccount }, () => {
      this.fetchAccount();
    });
  }

  render() {
    let data = "";
    if (
      this.state.accounts &&
      this.state.accounts.data &&
      this.state.accounts.data.length
    ) {
      data = this.state.accounts.data.map((account) => (
        <ListRow
          key={account.id}
          data={account}
          {...this.props}
          selecteItem={this.props.selecteItem}
          selectedItemId={this.props.selectedItemId}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
        />
      ));
    }

    return (
      <div>
        {data && (
          <div
            //  className='fms-sidebar'ch A
            //  style={{height:"400px"}}
            className="individual-view"
          >
            {/* <Scrollbar> */}
            <ul className="list-view with-padding-right">{data}</ul>
            {/* </Scrollbar> */}
          </div>
        )}
      </div>
    );
  }
}

export class ListRows extends Component {
  render() {
    if (this.props.children.length) {
      return (
        <ul className="list-view">
          {this.props.children.map((data) => (
            <ListRow key={data.id} data={data} {...this.props} />
          ))}
        </ul>
      );
    } else {
      return "";
    }
  }
}
export class ListRow extends Component {
  render() {
    return (
      <li>
        <Link
          className={
            "clearfix list-row " +
            (parseInt(this.props.match.params.id) === this.props.data.id
              ? "active"
              : "")
          }
          to={`/${this.props.pathName}/` + this.props.data.id}
        >
          <label
            className="checkbox"
            onClick={() => this.props.selecteItem(this.props.data)}
          >
            <span
              className="unit-name"
              style={{
                minWidth: 220,
                maxWidth: 220,
                marginLeft: 0,
                lineHeight: "24px",
                verticalAlign: "middle",
              }}
            >
              <ContractMgmtIcon
                style={{
                  verticalAlign: "middle",
                  width: 16,
                  height: 16,
                  fill: "currentColor",
                  display: "inline-block",
                  marginRight: 8,
                  marginBottom: 6,
                }}
              />
              {/* </span> */}
              {this.props.data?.contractNumber || ""}
              {"  "}
              {" - "}
              {"  "}
              {this.props.data.name}
            </span>
          </label>
        </Link>
      </li>
    );
  }
}

const mapState = (state) => {
  if (state.roles) {
    var role = state.roles.filter(
      (item) => item.name.toLowerCase().indexOf(state.searchRole) !== -1
    );
  }
  return {
    logInUser: state.logInUsers,
  };
};

const mapStateToProps = connect(mapState);
export const ContractShortList = mapStateToProps(Contract);

export const timeZonesTypes = [
  {
    "key": "None",
    "label": "None"
  },
  {
    "key": "Etc/GMT+12",
    "label": "(-12:00) Etc/GMT+12",
    "offset": -720
  },
  {
    "key": "Etc/GMT+11",
    "label": "(-11:00) Etc/GMT+11",
    "offset": -660
  },
  {
    "key": "Pacific/Niue",
    "label": "(-11:00) Pacific/Niue",
    "offset": -660
  },
  {
    "key": "Pacific/Pago_Pago",
    "label": "(-11:00) Pacific/Pago_Pago",
    "offset": -660
  },
  {
    "key": "America/Adak",
    "label": "(-10:00) America/Adak",
    "offset": -600
  },
  {
    "key": "Etc/GMT+10",
    "label": "(-10:00) Etc/GMT+10",
    "offset": -600
  },
  {
    "key": "HST",
    "label": "(-10:00) HST",
    "offset": -600
  },
  {
    "key": "Pacific/Honolulu",
    "label": "(-10:00) Pacific/Honolulu",
    "offset": -600
  },
  {
    "key": "Pacific/Rarotonga",
    "label": "(-10:00) Pacific/Rarotonga",
    "offset": -600
  },
  {
    "key": "Pacific/Tahiti",
    "label": "(-10:00) Pacific/Tahiti",
    "offset": -600
  },
  {
    "key": "Pacific/Marquesas",
    "label": "(-09:30) Pacific/Marquesas",
    "offset": -570
  },
  {
    "key": "America/Anchorage",
    "label": "(-09:00) America/Anchorage",
    "offset": -540
  },
  {
    "key": "America/Juneau",
    "label": "(-09:00) America/Juneau",
    "offset": -540
  },
  {
    "key": "America/Metlakatla",
    "label": "(-09:00) America/Metlakatla",
    "offset": -540
  },
  {
    "key": "America/Nome",
    "label": "(-09:00) America/Nome",
    "offset": -540
  },
  {
    "key": "America/Sitka",
    "label": "(-09:00) America/Sitka",
    "offset": -540
  },
  {
    "key": "America/Yakutat",
    "label": "(-09:00) America/Yakutat",
    "offset": -540
  },
  {
    "key": "Etc/GMT+9",
    "label": "(-09:00) Etc/GMT+9",
    "offset": -540
  },
  {
    "key": "Pacific/Gambier",
    "label": "(-09:00) Pacific/Gambier",
    "offset": -540
  },
  {
    "key": "America/Dawson",
    "label": "(-08:00) America/Dawson",
    "offset": -480
  },
  {
    "key": "America/Los_Angeles",
    "label": "(-08:00) America/Los_Angeles",
    "offset": -480
  },
  {
    "key": "America/Tijuana",
    "label": "(-08:00) America/Tijuana",
    "offset": -480
  },
  {
    "key": "America/Vancouver",
    "label": "(-08:00) America/Vancouver",
    "offset": -480
  },
  {
    "key": "America/Whitehorse",
    "label": "(-08:00) America/Whitehorse",
    "offset": -480
  },
  {
    "key": "Etc/GMT+8",
    "label": "(-08:00) Etc/GMT+8",
    "offset": -480
  },
  {
    "key": "Pacific/Pitcairn",
    "label": "(-08:00) Pacific/Pitcairn",
    "offset": -480
  },
  {
    "key": "PST8PDT",
    "label": "(-08:00) PST8PDT",
    "offset": -480
  },
  {
    "key": "America/Boise",
    "label": "(-07:00) America/Boise",
    "offset": -420
  },
  {
    "key": "America/Cambridge_Bay",
    "label": "(-07:00) America/Cambridge_Bay",
    "offset": -420
  },
  {
    "key": "America/Chihuahua",
    "label": "(-07:00) America/Chihuahua",
    "offset": -420
  },
  {
    "key": "America/Creston",
    "label": "(-07:00) America/Creston",
    "offset": -420
  },
  {
    "key": "America/Dawson_Creek",
    "label": "(-07:00) America/Dawson_Creek",
    "offset": -420
  },
  {
    "key": "America/Denver",
    "label": "(-07:00) America/Denver",
    "offset": -420
  },
  {
    "key": "America/Edmonton",
    "label": "(-07:00) America/Edmonton",
    "offset": -420
  },
  {
    "key": "America/Fort_Nelson",
    "label": "(-07:00) America/Fort_Nelson",
    "offset": -420
  },
  {
    "key": "America/Hermosillo",
    "label": "(-07:00) America/Hermosillo",
    "offset": -420
  },
  {
    "key": "America/Inuvik",
    "label": "(-07:00) America/Inuvik",
    "offset": -420
  },
  {
    "key": "America/Mazatlan",
    "label": "(-07:00) America/Mazatlan",
    "offset": -420
  },
  {
    "key": "America/Ojinaga",
    "label": "(-07:00) America/Ojinaga",
    "offset": -420
  },
  {
    "key": "America/Phoenix",
    "label": "(-07:00) America/Phoenix",
    "offset": -420
  },
  {
    "key": "America/Yellowknife",
    "label": "(-07:00) America/Yellowknife",
    "offset": -420
  },
  {
    "key": "Etc/GMT+7",
    "label": "(-07:00) Etc/GMT+7",
    "offset": -420
  },
  {
    "key": "MST",
    "label": "(-07:00) MST",
    "offset": -420
  },
  {
    "key": "MST7MDT",
    "label": "(-07:00) MST7MDT",
    "offset": -420
  },
  {
    "key": "America/Bahia_Banderas",
    "label": "(-06:00) America/Bahia_Banderas",
    "offset": -360
  },
  {
    "key": "America/Belize",
    "label": "(-06:00) America/Belize",
    "offset": -360
  },
  {
    "key": "America/Chicago",
    "label": "(-06:00) America/Chicago",
    "offset": -360
  },
  {
    "key": "America/Costa_Rica",
    "label": "(-06:00) America/Costa_Rica",
    "offset": -360
  },
  {
    "key": "America/El_Salvador",
    "label": "(-06:00) America/El_Salvador",
    "offset": -360
  },
  {
    "key": "America/Guatemala",
    "label": "(-06:00) America/Guatemala",
    "offset": -360
  },
  {
    "key": "America/Indiana/Knox",
    "label": "(-06:00) America/Indiana/Knox",
    "offset": -360
  },
  {
    "key": "America/Indiana/Tell_City",
    "label": "(-06:00) America/Indiana/Tell_City",
    "offset": -360
  },
  {
    "key": "America/Managua",
    "label": "(-06:00) America/Managua",
    "offset": -360
  },
  {
    "key": "America/Matamoros",
    "label": "(-06:00) America/Matamoros",
    "offset": -360
  },
  {
    "key": "America/Menominee",
    "label": "(-06:00) America/Menominee",
    "offset": -360
  },
  {
    "key": "America/Merida",
    "label": "(-06:00) America/Merida",
    "offset": -360
  },
  {
    "key": "America/Mexico_City",
    "label": "(-06:00) America/Mexico_City",
    "offset": -360
  },
  {
    "key": "America/Monterrey",
    "label": "(-06:00) America/Monterrey",
    "offset": -360
  },
  {
    "key": "America/North_Dakota/Beulah",
    "label": "(-06:00) America/North_Dakota/Beulah",
    "offset": -360
  },
  {
    "key": "America/North_Dakota/Center",
    "label": "(-06:00) America/North_Dakota/Center",
    "offset": -360
  },
  {
    "key": "America/North_Dakota/New_Salem",
    "label": "(-06:00) America/North_Dakota/New_Salem",
    "offset": -360
  },
  {
    "key": "America/Rainy_River",
    "label": "(-06:00) America/Rainy_River",
    "offset": -360
  },
  {
    "key": "America/Rankin_Inlet",
    "label": "(-06:00) America/Rankin_Inlet",
    "offset": -360
  },
  {
    "key": "America/Regina",
    "label": "(-06:00) America/Regina",
    "offset": -360
  },
  {
    "key": "America/Resolute",
    "label": "(-06:00) America/Resolute",
    "offset": -360
  },
  {
    "key": "America/Swift_Current",
    "label": "(-06:00) America/Swift_Current",
    "offset": -360
  },
  {
    "key": "America/Tegucigalpa",
    "label": "(-06:00) America/Tegucigalpa",
    "offset": -360
  },
  {
    "key": "America/Winnipeg",
    "label": "(-06:00) America/Winnipeg",
    "offset": -360
  },
  {
    "key": "CST6CDT",
    "label": "(-06:00) CST6CDT",
    "offset": -360
  },
  {
    "key": "Etc/GMT+6",
    "label": "(-06:00) Etc/GMT+6",
    "offset": -360
  },
  {
    "key": "Pacific/Galapagos",
    "label": "(-06:00) Pacific/Galapagos",
    "offset": -360
  },
  {
    "key": "America/Grand_Turk",
    "label": "(-05:00) America/Grand_Turk",
    "offset": -300
  },
  {
    "key": "America/Atikokan",
    "label": "(-05:00) America/Atikokan",
    "offset": -300
  },
  {
    "key": "America/Bogota",
    "label": "(-05:00) America/Bogota",
    "offset": -300
  },
  {
    "key": "America/Cancun",
    "label": "(-05:00) America/Cancun",
    "offset": -300
  },
  {
    "key": "America/Detroit",
    "label": "(-05:00) America/Detroit",
    "offset": -300
  },
  {
    "key": "America/Eirunepe",
    "label": "(-05:00) America/Eirunepe",
    "offset": -300
  },
  {
    "key": "America/Guayaquil",
    "label": "(-05:00) America/Guayaquil",
    "offset": -300
  },
  {
    "key": "America/Havana",
    "label": "(-05:00) America/Havana",
    "offset": -300
  },
  {
    "key": "America/Indiana/Indianapolis",
    "label": "(-05:00) America/Indiana/Indianapolis",
    "offset": -300
  },
  {
    "key": "America/Indiana/Marengo",
    "label": "(-05:00) America/Indiana/Marengo",
    "offset": -300
  },
  {
    "key": "America/Indiana/Petersburg",
    "label": "(-05:00) America/Indiana/Petersburg",
    "offset": -300
  },
  {
    "key": "America/Indiana/Vevay",
    "label": "(-05:00) America/Indiana/Vevay",
    "offset": -300
  },
  {
    "key": "America/Indiana/Vincennes",
    "label": "(-05:00) America/Indiana/Vincennes",
    "offset": -300
  },
  {
    "key": "America/Indiana/Winamac",
    "label": "(-05:00) America/Indiana/Winamac",
    "offset": -300
  },
  {
    "key": "America/Iqaluit",
    "label": "(-05:00) America/Iqaluit",
    "offset": -300
  },
  {
    "key": "America/Jamaica",
    "label": "(-05:00) America/Jamaica",
    "offset": -300
  },
  {
    "key": "America/Kentucky/Louisville",
    "label": "(-05:00) America/Kentucky/Louisville",
    "offset": -300
  },
  {
    "key": "America/Kentucky/Monticello",
    "label": "(-05:00) America/Kentucky/Monticello",
    "offset": -300
  },
  {
    "key": "America/Lima",
    "label": "(-05:00) America/Lima",
    "offset": -300
  },
  {
    "key": "America/Nassau",
    "label": "(-05:00) America/Nassau",
    "offset": -300
  },
  {
    "key": "America/New_York",
    "label": "(-05:00) America/New_York",
    "offset": -300
  },
  {
    "key": "America/Nipigon",
    "label": "(-05:00) America/Nipigon",
    "offset": -300
  },
  {
    "key": "America/Panama",
    "label": "(-05:00) America/Panama",
    "offset": -300
  },
  {
    "key": "America/Pangnirtung",
    "label": "(-05:00) America/Pangnirtung",
    "offset": -300
  },
  {
    "key": "America/Port-au-Prince",
    "label": "(-05:00) America/Port-au-Prince",
    "offset": -300
  },
  {
    "key": "America/Rio_Branco",
    "label": "(-05:00) America/Rio_Branco",
    "offset": -300
  },
  {
    "key": "America/Thunder_Bay",
    "label": "(-05:00) America/Thunder_Bay",
    "offset": -300
  },
  {
    "key": "America/Toronto",
    "label": "(-05:00) America/Toronto",
    "offset": -300
  },
  {
    "key": "EST",
    "label": "(-05:00) EST",
    "offset": -300
  },
  {
    "key": "EST5EDT",
    "label": "(-05:00) EST5EDT",
    "offset": -300
  },
  {
    "key": "Etc/GMT+5",
    "label": "(-05:00) Etc/GMT+5",
    "offset": -300
  },
  {
    "key": "Pacific/Easter",
    "label": "(-05:00) Pacific/Easter",
    "offset": -300
  },
  {
    "key": "America/Barbados",
    "label": "(-04:00) America/Barbados",
    "offset": -240
  },
  {
    "key": "America/Blanc-Sablon",
    "label": "(-04:00) America/Blanc-Sablon",
    "offset": -240
  },
  {
    "key": "America/Boa_Vista",
    "label": "(-04:00) America/Boa_Vista",
    "offset": -240
  },
  {
    "key": "America/Caracas",
    "label": "(-04:00) America/Caracas",
    "offset": -240
  },
  {
    "key": "America/Curacao",
    "label": "(-04:00) America/Curacao",
    "offset": -240
  },
  {
    "key": "America/Glace_Bay",
    "label": "(-04:00) America/Glace_Bay",
    "offset": -240
  },
  {
    "key": "America/Goose_Bay",
    "label": "(-04:00) America/Goose_Bay",
    "offset": -240
  },
  {
    "key": "America/Guyana",
    "label": "(-04:00) America/Guyana",
    "offset": -240
  },
  {
    "key": "America/Halifax",
    "label": "(-04:00) America/Halifax",
    "offset": -240
  },
  {
    "key": "America/La_Paz",
    "label": "(-04:00) America/La_Paz",
    "offset": -240
  },
  {
    "key": "America/Manaus",
    "label": "(-04:00) America/Manaus",
    "offset": -240
  },
  {
    "key": "America/Martinique",
    "label": "(-04:00) America/Martinique",
    "offset": -240
  },
  {
    "key": "America/Moncton",
    "label": "(-04:00) America/Moncton",
    "offset": -240
  },
  {
    "key": "America/Port_of_Spain",
    "label": "(-04:00) America/Port_of_Spain",
    "offset": -240
  },
  {
    "key": "America/Porto_Velho",
    "label": "(-04:00) America/Porto_Velho",
    "offset": -240
  },
  {
    "key": "America/Puerto_Rico",
    "label": "(-04:00) America/Puerto_Rico",
    "offset": -240
  },
  {
    "key": "America/Santo_Domingo",
    "label": "(-04:00) America/Santo_Domingo",
    "offset": -240
  },
  {
    "key": "America/Thule",
    "label": "(-04:00) America/Thule",
    "offset": -240
  },
  {
    "key": "Atlantic/Bermuda",
    "label": "(-04:00) Atlantic/Bermuda",
    "offset": -240
  },
  {
    "key": "Etc/GMT+4",
    "label": "(-04:00) Etc/GMT+4",
    "offset": -240
  },
  {
    "key": "America/St_Johns",
    "label": "(-03:30) America/St_Johns",
    "offset": -210
  },
  {
    "key": "America/Araguaina",
    "label": "(-03:00) America/Araguaina",
    "offset": -180
  },
  {
    "key": "America/Argentina/Buenos_Aires",
    "label": "(-03:00) America/Argentina/Buenos_Aires",
    "offset": -180
  },
  {
    "key": "America/Argentina/Catamarca",
    "label": "(-03:00) America/Argentina/Catamarca",
    "offset": -180
  },
  {
    "key": "America/Argentina/Cordoba",
    "label": "(-03:00) America/Argentina/Cordoba",
    "offset": -180
  },
  {
    "key": "America/Argentina/Jujuy",
    "label": "(-03:00) America/Argentina/Jujuy",
    "offset": -180
  },
  {
    "key": "America/Argentina/La_Rioja",
    "label": "(-03:00) America/Argentina/La_Rioja",
    "offset": -180
  },
  {
    "key": "America/Argentina/Mendoza",
    "label": "(-03:00) America/Argentina/Mendoza",
    "offset": -180
  },
  {
    "key": "America/Argentina/Rio_Gallegos",
    "label": "(-03:00) America/Argentina/Rio_Gallegos",
    "offset": -180
  },
  {
    "key": "America/Argentina/Salta",
    "label": "(-03:00) America/Argentina/Salta",
    "offset": -180
  },
  {
    "key": "America/Argentina/San_Juan",
    "label": "(-03:00) America/Argentina/San_Juan",
    "offset": -180
  },
  {
    "key": "America/Argentina/San_Luis",
    "label": "(-03:00) America/Argentina/San_Luis",
    "offset": -180
  },
  {
    "key": "America/Argentina/Tucuman",
    "label": "(-03:00) America/Argentina/Tucuman",
    "offset": -180
  },
  {
    "key": "America/Argentina/Ushuaia",
    "label": "(-03:00) America/Argentina/Ushuaia",
    "offset": -180
  },
  {
    "key": "America/Bahia",
    "label": "(-03:00) America/Bahia",
    "offset": -180
  },
  {
    "key": "America/Belem",
    "label": "(-03:00) America/Belem",
    "offset": -180
  },
  {
    "key": "America/Cayenne",
    "label": "(-03:00) America/Cayenne",
    "offset": -180
  },
  {
    "key": "America/Fortaleza",
    "label": "(-03:00) America/Fortaleza",
    "offset": -180
  },
  {
    "key": "America/Godthab",
    "label": "(-03:00) America/Godthab",
    "offset": -180
  },
  {
    "key": "America/Maceio",
    "label": "(-03:00) America/Maceio",
    "offset": -180
  },
  {
    "key": "America/Miquelon",
    "label": "(-03:00) America/Miquelon",
    "offset": -180
  },
  {
    "key": "America/Montevideo",
    "label": "(-03:00) America/Montevideo",
    "offset": -180
  },
  {
    "key": "America/Paramaribo",
    "label": "(-03:00) America/Paramaribo",
    "offset": -180
  },
  {
    "key": "America/Recife",
    "label": "(-03:00) America/Recife",
    "offset": -180
  },
  {
    "key": "America/Santarem",
    "label": "(-03:00) America/Santarem",
    "offset": -180
  },
  {
    "key": "Antarctica/Rothera",
    "label": "(-03:00) Antarctica/Rothera",
    "offset": -180
  },
  {
    "key": "Atlantic/Stanley",
    "label": "(-03:00) Atlantic/Stanley",
    "offset": -180
  },
  {
    "key": "Etc/GMT+3",
    "label": "(-03:00) Etc/GMT+3",
    "offset": -180
  },
  {
    "key": "America/Asuncion",
    "label": "(-03:00) America/Asuncion",
    "offset": -180
  },
  {
    "key": "America/Campo_Grande",
    "label": "(-03:00) America/Campo_Grande",
    "offset": -180
  },
  {
    "key": "America/Cuiaba",
    "label": "(-03:00) America/Cuiaba",
    "offset": -180
  },
  {
    "key": "America/Santiago",
    "label": "(-03:00) America/Santiago",
    "offset": -180
  },
  {
    "key": "Antarctica/Palmer",
    "label": "(-03:00) Antarctica/Palmer",
    "offset": -180
  },
  {
    "key": "America/Noronha",
    "label": "(-02:00) America/Noronha",
    "offset": -120
  },
  {
    "key": "Atlantic/South_Georgia",
    "label": "(-02:00) Atlantic/South_Georgia",
    "offset": -120
  },
  {
    "key": "Etc/GMT+2",
    "label": "(-02:00) Etc/GMT+2",
    "offset": -120
  },
  {
    "key": "America/Sao_Paulo",
    "label": "(-02:00) America/Sao_Paulo",
    "offset": -120
  },
  {
    "key": "America/Scoresbysund",
    "label": "(-01:00) America/Scoresbysund",
    "offset": -60
  },
  {
    "key": "Atlantic/Azores",
    "label": "(-01:00) Atlantic/Azores",
    "offset": -60
  },
  {
    "key": "Atlantic/Cape_Verde",
    "label": "(-01:00) Atlantic/Cape_Verde",
    "offset": -60
  },
  {
    "key": "Etc/GMT+1",
    "label": "(-01:00) Etc/GMT+1",
    "offset": -60
  },
  {
    "key": "Africa/Abidjan",
    "label": "(+00:00) Africa/Abidjan",
    "offset": 0
  },
  {
    "key": "Africa/Accra",
    "label": "(+00:00) Africa/Accra",
    "offset": 0
  },
  {
    "key": "Africa/Bissau",
    "label": "(+00:00) Africa/Bissau",
    "offset": 0
  },
  {
    "key": "Africa/Monrovia",
    "label": "(+00:00) Africa/Monrovia",
    "offset": 0
  },
  {
    "key": "America/Danmarkshavn",
    "label": "(+00:00) America/Danmarkshavn",
    "offset": 0
  },
  {
    "key": "Antarctica/Troll",
    "label": "(+00:00) Antarctica/Troll",
    "offset": 0
  },
  {
    "key": "Atlantic/Canary",
    "label": "(+00:00) Atlantic/Canary",
    "offset": 0
  },
  {
    "key": "Atlantic/Faroe",
    "label": "(+00:00) Atlantic/Faroe",
    "offset": 0
  },
  {
    "key": "Atlantic/Madeira",
    "label": "(+00:00) Atlantic/Madeira",
    "offset": 0
  },
  {
    "key": "Atlantic/Reykjavik",
    "label": "(+00:00) Atlantic/Reykjavik",
    "offset": 0
  },
  {
    "key": "GMT",
    "label": "(+00:00) GMT",
    "offset": 0
  },
  {
    "key": "Etc/GMT",
    "label": "(+00:00) Etc/GMT",
    "offset": 0
  },
  {
    "key": "Etc/UCT",
    "label": "(+00:00) Etc/UCT",
    "offset": 0
  },
  {
    "key": "Etc/UTC",
    "label": "(+00:00) Etc/UTC",
    "offset": 0
  },
  {
    "key": "Europe/Dublin",
    "label": "(+00:00) Europe/Dublin",
    "offset": 0
  },
  {
    "key": "Europe/Lisbon",
    "label": "(+00:00) Europe/Lisbon",
    "offset": 0
  },
  {
    "key": "Europe/London",
    "label": "(+00:00) Europe/London",
    "offset": 0
  },
  {
    "key": "WET",
    "label": "(+00:00) WET",
    "offset": 0
  },
  {
    "key": "Africa/Casablanca",
    "label": "(+01:00) Africa/Casablanca",
    "offset": 60
  },
  {
    "key": "Africa/El_Aaiun",
    "label": "(+01:00) Africa/El_Aaiun",
    "offset": 60
  },
  {
    "key": "Africa/Algiers",
    "label": "(+01:00) Africa/Algiers",
    "offset": 60
  },
  {
    "key": "Africa/Ceuta",
    "label": "(+01:00) Africa/Ceuta",
    "offset": 60
  },
  {
    "key": "Africa/Lagos",
    "label": "(+01:00) Africa/Lagos",
    "offset": 60
  },
  {
    "key": "Africa/Ndjamena",
    "label": "(+01:00) Africa/Ndjamena",
    "offset": 60
  },
  {
    "key": "Africa/Tunis",
    "label": "(+01:00) Africa/Tunis",
    "offset": 60
  },
  {
    "key": "CET",
    "label": "(+01:00) CET",
    "offset": 60
  },
  {
    "key": "Etc/GMT-1",
    "label": "(+01:00) Etc/GMT-1",
    "offset": 60
  },
  {
    "key": "Europe/Amsterdam",
    "label": "(+01:00) Europe/Amsterdam",
    "offset": 60
  },
  {
    "key": "Europe/Andorra",
    "label": "(+01:00) Europe/Andorra",
    "offset": 60
  },
  {
    "key": "Europe/Belgrade",
    "label": "(+01:00) Europe/Belgrade",
    "offset": 60
  },
  {
    "key": "Europe/Berlin",
    "label": "(+01:00) Europe/Berlin",
    "offset": 60
  },
  {
    "key": "Europe/Brussels",
    "label": "(+01:00) Europe/Brussels",
    "offset": 60
  },
  {
    "key": "Europe/Budapest",
    "label": "(+01:00) Europe/Budapest",
    "offset": 60
  },
  {
    "key": "Europe/Copenhagen",
    "label": "(+01:00) Europe/Copenhagen",
    "offset": 60
  },
  {
    "key": "Europe/Gibraltar",
    "label": "(+01:00) Europe/Gibraltar",
    "offset": 60
  },
  {
    "key": "Europe/Luxembourg",
    "label": "(+01:00) Europe/Luxembourg",
    "offset": 60
  },
  {
    "key": "Europe/Madrid",
    "label": "(+01:00) Europe/Madrid",
    "offset": 60
  },
  {
    "key": "Europe/Malta",
    "label": "(+01:00) Europe/Malta",
    "offset": 60
  },
  {
    "key": "Europe/Monaco",
    "label": "(+01:00) Europe/Monaco",
    "offset": 60
  },
  {
    "key": "Europe/Oslo",
    "label": "(+01:00) Europe/Oslo",
    "offset": 60
  },
  {
    "key": "Europe/Paris",
    "label": "(+01:00) Europe/Paris",
    "offset": 60
  },
  {
    "key": "Europe/Prague",
    "label": "(+01:00) Europe/Prague",
    "offset": 60
  },
  {
    "key": "Europe/Rome",
    "label": "(+01:00) Europe/Rome",
    "offset": 60
  },
  {
    "key": "Europe/Stockholm",
    "label": "(+01:00) Europe/Stockholm",
    "offset": 60
  },
  {
    "key": "Europe/Tirane",
    "label": "(+01:00) Europe/Tirane",
    "offset": 60
  },
  {
    "key": "Europe/Vienna",
    "label": "(+01:00) Europe/Vienna",
    "offset": 60
  },
  {
    "key": "Europe/Warsaw",
    "label": "(+01:00) Europe/Warsaw",
    "offset": 60
  },
  {
    "key": "Europe/Zurich",
    "label": "(+01:00) Europe/Zurich",
    "offset": 60
  },
  {
    "key": "MET",
    "label": "(+01:00) MET",
    "offset": 60
  },
  {
    "key": "Africa/Windhoek",
    "label": "(+02:00) Africa/Windhoek",
    "offset": 120
  },
  {
    "key": "Africa/Cairo",
    "label": "(+02:00) Africa/Cairo",
    "offset": 120
  },
  {
    "key": "Africa/Johannesburg",
    "label": "(+02:00) Africa/Johannesburg",
    "offset": 120
  },
  {
    "key": "Africa/Maputo",
    "label": "(+02:00) Africa/Maputo",
    "offset": 120
  },
  {
    "key": "Africa/Tripoli",
    "label": "(+02:00) Africa/Tripoli",
    "offset": 120
  },
  {
    "key": "Asia/Amman",
    "label": "(+02:00) Asia/Amman",
    "offset": 120
  },
  {
    "key": "Asia/Beirut",
    "label": "(+02:00) Asia/Beirut",
    "offset": 120
  },
  {
    "key": "Asia/Damascus",
    "label": "(+02:00) Asia/Damascus",
    "offset": 120
  },
  {
    "key": "Asia/Gaza",
    "label": "(+02:00) Asia/Gaza",
    "offset": 120
  },
  {
    "key": "Asia/Hebron",
    "label": "(+02:00) Asia/Hebron",
    "offset": 120
  },
  {
    "key": "Asia/Jerusalem",
    "label": "(+02:00) Asia/Jerusalem",
    "offset": 120
  },
  {
    "key": "Asia/Nicosia",
    "label": "(+02:00) Asia/Nicosia",
    "offset": 120
  },
  {
    "key": "EET",
    "label": "(+02:00) EET",
    "offset": 120
  },
  {
    "key": "Etc/GMT-2",
    "label": "(+02:00) Etc/GMT-2",
    "offset": 120
  },
  {
    "key": "Europe/Athens",
    "label": "(+02:00) Europe/Athens",
    "offset": 120
  },
  {
    "key": "Europe/Bucharest",
    "label": "(+02:00) Europe/Bucharest",
    "offset": 120
  },
  {
    "key": "Europe/Chisinau",
    "label": "(+02:00) Europe/Chisinau",
    "offset": 120
  },
  {
    "key": "Europe/Helsinki",
    "label": "(+02:00) Europe/Helsinki",
    "offset": 120
  },
  {
    "key": "Europe/Kaliningrad",
    "label": "(+02:00) Europe/Kaliningrad",
    "offset": 120
  },
  {
    "key": "Europe/Kiev",
    "label": "(+02:00) Europe/Kiev",
    "offset": 120
  },
  {
    "key": "Europe/Riga",
    "label": "(+02:00) Europe/Riga",
    "offset": 120
  },
  {
    "key": "Europe/Sofia",
    "label": "(+02:00) Europe/Sofia",
    "offset": 120
  },
  {
    "key": "Europe/Tallinn",
    "label": "(+02:00) Europe/Tallinn",
    "offset": 120
  },
  {
    "key": "Europe/Uzhgorod",
    "label": "(+02:00) Europe/Uzhgorod",
    "offset": 120
  },
  {
    "key": "Europe/Vilnius",
    "label": "(+02:00) Europe/Vilnius",
    "offset": 120
  },
  {
    "key": "Europe/Zaporozhye",
    "label": "(+02:00) Europe/Zaporozhye",
    "offset": 120
  },
  {
    "key": "Africa/Khartoum",
    "label": "(+02:00) Africa/Khartoum",
    "offset": 120
  },
  {
    "key": "Asia/Famagusta",
    "label": "(+02:00) Asia/Famagusta",
    "offset": 120
  },
  {
    "key": "Africa/Nairobi",
    "label": "(+03:00) Africa/Nairobi",
    "offset": 180
  },
  {
    "key": "Antarctica/Syowa",
    "label": "(+03:00) Antarctica/Syowa",
    "offset": 180
  },
  {
    "key": "Asia/Baghdad",
    "label": "(+03:00) Asia/Baghdad",
    "offset": 180
  },
  {
    "key": "Asia/Qatar",
    "label": "(+03:00) Asia/Qatar",
    "offset": 180
  },
  {
    "key": "Asia/Riyadh",
    "label": "(+03:00) Asia/Riyadh",
    "offset": 180
  },
  {
    "key": "Etc/GMT-3",
    "label": "(+03:00) Etc/GMT-3",
    "offset": 180
  },
  {
    "key": "Europe/Istanbul",
    "label": "(+03:00) Europe/Istanbul",
    "offset": 180
  },
  {
    "key": "Europe/Kirov",
    "label": "(+03:00) Europe/Kirov",
    "offset": 180
  },
  {
    "key": "Europe/Minsk",
    "label": "(+03:00) Europe/Minsk",
    "offset": 180
  },
  {
    "key": "Europe/Moscow",
    "label": "(+03:00) Europe/Moscow",
    "offset": 180
  },
  {
    "key": "Europe/Simferopol",
    "label": "(+03:00) Europe/Simferopol",
    "offset": 180
  },
  {
    "key": "Asia/Tehran",
    "label": "(+03:30) Asia/Tehran",
    "offset": 210
  },
  {
    "key": "Europe/Volgograd",
    "label": "(+04:00) Europe/Volgograd",
    "offset": 240
  },
  {
    "key": "Asia/Baku",
    "label": "(+04:00) Asia/Baku",
    "offset": 240
  },
  {
    "key": "Asia/Dubai",
    "label": "(+04:00) Asia/Dubai",
    "offset": 240
  },
  {
    "key": "Asia/Tbilisi",
    "label": "(+04:00) Asia/Tbilisi",
    "offset": 240
  },
  {
    "key": "Asia/Yerevan",
    "label": "(+04:00) Asia/Yerevan",
    "offset": 240
  },
  {
    "key": "Etc/GMT-4",
    "label": "(+04:00) Etc/GMT-4",
    "offset": 240
  },
  {
    "key": "Europe/Astrakhan",
    "label": "(+04:00) Europe/Astrakhan",
    "offset": 240
  },
  {
    "key": "Europe/Samara",
    "label": "(+04:00) Europe/Samara",
    "offset": 240
  },
  {
    "key": "Europe/Saratov",
    "label": "(+04:00) Europe/Saratov",
    "offset": 240
  },
  {
    "key": "Europe/Ulyanovsk",
    "label": "(+04:00) Europe/Ulyanovsk",
    "offset": 240
  },
  {
    "key": "Indian/Mahe",
    "label": "(+04:00) Indian/Mahe",
    "offset": 240
  },
  {
    "key": "Indian/Mauritius",
    "label": "(+04:00) Indian/Mauritius",
    "offset": 240
  },
  {
    "key": "Indian/Reunion",
    "label": "(+04:00) Indian/Reunion",
    "offset": 240
  },
  {
    "key": "Asia/Kabul",
    "label": "(+04:30) Asia/Kabul",
    "offset": 270
  },
  {
    "key": "Antarctica/Mawson",
    "label": "(+05:00) Antarctica/Mawson",
    "offset": 300
  },
  {
    "key": "Asia/Aqtau",
    "label": "(+05:00) Asia/Aqtau",
    "offset": 300
  },
  {
    "key": "Asia/Aqtobe",
    "label": "(+05:00) Asia/Aqtobe",
    "offset": 300
  },
  {
    "key": "Asia/Ashgabat",
    "label": "(+05:00) Asia/Ashgabat",
    "offset": 300
  },
  {
    "key": "Asia/Atyrau",
    "label": "(+05:00) Asia/Atyrau",
    "offset": 300
  },
  {
    "key": "Asia/Dushanbe",
    "label": "(+05:00) Asia/Dushanbe",
    "offset": 300
  },
  {
    "key": "Asia/Karachi",
    "label": "(+05:00) Asia/Karachi",
    "offset": 300
  },
  {
    "key": "Asia/Oral",
    "label": "(+05:00) Asia/Oral",
    "offset": 300
  },
  {
    "key": "Asia/Samarkand",
    "label": "(+05:00) Asia/Samarkand",
    "offset": 300
  },
  {
    "key": "Asia/Tashkent",
    "label": "(+05:00) Asia/Tashkent",
    "offset": 300
  },
  {
    "key": "Asia/Yekaterinburg",
    "label": "(+05:00) Asia/Yekaterinburg",
    "offset": 300
  },
  {
    "key": "Etc/GMT-5",
    "label": "(+05:00) Etc/GMT-5",
    "offset": 300
  },
  {
    "key": "Indian/Kerguelen",
    "label": "(+05:00) Indian/Kerguelen",
    "offset": 300
  },
  {
    "key": "Indian/Maldives",
    "label": "(+05:00) Indian/Maldives",
    "offset": 300
  },
  {
    "key": "Asia/Qyzylorda",
    "label": "(+05:00) Asia/Qyzylorda",
    "offset": 300
  },
  {
    "key": "Asia/Colombo",
    "label": "(+05:30) Asia/Colombo",
    "offset": 330
  },
  {
    "key": "Asia/Kolkata",
    "label": "(+05:30) Asia/Kolkata",
    "offset": 330
  },
  {
    "key": "Asia/Kathmandu",
    "label": "(+05:45) Asia/Kathmandu",
    "offset": 345
  },
  {
    "key": "Antarctica/Vostok",
    "label": "(+06:00) Antarctica/Vostok",
    "offset": 360
  },
  {
    "key": "Asia/Almaty",
    "label": "(+06:00) Asia/Almaty",
    "offset": 360
  },
  {
    "key": "Asia/Bishkek",
    "label": "(+06:00) Asia/Bishkek",
    "offset": 360
  },
  {
    "key": "Asia/Dhaka",
    "label": "(+06:00) Asia/Dhaka",
    "offset": 360
  },
  {
    "key": "Asia/Omsk",
    "label": "(+06:00) Asia/Omsk",
    "offset": 360
  },
  {
    "key": "Asia/Thimphu",
    "label": "(+06:00) Asia/Thimphu",
    "offset": 360
  },
  {
    "key": "Asia/Urumqi",
    "label": "(+06:00) Asia/Urumqi",
    "offset": 360
  },
  {
    "key": "Etc/GMT-6",
    "label": "(+06:00) Etc/GMT-6",
    "offset": 360
  },
  {
    "key": "Indian/Chagos",
    "label": "(+06:00) Indian/Chagos",
    "offset": 360
  },
  {
    "key": "Asia/Yangon",
    "label": "(+06:30) Asia/Yangon",
    "offset": 390
  },
  {
    "key": "Indian/Cocos",
    "label": "(+06:30) Indian/Cocos",
    "offset": 390
  },
  {
    "key": "Antarctica/Davis",
    "label": "(+07:00) Antarctica/Davis",
    "offset": 420
  },
  {
    "key": "Asia/Bangkok",
    "label": "(+07:00) Asia/Bangkok",
    "offset": 420
  },
  {
    "key": "Asia/Barnaul",
    "label": "(+07:00) Asia/Barnaul",
    "offset": 420
  },
  {
    "key": "Asia/Ho_Chi_Minh",
    "label": "(+07:00) Asia/Ho_Chi_Minh",
    "offset": 420
  },
  {
    "key": "Asia/Hovd",
    "label": "(+07:00) Asia/Hovd",
    "offset": 420
  },
  {
    "key": "Asia/Jakarta",
    "label": "(+07:00) Asia/Jakarta",
    "offset": 420
  },
  {
    "key": "Asia/Krasnoyarsk",
    "label": "(+07:00) Asia/Krasnoyarsk",
    "offset": 420
  },
  {
    "key": "Asia/Novokuznetsk",
    "label": "(+07:00) Asia/Novokuznetsk",
    "offset": 420
  },
  {
    "key": "Asia/Novosibirsk",
    "label": "(+07:00) Asia/Novosibirsk",
    "offset": 420
  },
  {
    "key": "Asia/Pontianak",
    "label": "(+07:00) Asia/Pontianak",
    "offset": 420
  },
  {
    "key": "Asia/Tomsk",
    "label": "(+07:00) Asia/Tomsk",
    "offset": 420
  },
  {
    "key": "Etc/GMT-7",
    "label": "(+07:00) Etc/GMT-7",
    "offset": 420
  },
  {
    "key": "Indian/Christmas",
    "label": "(+07:00) Indian/Christmas",
    "offset": 420
  },
  {
    "key": "Asia/Brunei",
    "label": "(+08:00) Asia/Brunei",
    "offset": 480
  },
  {
    "key": "Asia/Choibalsan",
    "label": "(+08:00) Asia/Choibalsan",
    "offset": 480
  },
  {
    "key": "Asia/Hong_Kong",
    "label": "(+08:00) Asia/Hong_Kong",
    "offset": 480
  },
  {
    "key": "Asia/Irkutsk",
    "label": "(+08:00) Asia/Irkutsk",
    "offset": 480
  },
  {
    "key": "Asia/Kuala_Lumpur",
    "label": "(+08:00) Asia/Kuala_Lumpur",
    "offset": 480
  },
  {
    "key": "Asia/Kuching",
    "label": "(+08:00) Asia/Kuching",
    "offset": 480
  },
  {
    "key": "Asia/Macau",
    "label": "(+08:00) Asia/Macau",
    "offset": 480
  },
  {
    "key": "Asia/Makassar",
    "label": "(+08:00) Asia/Makassar",
    "offset": 480
  },
  {
    "key": "Asia/Manila",
    "label": "(+08:00) Asia/Manila",
    "offset": 480
  },
  {
    "key": "Asia/Shanghai",
    "label": "(+08:00) Asia/Shanghai",
    "offset": 480
  },
  {
    "key": "Asia/Singapore",
    "label": "(+08:00) Asia/Singapore",
    "offset": 480
  },
  {
    "key": "Asia/Taipei",
    "label": "(+08:00) Asia/Taipei",
    "offset": 480
  },
  {
    "key": "Asia/Ulaanbaatar",
    "label": "(+08:00) Asia/Ulaanbaatar",
    "offset": 480
  },
  {
    "key": "Australia/Perth",
    "label": "(+08:00) Australia/Perth",
    "offset": 480
  },
  {
    "key": "Etc/GMT-8",
    "label": "(+08:00) Etc/GMT-8",
    "offset": 480
  },
  {
    "key": "Antarctica/Casey",
    "label": "(+08:00) Antarctica/Casey",
    "offset": 480
  },
  {
    "key": "Australia/Eucla",
    "label": "(+08:45) Australia/Eucla",
    "offset": 525
  },
  {
    "key": "Asia/Pyongyang",
    "label": "(+09:00) Asia/Pyongyang",
    "offset": 540
  },
  {
    "key": "Asia/Chita",
    "label": "(+09:00) Asia/Chita",
    "offset": 540
  },
  {
    "key": "Asia/Dili",
    "label": "(+09:00) Asia/Dili",
    "offset": 540
  },
  {
    "key": "Asia/Jayapura",
    "label": "(+09:00) Asia/Jayapura",
    "offset": 540
  },
  {
    "key": "Asia/Khandyga",
    "label": "(+09:00) Asia/Khandyga",
    "offset": 540
  },
  {
    "key": "Asia/Seoul",
    "label": "(+09:00) Asia/Seoul",
    "offset": 540
  },
  {
    "key": "Asia/Tokyo",
    "label": "(+09:00) Asia/Tokyo",
    "offset": 540
  },
  {
    "key": "Asia/Yakutsk",
    "label": "(+09:00) Asia/Yakutsk",
    "offset": 540
  },
  {
    "key": "Etc/GMT-9",
    "label": "(+09:00) Etc/GMT-9",
    "offset": 540
  },
  {
    "key": "Pacific/Palau",
    "label": "(+09:00) Pacific/Palau",
    "offset": 540
  },
  {
    "key": "Australia/Darwin",
    "label": "(+09:30) Australia/Darwin",
    "offset": 570
  },
  {
    "key": "Antarctica/DumontDUrville",
    "label": "(+10:00) Antarctica/DumontDUrville",
    "offset": 600
  },
  {
    "key": "Asia/Ust-Nera",
    "label": "(+10:00) Asia/Ust-Nera",
    "offset": 600
  },
  {
    "key": "Asia/Vladivostok",
    "label": "(+10:00) Asia/Vladivostok",
    "offset": 600
  },
  {
    "key": "Australia/Brisbane",
    "label": "(+10:00) Australia/Brisbane",
    "offset": 600
  },
  {
    "key": "Australia/Lindeman",
    "label": "(+10:00) Australia/Lindeman",
    "offset": 600
  },
  {
    "key": "Etc/GMT-10",
    "label": "(+10:00) Etc/GMT-10",
    "offset": 600
  },
  {
    "key": "Pacific/Chuuk",
    "label": "(+10:00) Pacific/Chuuk",
    "offset": 600
  },
  {
    "key": "Pacific/Guam",
    "label": "(+10:00) Pacific/Guam",
    "offset": 600
  },
  {
    "key": "Pacific/Port_Moresby",
    "label": "(+10:00) Pacific/Port_Moresby",
    "offset": 600
  },
  {
    "key": "Australia/Adelaide",
    "label": "(+10:30) Australia/Adelaide",
    "offset": 630
  },
  {
    "key": "Australia/Broken_Hill",
    "label": "(+10:30) Australia/Broken_Hill",
    "offset": 630
  },
  {
    "key": "Australia/Currie",
    "label": "(+11:00) Australia/Currie",
    "offset": 660
  },
  {
    "key": "Australia/Hobart",
    "label": "(+11:00) Australia/Hobart",
    "offset": 660
  },
  {
    "key": "Australia/Melbourne",
    "label": "(+11:00) Australia/Melbourne",
    "offset": 660
  },
  {
    "key": "Australia/Sydney",
    "label": "(+11:00) Australia/Sydney",
    "offset": 660
  },
  {
    "key": "Australia/Lord_Howe",
    "label": "(+11:00) Australia/Lord_Howe",
    "offset": 660
  },
  {
    "key": "Antarctica/Macquarie",
    "label": "(+11:00) Antarctica/Macquarie",
    "offset": 660
  },
  {
    "key": "Asia/Magadan",
    "label": "(+11:00) Asia/Magadan",
    "offset": 660
  },
  {
    "key": "Asia/Sakhalin",
    "label": "(+11:00) Asia/Sakhalin",
    "offset": 660
  },
  {
    "key": "Asia/Srednekolymsk",
    "label": "(+11:00) Asia/Srednekolymsk",
    "offset": 660
  },
  {
    "key": "Etc/GMT-11",
    "label": "(+11:00) Etc/GMT-11",
    "offset": 660
  },
  {
    "key": "Pacific/Bougainville",
    "label": "(+11:00) Pacific/Bougainville",
    "offset": 660
  },
  {
    "key": "Pacific/Efate",
    "label": "(+11:00) Pacific/Efate",
    "offset": 660
  },
  {
    "key": "Pacific/Guadalcanal",
    "label": "(+11:00) Pacific/Guadalcanal",
    "offset": 660
  },
  {
    "key": "Pacific/Kosrae",
    "label": "(+11:00) Pacific/Kosrae",
    "offset": 660
  },
  {
    "key": "Pacific/Norfolk",
    "label": "(+11:00) Pacific/Norfolk",
    "offset": 660
  },
  {
    "key": "Pacific/Noumea",
    "label": "(+11:00) Pacific/Noumea",
    "offset": 660
  },
  {
    "key": "Pacific/Pohnpei",
    "label": "(+11:00) Pacific/Pohnpei",
    "offset": 660
  },
  {
    "key": "Asia/Anadyr",
    "label": "(+12:00) Asia/Anadyr",
    "offset": 720
  },
  {
    "key": "Asia/Kamchatka",
    "label": "(+12:00) Asia/Kamchatka",
    "offset": 720
  },
  {
    "key": "Etc/GMT-12",
    "label": "(+12:00) Etc/GMT-12",
    "offset": 720
  },
  {
    "key": "Pacific/Funafuti",
    "label": "(+12:00) Pacific/Funafuti",
    "offset": 720
  },
  {
    "key": "Pacific/Kwajalein",
    "label": "(+12:00) Pacific/Kwajalein",
    "offset": 720
  },
  {
    "key": "Pacific/Majuro",
    "label": "(+12:00) Pacific/Majuro",
    "offset": 720
  },
  {
    "key": "Pacific/Nauru",
    "label": "(+12:00) Pacific/Nauru",
    "offset": 720
  },
  {
    "key": "Pacific/Tarawa",
    "label": "(+12:00) Pacific/Tarawa",
    "offset": 720
  },
  {
    "key": "Pacific/Wake",
    "label": "(+12:00) Pacific/Wake",
    "offset": 720
  },
  {
    "key": "Pacific/Wallis",
    "label": "(+12:00) Pacific/Wallis",
    "offset": 720
  },
  {
    "key": "Pacific/Auckland",
    "label": "(+13:00) Pacific/Auckland",
    "offset": 780
  },
  {
    "key": "Pacific/Fiji",
    "label": "(+13:00) Pacific/Fiji",
    "offset": 780
  },
  {
    "key": "Etc/GMT-13",
    "label": "(+13:00) Etc/GMT-13",
    "offset": 780
  },
  {
    "key": "Pacific/Enderbury",
    "label": "(+13:00) Pacific/Enderbury",
    "offset": 780
  },
  {
    "key": "Pacific/Fakaofo",
    "label": "(+13:00) Pacific/Fakaofo",
    "offset": 780
  },
  {
    "key": "Pacific/Tongatapu",
    "label": "(+13:00) Pacific/Tongatapu",
    "offset": 780
  },
  {
    "key": "Pacific/Chatham",
    "label": "(+13:45) Pacific/Chatham",
    "offset": 825
  },
  {
    "key": "Pacific/Apia",
    "label": "(+14:00) Pacific/Apia",
    "offset": 840
  },
  {
    "key": "Etc/GMT-14",
    "label": "(+14:00) Etc/GMT-14",
    "offset": 840
  },
  {
    "key": "Pacific/Kiritimati",
    "label": "(+14:00) Pacific/Kiritimati",
    "offset": 840
  }
]
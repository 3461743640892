import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import CustomDialog from "../common/Dialog";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditItems from "./addMissingItems";
import { parentUnitsLimit, getDevices } from "../../Actions/Devices";
import Table from "../common/tableWithBackEndPagination";
import isEqual from "react-fast-compare";
import { checkPrivileges, PaginationConfig, replaceSpecialCh } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
// import DevicePortList from './devicePortList'
// import Table from "../common/tableWithBackEndPagination";
import Button from "../common/Button";
import { Grid } from "@mui/material";
import TextField from "../common/TextField";
import instance from "../../axios";
import ExportFile from "../common/ExportFile";
import Style from "style-it";

import moment from "moment";
import { Rowing } from "@mui/icons-material";
import Loader from "../../Layout/Loader";
import { async } from "q";

const formDefault = {
    itemPrice:""
  };
  // refNum:replaceSpecialCh(data.refNum || ''),
  const makeSubType= (data, parentId) => ({
    // ...data,
    expensetypeId:data.expensetypeId,
    id:data.id ||  '',
    label :replaceSpecialCh(data.itemName || "") ,
    modified : null,
    modifiedBy : null,
    parentId:parentId,
    purchasePrice : data&&data.purchasePrice ? parseFloat(data.purchasePrice)  : 0,     //itemTotalAmount
    subTypeGroup : data.subTypeGroup || "",
    subTypeNum : replaceSpecialCh(data.itemCode || ""),
    unitPrice : data.itemPrice || "",
    generic:false,
    attributes:data.attributes || {},
   
  });
let source;
class itemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        editOption: false,
        form:{
          expensetypeId: "",
          attributes:{
            quantityPriceScale: [{quantity:"",price:""}],
          }
        },
        isVisible:false,
        quantityError:"",
        itemAlredyExists:"",
        showGrid: false,
        deleteRow:false

    };
   
  }

  handleAddMore = () => {
    const { form } = this.state;
    const { quantityPriceScale } = form.attributes;
  
    const updatedQuantityPriceScale = [...quantityPriceScale, { quantity: "", price: "" }];
  
    const updatedForm = {
      ...form,
      attributes: {
        ...form.attributes,
        quantityPriceScale: updatedQuantityPriceScale,
      },
    };
  
    this.setState({ form: updatedForm });
  };


  onCancel1 = () => {
    this.setState({
      deleteRow: false,
    });
  };
  // handleDelete = index => {
  //   const { form } = this.state;
  //   const updatedForm = { ...form };
  //   updatedForm.attributes.quantityPriceScale.splice(index, 1);
  //   this.setState({ form: updatedForm });
  // };

  handleDelete = index => {
    const { form } = this.state;
    const updatedForm = { ...form };
    if (updatedForm?.attributes?.quantityPriceScale?.length > 1) {
    updatedForm.attributes.quantityPriceScale.splice(index, 1);
    this.setState({ form: updatedForm });
  } else {
    // Display a message or perform any other action when attempting to delete the last row
    this.setState({
      deleteRow: true,
    });
  }
  };
  handleChange = (name) => (event) => {
   let val;
   if(name === "itemTotalAmount" || name === "itemPrice"){
    val = parseFloat(event.target.value);
   }else{
         val = event.target.value;
   }
      this.setState(
            {
              form: {
                ...this.state.form,
                  [name]: val,
              },
            },
            () => {
              this.checkRequiredFields();
            }
        );
  };

  checkRequiredFields = () => {
    const { itemTotalAmount } =this.state.form;   
    const {   quantityError, itemAlredyExists,  showGrid} =this.state;
    if(showGrid){
      if ( !quantityError && !itemAlredyExists) {
        this.setState({ isVisible: true });
      }
      else {
        this.setState({ isVisible: false });
      }
    } else {
      if (  !itemAlredyExists) {
        this.setState({ isVisible: true });
      }else{
        this.setState({ isVisible: false });
      }
      }
  };

  handleQuntityPriceScale = () => {
    this.setState({showGrid: !this.state.showGrid});
  }
  
  modalControle = () => {
    this.setState(
      {
        editOption: false,
        showGrid:false
      },
      // () => this.props.closeModale()
    );
  };

  openModale = (item) => {
    // Destructure the item to get the necessary data
    const { contractNumber, categoryName, itemCode } = item[0];
  
    // Create the URLs for the two API calls
    const apiUrl = `/api/expensetypes/get?contractNumber=${contractNumber}&all=true`;
    const apiUrl2 = `/api/deliverynotes/items/incontract?contractnumber=${contractNumber}&categoryname=${categoryName}&subtypenum=${itemCode}`;
  
    source = axios.CancelToken.source();
  
    // Use axios.all to make multiple API requests together
    axios.all([
      axios.get(apiUrl, { cancelToken: source.token }),
      axios.get(apiUrl2, { cancelToken: source.token })
    ])
    .then(axios.spread((response1, response2) => {
      // Process the responses of both API calls here
      // console.log("res=1==== ", response1.data?.data?.data );
      // console.log("res=2===== ",  response2.data.data);
      const allAccounts = response1.data?.data?.data || [];
      const matchedAccount = allAccounts.find(account => account.description === categoryName);
      const expenseTypeId = matchedAccount ? matchedAccount.id : null;
  
      const userAccount = this.props.accounts?.data.find(account => account.contractNumber === contractNumber);
      const updatedItem = {
        ...item[0],
        accountName: userAccount?.label
      };
      this.setState({
        editOption: true,
        itemAlredyExists :response2.data.data,
        isVisible: response2&&response2.data&&response2.data.data ? false : true,
        form: {
          ...updatedItem,
          expensetypeId: expenseTypeId,
          purchasePrice:item[0]?.itemPrice || 0,
          attributes: {
            quantityPriceScale: [{ quantity: "", price: "" }],
          }
        },
      });
    }))
    .catch((e) => {
      console.log("Error:", e);
    });
  };
  

  
  submitForm = () => {
    let url = `/api/expensetypes/subtype`
    let updatedPayload
    if (Object.keys(this.state.form.attributes.quantityPriceScale).length === 0) {
        updatedPayload = {
          ...this.state.form,
          attributes: {
            // quantityPriceScale: { NaN: null },
            quantityPriceScale: { 0: 0 },
          },
        };
      }else{
        updatedPayload = {
          ...this.state.form,
          attributes: {
            quantityPriceScale: this.state.form?.attributes?.quantityPriceScale?.reduce((acc, item) => {
              acc[item.quantity] = item.price;
              return acc;
            }, {}),
          },
        };
      }
      // Convert empty strings to 0 and ensure values are numbers
        Object.keys(updatedPayload.attributes.quantityPriceScale).forEach((key) => {
          if (updatedPayload.attributes.quantityPriceScale[key] === '') {
            updatedPayload.attributes.quantityPriceScale[key] = 0;
          } else {
            updatedPayload.attributes.quantityPriceScale[key] = parseFloat(updatedPayload.attributes.quantityPriceScale[key]);
          }
        });

        // Convert the keys to numbers (if they are not already) and handle NaN keys
        updatedPayload.attributes.quantityPriceScale = Object.keys(updatedPayload.attributes.quantityPriceScale).reduce((acc, key) => {
          const numericKey = parseFloat(key);
          acc[Number.isNaN(numericKey) ? 0 : numericKey] = updatedPayload.attributes.quantityPriceScale[key];
          return acc;
        }, {});



      let parentId = this.props?.logInUser?.id
      const obj1 = makeSubType(updatedPayload, parentId) 

    
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj1),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((res) => {

    
        this.setState({  form: {
          expensetypeId: this.props?.selectedServiceTypeId,
          attributes:{
            quantityPriceScale: [{quantity:"",price:""}],
          }
        }, editOption: false }, () => {
          this.props.getMissingItems();
        });


        // this.props.dispatch(
        //   toast.success({
        //     message: this.props.translate("subTypeCreated"),
        //     autoDismiss: 5,
        //     location: this.props.location,
        //   })
        // );


        
        if (res.status === "success") {
       
            toast.success( this.props.translate("subTypeCreated")
          );
        }
        else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
 
              toast.error( this.props.translate(err[1])
            );
          } else {
            throw response;
          }




      })
      }
    })
      .catch((error) => {
        console.log("error ==", error);
      });
};
 

  regenerateDeliveryNote = (item, type) => {
      let api= `/api/deliverynotes/${item[0].deliveryNoteId}/recompute`
      axios({
        method: "GET",
        url: api,
      })
        .then((response) => {
          if (response?.data.status === "success"  ) {

              toast.success( this.props.translate("itemNoteRegeneatedSuccessFul")
  
            );
            this.setState({},()=>{this.props.getMissingItems()});
          }
          else{
          
              toast.error( this.props.translate(response?.data?.message)
             
            );
          }
        })
        .catch((error) => {
       
            toast.error( this.props.translate("somethingWentWrong")
          );
        });
  };
  handleChange1 = (index, field, value) => {
    const { form } = this.state;
    const updatedForm = { ...form };
  
    // Update the quantity or price value with parseFloat
    updatedForm.attributes.quantityPriceScale[index][field] = parseFloat(value);
  
    // Check for duplicate entries
    const quantities = updatedForm.attributes.quantityPriceScale.map(item => item.quantity);
    const hasDuplicates = (new Set(quantities)).size !== quantities.length;
  
    if (hasDuplicates) {
      this.setState({
        quantityError: "Duplicate entry",
        quantityHelperText: "Please enter unique quantities",
        
      } , ()=> this.checkRequiredFields());
      return;
    }
    else{
          this.setState({
            quantityError: "",
            quantityHelperText: "",
          } , ()=> this.checkRequiredFields());
        }
  
    this.setState({ form: updatedForm } , ()=> this.checkRequiredFields());
  };
  render() {
    const { devices, group } = this.props;
    let fileName = "units";
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        {this.state.editOption ? (
          <Fragment>
            <EditItems
              {...this.props}
              {...this.state}
              handleQuntityPriceScale={this.handleQuntityPriceScale}
              title="createSubtype"
              handleChange={this.handleChange}
              handleChange1={this.handleChange1}
              modalControle={this.modalControle} 
              formSubmit={this.submitForm}
              handleAddMore={this.handleAddMore}
              handleDelete={this.handleDelete}
            />
          </Fragment>
        )  : (
          <>
            <Grid
              container
              className="breadcrumb-row"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>
                  {this.props.translate("items")}
                </h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.props.searchItem}
                    onChange={(e) => this.props.searchItems(e)}
                  />
                </div>
             
                {/* <Button
                  aria-label="Create"
                  size="small"
                  color="inherit"
                  onClick={() => {
                    this.openModale();
                    // this.props.openModale();
                  }}
                  id="createButton"
                >
                  {this.props.translate("create")}
                </Button> */}
                {/* <Button
                  aria-label="Back"
                  size="small"
                  color="inherit"
                  onClick={this.props.goBack}
                  id="back"
                  style={{ margin: 10 }}
                >
                  {this.props.translate("sharedBack")}
                </Button> */}
              </Grid>
            </Grid>
            {this.props.loader2 ? <Loader/> : 
            <Table
              rows={this.props.itemsData || []}
              pagination={this.props.setPagination()}
              handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              handleChangePage={this.props.handleChangePage}
              isEditable
              // onClick={this.onEdit}
              onEdit={this.openModale}
              regenerateInvoice={this.regenerateDeliveryNote}
              hasAccessOfRegenerate
              isCursoPointer
              hasAccessOfAdd
              translate={this.props.translate}
              downloadInvoice={this.downloadCreditDebit}
              // leasing={this.props.leasing}
              rowDefinition={[
                {
                  id: "categoryName",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("categoryName"),
                },
                {
                  id: "contractNumber",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("contractNumber"),
                },
                {
                  id: "itemCode",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("itemCode"),
                },
                {
                  id: "itemName",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("itemName"),
                },
                {
                  id: "serviceDate",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("serviceDate"),
                },
                {
                  id: "itemPrice",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("itemPrice"),
                },
              ]}
            />
  }
          </>
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteCreditDebit}
            title={this.props.translate("areYouWantToDelete")}
            // children={this.state.selectedUser.name}
          />
        )}
        {this.state.deleteRow && (
          <ConfirmDialoag
            onCancel={this.onCancel1}
            onOk={this.onCancel1}
            title={this.props.translate("cannottDeleteLastRow")}
            // children={this.state.selectedUser.name}
          />
        )}
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const ItemsList = mapStateToProps((itemsList));

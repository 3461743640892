import React, { Component } from 'react'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import {
    coordinateFormatTypes,
  } from '../Users/userDataTypes'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1) * 2
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 100,
    borderRadius: '2%'
  }
})

class ViewUserDetails extends Component {
  constructor () {
    super()
    this.state = {
      selectedUserDetails: {
        serviceTitle: '',
        footerText: '',
        privacyPolicyLink: '',
        termsLink: '',
        hostUrl: '',
        adminUrl: '',
        serverIp: '',
        favIcon: '',
        logo: '',
        logInPageImage: '',
        accountName: ''
      },
      selectedNewUser:{}
    }
  }
  componentWillMount () {
    this.setState({
      selectedUserDetails: this.props.selectedUserDetails,
      selectedNewUser:this.props.selectedUserDetails
    })
  }

 

  render () {
    let selectedRole = this.props.roles.find(d => d.id === this.state.selectedNewUser[0].roleId)
    let selectedcoordinate= coordinateFormatTypes.find(d=> d.key === this.state.selectedNewUser[0].coordinateFormat)
    
    const { selectedUserDetails } = this.state
   
  
    const { classes } = this.props
    return (
      <>
        <Grid container spacing={0}>
          <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='name'
                    label={this.props.translate('sharedName')}
                    type='text'
                    placeholder=''
                    value={this.state.selectedNewUser[0].name}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='email'
                    label={this.props.translate('userEmail')}
                    type='text'
                    placeholder=''
                    value={this.state.selectedNewUser[0].email}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    readOnly
                  />
                </Grid>
                  {/* <Grid item xs={12} md={4} sm={6}>
                      <TextField
                        id='userType'
                        label={this.props.translate('userType')}
                        value={this.state.selectedNewUser[0].userType?this.state.selectedNewUser[0].userType:'userType'}
                        margin='dense'
                        fullWidth
                        readOnly
                      >
                      </TextField>
                    </Grid> */}
                
      
                  <Grid item xs={12} md={4} sm={6}>
                    <TextField
                      id='role'
                      label={this.props.translate('Role')}
                      value={selectedRole&&selectedRole.name ? selectedRole.name : 'Role'}
                      margin='dense'
                      fullWidth
                      readOnly
                      />
                  </Grid>
               
              </Grid>
           
              <h4>{this.props.translate('sharedPreferences')}</h4>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='phone'
                    label={this.props.translate('sharedPhone')}
                    type='text'
                    value={this.props.selectedUserDetails[0].phone?this.props.selectedUserDetails[0].phone:'phone'}
                    margin='dense'
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='coordinateFormat'
                    label={this.props.translate('CoordinateFormat')}
                    value={selectedcoordinate&&selectedcoordinate.name ?selectedcoordinate.name : 'coordinate'}
                    margin='dense'
                    fullWidth
                  >
               
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='map'
                    label={this.props.translate('mapTitle')}
                    value={this.props.selectedUserDetails[0].map?this.props.selectedUserDetails[0].map: 'map'}
                    margin='dense'
                    fullWidth
                    readOnly
                  >
                  </TextField>
                </Grid>

              </Grid>
              
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='latitude'
                    type='number'
                    label={this.props.translate('positionLatitude')}
                    placeholder=''
                    value={this.props.selectedUserDetails[0].latitude?this.props.selectedUserDetails[0].latitude : 'latitude'}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='longitude'
                    type='number'
                    label={this.props.translate('positionLongitude')}
                    value={this.props.selectedUserDetails[0].longitude?this.props.selectedUserDetails[0].longitude: 'longitude'}
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='zoom'
                    label={this.props.translate('serverZoom')}
                    type='number'
                    placeholder=''
                    value={this.props.selectedUserDetails[0].zoom?this.props.selectedUserDetails[0].zoom:'zoom'}
                    margin='dense'
                    fullWidth
                    readOnly
                  />
                </Grid>
              </Grid>
              <h4>{this.props.translate('sharedPermissions')}</h4>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    error={this.props.deviceLimitError}
                    id='userExpirationTime'
                    label={this.props.translate('userExpirationTime')}
                    type='text'
                    value={this.props.selectedUserDetails[0].expirationTime ? this.props.selectedUserDetails[0].expirationTime:'User Expiration Time'}
                    readOnly
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    error={this.props.deviceLimitError}
                    id='deviceLimit'
                    label={this.props.translate('userDeviceLimit')}
                    type='number'
                    value={this.props.selectedUserDetails[0].deviceLimit?this.props.selectedUserDetails[0].deviceLimit:'Device limit'}
                    readOnly
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='userLimit'
                    error={this.props.userLimitError}
                    label={this.props.translate('userUserLimit')}
                    type='number'
                    placeholder=''
                    value={this.props.selectedUserDetails[0].userLimit?this.props.selectedUserDetails[0].userLimit:'User limit'}
                    readOnly
                    margin='dense'
                    fullWidth
                  />
                    </Grid>
                    
              </Grid>

              <h4 style={{ margin: '10px 0' }}>
                {this.props.translate('sharedAttributes')}
              </h4>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='timezone'
                    label={this.props.translate('timezone')}
                    value={this.props.selectedUserDetails[0].attributes['timezone'] ?
                    this.props.selectedUserDetails[0].attributes['timezone'] : 'Time Zone'  }
                    margin='dense'
                    fullWidth
                    readOnly
                  />
                </Grid>
                  <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='measurementStandard'
                    label={this.props.translate('measurementStandard')}
                    value={this.props.selectedUserDetails[0].attributes.measurementStandard ?
                         this.props.selectedUserDetails[0].attributes.measurementStandard : 'measurementStandard'
                      }
                   
                    margin='dense'
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    id='currencies'
                    value={this.props.selectedUserDetails[0].attributes.currencies ?this.props.selectedUserDetails[0].attributes.currencies : 'currency'}
                    margin='dense'
                    fullWidth
                    />
                </Grid>
              </Grid>   
           
          </Grid>
        </Grid>
      </>
    )
  }
}
const Branding = withStyles(styles)
export default Branding(ViewUserDetails)

const ownerUserTypes = [
  {
    key: 1,
    name: 'user'
  },
  {
    key: 2,
    name: 'userAdmin'
  }
]
import React, { Component } from "react";
import { connect } from "react-redux";

class Loader extends Component {
  render() {
    let { background } = this.props;
    let themColor;

    // let themColor;
    // if (background) {
    if (this.props?.component === "filter") {
      themColor = "#fff";
    } else {
      // themColor = this.props.themecolors["500"] + "44";
      themColor = "#00c179";
    }
    // }
    const style = {
      pageLoader: {
        maxHeight: "100%",
        height: this.props.viewUserDetailHeight
          ? this.props.viewUserDetailHeight
          : "100vh",
        minHeight:this.props.fixHeight ?'' : 300,
        background: themColor,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      },
      defaultPageLoader: {
        marginTop: "1%",
        marginBottom: "1%",
        minHeight: 300,
        background: themColor,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      },
      p: {
        fontSize: 36,
        fontFamily: "'Open Sans', sans-serif",
      },
    };
    return (
      <div
        className="page-loader"
        style={
          this.props.defaultStyle ? style.defaultPageLoader : style.pageLoader
        }
      >
        <p style={{ textAlign: "center" }}>
          <img
            width={this.props.imgSize || 50}
            height={this.props.imgSize || 50}
            // src="/assets/images/tracks-loader.svg"
            src={
              this.props?.component === "filter"
                ? "/assets/images/tracks-loader-green.svg"
                : "/assets/images/tracks-loader.svg"
            }
            alt="Loading"
          />
        </p>
      </div>
    );
  }
}

const mapState = (state) => ({
  themecolors: state.themecolors,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(Loader);

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MultiselectTwoSides from 'react-multiselect-two-sides'
import Loader from '../../../../Layout/Loader'
import Table from '../../../common/TableMultiCheckbox'
// import 'react-multiselect-two-sides/style.css'
import './style.scss'
import { Grid, } from '@mui/material'
import { PaginationConfig } from '../../../../Helpers'
import TextField from '../../../common/TextField'
import axios from 'axios'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class Group extends Component {
  constructor () {
    super()
    this.state = {
      isGroupSet: false,
      assignGroupisRecived: false,
      selectedUseGroupsList: '',
      loaderOption: true,
      isChecked:false,
      multiselect: {
        options: [],
        value: []
      },
      loginUserGroupList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5, page: 1 },
      selectedUserIds:[],
      loginUserids:[]
    }
    this.onGroupAssignToUserSubmit = this.onGroupAssignToUserSubmit.bind(this)
  }
  componentWillMount () {
    this.getMoreResources()
  }

  getMoreResources = () =>{
    let { pagination } = this.state
    let { page, pageSize, itemSearch } = pagination
    let sellectedIds = []
    let loginUserids = []
    let check2 = false
  
    axios
    .get(
      `/api/groups/get?userId=${this.props.logInUser.id}&all=true&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
    )
  
    .then(res => {
      let loginUserGroups = res.data
      if (loginUserGroups.status === 'success') {        
        let lastPage = loginUserGroups.data.total / pageSize
        let IsFloate = this.checkFloteNumber(lastPage)
       
       
      fetch(`/api/groups?userId=${this.props.selectItemId.id}&all=true&limit=-1`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
            if (response.ok) {
              response.json().then(res2 => {
                if(res2.status ==='success'){ 
                  let selectedResources= res2.data 
                  loginUserGroups.data.data.map(allResources => {
                    const linkedResources =selectedResources.find(userId => allResources.id === userId.id) 
                    if(linkedResources) {
                        allResources.check = true
                      }else{
                        allResources.check = false
                      }
                  })
                  this.setState(
                    {
                      selectedUseGroupsList: loginUserGroups.data.data,
                      assignGroupisRecived: true,
                      loaderOption:false,
                      isGroupSet:true,
                      pagination: {
                        ...this.state.pagination,
                        lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                        hasNext: loginUserGroups.data.hasNext,
                        total: loginUserGroups.data.total
                      },
                    })
                    selectedResources.map(userId => {
                      sellectedIds.push(userId.id)
                    })
                    this.props.groups.data.map(groupIds => {
                      loginUserids.push(groupIds.id)
                    })
                    this.setState({
                      selectedUserIds: sellectedIds,
                      loginUserids:loginUserids,

                    },()=>{
                      if (this.state.selectedUserIds.length > this.state.loginUserids.length) {
                        check2 = this.state.loginUserids.every(item => this.state.selectedUserIds.includes(item))
                        this.setState({isChecked: check2})
                      }
                       else {
                        if (this.state.selectedUserIds.length === this.state.loginUserids.length) {
                          let res = this.checker(this.state.selectedUserIds,this.state.loginUserids)
                          this.setState({isChecked: res})
                          
                        } else {this.setState({isChecked: false})}
                      }
                    })
                }
                else if(res2?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res2.statusCode){
                  var err = res2?.message.split(':')
                  err[1] =err[1].replace(')', "")
                    toast.error( this.props.translate(err[1])
                  )
                  this.setState({
                    loaderOption:false
                  })
                }
              })
            } else {
              throw response
            }
          }).catch(e => {
              console.log('e =',e)
            // this.props.dispatch(toast.error({
            //   message: 'somethingWentWrong',
            //   autoDismiss: 5
            // }))
          })
      }
      else if(res?.statusCode === '440'){
        window.location.replace('/login')
      }
      else if(res.statusCode){
        var err = res?.message.split(':')
        err[1] =err[1].replace(')', "")
          toast.error( this.props.translate(err[1])
        )
      }
    })
  }

checker =(array1, array2) =>{
  return array1.every(v => array2.includes(v));
 } 

  setUnits = () => {
    let {
      selectedUseGroupsList,
      loginUserGroupList,
      assignGroupisRecived
    } = this.state
    let options = [],
      value = []
    if (loginUserGroupList.length && assignGroupisRecived) {
      loginUserGroupList.map(item => {
        options.push({ name: item.name, value: item.id })
        if (selectedUseGroupsList.length) {
          let data = selectedUseGroupsList.filter(dec => dec.id === item.id)
          if (data.length) {
            value.push(item.id)
          }
        }
      })
      this.setState({
        loaderOption: false,
        isGroupSet: true,
        multiselect: {
          ...this.state.multiselect,
          options,
          value
        }
      })
    } else {
      this.setState({
        loaderOption: false
      })
    }
  }

  symmetricDifference = (a1, a2) => {
    var a = [],
      diff = []

    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]]
      } else {
        a[a2[i]] = true
      }
    }

    for (let k in a) {
      diff.push(k)
    }

    return diff
  }

  handleChange2 = value => {
    this.setState(
      {
        loaderOption: true
      },
      () => {
        let prvValue = this.state.multiselect.value
        let pormissionData = ''
        let method = 'DELETE'
        if (value.length) {
          if (value.length < prvValue.length) {
            method = 'DELETE'
            pormissionData = this.symmetricDifference(prvValue, value)
          } else {
            method = 'POST'
            pormissionData = this.symmetricDifference(prvValue, value)
          }
        } else {
          pormissionData = prvValue
        }
        if (value.length < prvValue.length) {
        }
        this.setState(
          {
            multiselect: {
              ...this.state.multiselect,
              value
            }
          },
          () => {
            this.onGroupAssignToUserSubmit(pormissionData, method)
          }
        )
      }
    )
  }
  onGroupAssignToUserSubmit (item, option) {
    if (item.length === 1) {
      let obj = {
        userId: this.props.selectItemId.id,
        groupId: parseInt(item[0])
      }
      this.groupAssignSubmit(true, option, obj)
    } else {
      item.map(
        elm => {
          let obj = {
            userId: this.props.selectItemId.id,
            groupId: parseInt(elm)
          }
          this.groupAssignSubmit(false, option, obj)
        },
        () => {
          this.setState({
            loaderOption: false
          })
        }
      )
    }
  }

  groupAssignSubmit = (single, option, obj) => {
    if (single) {
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          // if (response.status === 204) {
            response.json().then(res => {
              if(res.status ==='success'){  
                this.setState({
                  loaderOption: false
                })
                if (option === 'POST') {
                    toast.success( this.props.translate('groupsIsAssignedSuccessfully')
                  )
                } else {
                    toast.success( this.props.translate(
                        'groupsIsUnAssignedSuccessfully'
                      )
                  )
                }
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                  toast.error( this.props.translate(err[1])
                )
              }
            })

        })
        .catch(e => {
           toast.error( 'somethingWentWrong')
        })
    } else {
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res => {
            if(res.status ==='success'){  
              if (option === 'POST') {
                  toast.success( this.props.translate('resourceIsAssignedSuccessfully')
                )
              } else {
                  toast.success( this.props.translate('resourceIsUnAssignedSuccessfully')
                )
              }
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
                toast.error( this.props.translate(err[1])
              )
            }
        })
      })
        .catch(e => {})
    }
  }

    allUsersId = event => {
      let array1 = []
      if(event.target.checked){
        array1 =this.state &&
        this.state.loginUserids.filter(
          val => !this.state.selectedUserIds.includes(val)
        )
      }
      else {
        array1 = this.state &&
        this.state.loginUserids.filter(
          val => this.state.selectedUserIds.includes(val)
        )
      }
      
      let check = event.target.checked
      this.selectAllUser(array1,check)
    }

    selectAllUser =(array1, event)=>{
     
    // totalUserId.deviceId = this.props.selectItemId
    let obj
    let option = 'DELETE'
    if (event) {
      option = 'POST'
      obj = { userId: this.props.selectItemId.id, groupId: array1 }
    } else {
      obj = {
        userId: this.props.selectItemId.id,
        groupId: array1
      }
    }
    fetch(`api/permissions/multiproperty`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        response.json().then(res => {
          if(res.status ==='success'){ 
            if (option === 'POST') {
              this.setState({
                isChecked: true
              })
  
                toast.success( this.props.translate('allResourceAssignedSuccessfully')
              )
            } else {
              this.setState({
                isChecked: false
              })
                toast.success( this.props.translate('allResourceUnAssignedSuccessfully')
              )
            }
            this.getMoreResources()
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
              toast.error( this.props.translate(err[1])
            )
          }
        })
       
      })
      .catch(e => {
         toast.error(
       'somethingWentWrong')
      })
        // totalUserId = {
        //   userId: [],
        //   deviceId: ''
        // }
    }

    ResourceToUserpermission =(event, item)=> {
      let sellectedIds = []
      let option = 'DELETE'
      let count = 0,
        allUserId = 0
      if (event) {
        option = 'POST'
      }
      let obj = {
        userId: this.props.selectItemId.id,
        groupId: item.id
      }
      fetch(`/api/permissions`, {
        method: `${option}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res =>{
          if (res.status === 'success') {
            this.getMoreResources()
            if (option === 'POST') {

                toast.success( this.props.translate('resourceIsAssignedSuccessfully')

              )
            } else {
    
                toast.success( this.props.translate('resourceIsUnAssignedSuccessfully')
    
              )
            }
          } 
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")

              toast.error( this.props.translate(err[1])
    
            )
          }
            
          })
        
        })
        .catch(e => {
    toast.error('somethingWentWrong'
          )
        })
    }

    loginAsUserhandleChange = (id, value) => {
      let { selectedUseGroupsList } = this.state
      selectedUseGroupsList.entity.map(item => {
        if (item.id === id) {
          item.logInAsUserState = value
        }
        return null
      })
      this.setState({
        selectedUseGroupsList
      })
    }

  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreResources()
    )
  }

  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value,
          page:1
        }
      },
      () => {
        this.getMoreResources()
      }
    )
  }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }

  SearchItem = searchValue => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreResources()
    )
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
        {this.state.loaderOption ? (
          <div style={{ textAlign: 'center' }}>
            <Loader defaultStyle />
          </div>
        ) : (
          <>
            {this.state.selectedUseGroupsList.length ? (
                <Grid container className="breadcrumb-row"> 
                <Grid item xs={12} sm={3}>
                    <h3 style={{margin: 0}}>{this.props.translate('Resources')}</h3>
                </Grid>
                    <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                      <div style={{ marginRight: '20px', width: "25%" }}>
                        <TextField
                          fullWidth
                          label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                      </div>
                  </Grid>
                </Grid>
              )
                : ''
              }


            {this.state.isGroupSet && this.state.selectedUseGroupsList.length ? (
               <Table
               translate={this.props.translate}
                    rows={this.state.selectedUseGroupsList}
                    allUsersId= {this.allUsersId}
                    selectItemParentId={this.props.selectItemId.id}
                    pagination={this.state.pagination}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    loginAsUser={this.loginAsUser}
                    isChecked={this.state.isChecked}
                    isIndeterminate={this.state.isIndeterminate}
                    rowsPerPage={10}
                    isEditable={false}
                    showCheckbox={true}
                    ServerSetting={this.props.ServerSetting}
                    themecolors={this.props.themecolors}
                    checkHandleChange={this.ResourceToUserpermission}
                    canAssign
                    canRemove
                    rowDefinition={[
                      {
                        id: 'name',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('Resources')
                      }
                    ]}
                  />
               
                    // <MultiselectTwoSides
                    //   {...this.state.multiselect}
                    //   className='msts_theme_example'
                    //   onChange={this.handleChange2}
                    //   availableHeader={this.props.translate('available')}
                    //   selectedHeader={this.props.translate('assign')}
                    //   labelKey='name'
                    //   showControls
                    //   searchable
                    //   availableFooter={
                    //     this.props.translate('available') +
                    //     ' : ' +
                    //     (this.state.multiselect.options.length -
                    //       this.state.multiselect.value.length)
                    //   }
                    //   selectedFooter={
                    //     this.props.translate('assign') +
                    //     ' : ' +
                    //     this.state.multiselect.value.length
                    //   }
                    // />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <h4> {this.props.translate('noGroupsFound')}</h4>
              </div>
            )}
          </>
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(Group)

import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import Table from '../../../common/tableWithBackEndPagination'
import {
  prepareUnits,
  checkRolesOfParent,
  PaginationConfig
} from '../../../../Helpers'
import Loader from '../../../../Layout/Loader'
import { Grid } from '@mui/material'
import TextField from '../../../common/TextField'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source;
class AccountUnits extends Component {
  constructor () {
    super()
    this.state = {
      usersReceived: false,
      selectedAccUnitsList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: '',
      unitsRecord: "",
      noRecords: false
    }
  }
  componentWillMount () {
    // here units call
    this.getMoreUnits()
  }
  // componentWillUnmount(){
  //   if(source)
  //   {
  //     source.cancel()
  //   }
  // }
  getMoreUnits = () => {
    let { page, pageSize, itemSearch } = this.state.pagination
    source = axios.CancelToken.source();
    
    axios.get(`/api/devices/get?accountId=${this.props.selectItemId}&all=true&&page=${page}&limit=${pageSize}&search=${itemSearch}`,{
      cancelToken : source.token
    })
    .then(res => {
      let response=res.data
      if (response.status === 'success') {
           if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
          let lastPage = response.data.total / pageSize
          let IsFloate = this.checkFloteNumber(lastPage)
          this.setState({
            pagination: {
              ...this.state.pagination,
              total: response.data.total,
              lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
              total: response.data.total,
              hasNext: response.data.hasNext
            },
            selectedAccUnitsList: checkRolesOfParent(
              prepareUnits(response.data.data),
              this.props.users.data,
              this.props.roles
            ),
            usersReceived: true,
            unitsRecord:response.data.data.length,
            noRecords:false
          })
      } 
      else {
        this.setState({
          usersReceived:false,
          noRecords:true
        })
      }
    }
    else if(res?.statusCode === '440'){
      window.location.replace('/login')
    }
    else if(res.statusCode){
      var err = res?.message.split(':')
      err[1] =err[1].replace(')', "")
        toast.error(this.props.translate(err[1]))
    }
    })
    .catch(e => {
      console.log('e =',e)
      //   this.props.dispatch(toast.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        this.getMoreUnits()
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUnits()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreUnits()
    )
  }

  render () {
    // const { classes } = this.props
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
               <Grid item xs={12} sm={3}>
                   <h3 style={{margin: 0}}>{this.props.translate('deviceTitle')}</h3>
                </Grid>
                    <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                       <div style={{ marginRight: '20px', width: "25%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                       />
                      </div>
                  </Grid>
               </Grid>
          {this.state && this.state.unitsRecord && !this.state.noRecords ? (<>
            <Table
              rows={this.state.selectedAccUnitsList || []}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              rowsPerPage={10}
              translate={this.props.translate}
              isEditable={true}
              themecolors={this.props.themecolors}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedName')
                },
                {
                  id: 'uniqueId',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('driverUniqeId')
                },
                // {
                //   id: 'ownerRole',
                //   numeric: false,
                //   disablePadding: false,
                //   label: this.props.translate('ownerRole')
                // },
                {
                  id: 'parentName',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('owner')
                }
              ]}
            /></>
          ) :  
          !this.state.unitsRecord && !this.state.noRecords ?
           (<div style={{ textAlign: 'center' }}>
                <Loader defaultStyle />
          </div>)
            : null
        }
  
          {!this.state.unitsRecord && this.state.noRecords && (
                <div style={{ textAlign: 'center' }}>
                    <h4> {this.props.translate('noUnitsFound')}</h4>
                </div>
            )}
      
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(AccountUnits)

import React, { Component, forwardRef } from 'react'
import MuiSwitch from '@mui/material/Switch'
import { connect } from 'react-redux'
import Style from 'style-it'

const mapStateToProps = (state, ownProps) => {
  return { themecolors: state.themecolors }
}
const Switch = (props, ref) => {
  const p = { ...props }
  delete p.dispatch
  return (
    <React.Fragment>
      <Style>{`
    .switch-base.switch-base-checked + .switch-base-bar {
      background-color: ${p.themecolors.themeLightColor}
    }
    .switch-base.switch-base-checked,
    .switch-base {
      color: ${p.themecolors.themeLightColor}
    }`}</Style>
      <MuiSwitch
        {...p}
        classes={{
          switchBase: 'switch-base',
          checked: 'switch-base-checked',
          track: 'switch-base-bar'
        }}
      />
    </React.Fragment>
  )
}

export default connect(mapStateToProps)(forwardRef(Switch))

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  margin: 1em;
  display: flex;
  flex-direction: column;
}
.table .header, .table .row {
  display: flex;
}
.table .header div, .table .row div {
  flex: 1 1;
  padding: 0.2rem 0.4em;
  border: 1px solid rgb(238, 238, 238);
}
.table .header {
  background-color: rgb(238, 238, 238);
}
.table .header div {
  cursor: pointer;
}

/*   form{
  height: 514px;
  overflow-y: scroll;
} */`, "",{"version":3,"sources":["webpack://./src/Components/Users/usersModal.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACE,aAAA;AACN;AACM;EACE,SAAA;EACA,qBAAA;EACA,oCAAA;AACR;AAGI;EACE,oCAAA;AADN;AAEM;EACE,eAAA;AAAR;;AAIA;;;GAAA","sourcesContent":[".table {\n    margin: 1em;\n    display: flex;\n    flex-direction: column;\n    \n    .header, .row {\n      display: flex;\n      \n      div {\n        flex: 1;\n        padding: 0.2rem 0.4em;\n        border: 1px solid rgb(238, 238, 238);\n      }\n    }\n    \n    .header {\n      background-color: rgb(238, 238, 238);\n      div { \n        cursor: pointer;\n      }\n    }\n  }\n/*   form{\n  height: 514px;\n  overflow-y: scroll;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from "react";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
// import MenuItem from '../common/MenuItem'
import Checkbox from "../common/Checkbox";
import { errorHandler } from "../../Helpers";
// import CustomDialog from './Dialog';
import Stepper from "../Invoice/Stepper";
import moment from "moment";
import DateRangePicker from "../Invoice/DateRangePicker";
// import TextField from './TextField';
import { Grid, FormControlLabel } from "@mui/material";
import Loader from "../../Layout/Loader";
// import Button from './Button';
import VehicleSelector from "../Invoice/VehicleSelector";
import AccountSelector from "../Invoice/AccountSelector";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// // // import Menu from '../Menu/CommonMenu'
import AreaSelector from "../Invoice/AreaSelector";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import { GetApp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export default class invoiceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilterModal: false,
      from: "",
      to: "",
      selectedDate: "",
      vehicleId: [],
      contractNumber: [],
      areaId: [],
      loader: false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId: [],
      selectedaccountId: [],
      selectedAreaId: [],
      steps: [
        { id: 1, title: "selectServiceTimeControl" },
        { id: 2, title: "selectAccount" },
        // ,{id: 3, title: 'selectVehicles'},{id:4, title:'selectAreaIds'}
      ],
      singleAccountSteps: [
        { id: 1, title: "selectServiceTimeControl" },
        // , {id: 2, title: 'selectAccount'} ,{id: 3, title: 'selectVehicles'},{id:4, title:'selectAreaIds'}
      ],
      activeStep: 0,
      reportType: 0,
      isSubmitBtn: false,
    };
  }

  onCloseDueMaintenanceModel = () => {
    this.setState({
      openFilterModal: false,
      to: "",
      from: "",
      isSubmitBtn: false,
      selectedDate: "",
      from: "",
      to: "",
      vehicleId: [],
      areaId: [],
      contractNumber: [],
      countChecked: 0,
      anchorEl: null,
      activeStep: 0,
    });
  };

  showDates = (e, date) => {
    // console.log("e, data=====", e, date);
  
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    },()=>this.handleSubmitBtn());
  };


  handleSubmitBtn = () => {
    const { selectedDate, selectedaccountId } = this.state;
    const { showPayments } = this.props;
  
    if (showPayments) {
      // If showPayments is true, check if selectedDate is not empty
      if (selectedDate) {
        this.setState({ isSubmitBtn: true });
      } else {
        this.setState({ isSubmitBtn: false });
      }
    } else {
      // If showPayments is false, check if selectedDate and selectedaccountId meet the conditions
      // console.log("ct====",selectedaccountId);
      if (selectedDate && selectedaccountId && selectedaccountId.length > 0) {
        this.setState({ isSubmitBtn: true });
      } else {
        this.setState({ isSubmitBtn: false });
      }
    }
  }



  handleChange = (name, e) => {
    if (e === "area") {
      this.setState({
        areaId: name,
      });
      this.setState({ areaId: name }, () => {
        let filter = "";
        Object.entries(this.state.areaId).map(([key, value]) => {
          if (value) {
            filter += "&areaId=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedAreaId: filter,
        });
      });
    } else if (e === "vehicle") {
      this.setState({
        vehicleId: name,
      });
      this.setState({ vehicleId: name }, () => {
        let filter = "";
        Object.entries(this.state.vehicleId).map(([key, value]) => {
          if (value) {
            filter += "&vehicleId=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedvehicleId: filter,
        });
      });
    } else {
      this.setState({
        contractNumber: name,
      });
      this.setState({ contractNumber: name }, () => {
        let filter = "";
        Object.entries(this.state.contractNumber).map(([key, value]) => {
          if (value) {
            filter += "&contractNumber=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedaccountId: filter,
        },()=>this.handleSubmitBtn());
      });
    }
  };

  validatedForm = (step, type, index) => {
    let validated = false;
    let errorMsg = null;

    if (this.state.to && this.state.from) {
      validated = true;
      errorMsg = null;
      this.setState({ isSubmitBtn: true });
    } else {
      validated = false;
      errorMsg = (
        <span className="text-warning">
          Please select 'Date and Maintenance Status' from above
        </span>
      );
    }

    if (this.props.showPayments) {
      validated = true;
    } else {
      if (this.state.selectedaccountId.length > 0) {
        validated = true;
        errorMsg = null;
        this.setState({ isSubmitBtn: true });
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">
            Please select atleast one account
          </span>
        );
      }
    }
    if (validated === true) {
      this.setState({}, () => this.handleNext(step, type, index));
    } else {
      validated = false;
      errorMsg = (
        <span className="text-warning">
          Please select 'Date and Maintenance Status' from above
        </span>
      );
    }
  };

  handleNext = (step, type, index) => {
    // if (validated === true) {
    this.setState({}, () => {
      if (type === "downloadExcel" || type === "downloadPdf") {
        this.props.downloadInvoices(
          this.state.to,
          this.state.from,
          this.state.selectedaccountId,
          this.state.selectedvehicleId,
          this.state.selectedAreaId,
          type
        );
      } else {
        this.props.paymentFilter(
          this.state.to,
          this.state.from,
          this.state.selectedaccountId,
          this.state.selectedvehicleId,
          this.state.selectedAreaId
        );
      }
    });
    // }
  };

  goToNextStep = (step, type, index) => {
    if (this.props.showPayments) {
      let filter = "";
      filter = "&contractNumber=" + this.props.selectedAccount?.contractNumber;
      this.setState({
        selectedaccountId: filter,
      });

      if (step === 1) {
        this.setState({}, () => {
          if (type === "downloadExcel" || type === "downloadPdf") {
            this.props.downloadInvoices(
              this.state.to,
              this.state.from,
              this.state.selectedaccountId,
              this.state.selectedvehicleId,
              this.state.selectedAreaId,
              type
            );
          } else {
            this.props.paymentFilter(
              this.state.to,
              this.state.from,
              this.state.selectedaccountId,
              this.state.selectedvehicleId,
              this.state.selectedAreaId
            );
          }
        });
      } else {
        this.validatedCurrentStep(step, type, index);
      }
    } else {
      if (step === 2) {
        this.setState({}, () => {
          if (type === "downloadExcel" || type === "downloadPdf") {
            this.props.downloadInvoices(
              this.state.to,
              this.state.from,
              this.state.selectedaccountId,
              this.state.selectedvehicleId,
              this.state.selectedAreaId,
              type
            );
          } else {
            this.props.paymentFilter(
              this.state.to,
              this.state.from,
              this.state.selectedaccountId,
              this.state.selectedvehicleId,
              this.state.selectedAreaId
            );
          }
        });
      } else {
        this.validatedCurrentStep(step, type, index);
      }
    }
  };

  goToBackStep = () => {
    const currentStep = this.state.activeStep;
    this.setState({ activeStep: currentStep - 1 });
  };

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;

    if (step === 1) {
      if (this.state.to && this.state.from) {
        validated = true;
        errorMsg = null;
        this.setState({ isSubmitBtn: true });
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">
            Please select 'Date and Maintenance Status' from above
          </span>
        );
      }
    } else if (step === 2) {
      if (this.props.showPayments) {
        validated = true;
      } else {
        if (this.state.selectedaccountId.length > 0) {
          validated = true;
          errorMsg = null;
        } else {
          validated = false;
          errorMsg = (
            <span className="text-warning">
              Please select atleast one account
            </span>
          );
        }
      }
    }

    //   else if(step === 3) {
    //       validated = true
    // }

    if (validated === true) {
      if (this.props.showPayments) {
        this.setState({ activeStep: index + 1 }, () => {
          if (this.state.activeStep === 1) {
            this.setState({ isSubmitBtn: true });
          }
        });
      } else {
        this.setState({ activeStep: index + 1 }, () => {
          if (this.state.activeStep === 2) {
            this.setState({ isSubmitBtn: true });
          }
        });
      }
    }

    if (this.props.showPayments) {
      this.state.singleAccountSteps.map((s) => {
        if (s.id === step) {
          steps.push({ ...s, errorMsg });
        } else steps.push(s);

        return null;
      });
      this.setState({ steps });
    } else {
      this.state.steps.map((s) => {
        if (s.id === step) {
          steps.push({ ...s, errorMsg });
        } else steps.push(s);

        return null;
      });
      this.setState({ steps });
    }
  };

  getStepContent = (id) => {
    if (this.props.showPayments) {
      switch (id) {
        case 1:
          return (
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12}>
                {/* Date */}
                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
            </Grid>
          );

        default:
          return null;
      }
    } else {
      switch (id) {
        case 1:
          return (
            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12}>
                {/* Date */}
                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
              <Grid item xs={12}>
                {/*  */}
                <AccountSelector
                  {...this.props}
                  contractType={this.props.contractType}

                  payments
                  api="accounts"
                  fill
                  hideIcons
                  isClearable
                  placeholder={this.props.translate("searchAccount")}
                  onChange={this.handleChange}
                  value={this.state?.contractNumber || []}
                />
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid container className="form-group">
              <Grid item xs={12}>
                {/*  */}
                <AccountSelector
                    contractType={this.props.contractType}

                 {...this.props}
                 payments
                  api="accounts"
                  fill
                  hideIcons
                  isClearable
                  placeholder={this.props.translate("searchAccount")}
                  onChange={this.handleChange}
                  value={this.state?.contractNumber || []}
                />
              </Grid>
            </Grid>
          );
     
        default:
          return null;
      }
    }
  };

  render() {
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>

            <Grid container className="form-group sidebar-bg-layer">
              <Grid item xs={12} style={{ padding: 10 }}>
                {/* Date */}
                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
              {this.props.showPayments ? (
                ""
              ) : (
                <Grid item xs={12} style={{ padding: 10 }}>
                  <AccountSelector
                   {...this.props}
                    payments
                    api="accounts"
                    fill
                    hideIcons
                    contractType={this.props.contractType}
                    isClearable
                    placeholder={this.props.translate("searchAccount")}
                    onChange={this.handleChange}
                    value={this.state?.contractNumber || []}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ padding: 10 }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 20,
                    }}
                  >
                    <Button
                      disabled={this.state.isSubmitBtn ? "" : "disabled"}
                      variant="contained"
                      color="primary"
                      // downloadInvoices={this.props.downloadInvoices}
                      onClick={(e) =>
                        this.validatedForm("", "downloadExcel", "")
                      }
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                    >
                      <GetApp />
                      {this.props.translate("Excel")}
                    </Button>
                    <Button
                      disabled={this.state.isSubmitBtn ? "" : "disabled"}

                      onClick={(e) => this.validatedForm("", "", "")}
                      style={{
                        margin: 5,
                        height: "30px",
                        width: "60px",
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
      </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}

export const actionTypes = [
  // {
  //   key: 'oldValue',
  //   name: 'oldValue'
  // },
  // {
  //   key: 'newValue',
  //   name: 'newValue'
  // },
  {
    key: 0,
    name: 'unKnown action'
  },
  {
    key: 1,
    name: 'created'
  },
  {
    key: 2,
    name: 'updated'
  },
  {
    key: 3,
    name: 'archived'
  },
  {
    key: 4,
    name: 'unarchived'
  }, 
  // {
  //   key: 11,
  //   name: 'Created'
  // },
  // {
  //   key: 12,
  //   name: 'updated'
  // },
  // {
  //   key: 13,
  //   name: 'archived'
  // },
  // {
  //   key: 14,
  //   name: 'unarchived'
  // },
  {
    key: 21,
    name: 'created'
  },
  {
    key: 22,
    name: 'updated'
  },
  {
    key: 23,
    name: 'archived'
  },
  {
    key: 24,
    name: 'Unarchived'
  },
  {
    key: 31,
    name: 'created'
  },
  {
    key: 32,
    name: 'updated'
  },
  {
    key: 33,
    name: 'archived'
  },
  {
    key: 34,
    name: 'unarchived'
  },
  {
    key: 41,
    name: 'created'
  },
  {
    key: 42,
    name: 'updated'
  },
  {
    key: 43,
    name: 'archived'
  },
  {
    key: 44,
    name: 'unarchived'
  },
  {
    key: 45,
    name: 'send'
  },
  {
    key: 51,
    name: 'Created'
  },
  {
    key: 52,
    name: 'updated'
  },
  {
    key: 53,
    name: 'archived'
  },
  {
    key: 54,
    name: 'unarchived'
  },
  {
    key: 61,
    name: 'created'
  },
  {
    key: 62,
    name: 'updated'
  },
  {
    key: 63,
    name: 'archived'
  },
  {
    key: 64,
    name: 'unarchived'
  },
  {
    key: 71,
    name: 'created'
  },
  {
    key: 72,
    name: 'updated'
  },
  {
    key: 73,
    name: 'archived'
  },
  {
    key: 74,
    name: 'unarchived'
  },
  {
    key: 75,
    name: 'migrated'
  },
  {
    key: 76,
    name: 'exported'
  },
  {
    key: 77,
    name: 'imported'
  },
  {
    key: 78,
    name: 'hours updated'
  },
  {
    key: 79,
    name: 'distance updated'
  },
  {
    key: 80,
    name: 'name updated'
  },
  {
    key: 81,
    name: 'IMEI updated'
  },
  {
    key: 82,
    name: 'model updated'
  },
  {
    key: 83,
    name: 'first position received'
  },
  {
    key: 91,
    name: 'created'
  },
  {
    key: 92,
    name: 'updated'
  },
  {
    key: 93,
    name: 'archived'
  },
  {
    key: 94,
    name: 'unarchived'
  },
  {
    key: 95,
    name: 'exported'
  },
  {
    key: 96,
    name: 'imported'
  },
  {
    key: 101,
    name: 'created'
  },
  {
    key: 102,
    name: 'updated'
  },
  {
    key: 103,
    name: 'archived'
  },
  {
    key: 104,
    name: 'unarchived'
  },
  {
    key: 111,
    name: 'created'
  },
  {
    key: 112,
    name: 'updated'
  },
  {
    key: 113,
    name: 'archived'
  },
  {
    key: 114,
    name: 'unarchived'
  },
  {
    key: 121,
    name: 'created'
  },
  {
    key: 122,
    name: 'updated'
  },
  {
    key: 123,
    name: 'archived'
  },
  {
    key: 124,
    name: 'unarchived'
  },
  {
    key: 125,
    name: 'exported'
  },
  {
    key: 126,
    name: 'imported'
  },
  {
    key: 131,
    name: 'created'
  },
  {
    key: 132,
    name: 'updated'
  },
  {
    key: 133,
    name: 'archived'
  },
  {
    key: 134,
    name: 'unarchived'
  },
  {
    key: 141,
    name: 'created'
  },
  {
    key: 142,
    name: 'updated'
  },
  {
    key: 143,
    name: 'archived'
  },
  {
    key: 144,
    name: 'unarchived'
  },
  {
    key: 151,
    name: 'created'
  },
  {
    key: 152,
    name: 'updated'
  },
  {
    key: 153,
    name: 'archived'
  },
  {
    key: 154,
    name: 'unarchived'
  },
  {
    key: 161,
    name: 'created'
  },
  {
    key: 162,
    name: 'updated'
  },
  {
    key: 163,
    name: 'archived'
  },
  {
    key: 164,
    name: 'unarchived'
  },
  {
    key: 171,
    name: 'created'
  },
  {
    key: 172,
    name: 'updated'
  },
  {
    key: 173,
    name: 'archived'
  },
  {
    key: 174,
    name: 'unarchived'
  },
  {
    key: 181,
    name: 'created'
  },
  {
    key: 182,
    name: 'updated'
  },
  {
    key: 183,
    name: 'archived'
  },
  {
    key: 184,
    name: 'unarchived'
  },
  {
    key: 191,
    name: 'created'
  },
  {
    key: 192,
    name: 'updated'
  },
  {
    key: 193,
    name: 'archived'
  },
  {
    key: 194,
    name: 'unarchived'
  },
  {
    key: 195,
    name: 'reset'
  },
  {
    key: 201,
    name: 'created'
  },
  {
    key: 202,
    name: 'updated'
  },
  {
    key: 203,
    name: 'archived'
  },
  {
    key: 204,
    name: 'unarchived'
  },
  {
    key: 211,
    name: 'exported'
  },
  // {
  //   key: 216,
  //   name: 'created'
  // },
  // {
  //   key: 217,
  //   name: 'updated'
  // },
  // {
  //   key: 218,
  //   name: 'archived'
  // },
  // {
  //   key: 219,
  //   name: 'unarchived'
  // },
  // {
  //   key: 220,
  //   name: 'exported'
  // },
  {
    key: 231,
    name: 'created'
  },
  {
    key: 232,
    name: 'updated'
  },
  {
    key: 233,
    name: 'archived'
  },
  {
    key: 234,
    name: 'unarchived'
  },
  {
    key: 241,
    name: 'created'
  },
  {
    key: 242,
    name: 'updated'
  },
  {
    key: 243,
    name: 'archived'
  },
  {
    key: 244,
    name: 'unarchived'
  },
  {
    key: 251,
    name: 'created'
  },
  {
    key: 252,
    name: 'updated'
  },
  {
    key: 253,
    name: 'archived'
  },
  {
    key: 254,
    name: 'unarchived'
  },
  {
    key: 261,
    name: 'created'
  },
  {
    key: 262,
    name: 'updated'
  },
  {
    key: 263,
    name: 'archived'
  },
  {
    key: 264,
    name: 'unarchived'
  },
  {
    key: 265,
    name: 'imported'
  },
  {
    key: 266,
    name: 'exported'
  },
  {
    key: 271,
    name: 'Login'
  },
  {
    key: 272,
    name: 'Logout'
  },
  {
    key: 281,
    name: 'created'
  },
  {
    key: 282,
    name: 'updated'
  },
  {
    key: 283,
    name: 'archived'
  },
  {
    key: 284,
    name: 'unarchived'
  },

  {
    key: 285,
    name: 'imported'
  },
  {
    key: 286,
    name: 'exported'
  },
  {
    key: 291,
    name: 'created'
  },
  {
    key: 292,
    name: 'updated'
  },
  {
    key: 293,
    name: 'archived'
  },
  {
    key: 294,
    name: 'unarchived'
  },
  {
    key: 295,
    name: 'disabled'
  },

  {
    key: 296,
    name: 'enabled'
  },
  {
    key: 297,
    name: 'exported'
  },
  {
    key: 311,
    name: 'created'
  },
  {
    key: 312,
    name: 'updated'
  },
  {
    key: 313,
    name: 'archived'
  },
  {
    key: 314,
    name: 'unarchived'
  },
  {
    key: 315,
    name: 'imported'
  },
  {
    key: 316,
    name: 'exported'
  },
  {
    key: 321,
    name: 'linked with device'
  },
  {
    key: 322,
    name: 'unlinked from device'
  },
  {
    key: 323,
    name: 'linked with device'
  },
  {
    key: 324,
    name: 'unlinked from device'
  },
  {
    key: 325,
    name: 'link with device'
  },
  {
    key: 326,
    name: 'unlink from device'
  },
  {
    key: 327,
    name: 'link with device'
  },
  {
    key: 328,
    name: 'unlink from device'
  },
  {
    key: 329,
    name: 'linked with resource'
  },
  {
    key: 330,
    name: 'unlinked from resource'
  },
  {
    key: 331,
    name: 'linked with resource'
  },
  {
    key: 332,
    name: 'unlinked from resource'
  },
  {
    key: 333,
    name: 'linked with resource'
  },
  {
    key: 334,
    name: 'unlinked from resource'
  },
  {
    key: 335,
    name: 'linked with resource'
  },
  {
    key: 336,
    name: 'unlinked from resource'
  },
  {
    key: 337,
    name: 'linked with resource'
  },
  {
    key: 338,
    name: 'unlinked from resource'
  },
  {
    key: 339,
    name: 'linked with resource'
  },
  {
    key: 340,
    name: 'unlinked from resource'
  },
  {
    key: 341,
    name: 'linked with user'
  },
  {
    key: 342,
    name: 'unlinked from user '
  },
  {
    key: 343,
    name: 'linked with user'
  },
  {
    key: 344,
    name: 'unlinked from user'
  },
  {
    key: 345,
    name: 'linked with user'
  },
  {
    key: 346,
    name: 'unlinked from user'
  },
  {
    key: 347,
    name: 'linked with user'
  },
  {
    key: 348,
    name: 'unlinked from user'
  },
  {
    key: 349,
    name: 'linked with user'
  },
  {
    key: 350,
    name: 'unlinked from user'
  },
  {
    key: 351,
    name: 'linked with user'
  },
  {
    key: 352,
    name: 'unlinked from user'
  },
  {
    key: 353,
    name: 'linked with user'
  },
  {
    key: 354,
    name: 'unlinked from user'
  },
  {
    key: 355,
    name: 'linked with user'
  },
  {
    key: 356,
    name: 'unlinked from user'
  },
  {
    key: 357,
    name: 'link with user'
  },
  {
    key: 358,
    name: 'unlink from user'
  },
  {
    key: 359,
    name: 'link with user'
  },
  {
    key: 360,
    name: 'unlink from user'
  },
  {
    key: 361,
    name: 'link with user'
  },
  {
    key: 362,
    name: 'unlink from user'
  },
  {
    key: 363,
    name: 'link with role'
  },
  {
    key: 364,
    name: 'unlink from role'
  },
  {
    key: 365,
    name: 'link with user'
  },
  {
    key: 366,
    name: 'unlink from user'
  },
  {
    key: 367,
    name: 'link with user'
  },
  {
    key: 368,
    name: 'unlink from user'
  },
  {
    key: 369,
    name: 'link with user'
  },
  {
    key: 370,
    name: 'unlink from user'
  },
  {
    key: 371,
    name: 'link with user'
  },
  {
    key: 372,
    name: 'unlink from user'
  },
  {
    key: 373,
    name: 'link with user'
  },
  {
    key: 374,
    name: 'unlink from user'
  },
  {
    key: 375,
    name: 'link with resource'
  },
  {
    key: 376,
    name: 'unlink from resource'
  },
  {
    key: 377,
    name: 'link with itemGroup'
  },
  {
    key: 378,
    name: 'unlink from itemGroup'
  },
  {
    key: 379,
    name: 'link with resource'
  },
  {
    key: 380,
    name: 'unlink from resource'
  },
  {
    key: 381,
    name: 'link with user'
  },
  {
    key: 382,
    name: 'unlink from user'
  },
  {
    key: 383,
    name: 'link with user'
  },
  {
    key: 384,
    name: 'unlink from user'
  },
  {
    key: 385,
    name: 'link with vehicle'
  },
  {
    key: 386,
    name: 'unlink from vehicle'
  },
  {
    key: 387,
    name: 'link with driver'
  },
  {
    key: 388,
    name: 'unlink from driver'
  },
  {
    key:389,
    name:'Link with User'
  },
  {
    key:390,
    name:"UnLink from User"
  },
  {
    key:391,
    name:"Link with User"
  },
  {
    key:392,
    name:"UnLink from User"
  },
  {
    key:393,
    name:"Link with User"
  },
  {
    key:394,
    name:"UnLink from User"
  },
  {
    key:395,
    name:"Link with Resourse"
  },
  {
    key:396,
    name:"UnLink from Resourse"
  },
  {
    key:397,
    name:"Link with Resourse"
  },
  {
    key:398,
    name:"UnLink from Resourse"
  },
  {
    key:399,
    name:"Link with Resourse"
  },
  {
    key:400,
    name:"UnLink from Resourse"
  },
  {
    key: 501,
    name: 'Create'
  },
  {
    key: 502,
    name: 'Edit'
  },
  {
    key: 503,
    name: 'Archive'
  },
  {
    key: 504,
    name: 'Un archive'
  },
  {
    key: 526,
    name: 'Create'
  },
  {
    key: 527,
    name: 'Edit'
  },
  {
    key: 528,
    name: 'Archive'
  },
  {
    key: 529,
    name: 'Un archive'
  },
  {
    key: 541,
    name: 'Create'
  },
  {
    key: 542,
    name: 'Update'
  },
  {
    key: 543,
    name: 'Archive'
  },
  {
    key: 544,
    name: 'Un archive'
  },
  {
    key: 551,
    name: 'Create'
  },
  {
    key: 552,
    name: 'Edit'
  },
  {
    key: 553,
    name: 'Archive'
  },
  {
    key: 554,
    name: 'Un archive'
  },
  {
    key: 545,
    name: 'label change'
  },
  {
    key: 546,
    name: 'subtype num change'
  },
  {
    key: 547,
    name: 'unit price change'
  }
]
export const itemTypes = [
  {
    key: 'all',
    name: 'All'
    },
    {
        key: 1,
        name: 'account'
    },
    // {
    //   key: 11,
    //   name: 'Archive'
    // },
    {
      key: 21,
      name: 'Attribute'
    },
    {
      key: 31,
      name: 'Calender'
    },
    {
      key: 41,
      name: 'Command'
    },
    {
      key: 51,
      name: 'Coupon'
    },
    {
      key: 61,
      name: 'Dashboard'
    },
    {
      key: 71,
      name: 'Device'
    },
    {
      key: 91,
      name: 'Driver'
    },
    {
      key: 101,
      name: 'Eco Driving'
    },{
      key: 111,
      name: 'Event'
    },
    {
      key: 121,
      name: 'Geofence'
    },{
      key: 131,
      name: 'Resource'
    },{
      key: 141,
      name: 'Item Group'
    },{
      key: 151,
      name: 'Schedule'
    }, {
      key: 161,
      name: 'Maintenance', 
    } ,
    {
      key: 171,
      name: 'Notification'
    },
    // {
    //   key: 181,
    //   name: 'Order'
    // },
    {
      key: 191,
      name: 'Password'
    },
    {
      key: 201,
      name: 'POI'
    },
    {
      key: 211,
      name: 'Position'
    },
    // {
    //   key: 216,
    //   name: 'Report'
    // },
    {
      key: 231,
      name: 'Role'
    },
    {
      key: 241,
      name: 'Report Template'
    },
    {
      key: 251,
      name: 'Sensor'
    },
    {
      key: 261,
      name: 'Service'
    },
    {
      key: 271,
      name: 'Session'
    },
    {
      key: 281,
      name: 'Trailer'
    },
    {
      key: 291,
      name: 'User'
    },
    {
      key: 311,
      name: 'Vehicle'
    },
    {
      key: 321,
      name: 'Link'
    },
    
]
export const AccountsItem = [
    {
      key: 1,
      name: 'Create'
    },
    {
      key: 2,
      name: 'Edit'
    },
    {
      key: 3,
      name: 'Archive'
    },
    {
      key: 4,
      name: 'Un archive'
    },
]

// export const ArchiveItem = [
//     {
//       key: 11,
//       name: 'Create'
//     },
//     {
//       key: 12,
//       name: 'Edit'
//     },
//     {
//       key: 13,
//       name: 'Archive'
//     },
//     {
//       key: 14,
//       name: 'Un archive'
//     },
// ]

export const AttributesItem = [
    {
      key: 21,
      name: 'Create'
    },
    {
      key: 22,
      name: 'Edit'
    },
    {
      key: 23,
      name: 'Archive'
    },
    {
      key: 24,
      name: 'Un archive'
    },
]
export const CalenderItem = [
    {
      key: 31,
      name: 'Create'
    },
    {
      key: 32,
      name: 'Edit'
    },
    {
      key: 33,
      name: 'Archive'
    },
    {
      key: 34,
      name: 'Un archive'
    },
]
export const CommandItem = [
    {
      key: 41,
      name: 'Create'
    },
    {
      key: 42,
      name: 'Edit'
    },
    {
      key: 43,
      name: 'Archive'
    },
    {
      key: 44,
      name: 'Un archive'
    },
    {
      key: 45,
      name: 'Send'
    }
]
export const CouponItem = [
    {
      key: 51,
      name: 'Create'
    },
    {
      key: 52,
      name: 'Edit'
    },
    {
      key: 53,
      name: 'Archive'
    },
    {
      key: 54,
      name: 'Un archive'
    },
]
export const DashboardItem = [
    {
      key: 61,
      name: 'Create'
    },
    {
      key: 62,
      name: 'Edit'
    },
    {
      key: 63,
      name: 'Archive'
    },
    {
      key: 64,
      name: 'Un archive'
    },
]
export const DevicesItem = [
    {
      key: 71,
      name: 'Create'
    },
    {
      key: 72,
      name: 'Edit'
    },
    {
      key: 73,
      name: 'Archive'
    },
    {
      key: 74,
      name: 'Unarchive'
    },
    {
      key: 75,
      name: 'Migrate'
    },
    {
      key: 76,
      name: 'Export'
    },
    {
      key: 77,
      name: 'Import'
    },
    {
      key: 78,
      name: 'Device hours'
    },
    {
      key: 79,
      name: 'Dstance edit'
    },
    {
      key: 80,
      name: 'Name edit'
    },
    {
      key: 81,
      name: 'IMEI edit'
    },
    {
      key: 82,
      name: 'Model edit'
    },
    {
      key: 83,
      name: 'First position received'
    },
]
export const DriverItem = [
    {
      key: 91,
      name: 'Create'
    },
    {
      key: 92,
      name: 'Edit'
    },
    {
      key: 93,
      name: 'Archive'
    },
    {
      key: 94,
      name: 'Un archive'
    },
    {
      key: 95,
      name: 'Export'
    },
    {
      key: 96,
      name: 'Import'
    },
]
export const EcoDrivingItem = [
    {
      key: 101,
      name: 'Create'
    },
    {
      key: 102,
      name: 'Edit'
    },
    {
      key: 103,
      name: 'Archive'
    },
    {
      key: 104,
      name: 'Un archive'
    },
]
export const EventsItem = [
    {
      key: 111,
      name: 'Create'
    },
    {
      key: 112,
      name: 'Edit'
    },
    {
      key: 113,
      name: 'Archive'
    },
    {
      key: 114,
      name: 'Un archive'
    },
]
export const GeoFenceItem = [
    {
      key: 121,
      name: 'Create'
    },
    {
      key: 122,
      name: 'Edit'
    },
    {
      key: 123,
      name: 'Archive'
    },
    {
      key: 124,
      name: 'Un archive'
    },
    {
      key: 125,
      name: 'Export'
    },
    {
      key: 126,
      name: 'Import'
    }
]
export const ResourcesItem = [
    {
      key: 131,
      name: 'Create'
    },
    {
      key: 132,
      name: 'Edit'
    },
    {
      key: 133,
      name: 'Archive'
    },
    {
      key: 134,
      name: 'Un archive'
    },
]
export const ItemGroups = [
    {
      key: 141,
      name: 'Create'
    },
    {
      key: 142,
      name: 'Edit'
    },
    {
      key: 143,
      name: 'Archive'
    },
    {
      key: 144,
      name: 'Un archive'
    },
]
export const JobsItem = [
    {
      key: 151,
      name: 'Create'
    },
    {
      key: 152,
      name: 'Edit'
    },
    {
      key: 153,
      name: 'Archive'
    },
    {
      key: 154,
      name: 'Un archive'
    },
]
export const MaintenanceItem = [
    {
      key: 161,
      name: 'Create'
    },
    {
      key: 162,
      name: 'Edit'
    },
    {
      key: 163,
      name: 'Archive'
    },
    {
      key: 164,
      name: 'Un archive'
    },
]
export const NotificationItem = [
    {
      key: 171,
      name: 'Create'
    },
    {
      key: 172,
      name: 'Edit'
    },
    {
      key: 173,
      name: 'Archive'
    },
    {
      key: 174,
      name: 'Un archive'
    },
]
export const OrdersItem = [
    {
      key: 181,
      name: 'Create'
    },
    {
      key: 182,
      name: 'Edit'
    },
    {
      key: 183,
      name: 'Archive'
    },
    {
      key: 184,
      name: 'Un archive'
    },
]
export const PasswordsItem = [
    {
      key: 195,
      name: 'Reset'
    }
]
export const POIItem = [
    {
      key: 201,
      name: 'Create'
    },
    {
      key: 202,
      name: 'Edit'
    },
    {
      key: 203,
      name: 'Archive'
    },
    {
      key: 204,
      name: 'Un archive'
    }
]
export const PositionItem = [
    {
      key: 211,
      name: 'Export'
    }
]
export const GarageItem = [
  {
    key: 501,
    name: 'Create'
  },
  {
    key: 502,
    name: 'Edit'
  },
  {
    key: 503,
    name: 'Archive'
  },
  {
    key: 504,
    name: 'Un archive'
  },
]
export const AreaItem = [
  {
    key: 551,
    name: 'Create'
  },
  {
    key: 552,
    name: 'Edit'
  },
  {
    key: 553,
    name: 'Archive'
  },
  {
    key: 554,
    name: 'Un archive'
  },
]
// export const ReportsItem = [
//     {
//       key: 216,
//       name: 'Create'
//     },
//     {
//       key: 217,
//       name: 'Edit'
//     },
//     {
//       key: 218,
//       name: 'Archive'
//     },
//     {
//       key: 219,
//       name: 'Un archive'
//     },
//     {
//       key: 220,
//       name: 'Export'
//     }
// ]

export const RolesItem = [
    {
      key: 231,
      name: 'Create'
    },
    {
      key: 232,
      name: 'Edit'
    },
    {
      key: 233,
      name: 'Archive'
    },
    {
      key: 234,
      name: 'Un archive'
    }
]
export const ReportTemplatesItem = [
    {
      key: 241,
      name: 'Create'
    },
    {
      key: 242,
      name: 'Edit'
    },
    {
      key: 243,
      name: 'Archive'
    },
    {
      key: 244,
      name: 'Un archive'
    }
]
export const SensorsItem = [
    {
      key: 251,
      name: 'Create'
    },
    {
      key: 252,
      name: 'Edit'
    },
    {
      key: 253,
      name: 'Archive'
    },
    {
      key: 254,
      name: 'Un archive'
    }
]
export const ServicesItem = [
    {
      key: 261,
      name: 'Create'
    },
    {
      key: 262,
      name: 'Edit'
    },
    {
      key: 263,
      name: 'Archive'
    },
    {
      key: 264,
      name: 'Un archive'
    },
    {
      key: 265,
      name: 'Import'
    },
    {
      key: 266,
      name: 'Export'
    }
]
export const SessionsItem = [
    {
      key: 271,
      name: 'Login'
    },
    {
      key: 272,
      name: 'Logout'
    }
]
export const TrailersItem = [
    {
      key: 281,
      name: 'Create'
    },
    {
      key: 282,
      name: 'Edit'
    },
    {
      key: 283,
      name: 'Archive'
    },
    {
      key: 284,
      name: 'Un archive'
    },

    {
      key: 285,
      name: 'Import'
    },
    {
      key: 286,
      name: 'Export'
    },
]
export const UsersItem = [
    {
      key: 291,
      name: 'Create'
    },
    {
      key: 292,
      name: 'Edit'
    },
    {
      key: 293,
      name: 'Archive'
    },
    {
      key: 294,
      name: 'Un archive'
    },
    {
      key: 295,
      name: 'Disable'
    },

    {
      key: 296,
      name: 'Enable'
    },
    {
      key: 297,
      name: 'Export'
    },
]
export const VehiclesItem = [
  {
    key: 311,
    name: 'Create'
  },
  {
    key: 312,
    name: 'Edit'
  },
  {
    key: 313,
    name: 'Archive'
  },
  {
    key: 314,
    name: 'Un archive'
  },
  {
    key: 315,
    name: 'Import'
  },
  {
    key: 316,
    name: 'Export'
  },
]

export const LinkUnlinkItems = [
  {
    key: 321,
    name: 'Device Link Attribute'
  },
  {
    key: 322,
    name: 'Device UnLink Attribute'
  },
  {
    key: 324,
    name: 'Device Link Command'
  },
  {
    key: 324,
    name: 'Device UnLink Command'
  },
  {
    key: 325,
    name: 'Device Link Maintenance'
  },
  {
    key: 326,
    name: 'DDevice UnLink Maintenance'
  },
  {
    key: 327,
    name: 'Device Link Notification'
  },
  {
    key: 328,
    name: 'Device UnLink Notification'
  },
  {
    key: 329,
    name: 'Group Link Attribute'
  },
  {
    key: 330,
    name: 'Group UnLink Attribute'
  },
  {
    key: 331,
    name: 'Group Link Command'
  },
  {
    key: 332,
    name: 'Group UnLink Command'
  },
  {
    key: 333,
    name: 'Group Link Driver'
  },
  {
    key: 334,
    name: 'Group UnLink Driver'
  },
  {
    key: 335,
    name: 'Group Link GeoFence'
  },
  {
    key: 336,
    name: 'Group UnLink GeoFence'
  },
  {
    key: 337,
    name: 'Group Link Maintenance'
  },
  {
    key: 338,
    name: 'Group UnLink Maintenance'
  },
  {
    key: 339,
    name: 'Group Link Device'
  },
  {
    key: 340,
    name: 'Group UnLink Device'
  },
  {
    key: 341,
    name: 'User Link Attribute'
  },
  {
    key: 342,
    name: 'User UnLink Attribute'
  },
  {
    key: 343,
    name: 'User Link Calendar'
  },
  {
    key: 344,
    name: 'User UnLink Calendar'
  },
  {
    key: 345,
    name: 'User Link Command'
  },
  {
    key: 346,
    name: 'User UnLink Command'
  },
  {
    key: 347,
    name: 'User Link Device'
  },
  {
    key: 348,
    name: 'User UnLink Device'
  },
  {
    key: 349,
    name: 'User Link Driver'
  },
  {
    key: 350,
    name: 'User UnLink Driver'
  },
  {
    key: 351,
    name: 'User Link Geofence'
  },
  {
    key: 352,
    name: 'User UnLink Geofence'
  },
  {
    key: 353,
    name: 'User Link Group'
  },
  {
    key: 354,
    name: 'User UnLink Group'
  },
  {
    key: 355,
    name: 'User Link Maintenance'
  },
  {
    key: 356,
    name: 'User UnLink Maintenance'
  },
  {
    key: 357,
    name: 'User Link Notification'
  },
  {
    key: 358,
    name: 'User UnLink Notification'
  },
  {
    key: 359,
    name: 'User Link Manage User'
  },
  {
    key: 360,
    name: 'User UnLink Manage User'
  },
  {
    key: 361,
    name: 'User Link Role'
  },
  {
    key: 362,
    name: 'User UnLink Role'
  },
  {
    key: 363,
    name: 'Role Link Privilege'
  },
  {
    key: 364,
    name: 'Role UnLink Privilege'
  },
  {
    key: 365,
    name: 'User Link POI'
  },
  {
    key: 366,
    name: 'User UnLink POI'
  },
  {
    key: 367,
    name: 'User Link Report Template'
  },
  {
    key: 368,
    name: 'User UnLink Report Template'
  },
  {
    key: 369,
    name: 'User Link Report'
  },
  {
    key: 370,
    name: 'User UnLink Report'
  },
  {
    key: 371,
    name: 'User Link Vehicle'
  },
  {
    key: 372,
    name: 'User UnLink Vehicle'
  },
  {
    key: 373,
    name: 'User Link Trailer'
  },
  {
    key: 374,
    name: 'User UnLink Trailer'
  },
  {
    key: 375,
    name: 'Group Link Trailer'
  },
  {
    key: 376,
    name: 'Group UnLink Trailer'
  },
  {
    key: 377,
    name: 'ItemGroup Link Item'
  },
  {
    key: 378,
    name: 'ItemGroup UnLink Item'
  },
  {
    key: 379,
    name: 'Group Link Vehicle'
  },
  {
    key: 380,
    name: 'Group UnLink Vehicle'
  },
  {
    key: 381,
    name: 'User Link gadget'
  },
  {
    key: 382,
    name: 'User UnLink gadget'
  },
  {
    key: 383,
    name: 'User Link Dashboard'
  },
  {
    key: 384,
    name: 'User UnLink Dashboard'
  },
  {
    key: 385,
    name: 'Vehicle Link Device'
  },
  {
    key: 386,
    name: 'Vehicle UnLink Device'
  },
  {
    key: 387,
    name: 'Driver Link Device'
  },
  {
    key: 388,
    name: 'Driver UnLink Device'
  },
  {
    key:389,
    name:'User Link Garage'
  },
  {
    key:390,
    name:"User UnLink Garage"
  },
  {
    key:391,
    name:"User Link Area"
  },
  {
    key:392,
    name:"User UnLink Area"
  },
  {
    key:393,
    name:"User Link Expense Type"
  },
  {
    key:394,
    name:"User UnLink Expense Type"
  },
  {
    key:395,
    name:"Group Link Garage"
  },
  {
    key:396,
    name:"Group UnLink Garage"
  },
  {
    key:397,
    name:"Group Link Area"
  },
  {
    key:398,
    name:"Group UnLink Area"
  },
  {
    key:399,
    name:"Group Link Expense Type"
  },
  {
    key:400,
    name:"Group UnLink Expense Type"
  },
]
export const ExpenseTypeItem = [
  {
    key: 526,
    name: 'Create'
  },
  {
    key: 527,
    name: 'Edit'
  },
  {
    key: 528,
    name: 'Archive'
  },
  {
    key: 529,
    name: 'Un archive'
  },
]
export const ExpenseSubTypeItem = [
  {
    key: 541,
    name: 'Create'
  },
  {
    key: 542,
    name: 'Edit'
  },
  {
    key: 543,
    name: 'Archive'
  },
  {
    key: 545,
    name: 'Label Change'
  },
  {
    key: 546,
    name: 'subtype num change'
  },
  {
    key: 547,
    name: 'unit price change'
  },
]
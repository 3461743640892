import React, { Component } from 'react'
import { logout } from './../../Actions/Users'
import { setCookie, getCookie } from '../../Helpers'
import { connect } from 'react-redux'
import Loader from './../../Layout/Loader'
import { batch } from 'react-redux'
import { removeTab1, removeTab10, removeTab11, removeTab12, removeTab13, removeTab14,removeTab18, removeTab17, removeTab19, removeTab15, removeTab2, removeTab3, removeTab4, removeTab5, removeTab6, removeTab7, removeTab8, removeTab9 } from '../../Actions/MaintDashboard'
import withRoutes from '../../HOC/HocRoutes'
class logoutPage extends Component {
  constructor (props) {
    super(props)
    this.state = { loading: false }
  }

  componentWillMount () {
    this.setState({
      loading: true
    })
    if (getCookie('JSESSIONID')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        res.json().then(response=>{
          if (response.status === 'success') {
               this.props.navigate('/login')
            localStorage.setItem('privileges', '')
            localStorage.setItem('userInfo', '')
            localStorage.setItem('adminToken', '')
            this.props.dispatch(logout())
            setCookie('JSESSIONID', 0)
            this.setState({
              loading: false
            })
          } 
          else if(response.statusCode) {
                  this.props.navigate('/login')
            localStorage.setItem('privileges', '')
            localStorage.setItem('userInfo', '')
            localStorage.setItem('adminToken', '')
            this.props.dispatch(logout())
            setCookie('JSESSIONID', 0)
            this.setState({
              loading: false
            })
            }
        })
        }).catch(e => {
                this.props.navigate('/login')
          localStorage.setItem('privileges', '')
          localStorage.setItem('userInfo', '')
          localStorage.setItem('adminToken', '')
          this.props.dispatch(logout())
          setCookie('JSESSIONID', 0)
          this.setState({
            loading: false
          })
        })
    } else {
      this.setState({
        loading: false
      })
            this.props.navigate('/login')
      localStorage.setItem('privileges', '')
      localStorage.setItem('userInfo', '')
      localStorage.setItem('adminToken', '')
    }
    batch(() => {
      this.props.dispatch(removeTab1({}))
      this.props.dispatch(removeTab2({}))
      this.props.dispatch(removeTab3({}))
      this.props.dispatch(removeTab4({}))
      this.props.dispatch(removeTab5({}))
      this.props.dispatch(removeTab6({}))
      this.props.dispatch(removeTab7({}))
      this.props.dispatch(removeTab8({}))
      this.props.dispatch(removeTab9({}))
      this.props.dispatch(removeTab10({}))
      this.props.dispatch(removeTab11({}))
      this.props.dispatch(removeTab12({}))
      this.props.dispatch(removeTab13({}))
      this.props.dispatch(removeTab14({}))
      this.props.dispatch(removeTab15({}))
      this.props.dispatch(removeTab18({}))
      this.props.dispatch(removeTab17({}))
      this.props.dispatch(removeTab19({}))

      
    })
  }
  render () {
    if (this.state.loading) {
      return <Loader />
    } else {
      return null
    }
  }
}
const mapState = state => {
  return {}
}
const mapStateToProps = connect(mapState)

export const LogOut = mapStateToProps(withRoutes(logoutPage))

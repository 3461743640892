import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { AccountModal } from '../../Components/Accounts'
import { checkPrivileges } from '../../Helpers'
import withRoutes from '../../HOC/HocRoutes';

class Account extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sessionCallTrack: true
    }
  }
  componentWillMount () {
    // this.props.dispatch(toast.removeAll())
    // if (
    //   localStorage.getItem('adminToken')
    // ) {
    //   window.location.replace('/login')
    // }
  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(response => {
        response.json().then(res => {
          if(res.status ==='success'){   
               this.props.navigate('/logout')
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
        })
      })
         
        
      .catch(e => {
        toast.error(
         'somethingWentWrong')
      })
    }
  }

  render () {
    // console.log('this.props =', this.props.logInUser.userType)
    
      if (this.props.logInUser) {
        if (checkPrivileges('account')) {
          if (
            this.props.logInUser.userType !== 3   
          ) {
            return (
              <Layout {...this.props}>
               <AccountModal {...this.props} translate={this.props.translate} />
              </Layout>
            )
          } else {
            this.props.navigate('/users')
            return null
          }
        } else {
          if (this.state.sessionCallTrack) {
            this.setState(
              {
                sessionCallTrack: false
              },
              () => {
                fetch('/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05', {
                  headers: { Accept: 'application/json; odata=verbose' }
                }).then(response => {
                  if (response.ok) {
                    response.json().then(res => {
                      if(res.status ==='success'){   
                        let userInfo = res.data
                        this.props.setActiveLanguage(
                          userInfo?.attributes?.lang || 'en'
                        )
                        //API privilege call
                        if(userInfo?.roleId){
                          fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                            method: 'Get'
                          })
                            .then(response => {
                              if (response.ok) {
                                response.json().then(res3 => {
                                  if(res3.status ==='success'){
                                    let privileges = res3.data
                                    let privilegeKeys = []
                                    privileges.map(item => {
                                      privilegeKeys.push(item.key)
                                    })
                                    localStorage.setItem(
                                      'privileges',
                                      JSON.stringify({ ...privilegeKeys })
                                    )
                                    if (privileges.length) {
                                      localStorage.setItem('userToken', '')
                                      this.props.navigate('/accounts')
                                    } else {
                                      this.setState({
                                        loading: false,
                                        loginErrorMessage: 'lackOfPrivileges'
                                      })
                                    }
                                  }
                                  else if(res3?.statusCode === '440'){
                                    window.location.replace('/login')
                                  }
                                  else if(res3.statusCode){
                                    var err = res3?.message.split(':')
                                    err[1] =err[1].replace(')', "")
                                    toast.error(
                                      this.props.translate(err[1])
                                   )
                                      this.props.navigate("/login");
                                  }
                                })
                              } else {
                                this.props.navigate("/login");
                                throw response
                              }
                            })
                            .catch(e => {
                              console.log('e =',e)
                            })
                        }
                        //Privilege call ends
                        this.props.dispatch(logInUserInfo(userInfo))
                      }
                      else if(res?.statusCode === '440'){
                        window.location.replace('/login')
                      }
                      else if(res.statusCode){
                        var err = res?.message.split(':')
                        err[1] =err[1].replace(')', "")
                        toast.error(
                          this.props.translate(err[1])
                        )
                           this.props.navigate('/logout')
                      }
                    })
                  } else {
                    this.props.history.push('/logout')
                    throw response
                  }
                })
                .catch(e => {
                  toast.error(
                    'somethingWentWrong'
                  )
                })
              }
            )
          }
          return null
        }
        
      } else {
        if (this.state.sessionCallTrack) {
          this.setState(
            {
              sessionCallTrack: false
            },
            () => {
              fetch('/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05', {
                headers: { Accept: 'application/json; odata=verbose' }
              }).then(response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){   
                      let userInfo = res.data
                      this.props.setActiveLanguage(
                        userInfo?.attributes?.lang || 'en'
                      )
                      //API privilege call
                      if(userInfo?.roleId){
                        fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                          method: 'Get'
                        })
                          .then(response => {
                            if (response.ok) {
                              response.json().then(res3 => {
                                if(res3.status ==='success'){
                                  let privileges = res3.data
                                  let privilegeKeys = []
                                  privileges.map(item => {
                                    privilegeKeys.push(item.key)
                                  })
                                  localStorage.setItem(
                                    'privileges',
                                    JSON.stringify({ ...privilegeKeys })
                                  )
                                  if (privileges.length) {
                                    localStorage.setItem('userToken', '')
                                    this.props.navigate('/accounts')
                                  } else {
                                    this.setState({
                                      loading: false,
                                      loginErrorMessage: 'lackOfPrivileges'
                                    })
                                  }
                                }
                                else if(res3?.statusCode === '440'){
                                  window.location.replace('/login')
                                }
                                else if(res3.statusCode){
                                  var err = res3?.message.split(':')
                                  err[1] =err[1].replace(')', "")
                                  toast.error(
                                    this.props.translate(err[1])
                                 )
                                }
                              })
                            } else {
                              throw response
                            }
                          })
                          .catch(e => {
                            console.log('e =',e)
                          })
                      }
                      //Privilege call ends
                      this.props.dispatch(logInUserInfo(userInfo))
                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
                      toast.error(
                        this.props.translate(err[1])
                    )
                       this.props.navigate('/login')
                    }
                  })
                } else {
                  this.props.history.push('/login')
                  throw response
                }
              })
              .catch(e => {
                toast.error(
                  'somethingWentWrong')
              })
            }
          )
        }
        return null
      }
  }
}
  const mapStateToProps = state => ({
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers
  })
  export default connect(mapStateToProps)(withRoutes(Account))
  // const defaultPages = ['account', 'group', 'device']
  

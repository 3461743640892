import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import withTranslationWrapper from "../../HOC/HocTranslate";
import { styled } from "@mui/material/styles";
import { themecolors } from "../../Layout/theme";
import moment from "moment";
import "moment-timezone";

const StyledDatePicker = styled(DatePicker)(() => ({
  "& .MuiInputLabel-root": {
    marginTop: "-8px",
    fontSize: "12px",
    color: "#999", // Set default label color to grey
    "&.Mui-focused": {
      color: "#999",
      marginTop: "0",
    },
    "&.Mui-error": {
      color: "#999",
    },
    "&.MuiInputLabel-shrink": {
      marginTop: "0",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#999", // Set default border color to grey
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: themecolors["500"],
    },
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#999",
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "12px",
    padding: "5px 14px",
    minHeight: "18px",
    height: "auto",
    fontWeight: "normal",
    color: "#999",
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "0",
    fontSize: "14px",
  },
  "& .MuiSelect-icon": {
    color: "#999",
  },
  "& .MuiPaper-root": {
    backgroundColor: themecolors["500"],
  },
  "& .MuiFormHelperText-root": {
    fontSize: "12px",
    color: (props) => (props.error ? themecolors["error"] : "#999"),
  },
}));

function CustomDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledDatePicker
        inputFormat={props.format}
        error={props.error}
        margin="dense"
        views={props.views}
        label={props.required ? `${props.label} *` : props.label}
        variant="outlined"
        // fullWidth={props.fullWidth}
        value={moment(props.value)}
        onChange={props.onChange}
        sx={{ width: props.fullWidth ? "100%" : "", marginTop: "8px" }}
        helperText={props.helperText}
        minDate={props.minDate ? moment(props.minDate) : null} // Add minDate
        maxDate={props.maxDate ? moment(props.maxDate) : null} // Add maxDate
        // {...props}
      />
    </LocalizationProvider>
  );
}

export default withTranslationWrapper(CustomDatePicker);

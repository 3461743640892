import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { connect } from 'react-redux'
import Style from 'style-it'
// import UncheckIcon from './../../assets/nav/uncheck-box.svg'
// import CheckIcon from './../../assets/nav/check-box.svg'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors
  }
}
const styles = theme => ({
  root: {
    color: 'inherit',
    margin: 0,
    padding: 0,

    '&$label': {
      color: 'inherit'
    }
  },
  label: { color: 'inherit' }
})

class CheckboxLabels extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checked: props.checked,
      indeterminate: props.indeterminate
    }
  }
  handleChange = name => event => {
    this.setState({ checked: event.target.checked })
    this.props.onChange(event, name)
  }

  componentWillReceiveProps (n) {
    this.setState({ checked: n.checked, indeterminate: n.indeterminate })
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <Style>
          {`
    .theme-input-checkbox {
      color: inherit;
      padding: 2px;
    }
    .theme-input-custom-label:not(:empty) {
      margin-left: 10px;
      font-size: 0.875rem;
    }
    `}
        </Style>
        <FormControlLabel
          control={
            <Checkbox
              disableRipple
              // icon={
              //   <svg stroke='currentColor' width={18} height={18}>
              //     <use xlinkHref={`${UncheckIcon}#icon`} />
              //   </svg>
              // }
              // checkedIcon={
              //   <svg stroke='currentColor' width={18} height={18}>
              //     <use xlinkHref={`${CheckIcon}#icon`} />
              //   </svg>
              // }
              checked={this.state.checked ? true : false}
              indeterminate={this.state.indeterminate ? true : false}
              onChange={this.handleChange(this.props.value || this.props.id)}
              classes={{
                checked: 'theme-input-checkbox',
                root: 'theme-input-checkbox'
              }}
              color='default'
            />
          }
          classes={{
            ...classes,
            label: classes.label + ' theme-input-custom-label'
          }}
          label={this.props.label || ''}
        />
      </>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CheckboxLabels))

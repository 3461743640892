
export const tab1 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB1':
            return action.tab1
        case 'REMOVE_TAB1':
            return {}

    default:
        return state
    }
}
export const tab2 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB2':
  		    return action.tab2
        case 'REMOVE_TAB2':
            return {}
    default:
        return state
    }
}
export const tab3 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB3':
              return action.tab3
        case 'REMOVE_TAB3':
            return {}
    default:
          return state
  }
}
export const tab4 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB4':
              return action.tab4
        case 'REMOVE_TAB4':
            return {}
    default:
          return state
  }
}
export const tab5 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB5':
            return action.tab5
        case 'REMOVE_TAB5':
            return {}
    default:
            return state
  }
}
export const tab6 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB6':
  		    return action.tab6
        case 'REMOVE_TAB6':
            return {}
    default:
          return state
  }
}
export const tab7 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB7':
  		    return action.tab7
        case 'REMOVE_TAB7':
            return {}
    default:
          return state
  }
}
export const tab8 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB8':
  		    return action.tab8
        case 'REMOVE_TAB8':
            return {}
    default:
          return state
  }
}
export const tab9 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB9':
  		    return action.tab9
        case 'REMOVE_TAB9':
            return {}
    default:
          return state
  }
}
export const tab10 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB10':
  		    return action.tab10
        case 'REMOVE_TAB10':
            return {}
    default:
          return state
  }
}

export const tab11 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB11':
  		    return action.tab11
        case 'REMOVE_TAB11':
            return {}
    default:
          return state
  }
}
export const tab12 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB12':
  		    return action.tab12
        case 'REMOVE_TAB12':
         return {}
    default:
          return state
  }
}
export const tab13 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB13':
  		    return action.tab13
        case 'REMOVE_TAB13':
            return {}
    default:
          return state
  }
}
export const tab14 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB14':
  		    return action.tab14
        case 'REMOVE_TAB14':
         return {}
    default:
          return state
  }
}
export const tab15 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB15':
  		    return action.tab15
        case 'REMOVE_TAB15':
            return {}
    default:
          return state
  }
}
export const tab18 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB18':
  		    return action.tab18
        case 'REMOVE_TAB18':
            return {}
    default:
          return state
  }
}

export const tab17 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB17':
  		    return action.tab17
        case 'REMOVE_TAB17':
            return {}
    default:
          return state
  }
}

export const tab19 = (state = [], action) => {
    switch (action.type) {
        case 'GET_TAB19':
  		    return action.tab19
        case 'REMOVE_TAB19':
            return {}
    default:
          return state
  }
}
 
import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import Moment from 'moment'
import {getTrash} from '../../Actions/Trash'
import Table from '../common/tableWithBackEndPagination'
import './usersModal.scss'
import {
  checkPrivileges,
  PaginationConfig,
} from '../../Helpers'
import ConfirmDialoag from '../common/ConfirmDialoag'
import Loader from '../../Layout/Loader'
import Style from 'style-it'
import Button from './../common/Button'
import { Grid, Tooltip } from '@mui/material'
import TextField from '../common/TextField'
import { getTemplates } from '../../Actions/Templates'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let source
class trashModel extends Component {
  constructor () {
    super()
    this.state = {
      ...PaginationConfig,
      selectedItem: '',
      defaultItemsList: false,
      onDeleteConfirmation: false,
      onRestoreConfirmation: false,
      tableData: [],
      pagData: '',
      multiChecked:false,
      hasToCheckAll: false,
      onRestoreForce:false,
      confirmationText: ''
    }
    this.removeItem = this.removeItem.bind(this)
    this.restoreItem = this.restoreItem.bind(this)
    this.handleAllCheckboxes = this.handleAllCheckboxes.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
  }
  componentWillMount () {
    this.getTrashData()
  }
  // componentWillUnmount () {
  //   if (source) {
  //     source.cancel()
  //   }
  //   toast.removeAll()
  // }
  getTrashData = () => {
    let {
      page,
      pageSize,
      itemSearch,
    } = this.state

    if (this.props.logInUser.id) {
      let apiCall = `api/archives/get?page=${page}&limit=${pageSize}&search=${itemSearch}`
      source = axios.CancelToken.source()
      axios
        .get(apiCall, {
          cancelToken: source.token
        })
        .then(response => {
          let res = response.data
          if (res.status === 'success') {
            if (res.data.data) {
              let udata = res.data.data
               this.props.dispatch(getTrash(udata))
              let items = udata.map(u => {
                if (u.timeStamp) {
                  return {
                    ...u,
                    timeStamp: this.props.logInUser && this.props.logInUser.twelveHourFormat
                      ? Moment(u.timeStamp).format('YYYY-MM-DD hh:mm A')
                      : Moment(u.timeStamp).format('YYYY-MM-DD HH:mm')
                  }
                } else {
                  return { ...u }
                }
              })
              let currentDateTime = Date()
              let current =Moment(currentDateTime).format('YYYY-MM-DD hh:mm A')
              const settingExpire = items.map((data)=>{
              const duration = Moment.duration((Moment(current)).diff(data.timeStamp));
              let diff = 30 - duration._data.days
               return {...data, expire:  'Auto delete in ' + diff + ' days', checked:false }
              })
              this.setState({
                  tableData:settingExpire,
                  defaultItemsList:true,
                  pagData: res.data
              })
            }
             else {
              this.setState({
                // tableData: [],
                defaultItemsList: true,
                pagData: ''
              })
            }

          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
           
              toast.error(
               this.props.translate(err[1])
            )
            window.location.replace('/login')
          }
          
        })
        .catch(e => {
          // if (axios.isCancel(e)) {
           console.log('Request canceled = ', e);
          // }
        })
    }
  }
  
  removeItem (selected) {
    if (selected && selected.length) {
      this.setState({
        onDeleteConfirmation: true,
        selectedItem: selected[0],
      })
    } else {
      this.setState({
        onDeleteConfirmation: true,
        selectedItem: selected,
      })
    }
  }
  restoreItem (selected) {
    if (selected && selected.length) {
      this.setState({
        onRestoreConfirmation: true,
        selectedItem: selected[0],
      })
    } else {
      this.setState({
        onRestoreConfirmation: true,
        selectedItem: selected,
      })
    }
  }
    deleteItem = () => {
      let { selectedItem } = this.state
      if (this.state.multiChecked){
       fetch(`api/archives/deleteall`, {
          method:'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then(response =>{
          if(response.status === 200){
            if(response.ok){
              response.json().then(res => {
                if(res.status ==='success'){
                  this.setState({
                    multiChecked:false
                  })
                      this.getTrashData()
                     
                      toast.success(
                        this.props.translate('allDeletedSuccessfully')
                    )
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
              }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
                
                    toast.error(
                      this.props.translate(err[1])
                  )
                }
              })
            }
          }
      else{
        
          toast.error(
           'some thing went wrong',
        )
      }   
    })
    .catch(e => {
      toast.error(
     'somethingWentWrong')
    })
   }
   else {
    if (selectedItem && selectedItem.id) {
      fetch(`api/archives/delete/${selectedItem.id}`, {
        method:'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(response =>{
        // if(response.status === 200){
          if(response.ok){
            response.json().then(user => {
              if(user.status ==='success'){
                this.getTrashData()
             
                toast.success(
                   this.props.translate('deleteSuccessfully')
              )
              }
              else if(user?.statusCode === '440'){
                window.location.replace('/login')
            }
              else if(user.statusCode){
                var err = user?.message.split(':')
                err[1] =err[1].replace(')', "")
             
                  toast.error(
                    this.props.translate(err[1])
                )
              }
            })
          } 
      })
      .catch(e => {
        toast.error(
         'somethingWentWrong')
      })

    }
   }
    this.setState({
      onDeleteConfirmation: false,
    })
  }
// restoring item
restoreSelectedItem = (isForceRestore ) => {
  let { selectedItem } = this.state
  if (selectedItem && selectedItem.id) {

      axios({
          method: 'PUT',
          url: `api/archives/unarchive/${selectedItem.id}?forceRestore=${isForceRestore}`,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
        })
      .then(response => {
          if (response.data&&response.data.status==='success') {
             
                  toast.success(
                    this.props.translate('restoreSuccessfully')
              )
              this.getTrashData()     
            }else if(response.data&&response.data.statusCode === '422'){
              this.setState({
                  onRestoreForce: true,
                  confirmationText: `Not a ${selectedItem.itemType} creator ? ${selectedItem.itemType} parent user not found. Please activate ${selectedItem.itemType} parent user first OR click 'RESTORE UNDER MY ACCOUNT' button to restore ${selectedItem.itemType} under your account.`
              });
           }else{
              let array = response.data&&response.data.message.split("HINT:")
              
                  toast.error(
                     this.props.translate(array[1].replace(")", ""))
              )
          }
          })
          .catch((error)=>{
              if(error.response){
                  var array = error.response&&error.response.data.split("-")
                    if(error.response&&error.response.data.includes(array[1])){
                        
                            toast.error(
                             this.props.translate(array[0])
                          )
                    }
                    else {
                       
                          toast.error(
                            this.props.translate('somthingWentWrongMessage')
                        )
                    }
              }
                 else if(error.request){
                  console.log('error.request =', error.request)
                  }
                  else {
                    console.log('error.message =',error.message)
              }
          })
      this.setState({
          // selectedItem: '',
          onRestoreConfirmation: false,
          defaultItemsList: true
      })
  }

      if(selectedItem && selectedItem.itemType ==='RpTmpl'){
          let query = 'all=true&userId=' + this.props.logInUser.id
          this.setState({ templatesCall: true }, () => {
            fetch('/api/report/templates?' + query).then(response => {
              if (response.ok) {
                response.json().then(templates => {
                  if (templates.status === 'success') {
                    this.props.dispatch(getTemplates(templates.data))
                  }
                  else if(templates?.statusCode === '440'){
                    window.location.replace('/login')
                }
                  else if(templates.statusCode){
                    var err = templates?.message.split(':')
                    err[1] =err[1].replace(')', "")
                 
                      toast.error(
                        this.props.translate(err[1])
                    )
                  }
                })
              }
              else{
                throw response
              }
            }).catch(e => {
           toast.error(
                'somethingWentWrong'
             )
            })
          })
      }
      else{
        return null
      }
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      onRestoreConfirmation: false,
      onRestoreForce:false
    })
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pageSize: value
      },
      () => this.getTrashData()
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        //tableData: [],
        page: value
        //defaultItemsList: false
      },
      () => this.getTrashData()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        page: 1,
        itemSearch: searchValue
      },
      () => this.getTrashData()
    )
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  setPagination = user => {
    if (this.state.pagData) {
      let nUser = { ...user }
      let lastPage = nUser.total / this.state.pageSize
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pageSize = this.state.pageSize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }
  // multihandleChange = e => {
  //    console.log('multi change =',this.state.tableData)
  //   this.setState({
  //     multiChecked:e.target.checked
  //   })
  // }
  // singlehandleChange = e => {
  //   console.log('esingle =', e)
  // }
  handleAllCheckboxes = e => {
    const hasToCheckAll = !this.state.hasToCheckAll;
    const updatedArray = this.state.tableData.map((item) => {
        item.checked = hasToCheckAll;
        return item;
    });

    this.setState({
        ...this.state,
        tableData: updatedArray,
        hasToCheckAll: e.target.checked,
        multiChecked: true,
    });
};

handleCheckboxClick = (clickedItem) => {
  // console.log('clickedItem =', clickedItem)
    const updatedArray = this.state.tableData.map((item) => {
        item.checked = item.id === clickedItem.id ? !item.checked : item.checked;
        return item;
    });
    this.setState({
        ...this.state,
        tableData: updatedArray,
    });
};
  render () {
    return (
      <div style={{ position: 'relative' }}>
        {this.state.defaultItemsList ? (<>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={3}>
                <h3 style={{margin: 0}}>{this.props.translate('trash')}</h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                <div style={{width: "25%"}}>
                  <TextField
                    fullWidth
                    label={this.props.translate('search')}
                    onChange={e => this.SearchItem(e.target.value)}
                  />
                </div>
            </Grid>
          </Grid>
           <Table
              rows={this.state.tableData || []}
              pagination={this.setPagination(this.state.pagData)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              SearchItem={this.SearchItem}
              onDelete={this.removeItem}
              onRestore={this.restoreItem}
              restore
              handleCheckboxClick = {(e) => this.handleCheckboxClick(e)}
              handleAllCheckboxes = {(e) => this.handleAllCheckboxes(e)}
              searchSelectedId={this.state && this.state.searchSelectedId}
              // searchSelectedId={this.state.searchSelectedId}
              themecolors={this.props.themecolors}
              ServerSetting={this.props.ServerSetting}
              logInUser={this.props.logInUser}
              isRowIcons={false}
              isCursoPointer
              setSerialNo
              isEditable
              hasAccessOfDelete={checkPrivileges('userDelete')}
              rowDefinition={rowDefinitionR(this.props.translate)}
          /></>
        ) : (
           <Loader />
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteItem}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.selectedItem.label}
          />
        )}
          {this.state.onRestoreConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={e => this.restoreSelectedItem(false)}
            title={this.props.translate('areYouWantToRestore')}
            children={this.state.selectedItem.label}
          />
        )}
        {this.state.onRestoreForce && (
            <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={e => this.restoreSelectedItem(true)}
                title={this.props.translate(this.state.confirmationText)}
                OkText={this.props.translate('restoreForcefully')}
                // children={this.state.selectedItem.label}
              />
        )}
      </div>
    )
  }
}

const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    trash: state.trash,
    ServerSetting:state.ServerSetting
  }
}
const mapStateToProps = connect(mapState)
export const ShowTrash = mapStateToProps((trashModel))


const rowDefinitionR = translate => {
  if (localStorage.getItem('adminToken')) {
    return [
      {
        id: 'label',
        numeric: false,
        disablePadding: false,
        label: translate('description')
      },
      {
        id: 'itemType',
        numeric: false,
        disablePadding: false,
        label: translate('type')
      },
      {
        id: 'timeStamp',
        numeric: false,
        disablePadding: false,
        label: translate('deleted')
      },
      {
        id: 'expire',
        numeric: false,
        disablePadding: false,
        label: translate('expiration')
      }
    ]
  } else {
    return [
      {
        id: 'label',
        numeric: false,
        disablePadding: false,
        label: translate('description')
      },
      {
        id: 'itemType',
        numeric: false,
        disablePadding: false,
        label: translate('type')
      },
      {
        id: 'timeStamp',
        numeric: false,
        disablePadding: false,
        label: translate('deleted')
      },
      {
        id: 'expire',
        numeric: false,
        disablePadding: false,
        label: translate('expiration')
      }
    ]
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import { WorkshopsList } from "../../Components/WorkShops/index";
import axios from "axios";
import withRoutes from "../../HOC/HocRoutes";

const formDefault = {
  id: "",
  name: "",
  description: "",
  garageNumber: "",
  garageArea: "",
  garageManager: "",
  email: "",
  phone: "",
  address: "",
  garageType: "",
  latitude: "",
  longitude: "",
};

const makeGrageData = (data) => ({
  ...data,
  garageType: data.garageType.id || data.garageType,
});

let source;
class Workshops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userFetch: false,
      addOption: false,
      editOption: false,
      garagesData: "",
      form: { ...formDefault },
      isVisableAddBtn: false,
      selectedWorkShop: "",
      onDeleteConfirmation: false,
      page: 1,
      pageSize: 20,
      searchGarage: "",
      loader1: false,
      garageCity:"",
      garageType:"",
      isVisableFilterBtn:false,
      enableReset:false,

    };
    this.getWorkshops = this.getWorkshops.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.workShopSelection = this.workShopSelection.bind(this);
    this.updateWorkshops = this.updateWorkshops.bind(this);
    this.deleteWorkShop = this.deleteWorkShop.bind(this);
    this.removeEnable = this.removeEnable.bind(this);
  }

  modalControle = () => {
    this.setState({
      addOption: false,
      editOption: false,
      form: "",
    });
  };

  addGarages = () => {
    this.setState({
      addOption: true,
    });
  };

  handleChange1 = (name, event) => {
    // if(name==="garageType"){
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: event,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange = (name) => (event) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: event.target.value,
        },
      },
      () => this.checkRequiredFields()
    );
  };

  checkRequiredFields() {
    let { name, garageType,garageNumber } = this.state.form;

    if (name && garageType && garageNumber) {
      this.setState({
        isVisableAddBtn: true,
      });
    } else {
      this.setState({
        isVisableAddBtn: false,
      });
    }
  }


 


  handleSubmit = async () => {
       let { form } = this.state;
        const obj = makeGrageData(form);
       let result;


    this.setState({
       addOption: false,
      editOption: false,
      isVisableAddBtn:false, }, async () => {
       result = await axios.post(`/api/garages`, obj);
       if (result.data.status === "success") {
        this.setState({
          searchGarage: "",
          // editOption: false,
          form:""
        });

          toast.success(
            this.props.translate("workShopAdded")
        );
        this.getWorkshops();
      } else {
        this.setState({
          searchGarage: "",
          // editOption: false,
          form:""
        }, this.getWorkshops());
      
          toast.error(
             this.props.translate(result.data.message)
        );
      }
    });
    };

    checkSearchFields() {
      let { garageCity, garageType } = this.state;
  
      if (garageCity ||  garageType) {
        this.setState({
          isVisableFilterBtn: true,
        });
      } else {
        this.setState({
          isVisableFilterBtn: false,
        });
      }
    }

  searchContractText =  (e, name) => {
    source.cancel();
    if (name === "garageCity" || name === "garageType") {
      this.setState({ [name]: e.target.value }, () => {
        this.checkSearchFields();
      });
    }
    else{
      this.setState(
        {
          searchGarage: e.target.value,
        },
        () => {
          this.getWorkshops();
        }
      );
    }
  };

  getWorkshops = () => {
    let { page, pageSize, searchGarage, garageCity, garageType } = this.state;
    let apiUrl = `/api/garages/get?userId=${
      this.props.logInUser?.id
    }&page=${page}&limit=${pageSize}&all=${true}&search=${searchGarage}&garageCity=${garageCity}&garageType=${garageType}`;

    source = axios.CancelToken.source();
    this.setState(
      {
        loader1: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            
            this.setState({
              loader1: false,
              enableReset:this.state.isVisableFilterBtn ?  true : false,
              garagesData: response?.data.data || [],
            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  };
 
  componentDidMount() {
    this.getWorkshops();
  }

  workShopSelection = (items) => {
    let item = items;
    // let item = items[0];

    if (item) {
      this.setState({
        // defaultDeviceList: false,
        selectedWorkShop: item,
        editOption: true,
        // form: { ...item},
        form: { ...item, garageType: item.garageType },
      });
    }
  };

  updateWorkshops = async () => {
    const { selectedWorkShop, form } = this.state;
    // const obj = { ...form };
    const obj = makeGrageData(form);
    let result = await axios.put(`/api/garages/${selectedWorkShop.id}`, obj);
    if (result.data.status === "success") {
      this.setState({
        addOption: false,
        editOption: false,
        isVisableAddBtn:false
      });
     
        toast.success(
        this.props.translate("WorkShop Updated")
      );
      this.getWorkshops();
    } else {
      
        toast.error(
        this.props.translate(result.data.message)
      );
    }
  };

  deleteWorkShop = async () => {
    const { selectedWorkShop } = this.state;
    let result = await axios.delete(`/api/garages/${selectedWorkShop.id}`);
  

    if (result.data.status === "success") {
      // this.getFilteredPAyments(this.state.to,this.state.from,this.state.account,this.state.vehcile,this.state.area)
      
        toast.success(
          this.props.translate("Workshop Deleted")
      );
        this.setState({
          onDeleteConfirmation:false,
          searchGarage:"",
        }, ()=>this.getWorkshops())
      
    } else {
     
        toast.error(
           this.props.translate(result.data.message)
      );
    }
  };

  removeEnable = async (selected) => {
    if (selected[0]) {
      await this.setState({
        selectedWorkShop: selected[0],
        onDeleteConfirmation: true,
        form: "",
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        form: "",
      });
    }
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  setPagination = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };

  //   getFilteredPAyments
  // getPaymentList
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({page:1, pageSize: rowsPerPage }, () => {
      this.getWorkshops();
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      this.getWorkshops();
    });
  };

  getGarages = () => {
    this.setState({ enableReset: true ,}, () => {
    this.getWorkshops();
    this.checkSearchFields()
  })

 };

 resetGarages = () => {
  this.setState({ garageCity: "", garageType :"" ,  isVisableFilterBtn:false}, () => {
  this.getWorkshops();
})
 }
  render() {
    if (this.props.logInUser) {
      return (
        <Layout {...this.props} noSidebar>
          {/* Workshops */}
          <WorkshopsList
            {...this.props}
            {...this.state}
            getGarages={this.getGarages}
            resetGarages={this.resetGarages}
            searchContractText={this.searchContractText}
            addOption={this.state.addOption}
            modalControle={this.modalControle}
            addGarages={this.addGarages}
            garagesData={this.state.garagesData}
            data={this.state.form}
            handleChange={this.handleChange}
            handleChange1={this.handleChange1}
            isVisableAddBtn={this.state.isVisableAddBtn}
            checkRequiredFields={this.checkRequiredFields}
            handleSubmit={this.handleSubmit}
            editOption={this.state.editOption}
            workShopSelection={this.workShopSelection}
            selectedWorkShop={this.state.selectedWorkShop}
            updateWorkshops={this.updateWorkshops}
            deleteWorkShop={this.deleteWorkShop}
            removeEnable={this.removeEnable}
            onCancel={this.onCancel}
            setPagination={this.setPagination}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Layout>
      );
    } else {
      fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              if (res.status === "success") {
               
                let userInfo = res.data;
                this.props.setActiveLanguage(
                  userInfo?.attributes?.lang || "en"
                );
                this.props.dispatch(logInUserInfo(userInfo));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
              
                  toast.error(
                 this.props.translate(err[1])
                );
                this.props.navigate("/login");
              }
            });
          } else {
            this.props.navigate("/login");
            throw response;
          }
        })
        .catch((e) => {
         
            toast.error(
              "somethingWentWrong"
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(Workshops));

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import { checkPrivileges } from "../../Helpers";
import { ViewAccountDetail } from "../../Components/Accounts/accountDetail/viewAccountDetail";
import withRoutes from "../../HOC/HocRoutes";

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionCallTrack: true,
    };
  }
  componentWillMount() {
        // this.props.dispatch(toast.removeAll());

  }
  controleSession = () => {
    if (localStorage.getItem("userInfo")) {
      fetch("/api/session", {
        method: "DELETE",
      })
        .then((res) => {
          let response = res.data;
          if (response.ok) {
            this.props.navigate("/logout");
          } else {
            throw response;
          }
        })
        .catch((e) => {});
    }
  };

  render() {
    if (checkPrivileges("account")) {
      if(this.props.logInUser){
      if (this.props.logInUser.userType === -1) {
        return (
          <Layout {...this.props}>
            <ViewAccountDetail
              user="owner"
              selectAccountId={
                this.props.logInUser && this.props.logInUser.accountId
              }
              selectItemName={this.props.logInUser && this.props.logInUser.name}
              selectItemId={
                this.props.logInUser && this.props.logInUser.accountId
              }
              selectedAccount={this.props.logInUser}
              getMoreAccounts={this.getMoreAccounts}
              AccountSettings
              onClose={this.onCloseC_Modal}
              roles={this.props.roles}
              value = "maintenance"
              {...this.props} 
            />
          </Layout>
        );
      } else {
        this.props.navigate("/users");
        return null;
      }}
      else {
        fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
          headers: { Accept: "application/json; odata=verbose" },
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  // console.log("Stat====",res);
                  let userInfo = res.data;
                  this.props.setActiveLanguage(
                    userInfo?.attributes?.lang || "en"
                  );
                  this.props.dispatch(logInUserInfo(userInfo));
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
               
                    toast.error(
                      this.props.translate(err[1])
                  );
                  this.props.navigate("/login");
                }
              });
            } else {
              this.props.navigate("/login");
              throw response;
            }
          })
          .catch((e) => {

              toast.error(
               "somethingWentWrong"
            );
          });
        return null;
      }
    }
  }
}
const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  roles: state.roles,
});

export default connect(mapStateToProps)(withRoutes(AccountSettings));

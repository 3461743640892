export const addTab1 = tab1 => ({
    type: 'GET_TAB1',
    tab1
  })
export const removeTab1 = tab1 => ({
    type: 'REMOVE_TAB1',
    tab1
  })
  export const addTab2 = tab2 => ({
    type: 'GET_TAB2',
    tab2
  })
  export const removeTab2 = tab2 => ({
      type: 'REMOVE_TAB2',
      tab2
    })
export const addTab3 = tab3 => ({
    type: 'GET_TAB3',
    tab3
  })
  export const removeTab3 = tab3 => ({
    type: 'REMOVE_TAB3',
    tab3
  })
export const addTab4 = tab4 => ({
    type: 'GET_TAB4',
    tab4
  })
  export const removeTab4 = tab4 => ({
    type: 'REMOVE_TAB4',
    tab4
  })

  export const addTab5 = tab5 => ({
    type: 'GET_TAB5',
    tab5
  })
  export const removeTab5 = tab5 => ({
    type: 'REMOVE_TAB5',
    tab5
  })

export const addTab6 = tab6 => ({
    type: 'GET_TAB6',
    tab6
  })
  export const removeTab6 = tab6 => ({
    type: 'REMOVE_TAB6',
    tab6
  })
export const addTab7 = tab7 => ({
    type: 'GET_TAB7',
    tab7
  })
  export const removeTab7 = tab7 => ({
    type: 'REMOVE_TAB7',
    tab7
  })
export const addTab8 = tab8 => ({
    type: 'GET_TAB8',
    tab8
  })
  export const removeTab8 = tab8 => ({
    type: 'REMOVE_TAB8',
    tab8
  })
export const addTab9 = tab9 => ({
    type: 'GET_TAB9',
    tab9
  })
  export const removeTab9 = tab9 => ({
    type: 'REMOVE_TAB9',
    tab9
  })
export const addTab10 = tab10 => ({
    type: 'GET_TAB10',
    tab10
  })
  export const removeTab10 = tab10 => ({
    type: 'REMOVE_TAB10',
    tab10
  })
export const addTab11 = tab11 => ({
    type: 'GET_TAB11',
    tab11
  })
  export const removeTab11 = tab11 => ({
    type: 'REMOVE_TAB11',
    tab11
  })
export const addTab12 = tab12 => ({
    type: 'GET_TAB12',
    tab12
  })
  export const removeTab12 = tab12 => ({
    type: 'REMOVE_TAB12',
    tab12
  })
export const addTab13 = tab13 => ({
    type: 'GET_TAB13',
    tab13
  })
  export const removeTab13 = tab13 => ({
    type: 'REMOVE_TAB13',
    tab13
  })
export const addTab14 = tab14 => ({
    type: 'GET_TAB14',
    tab14
  })
  export const removeTab14 = tab14 => ({
    type: 'REMOVE_TAB14',
    tab14
  })
export const addTab15 = tab15 => ({
    type: 'GET_TAB15',
    tab15
  })
  export const removeTab15 = tab15 => ({
    type: 'REMOVE_TAB15',
    tab15
  })
  export const addTab18 = tab18 => ({
    type: 'GET_TAB18',
    tab18
  })
  export const removeTab18 = tab18 => ({
    type: 'REMOVE_TAB18',
    tab18
  })
  export const addTab17 = tab17 => ({
    type: 'GET_TAB17',
    tab17
  })
  export const removeTab17 = tab17 => ({
    type: 'REMOVE_TAB17',
    tab17
  })
  export const addTab19 = tab19 => ({
    type: 'GET_TAB19',
    tab19
  })
  export const removeTab19 = tab19 => ({
    type: 'REMOVE_TAB19',
    tab19
  })
 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  margin: 1em;
  display: flex;
  flex-direction: column;
}
.table .header, .table .row {
  display: flex;
}
.table .header div, .table .row div {
  flex: 1 1;
  padding: 0.2rem 0.4em;
  border: 1px solid rgb(238, 238, 238);
}
.table .header {
  background-color: rgb(238, 238, 238);
}
.table .header div {
  cursor: pointer;
}

/*   form{
  height: 514px;
  overflow-y: scroll;
} */`, "",{"version":3,"sources":["webpack://./src/Components/B2C/usersModal.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACE,aAAA;AACN;AACM;EACE,SAAA;EACA,qBAAA;EACA,oCAAA;AACR;AAGI;EACE,oCAAA;AADN;AAEM;EACE,eAAA;AAAR;;AAIA;;;GAAA","sourcesContent":[".table {\r\n    margin: 1em;\r\n    display: flex;\r\n    flex-direction: column;\r\n    \r\n    .header, .row {\r\n      display: flex;\r\n      \r\n      div {\r\n        flex: 1;\r\n        padding: 0.2rem 0.4em;\r\n        border: 1px solid rgb(238, 238, 238);\r\n      }\r\n    }\r\n    \r\n    .header {\r\n      background-color: rgb(238, 238, 238);\r\n      div { \r\n        cursor: pointer;\r\n      }\r\n    }\r\n  }\r\n/*   form{\r\n  height: 514px;\r\n  overflow-y: scroll;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

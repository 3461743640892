import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from 'redux';
import { withStyles } from "@mui/styles";
import Table from "../../../common/TableServices";
import { PaginationConfig } from "../../../../Helpers";
import { Grid} from "@mui/material";
import TextField from "../../../common/TextField";
import Button from "../../../common/Button";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkPrivileges} from "../../../../Helpers";
import { ContractSidebar } from "../../../ContractManagment/ContractSidebar";
import ExportImportMenu from "../../../common/ExportImportMenu";
import ConfirmDialoag from "../../../common/ConfirmDialoag";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
let source;
class ContractManagement extends Component {
  constructor() {
    super();
    this.state = {
      contractsReceived: false,
      selectedSubTypesServices: [],
      expenseTypes: [],
      pagination: { ...PaginationConfig, hasNext: "", pageSize: 10 },
      total: "",
      lastPage: "",
      noRecords: false,
      rowsPerPage: 10,
      pageSize: 10,
      page: 1,
      itemSearch: "",
      selectedExpense: "",
      showSelectedExpense: "",
      createSubType: false,
      selectedServiceTypeId: "",
      openExpenseForm: false,
      selectedSubTypesAllServices: {},
      selectedServices: "",
      serviceForm: {
        // expensetypeId: this.props.selectedServiceTypeId || ''
      },
      onDeleteConfirmation: false,
      deleteSubType: false,
      expenseForm: {},

      mode: "create",
      openCreateExpense: false,
      openExpenseType: false,
      openSubType: false,
      createSubTypeNew: false,
      showServices: false,
      selectedSubType: "",
    };
  }
  componentWillMount() {
    // this.getMoreContracts()
    this.getExpenseTypes();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  editExpenseForm = (item) => {
    this.setState({
      expenseForm: item,
      openExpenseForm: true,
      mode: "update",

      // openTable: true,
      // showServices: true,
      openExpenseType: false,
    });
  };

  openExpenseType = () => {
    this.setState({
      mode: "create",
      openExpenseType: true,
      openExpenseForm: false,

      expenseForm: {
        contractNumber: this.props.selectedAccount?.contractNumber || "",
      },
    });
  };
  getExpenseTypes = () => {
    let api = `api/expensetypes/get?contractNumber=${this.props.selectedAccount.contractNumber}&all=true&page=1&limit=&search=`;
    // let api = `/api/users/get?accountId=${this.props.selectItemId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`
    source = axios.CancelToken.source();
    axios
      .get(api, {
        cancelToken: source.token,
      })
      .then((res) => {
        let response = res.data && res.data.data;
        this.setState({
          expenseTypes: response.data,
          selectedServices: response.data && response.data,
          allServicesList: response.data,
        });
      })
      .catch((e) => {
        // if (axios.isCancel(e)) {
        //   console.log('Request canceled = ', e);
        // }
      });
  };
  // getMoreContracts = () => {
  //   let api = `/api/expensetypes/subtype?expenseTypeId=${this.state.selectedServiceTypeId.id}&search=`;
  //   // let api = `/api/users/get?accountId=${this.props.selectItemId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`
  //   source = axios.CancelToken.source();
  //   axios
  //     .get(api, {
  //       cancelToken: source.token,
  //     })
  //     .then((res) => {
  //       let response = res.data && res.data.data;
  //       this.setState({
  //         selectedSubTypesServices: response,
  //         selectedSubTypesAllServices: response,
  //         contractsReceived: true,
  //       });
  //     })
  //     .catch((e) => {
  //       // if (axios.isCancel(e)) {
  //       //   console.log('Request canceled = ', e);
  //       // }
  //     });
  // };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  handleChangePage = (value) => {
    this.setState({
      page: value,
    });
  };

  searchItem = (e) => {
    let text = e.target.value;
    const u =
      // this.state.allServicesList &&
      this.state.allServicesList &&
      this.state.allServicesList.filter((u) =>
        (u.id + u.name).toLowerCase().includes(text.toLowerCase())
      );
    this.setState({ selectedServices: u });
  };

  searchSubTypeItem = (e) => {
    let text = e.target.value;
    const u =
      this.state.selectedSubTypesAllServices &&
      this.state.selectedSubTypesAllServices.filter((u) =>
        u.label.toLowerCase().includes(text.toLowerCase())
      );
    this.setState({ selectedSubTypesServices: u });
  };

  setPagination = (user) => {
    if (this.state.selectedInvoiceList.data.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };

  handleExpenseChange = (name) => (event) => {
    let val = event.target.value;
    this.setState(
      {
        selectedExpense: event.target.value,
        showSelectedExpense: val.name,
      },
      () => {
        this.getMoreContracts();
      }
    );
  };

  handleChange = (name, value) => {
    this.setState(
      {
        expenseForm: {
          ...this.state.expenseForm,
          [name]: value,
        },
      },
      () => {
        this.checkRequiredExpenses();
      }
    );
  };

  checkRequiredExpenses = () => {
    const { name, contractNumber } = this.state.expenseForm;
    if (name && contractNumber) {
      this.setState({ isVisableSubmitBtn: false });
    } else this.setState({ isVisableSubmitBtn: true });
  };

  handleChange1 = (name, value) => {
    // console.log("---->", name, value);
    if (name === "unitPrice") {
      this.setState(
        {
          serviceForm: {
            ...this.state.serviceForm,
            [name]: parseFloat(value),
          },
        }
        // () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          serviceForm: {
            ...this.state.serviceForm,
            [name]: value,
          },
        }
        // () => this.checkRequiredFields()
      );
    }
  };

  selectService = (item, check) => {
    let apiUrl = check
      ? `/api/expensetypes/subtype?expenseTypeId=${item.id}&search=`
      : `/api/expensetypes/subtype?expenseTypeId=${item.id}&search=`;
    source = axios.CancelToken.source();
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log("---->>", response);
        this.setState({
          selectedSubTypesServices: response.data.data,
          selectedSubTypesAllServices: response.data.data,
          tableData: response?.data?.data?.data || [],
          serviceForm: {
            expensetypeId: item || "",
          },
          // openExpenseForm:false,
          // openTable:true,
          showServices: true,
          selectedSubType: item,
          // selectedServiceTypeId: item,
        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
  };

  submitExpenseForm = () => {
    let url =
      this.state.mode === "create"
        ? `/api/expensetypes/`
        : `/api/expensetypes/${this.state.expenseForm.id}`;
    fetch(url, {
      method: this.state.mode === "create" ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...this.state.expenseForm,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.setState(
            {
              createSubTypeNew: false,
              createSubType: false,
              openExpenseForm: false,
              openExpenseType: false,
            },
            () => {
              this.getExpenseTypes();
            }
          );

            toast.success(
                this.state.mode === "create"
                  ? this.props.translate("serviceCreated")
                  : this.props.translate("serviceUpdated")
            )
        }
      })
      .catch((error) => {
        console.log("error ==", error);
      });
  };
  submitForm = () => {
    // console.log(this.state.mode);
    let url =
      this.state.mode === "create"
        ? `/api/expensetypes/subtype/`
        : `/api/expensetypes/subtype/${this.state.serviceForm.id}`;
    fetch(url, {
      method: this.state.mode === "create" ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...this.state.serviceForm,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.setState(
            { createSubType: false, createSubTypeNew: false }
            // ()=> {this.getMoreContracts()}
            // ()=>{this.props.selectService(this.props.selectedServiceTypeId,true)}
          );
            toast.success(
                  this.state.mode === "create"
                  ? this.props.translate("subTypeCreated")
                  : this.props.translate("subTypeUpdated")
                )
          this.selectService(this.state.selectedSubType);
        }
      })
      .catch((error) => {
        console.log("error ==", error);
      });
  };

  closeEditForm = () => {
    this.setState({ createSubType: false });
  };

  deleteExpense = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      expenseForm: item,
      deleteSubType: false,
    });
  };
  onDelete = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      serviceForm: item[0],
      deleteSubType: true,

      // expenseForm: item,
    });
  };

  removeSubType = () => {
    if (this.state.serviceForm && this.state.serviceForm.id) {
      fetch(`/api/expensetypes/subtype/${this.state.serviceForm.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.setState(
              { onDeleteConfirmation: false }
              //   , () => {
              //   this.props.selectService(this.state.serviceForm, true);
              // }
            );
              toast.success(this.props.translate("subTypeDeleted"))
            this.selectService(this.state.selectedSubType);
          }
        })
        .catch((er) => {
          console.log("err ===", er);
        });
    }
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  removeExpenseType = () => {
    // console.log(this.state.expenseForm.id);
    if (this.state.expenseForm && this.state.expenseForm.id) {
      fetch(`/api/expensetypes/${this.state.expenseForm.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.setState({ onDeleteConfirmation: false }, () => {
              this.getExpenseTypes();
            });
              toast.success(this.props.translate("expenseDeleted"))
          }
        })
        .catch((er) => {
          console.log("err ===", er);
        });
    }
  };

  // editExpenseForm = (item) => {
  //   console.log("==", item);
  //   this.setState({
  //     expenseForm: item,
  //     openExpenseForm: true,
  //     openExpenseType: false,
  //     // mode: "update",
  //     // openTable: true,
  //     // showServices: true,
  //   });
  // };

  closeExpenseForm = () => {
    this.setState({
      openExpenseForm: false,
      mode: "create",
      openTable: true,
      showServices: true,
      openExpenseType: false,
    });
  };

  onEdit = (item) => {
    this.setState({
      selectedServiceTypeId: item.id,
      serviceForm: item[0],
      createSubType: true,
      createSubTypeNew: false,

      mode: "update",
    });
  };
  openCreateSubTypeFrom = () => {
    this.setState({
      openExpenseForm: false,
      mode: "create",
      // openTable: true,
      // showServices: true,
      openExpenseType: false,
      createSubType: false,
      createSubTypeNew: true,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.createSubType || this.state.createSubTypeNew ? (
          <>
            <Grid>
              Edit here
              <EditSubForm
                form={this.state.serviceForm}
                handleChange={this.handleChange1}
                translate={this.props.translate}
                submitForm={this.submitForm}
                closeEditForm={this.closeEditForm}
                mode={this.state?.mode}
                // isVisableSubmitBtn={this.state?.isVisableSubmitBtn}
                checkPrivileges={checkPrivileges}
              />
            </Grid>
          </>
        ) : (
          <>
            {!this.state.createSubType &&
            (this.state.openExpenseForm || this.state.openExpenseType) ? (
              <>
                <Grid
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    background: "#fff",
                    // marginLeft: 300,
                  }}
                >
                  <ExpenseForm
                    form={this.state.expenseForm}
                    handleChange={this.handleChange}
                    translate={this.props.translate}
                    submitForm={this.submitExpenseForm}
                    closeEditForm={this.closeExpenseForm}
                    mode={this.state.mode}
                    isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                    checkPrivileges={checkPrivileges}
                  />
                </Grid>
              </>
            ) : (
              <>
                {/* <Grid item xs={12} sm={12}>
                  <h3 style={{ margin: 0 }}>
                    {this.props.translate("SelectExpenseType")}
                  </h3>
                </Grid> */}
                {/* <Grid item xs={12} md={3} sm={6}>
              <TextField
                id="expenseType"
                select
                label={this.props.translate("expenseType")}
                value={this.state.selectedExpense || ""}
                onChange={this.handleExpenseChange("selectedExpense")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="dense"
                fullWidth
              >
                {this.state.expenseTypes && this.state.expenseTypes.length ? (
                  this.state.expenseTypes.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {this.props.translate(option.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>{this.props.translate("none")}</em>
                  </MenuItem>
                )}
              </TextField>
            </Grid> */}
                {/* {this.state.selectedExpense ? ( */}
                {/* <> */}
                <Grid
                  container
                  spacing={3}
                  //  className="breadcrumb-row"
                >
                  <Grid item md={3}>
                    {/* <aside
                      className="fms-sidebar"
                      style={{ marginLeft: 20, marginTop: 20 }}
                    > */}
                    <div>
                      {
                        <Fragment>
                          <div
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              paddingLeft: 10,
                            }}
                          >
                            <h4>{this.state?.selectedAccount?.name}</h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              alignItems: "center",
                            }}
                            className="headerDiv"
                          >
                            <div className="searchBar">
                              <TextField
                                label={this.props.translate("search")}
                                type="search"
                                placeholder="search"
                                value={this.state.search}
                                onChange={this.searchItem}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                            </div>
                            <Button size="small" onClick={this.openExpenseType}>
                              {" "}
                              {this.props.translate("create")}
                            </Button>
                            <span style={{ paddingRight: 10 }}>
                              <ExportImportMenu
                                fileName="category"
                                category
                                checkPrivilege="serviceCreate"
                                data={this.state?.expense || []}
                                themecolors={this.props.themecolors}
                                expensetypeId={
                                  this.state?.selectedAccount?.contractNumber ||
                                  ""
                                }
                                selectedAccount={
                                  this.state?.selectedAccount?.contractNumber ||
                                  ""
                                }
                                fetchAccountServices={this.fetchAccountServices}
                              />
                            </span>
                          </div>
                        </Fragment>
                      }
                      <div className="sidebar-devices-list">
                        <ContractSidebar
                          value="accounts"
                          // selectedAccount={this.state.selectedExpense}
                          selectedAccServices={this.state.selectedServices}
                          selectedServiceTypeId={
                            this.state.selectedServiceTypeId
                          }
                          selectService={this.selectService}
                          pathName={"contractManagement"}
                          editExpenseForm={this.editExpenseForm}
                          deleteExpense={this.deleteExpense}
                        />
                      </div>
                    </div>
                    {/* </aside> */}
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                  <h3 style={{ margin: 0 }}>
                    {this.props.translate("Contracts")}
                  </h3>
                </Grid> */}
                  {/* <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                  // style={{ justifyContent: "flex-end" }}
                >
                  <div style={{ marginRight: "20px", width: "25%" }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("search")}
                      onChange={(e) => this.SearchItem(e.target.value)}
                    />
                  </div>
                </Grid> */}
                  {/* {this.state &&
              this.state.contractsReceived &&
              !this.state.noRecords ? ( */}
                  <Grid item md={9}>
                    <Grid
                      container
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {/* <div style={{ marginRight: "20px", width: "25%" }}>
                        <TextField
                          fullWidth
                          label={this.props.translate("search")}
                          onChange={(e) => this.SearchItem(e.target.value)}
                        />
                      </div> */}
                      <Grid
                        item
                        xs={12}
                        sm={9}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            width: "25%",
                            marginRight: 20,
                            // marginBottom: 10,
                          }}
                        >
                          <TextField
                            fullWidth
                            label={this.props.translate("search")}
                            value={this.state.openCreateSubTypeFrom}
                            onChange={(e) => this.searchSubTypeItem(e)}
                          />
                        </div>
                        {/* <div style={{ width: "15%" }}>
                          <Button
                            aria-label="Create"
                            size="small"
                            color="inherit"
                            onClick={this.openCreateSubTypeFrom}
                            id="createButton"
                          >
                            {this.props.translate("create")}
                          </Button>
                        </div> */}

                        {/* <div style={{ width: "15%" }}>
                          <ExportImportMenu
                            fileName="expensetypes/subtypes"
                            checkPrivilege="serviceCreate"
                            isServices
                            data={this.state?.expense || []}
                            themecolors={this.props.themecolors}
                            expensetypeId={
                              this.props?.selectedServiceTypeId || ""
                            }
                          />
                        </div> */}

                        {/* <div style={{ width: "10%", marginLeft: 20 }}>
                          <Button
                            aria-label="Back"
                            size="small"
                            color="inherit"
                            onClick={this.props.goBack}
                            id="back"
                          >
                            {this.props.translate("back")}
                          </Button>
                        </div> */}
                      </Grid>

                      <Table
                        rows={this.state.selectedSubTypesServices || []}
                        pagination={[]}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state?.rowsPerPage}
                        handleChangePage={this.handleChangePage}
                        translate={this.props.translate}
                        isEditable
                        canUpdate
                        canDelete
                        onClick={this.selectAccount}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        themecolors={this.props.themecolors}
                        rowDefinition={[
                          {
                            id: "label",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("sharedName"),
                          },
                          {
                            id: "subTypeGroup",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Group"),
                          },
                          {
                            id: "subTypeNum",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Number"),
                          },
                          {
                            id: "unitPrice",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Selling Price"),
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* ) : !this.state.contractsReceived && !this.state.noRecords ? (
                <div style={{ textAlign: "center" }}>
                  <Loader defaultStyle />
                </div>
              ) : null} */}
                {/* {this.state.noRecords && (
                <div style={{ textAlign: "center" }}>
                  <h4> {this.props.translate("noInvoiceFound")}</h4>
                </div>
              )} */}
                {/* </> */}
                {/* ) : null} */}

                {this.state.onDeleteConfirmation && (
                  <>
                    <ConfirmDialoag
                      onCancel={this.onCancel}
                      onOk={
                        this.state.deleteSubType
                          ? this.removeSubType
                          : this.removeExpenseType
                      }
                      title={this.props.translate("areYouWantToDelete")}
                      children={
                        this.state.deleteSubType
                          ? this.state.serviceForm?.label || ""
                          : this.state.expenseForm?.name || ""
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const EditSubForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  field,
  checkPrivileges,
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("services")}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form?.label || ""}
            onChange={(e) => handleChange("label", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="subTypeGroup"
            margin="dense"
            label={translate("Group")}
            variant="outlined"
            fullWidth
            value={form?.subTypeGroup || ""}
            onChange={(e) => handleChange("subTypeGroup", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="subTypeNum"
            margin="dense"
            label={translate("Number")}
            variant="outlined"
            fullWidth
            required
            value={form?.subTypeNum || ""}
            onChange={(e) => handleChange("subTypeNum", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="unitPrice"
            margin="dense"
            label={translate("Selling Price")}
            variant="outlined"
            fullWidth
            type="number"
            value={form?.unitPrice || ""}
            onChange={(e) => handleChange("unitPrice", e.target.value)}
          />
        </Grid>
        {checkPrivileges("invoicePurchasePriceView") && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="purchasePrice"
              margin="dense"
              label={translate("Purchase Price")}
              variant="outlined"
              fullWidth
              type="number"
              value={form?.purchasePrice || ""}
              onChange={(e) => handleChange("purchasePrice", e.target.value)}
            />
          </Grid>
        )}
      </Grid>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeEditForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {checkPrivileges("expensetypeCreate") && (
          <Button
            size="small"
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        )}
      </div>
    </div>
  );
};

const ExpenseForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  checkPrivileges,
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("services")}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form?.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="description"
            margin="dense"
            label={translate("description")}
            variant="outlined"
            fullWidth
            value={form?.description || ""}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='customerNumber'
              margin='dense'
              label={translate('Customer Number')}
              variant='outlined'
              fullWidth
              value={form?.customerNumber || ''}
              onChange={e => handleChange('customerNumber', e.target.value)}
            />
          </Grid> */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="contractNumber"
            margin="dense"
            label={translate("contractNumber")}
            variant="outlined"
            fullWidth
            readOnly
            value={form?.contractNumber || ""}
          />
        </Grid> */}
      </Grid>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeEditForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {checkPrivileges("expensetypeCreate") && (
          <Button
            size="small"
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        )}
      </div>
    </div>
  );
};

const enhance = compose(withStyles(styles), connect());
export default enhance(ContractManagement);

const rowDefinitionR = (translate) => {
  return [
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: translate("invoiceNumber"),
    },
    {
      id: "plateNumber",
      numeric: false,
      disablePadding: false,
      label: translate("vehicleLicensePlate"),
    },
    {
      id: "serviceId",
      numeric: false,
      disablePadding: false,
      label: translate("Booking Number"),
    },
    {
      id: "stationCode",
      numeric: false,
      disablePadding: false,
      label: translate("stationCode"),
    },
    {
      id: "viewInvoice",
      numeric: false,
      disablePadding: false,
      label: translate("invoice"),
      hide: false,
    },
    {
      id: "serviceDate",
      numeric: false,
      disablePadding: false,
      label: translate("serviceDate"),
    },
  ];
};

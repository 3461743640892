import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomDialog from "../common/Dialog";
import AddOffers from "./addOffers";
import { parentUnitsLimit, getDevices } from "../../Actions/Devices";
import Table from "../common/tableWithBackEndPagination";
import isEqual from "react-fast-compare";
import { checkPrivileges, PaginationConfig } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
// import DevicePortList from './devicePortList'
import Button from "../common/Button";
import Style from "style-it";
import {  Grid } from "@mui/material";
import TextField from "../common/TextField";

import moment from "moment";
import Loader from "../../Layout/Loader";

class offresList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  addOption:false,
    };
  }
  // modalControle =()=>{
  //   this.setState({
  //   addOption:false
  // })
  // }

  // addGarages=()=>{
  //   this.setState({
  //     addOption:true
  //   })
  // }
  render() {
    const { devices, group } = this.props;
    let fileName = "units";
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        <Fragment>
          {this.props.addOption ? (
            <AddOffers
              // {...this.state}
              {...this.props}
              title={this.props.translate("addOffers")}
              data={this.props.data}
              //   users={this.props.users}
              //   group={group}
              formSubmit={this.props.handleSubmit}
            handleImageChange={this.props.handleImageChange}

              //   handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}

              handleChange={this.props.handleChange}
              modalControle={this.props.modalControle}
              buttonText={this.props.translate("sharedCreate")}
              isVisableAddBtn={this.props.isVisableAddBtn}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          ) : this.props.editOption ? (
            <AddOffers
              // {...this.state}
              {...this.props}
            handleImageChange={this.props.handleImageChange}

              title={this.props.translate("updateOffers")}
              data={this.props.data}
              //   users={this.props.users}
              //   group={group}
              formSubmit={this.props.updateWorkshops}
              //   handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}
              buttonText={"Update"}
              handleChange={this.props.handleChange}
              handleChange2={this.props.handleChange2}
              modalControle={this.props.modalControle}
              // buttonText={this.props.translate('sharedCreate')}
              isVisableAddBtn={this.props.isVisableAddBtn}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          ) : (
            <>
              <Grid container className="breadcrumb-row">
                <Grid item xs={12} sm={3}>
                  <h3 style={{ margin: 0 }}>
                    {this.props.translate("offers")}
                  </h3>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={9}
                  style={{ justifyContent: "flex-end" }}
                >
{/*              
              <div div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("garageCity")}
                      value={this.props.garageCity}
                      onChange={(e) => this.props.searchContractText(e, "garageCity")}
                    />
                   
                  </div>
                  <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                      fullWidth
                      label={this.props.translate("garageType")}
                      value={this.props.garageType}
                      onChange={(e) => this.props.searchContractText(e, "garageType")}
                    />
                  </div>
                  <div style={{ width: "15%", marginRight: 20 }}>
                  <Button
                      aria-label="search"
                      size="small"
                      color="inherit"
                      onClick={this.props.getGarages}
                      id="search"
                    >
                      {this.props.translate("filter")}
                    </Button> 
                    </div> */}





               <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.props.searchGarage}
                    onChange={(e) => this.props.searchContractText(e, "search")}
                  />
                </div>
                  <Button
                    disabled={!(checkPrivileges("offerCreate") ? true : false)}
                    aria-label="Create"
                    size="small"
                    color="inherit"
                    onClick={() => {this.props.addGarages(); this.props.getExpenseType();}}
                    id="createButton"
                  >
                    {this.props.translate("create")}
                  </Button>
                  
                </Grid>
              </Grid>
{this.props.loader1 ? <Loader/>:
              <Table
                // rows={this.props.garagesData?.data ||[]}
                rows={this.props.garagesData?.data || []}
                // pagination={this.setPagination(this.state.pagData)}
                pagination={
                  this.props.garagesData &&
                  this.props.garagesData.data &&
                  this.props.garagesData.data.length &&
                  this.props.setPagination(this.props.garagesData)
                }
                // setSerialNo
                handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                handleChangePage={this.props.handleChangePage}
                translate={this.props.translate}
                // loginAsUser={this.loginAsUser}

                // filterDropdown
                // onClick={this.props.workShopSelection}
                isEditable={true}
                onDelete={this.props.removeEnable}
                hasAccessOfDelete={checkPrivileges("garageDelete")}
                hasAccessOfUpdate={checkPrivileges("garageUpdate")}
                isCursoPointer
                onEdit={this.props.workShopSelection}
                // setSerialNo
                // SearchItem={this.SearchItem}
                rowDefinition={[
                  // {
                  //   id: 'adjustmentType',

                  //   label: this.props.translate('adjustmentType')
                  // },
                  {
                    id: "title",
                    label: this.props.translate("title"),
                  },
                  {
                    id: "category",
                    label: this.props.translate("category"),
                  },
                  {
                    id: "contractNumber",
                    label: this.props.translate("contractNumber"),
                  },
                  // {
                  //   id: "description",
                  //   label: this.props.translate("description"),
                  // },
                  
                  // {
                  //   id: "garageType",
                  //   label: this.props.translate("garageType"),
                  // },
                  {
                    id: "type",
                    label: this.props.translate("type"),
                  },
                  
                  {
                    id: "created",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("created"),
                  },

                  // {
                  //   id: 'host',
                  //   numeric: false,
                  //   disablePadding: false,
                  //   label: this.props.translate('host')
                  // },
                ]}
              />
  }
            </>
          )}

          {this.props.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.props.onCancel}
              onOk={this.props.deleteWorkShop}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.selectedWorkShop.title}
            />
          )}
        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const OffersList = mapStateToProps((offresList));

import React, { Component } from "react";
import { connect } from "react-redux";
import { checkPrivileges } from "../../Helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../common/Button";
import { Tooltip, Typography } from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";

class ReportAndNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callPending: true,
      page: 1,
      pageSize: 20,
      itemSearch: "",
      accounts: {},
    };
  }


  render() {
    let data = "";
    if (
      this.props.notificationsList &&
      this.props.notificationsList.length
    ) {
      data = this.props.notificationsList.map((notification) => (
        <ListRow
          key={notification.id}
          data={notification}
          {...this.props}
          openCreateForm={this.props.openCreateForm}
        />
      ));
    }

    return (
      <div>
         <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"5px 10px",  margin:"10px 0px"}}>
            <h3>{this.props.translate('alerts')}</h3>
            <Button onClick={()=> this.props.openCreateForm()}>{this.props.translate('sharedCreate')}</Button>
          </div>
          
         
        <Scrollbar
          style={{
            height:
            "460px",
          }}
        >
        {data && (
          <div className="individual-view">
            <ul className="list-view">{data}</ul>
          </div>
        )}
        </Scrollbar>
      </div>
    );
  }
}

export class ListRows extends Component {
  render() {
    if (this.props.children.length) {
      return (
        <div>
        <ul className="list-view">
          {this.props.children.map((data) => (
            <ListRow key={data.id} data={data} {...this.props} />
          ))}
        </ul>
        </div>
      );
    } else {
      return "";
    }
  }
}
export class ListRow extends Component {
  render() {
    let { props } = this.props;
    return (
      <li
        style={{
          display: "flex",
          justifyContent:"space-between", 
          alignItems:"center"
        }}
      >
         {/* <Tooltip
            title={
              <Typography style={{fontSize:"14px",}}>
              {this.props.translate(this.props.data.description)}
              </Typography>
              }
            placement="bottom-start"
            enterDelay={300}
            classes={{ tooltip: 'customTooltip' }}
        > */}
            <span
              className="unit-name"
              style={{
                maxWidth: 190,
                // maxWidth: {props.value="accounts"},
                marginLeft: 0,
                lineHeight: "24px",
                verticalAlign: "middle",
              }}
            >
              {
              // this.props.data.type === "arrears" ?
              // this.props.translate("Due Overdue Payment Alerts") :
              // this.props.data.type === "services" ?
              // this.props.translate("Schedule Report : Maintenance Due Overdue") :
              this.props.translate(this.props.data.description) 
              }
            </span>
            {/* </Tooltip> */}
            <div>
              {checkPrivileges("expensetypeUpdate") && (
              <span
                onClick={(e) => this.props.openEditForm(this.props.data)}
                style={{ paddingTop: 5, cursor: "pointer" }}
              >
                <EditIcon fontSize="small" color="action" />
              </span>
              )}
              {checkPrivileges("expensetypeDelete") && (
              <span
                onClick={(e) => this.props.enableDeleteForm(this.props.data)}
                style={{ paddingTop: 5, cursor: "pointer" }}
              >
                <DeleteIcon fontSize="small" color="action" />
              </span>)}
           </div>
      </li>
    );
  }
}

const mapState = (state) => {
  if (state.roles) {
    var role = state.roles.filter(
      (item) => item.name.toLowerCase().indexOf(state.searchRole) !== -1
    );
  }
  return {
    logInUser: state.logInUsers,
  };
};

const mapStateToProps = connect(mapState);
export const ReportAndNotificationFilter = mapStateToProps(ReportAndNotification);

import React, { Component } from "react";
import Stepper from "../Invoice/Stepper";
import moment from "moment";
import Loader from "../../Layout/Loader";
import TextField from "../common/TextField";
import Scrollbar from "react-scrollbars-custom";
import {
  FormControl,
  ListItemText,
  Select,
  InputLabel,
  Grid,
  MenuItem,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers"; // Example of a component you might use
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormHelperText from "@mui/material/FormHelperText";
import SingleSelect from "../common/SingleSelect";
import AccountSelector from "../Invoice/AccountSelector";
import VehicleSelector from "../Invoice/VehicleSelector";
import Checkbox from "../common/Checkbox";
import "moment-timezone";

const Reports = [
  {
    key: 1,
    name: "Consolidated Revenue Report",
  },
  {
    key: 2,
    name: "Revenue Analysis Report",
  },
  {
    key: 3,
    name: "Fleet Details",
  },
  {
    key: 4,
    name: "Payment Report",
  },
  {
    key: 5,
    name: "Client Ageing Report",
  },
  {
    key: 12,
    name: "Client Ageing Report - Payment Split",
  },
  {
    key: 6,
    name: "Invoice Ageing Report",
  },
  {
    key: 7,
    name: "Customer Ledger",
  },
  {
    key: 8,
    name: "AR Report",
  },
  {
    key: 11,
    name: "Client Ageing Report New Format",
  },

  {
    key: 9,
    name: "Lease Sales Report",
  },
  {
    key: 10,
    name: "Lease History Report",
  },
  {
    key: 13,
    name: "Fleet Service Throughput Report",
  },
  {
    key: 14,
    name: "Lease Vehicle Ownership Report",
  },
  // {
  //   key: 16,
  //   name: "Lease SOA Report",
  // },
  {
    key: 16,
    name: "Statement of Account",
  },
  {
    key: 18,
    name: "Consolidated SOA Report",
  },
];

const maintenanceReports = [
  {
    key: 101,
    name: "Revenue Analysis Report",
  },
  {
    key: 102,
    name: "Client Ageing Report",
  },

  {
    key: 114,
    name: "Client Ageing Report - Payment Split",
  },
  {
    key: 103,
    name: "Invoice Ageing Report",
  },

  {
    key: 104,
    name: "Payment Report",
  },
  {
    key: 105,
    name: "Sales  Report",
  },
  {
    key: 106,
    name: "Customer Ledger",
  },
  {
    key: 107,
    name: "AR Report",
  },
  {
    key: 115,
    name: "Client Ageing Report New Format",
  },
  {
    key: 108,
    name: "Parts Profitability and Demand Analysis Report",
  },
  {
    key: 109,
    name: "Service Profitability and Demand Analysis Report",
  },
  {
    key: 110,
    name: "Package Profitability and Demand Analysis Report",
  },
  {
    key: 111,
    name: "Vehicle Classification Revenue Report",
  },
  {
    key: 112,
    name: "Workshop Revenue Report",
  },
  {
    key: 113,
    name: "Parts and Services Used Report",
  },
  {
    key: 116,
    name: "Vehicle Visit History Report",
  },
  {
    key: 117,
    name: "Fleet Service Throughput Report",
  },
  {
    key: 118,
    name: "Client Business Conversion Report",
  },
  {
    key: 119,
    name: "Maintenance Vehicle Ownership Report",
  },
  {
    key: 121,
    name: "Maintenance Items Revenue Report",
  },
  // ,
  // {
  //   key: 122,
  //   name: "Maintenance SOA Report",
  // }

  {
    key: 122,
    name: "Statement of Account",
  },
  {
    key: 125,
    name: "Summary Delivery Note Report",
  },
  {
    key: 126,
    name: "Maintenance Item-Wise Report",
  },
  {
    key: 128,
    name: "Consolidated SOA Report",
  },
  {
    key: 129,
    name: "Sales Collection History Report",
  },
  // MAINTENANCE_ITEM_WISE_REPORT
];
// 1= default,2=pcvc, 3= dex
const GarageTypes = [
  {
    key: 1,
    name: "Default",
  },

  {
    key: 2,
    name: "PCVC",
  },
  {
    key: 3,
    name: "DEX",
  },
  {
    key: 4,
    name: "PE",
  },
  {
    key: 5,
    name: "JV",
  },

  {
    key: 6,
    name: "TRISTAR",
  },
  {
    key: 7,
    name: "PAC",
  },
  {
    key: 0,
    name: "Others",
  },
];
const PredictionStatuses = [
  {
    key: "completed",
    name: "Completed",
  },

  {
    key: "overdue",
    name: "Overdue",
  },
  {
    key: "null",
    name: "Null",
  },
];
let contractTypes = [
  { id: 1, name: "Maintenance" },
  { id: 2, name: "Leasing" },
];
export default class reportsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilterModal: false,
      from: "",
      to: "",
      selectedDate: "",
      vehicleId: [],
      contractNumber: [],
      areaId: [],
      // loader: false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId: [],
      selectedaccountId: [],
      selectedAreaId: [],
      loaderChecking: false,
      allVehiclesSelected: false,
      steps: [
        { id: 1, title: "selectContractType" },
        { id: 2, title: "selectReport" },
        { id: 3, title: "selectServiceTimeControl" },
        { id: 4, title: "selectAccount" },
        { id: 5, title: "selectVehicles" },
      ],
      activeStep: 0,
      reportType: 0,
      isSubmitBtn: false,
      invoicesList: [],
      accounts: "",
      loader: true,
      newUser: [],
      makingAccounts: {},
      makingUsers: {},
      selectAccountReportError: false,
    };
  }

  onCloseDueMaintenanceModel = () => {
    this.setState({
      openFilterModal: false,
      to: "",
      from: "",
      isSubmitBtn: false,
      selectedDate: "",
      vehicleId: [],
      areaId: [],
      contractNumber: [],
      newUser: [],
      countChecked: 0,
      anchorEl: null,
      activeStep: 0,
    });
  };

  handleChange = (name, e, check) => {
    if (e === "area") {
      this.setState({
        areaId: name,
      });
      this.setState({ areaId: name }, () => {
        let filter = "";
        Object.entries(this.state.areaId).map(([key, value]) => {
          if (value) {
            filter += "&areaId=" + value;
            this.setState({});
          }
        });
        this.setState({
          selectedAreaId: filter,
        });
      });
    } else if (e === "vehicle") {
      this.setState({
        vehicleId: name,
      });
      this.setState(
        { vehicleId: name, allVehiclesSelected: check ? true : false },
        () => {
          let filter = name;
          this.setState({
            selectedvehicleId: name,
            loaderChecking: true,
          });
        }
      );
    } else {
      if (this.props.selectSingleInvoice) {
      } else {
        this.setState({
          contractNumber: name,
          newUser: e,
        });
        this.setState({ contractNumber: name }, () => {
          let filter = [];
          Object.entries(this.state.contractNumber).map(([key, value]) => {
            if (value) {
              filter.push(value);
              this.setState({});
            }
          });
          this.setState({
            selectedaccountId: filter,
          });
        });
      }
    }
  };

  goToNextStep = (step, type, index) => {
    if (step === 5) {
      if (
        this.props.form.reportNumber?.key === 2 ||
        this.props.form.reportNumber === 2 ||
        this.props.form.reportNumber?.key === 3 ||
        this.props.form.reportNumber === 3 ||
        this.props.form.reportNumber?.key === 7 ||
        this.props.form.reportNumber === 7 ||
        this.props.form.reportNumber?.key === 8 ||
        this.props.form.reportNumber === 8 ||
        this.props.form.reportNumber?.key === 105 ||
        this.props.form.reportNumber === 105 ||
        this.props.form.reportNumber?.key === 106 ||
        this.props.form.reportNumber === 106 ||
        this.props.form.reportNumber?.key === 107 ||
        this.props.form.reportNumber === 107 ||
        this.props.form?.reportNumber === 108 ||
        this.props.form?.reportNumber?.key === 108 ||
        this.props.form?.reportNumber === 109 ||
        this.props.form?.reportNumber?.key === 109 ||
        this.props.form?.reportNumber === 110 ||
        this.props.form?.reportNumber?.key === 110 ||
        this.props.form?.reportNumber?.key === 111 ||
        this.props.form?.reportNumber === 111 ||
        this.props.form?.reportNumber?.key === 112 ||
        this.props.form?.reportNumber === 112 ||
        this.props.form?.reportNumber?.key === 113 ||
        this.props.form?.reportNumber === 113 ||
        this.props.form?.reportNumber?.key === 10 ||
        this.props.form?.reportNumber === 10 ||
        this.props.form?.reportNumber?.key === 115 ||
        this.props.form?.reportNumber === 115 ||
        this.props.form?.reportNumber?.key === 11 ||
        this.props.form?.reportNumber === 11 ||
        this.props.form?.reportNumber?.key === 9 ||
        this.props.form?.reportNumber === 9 ||
        this.props.form?.reportNumber?.key === 13 ||
        this.props.form?.reportNumber === 13 ||
        this.props.form?.reportNumber?.key === 16 ||
        this.props.form?.reportNumber === 16 ||
        this.props.form?.reportNumber?.key === 117 ||
        this.props.form?.reportNumber === 117 ||
        this.props.form?.reportNumber?.key === 122 ||
        this.props.form?.reportNumber === 122 ||
        this.props.form?.reportNumber?.key === 118 ||
        this.props.form?.reportNumber === 118 ||
        this.props.form?.reportNumber?.key === 129 ||
        this.props.form?.reportNumber === 129
      ) {
        if (this.props.form.contractNumbers.length === 0) {
          this.setState(
            {
              //   openFilterModal:false,
            },
            () => {
              this.props.selecteFilters(
                this.props.form.from,
                this.props.form.to,
                this.props.form.reportNumber,
                // this.props.form.selectedAccount
                this.state.selectedaccountId,
                this.state.selectedvehicleId,
                type,
                this.state.allVehiclesSelected,
                this.props.form
              );
            }
          );
        } else {
          let filter = "";

          filter = this.props.form.contractNumbers?.contractNumber
            ? [this.props.form.contractNumbers?.contractNumber]
            : this.props.form.contractNumbers;
          this.setState(
            {
              selectedaccountId: filter,
            },

            () => {
              this.props.selecteFilters(
                this.props.form.from,
                this.props.form.to,
                this.props.form.reportNumber,
                this.state.selectedaccountId,
                this.state.selectedvehicleId,
                type,
                this.state.allVehiclesSelected,
                this.props.form
              );
            }
          );
        }
      } else {
        this.setState(
          {
            //   openFilterModal:false,
          },
          () => {
            this.props.selecteFilters(
              this.props.form.from,
              this.props.form.to,
              this.props.form.reportNumber,
              // this.props.form.selectedAccount
              this.state.selectedaccountId,
              this.state.selectedvehicleId || [],
              type,
              this.state.allVehiclesSelected,
              this.props.form
            );
          }
        );
      }
    } else {
      this.validatedCurrentStep(step, type, index);
    }
  };

  goToBackStep = () => {
    const currentStep = this.state.activeStep;
    this.setState({ activeStep: currentStep - 1 });
  };

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if (step === 3) {
      validated = true;
      if (
        this.props.form.to &&
        this.props.form.from &&
        this.props.form.to > this.props.form.from
      ) {
        validated = true;
        errorMsg = null;
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">Please select 'Date ' from above</span>
        );
      }
    } else if (step === 1) {
      if (this.props.contractType) {
        validated = true;
      } else {
        validated = false;
      }
    } else if (step === 2) {
      if (this.props.form.reportNumber) {
        validated = true;
      } else {
        validated = false;
      }
    } else if (step === 4) {
      validated = false;

      if (
        (this.props.form?.reportNumber?.key === 117 ||
          this.props.form?.reportNumber?.key === 122 ||
          this.props.form?.reportNumber?.key === 118 ||
          this.props.form?.reportNumber?.key === 13 ||
          this.props.form?.reportNumber?.key === 16) &&
        (this.props.form?.contractNumbers === "" ||
          this.props.form?.contractNumbers.length === 0)
      ) {
        validated = false;
        this.setState({ selectAccountReportError: true });
      } else {
        validated = true;
        errorMsg = null;
        this.setState({ selectAccountReportError: false });
      }
    } else if (step === 5) {
      validated = false;
      if (
        this.props.form?.reportNumber === 121 ||
        this.props.form?.reportNumber?.key === 121 ||
        this.props.form?.reportNumber === 125 ||
        this.props.form?.reportNumber?.key === 125 ||
        this.props.form?.reportNumber === 126 ||
        this.props.form?.reportNumber?.key === 126
      ) {
        if (this.props.form.area && this.props.form.garageVerticals) {
          validated = true;
        } else {
          validated = false;
        }
      }
    }

    if (validated === true) {
      this.setState({ activeStep: index + 1 }, () => {
        if (this.state.activeStep === 5) {
          this.setState({ isSubmitBtn: true });
        }
      });
    }
  };

  getStepContent = (id) => {
    const { classes } = this.props;

    let serverTimeZone = "";
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZone = this.props.logInUser.attributes.timezone;
    } else {
      serverTimeZone = "Asia/Riyadh";
    }
    switch (id) {
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                id="contractType"
                select
                label={this.props.translate("contractType")}
                value={this.props.contractType || ""}
                onChange={(e) => this.props.handleChange("contractType", e)}
                SelectProps={{
                  MenuProps: {
                    // className: classes.menu,
                  },
                }}
                margin="dense"
                fullWidth
                // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                required
              >
                {contractTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {this.props.translate(option.name)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12}>
            <>
              {this.props.contractType === 2 ? (
                <Grid container className="form-group">
                  <Grid item xs={12}>
                    <SingleSelect
                      array={Reports || []}
                      async
                      selectName="reportNumber"
                      // isClearable
                      label={this.props.translate("reportType")}
                      value={
                        this.props.form && this.props.form.reportNumber.id
                          ? {
                              id: this.props.form.reportNumber.id,
                              value: this.props.form.reportNumber.name,
                              label: this.props.form.reportNumber.name,
                            }
                          : ""
                      }
                      // value={this.props.form.reportNumber || ""}
                      handleChange={this.props.handleChange}
                      canAssign={true}
                    />
                  </Grid>
                </Grid>
              ) : this.props.contractType === 1 ? (
                <Grid container className="form-group">
                  <Grid item xs={12}>
                    <SingleSelect
                      array={maintenanceReports || []}
                      async
                      selectName="reportNumber"
                      // isClearable
                      label={this.props.translate("reportType")}
                      value={
                        this.props.form && this.props.form?.reportNumber?.id
                          ? {
                              id: this.props.form.reportNumber.id,
                              value: this.props.form.reportNumber.name,
                              label: this.props.form.reportNumber.name,
                            }
                          : ""
                      }
                      // value={this.props.form.reportNumber || ""}
                      handleChange={this.props.handleChange}
                      canAssign={true}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={2} className="form-group">
            {/* "From" DatePicker */}
            <Grid item md={6} sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  required
                  margin="dense"
                  disabled={[102, 5, 12, 6, 11, 114, 103, 115].includes(
                    this.props.form?.reportNumber?.key ||
                      this.props.form?.reportNumber
                  )}
                  label={this.props.translate("from")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month", "day"]}
                  error={!this.props.form?.from}
                  slotProps={{
                    textField: {
                      helperText: !this.props.form?.from
                        ? this.props.translate("enterDate")
                        : "",
                    },
                  }}
                  value={
                    [102, 5, 12, 6, 11, 114, 103, 115].includes(
                      this.props.form?.reportNumber?.key ||
                        this.props.form?.reportNumber
                    )
                      ? moment("2020-12-31T21:00:00.000Z")
                      : this.props.form?.from
                      ? moment(this.props.form.from)
                      : null
                  }
                  onChange={(e) => this.props.handleChange("from", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            {/* "To" DatePicker */}
            <Grid item md={6} sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  required
                  margin="dense"
                  label={this.props.translate("to")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month", "day"]}
                  error={
                    (this.props.form?.to &&
                      this.props.form?.from &&
                      moment(this.props.form.to).isBefore(
                        moment(this.props.form.from)
                      )) ||
                    !this.props.form?.to
                  }
                  slotProps={{
                    textField: {
                      helperText: !this.props.form?.to
                        ? this.props.translate("enterDate")
                        : "",
                    },
                  }}
                  value={
                    this.props.form?.to
                      ? moment.utc(this.props.form?.to).tz(serverTimeZone)
                      : null
                  }
                  onChange={(e) => this.props.handleChange("to", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {this.props.form?.to < this.props.form?.from && (
              <p
                style={{
                  fontSize: "12px",
                  color: "red",
                  marginTop: "8px",
                  marginLeft: "15px",
                }}
              >
                {this.props.translate("enterCorrectDate")}
              </p>
            )}
          </Grid>
        );
      case 4:
        return (
          <Grid container className="form-group">
            <Grid item xs={12}>
              {this.props.form?.reportNumber?.key === 2 ||
              this.props.form?.reportNumber === 2 ||
              this.props.form?.reportNumber?.key === 7 ||
              this.props.form?.reportNumber === 7 ||
              this.props.form?.reportNumber?.key === 8 ||
              this.props.form?.reportNumber === 8 ||
              this.props.form?.reportNumber?.key === 105 ||
              this.props.form?.reportNumber === 105 ||
              this.props.form?.reportNumber?.key === 106 ||
              this.props.form?.reportNumber === 106 ||
              this.props.form?.reportNumber?.key === 107 ||
              this.props.form?.reportNumber === 107 ||
              this.props.form?.reportNumber === 108 ||
              this.props.form?.reportNumber?.key === 108 ||
              this.props.form?.reportNumber === 109 ||
              this.props.form?.reportNumber?.key === 109 ||
              this.props.form?.reportNumber === 110 ||
              this.props.form?.reportNumber?.key === 110 ||
              this.props.form?.reportNumber?.key === 111 ||
              this.props.form?.reportNumber === 111 ||
              this.props.form?.reportNumber?.key === 112 ||
              this.props.form?.reportNumber === 112 ||
              this.props.form?.reportNumber?.key === 113 ||
              this.props.form?.reportNumber === 113 ||
              this.props.form?.reportNumber?.key === 10 ||
              this.props.form?.reportNumber === 10 ||
              this.props.form?.reportNumber?.key === 115 ||
              this.props.form?.reportNumber === 115 ||
              this.props.form?.reportNumber?.key === 11 ||
              this.props.form?.reportNumber === 11 ||
              this.props.form?.reportNumber?.key === 9 ||
              this.props.form?.reportNumber === 9 ||
              this.props.form?.reportNumber?.key === 117 ||
              this.props.form?.reportNumber === 117 ||
              this.props.form?.reportNumber?.key === 122 ||
              this.props.form?.reportNumber === 122 ||
              this.props.form?.reportNumber?.key === 118 ||
              this.props.form?.reportNumber === 118 ||
              this.props.form?.reportNumber?.key === 13 ||
              this.props.form?.reportNumber === 13 ||
              this.props.form?.reportNumber?.key === 16 ||
              this.props.form?.reportNumber === 16 ||
              this.props.form?.reportNumber?.key === 129 ||
              this.props.form?.reportNumber === 129 ? (
                // ||this.props.form?.reportNumber?.key === 116 ||
                // this.props.form?.reportNumber === 116
                <>
                  {this.props && this.props.accounts?.data?.length >= 0 ? (
                    <>
                      <SingleSelect
                        array={(this.props && this.props.accounts?.data) || []}
                        async
                        selectName="contractNumbers"
                        isClearable
                        label={this.props.translate("selectedAccount")}
                        value={
                          this.props?.form?.contractNumbers &&
                          this.props.form.contractNumbers.id
                            ? {
                                id: this.props.form.contractNumbers,
                                value:
                                  this.props.form.contractNumbers
                                    .contractNumber,
                                label: this.props?.form?.contractNumbers.label,
                              }
                            : ""
                        }
                        // value={this.props.form.contractNumbers || ""}
                        handleChange={this.props.handleChange}
                        canAssign={true}
                      />
                      {this.state.selectAccountReportError === true ? (
                        <FormHelperText
                          error={
                            this.state.selectAccountReportError === true
                              ? true
                              : false
                          }
                          style={{ textAlign: "end" }}
                        >
                          Select Account
                        </FormHelperText>
                      ) : null}
                    </>
                  ) : (
                    <Loader component="filter" />
                  )}
                </>
              ) : this.props.form?.reportNumber?.key === 1 ||
                this.props.form?.reportNumber === 1 ||
                this.props.form?.reportNumber?.key === 3 ||
                this.props.form?.reportNumber === 3 ||
                this.props.form?.reportNumber?.key === 4 ||
                this.props.form?.reportNumber === 4 ||
                this.props.form?.reportNumber?.key === 5 ||
                this.props.form?.reportNumber === 5 ||
                this.props.form?.reportNumber?.key === 6 ||
                this.props.form?.reportNumber === 6 ||
                this.props.form?.reportNumber?.key === 101 ||
                this.props.form?.reportNumber === 101 ||
                this.props.form?.reportNumber?.key === 102 ||
                this.props.form?.reportNumber === 102 ||
                this.props.form?.reportNumber?.key === 103 ||
                this.props.form?.reportNumber === 103 ||
                this.props.form?.reportNumber?.key === 104 ||
                this.props.form?.reportNumber === 104 ||
                this.props.form?.reportNumber?.key === 114 ||
                this.props.form?.reportNumber === 114 ||
                this.props.form?.reportNumber?.key === 12 ||
                this.props.form?.reportNumber === 12 ||
                this.props.form?.reportNumber?.key === 116 ||
                this.props.form?.reportNumber === 116 ||
                this.props.form?.reportNumber?.key === 14 ||
                this.props.form?.reportNumber === 14 ||
                this.props.form?.reportNumber?.key === 119 ||
                this.props.form?.reportNumber === 119 ||
                this.props.form?.reportNumber?.key === 121 ||
                this.props.form?.reportNumber === 121 ||
                this.props.form?.reportNumber?.key === 125 ||
                this.props.form?.reportNumber === 125 ||
                this.props.form?.reportNumber?.key === 126 ||
                this.props.form?.reportNumber === 126 ||
                this.props.form?.reportNumber?.key === 122 ||
                this.props.form?.reportNumber === 122 ||
                this.props.form?.reportNumber?.key === 128 ||
                this.props.form?.reportNumber === 128 ||
                this.props.form?.reportNumber?.key === 18 ||
                this.props.form?.reportNumber === 18 ||
                this.props.form?.reportNumber?.key === 16 ||
                this.props.form?.reportNumber === 16 ? (
                <>
                  <AccountSelector
                    type="reports"
                    data={(this.props && this.props.accounts) || []}
                    reportKey={this.props.reportKey}
                    searchItem={this.props.searchItem}
                    searchInvoiceText={this.props.searchInvoiceText}
                    selecteItem={this.props.selecteItem}
                    api="accounts"
                    fill
                    loaderR={this.props.loaderR}
                    contractType={this.props.contractType}
                    getContractsData={this.props.getContractsData}
                    hideIcons
                    isClearable
                    placeholder={this.props.translate("searchAccount")}
                    onChange={this.handleChange}
                    value={this.state?.contractNumber || []}
                    value2={this.state.newUser || ""}
                    makingAccounts={this.props.makingAccounts}
                    makingUsers={this.props.makingUsers}
                    sendAll
                  />
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        );

      case 5:
        return (
          <Grid container className="form-group">
            {this.props.form?.reportNumber?.key === 4 ||
            this.props.form?.reportNumber === 4 ||
            this.props.form?.reportNumber?.key === 5 ||
            this.props.form?.reportNumber === 5 ||
            this.props.form?.reportNumber?.key === 6 ||
            this.props.form?.reportNumber === 6 ||
            this.props.form?.reportNumber?.key === 7 ||
            this.props.form?.reportNumber === 7 ||
            this.props.form?.reportNumber?.key === 8 ||
            this.props.form?.reportNumber === 8 ||
            this.props.form?.reportNumber?.key === 102 ||
            this.props.form?.reportNumber === 102 ||
            this.props.form?.reportNumber?.key === 103 ||
            this.props.form?.reportNumber === 103 ||
            this.props.form?.reportNumber?.key === 104 ||
            this.props.form?.reportNumber === 104 ||
            this.props.form?.reportNumber?.key === 105 ||
            this.props.form?.reportNumber === 105 ||
            this.props.form?.reportNumber?.key === 106 ||
            this.props.form?.reportNumber === 106 ||
            this.props.form?.reportNumber?.key === 107 ||
            this.props.form?.reportNumber === 107 ||
            this.props.form?.reportNumber === 108 ||
            this.props.form?.reportNumber?.key === 108 ||
            this.props.form?.reportNumber === 109 ||
            this.props.form?.reportNumber?.key === 109 ||
            this.props.form?.reportNumber?.key === 115 ||
            this.props.form?.reportNumber === 115 ||
            this.props.form?.reportNumber?.key === 11 ||
            this.props.form?.reportNumber === 11 ||
            this.props.form?.reportNumber === 110 ||
            this.props.form?.reportNumber?.key === 110 ||
            this.props.form?.reportNumber?.key === 114 ||
            this.props.form?.reportNumber === 114 ||
            this.props.form?.reportNumber?.key === 12 ||
            this.props.form?.reportNumber === 12 ||
            this.props.form?.reportNumber?.key === 11 ||
            this.props.form?.reportNumber === 117 ||
            this.props.form?.reportNumber?.key === 117 ||
            this.props.form?.reportNumber?.key === 122 ||
            this.props.form?.reportNumber === 122 ||
            this.props.form?.reportNumber?.key === 128 ||
            this.props.form?.reportNumber === 128 ||
            this.props.form?.reportNumber?.key === 18 ||
            this.props.form?.reportNumber === 18 ||
            this.props.form?.reportNumber?.key === 13 ||
            this.props.form?.reportNumber === 13 ||
            this.props.form?.reportNumber?.key === 16 ||
            this.props.form?.reportNumber === 16 ||
            this.props.form?.reportNumber?.key === 118 ||
            this.props.form?.reportNumber === 118 ||
            this.props.form?.reportNumber?.key === 129 ||
            this.props.form?.reportNumber === 129 ? (
              <></>
            ) : (
              <>
                <Grid item xs={12}>
                  <VehicleSelector
                    type="reports"
                    userId={
                      this.props.form.contractNumbers?.userId ||
                      this.state.newUser
                    }
                    api="vehicle"
                    fill
                    hideIcons
                    isClearable
                    placeholder={this.props.translate("searchVehicle")}
                    onChange={this.handleChange}
                    value={this.state?.vehicleId || []}
                    loaderChecking={this.state.loaderChecking}
                  />
                </Grid>
              </>
            )}

            {this.props.form?.reportNumber?.key === 118 ||
            this.props.form?.reportNumber === 118 ? (
              <>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <TextField
                    id="predictionStatus"
                    select
                    label={this.props.translate("predictionStatus")}
                    value={this.props.form.predictionStatus || ""}
                    onChange={(e) =>
                      this.props.handleChange("predictionStatus", e)
                    }
                    margin="dense"
                    fullWidth
                    required
                  >
                    {PredictionStatuses.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {(this.props.form?.reportNumber?.key === 113 ||
              this.props.form?.reportNumber === 113) && (
              <Grid item xs={12}>
                <TextField
                  id="minFrequency"
                  margin="dense"
                  label={this.props.translate("minFrequency")}
                  variant="outlined"
                  fullWidth
                  value={this.props?.form?.minFrequency}
                  onChange={(e) => this.props.handleChange("minFrequency", e)}
                />
                <TextField
                  id="maxFrequency"
                  margin="dense"
                  label={this.props.translate("maxFrequency")}
                  variant="outlined"
                  fullWidth
                  value={this.props?.form?.maxFrequency}
                  onChange={(e) => this.props.handleChange("maxFrequency", e)}
                />
              </Grid>
            )}

            {/* {this.props.form?.reportNumber?.key === 2 ||
              this.props.form?.reportNumber === 2 ||
              this.props.form?.reportNumber?.key === 7 ||
              this.props.form?.reportNumber === 7 ||
              this.props.form?.reportNumber?.key === 8 ||
              this.props.form?.reportNumber === 8 ||
              this.props.form?.reportNumber?.key === 105 ||
              this.props.form?.reportNumber === 105 ||
              this.props.form?.reportNumber?.key === 106 ||
              this.props.form?.reportNumber === 106 ||
              this.props.form?.reportNumber?.key === 107 ||
              this.props.form?.reportNumber === 107 ||
              this.props.form?.reportNumber === 108 ||
              this.props.form?.reportNumber?.key === 108 ||
              this.props.form?.reportNumber === 109 ||
              this.props.form?.reportNumber?.key === 109 ||
              this.props.form?.reportNumber === 110 ||
              this.props.form?.reportNumber?.key === 110 ||
              this.props.form?.reportNumber?.key === 111 ||
              this.props.form?.reportNumber === 111 ||
              this.props.form?.reportNumber?.key === 112 ||
              this.props.form?.reportNumber === 112 ||
              this.props.form?.reportNumber?.key === 113 ||
              this.props.form?.reportNumber === 113 ||
              this.props.form?.reportNumber?.key === 10 ||
              this.props.form?.reportNumber === 10 ||
              this.props.form?.reportNumber?.key === 115 ||
              this.props.form?.reportNumber === 115 ||
              this.props.form?.reportNumber?.key === 11 ||
              this.props.form?.reportNumber === 11 ||
              this.props.form?.reportNumber?.key === 9 ||
              this.props.form?.reportNumber === 9 ||
              this.props.form?.reportNumber?.key === 117 ||
              this.props.form?.reportNumber === 117 ||
              this.props.form?.reportNumber?.key === 122 ||
              this.props.form?.reportNumber === 122 ||
              this.props.form?.reportNumber?.key === 118 ||
              this.props.form?.reportNumber === 118 ||
              this.props.form?.reportNumber?.key === 13 ||
              this.props.form?.reportNumber === 13 ||
              this.props.form?.reportNumber?.key === 16 ||
              this.props.form?.reportNumber === 16
              ? (
                    <Grid item xs={12} style={{marginTop:"20px"}}>
                  <SingleSelect
                      array={(this.props && this.props.mailAccounts)|| []}
                      async
                      selectName="additionalEmail"
                      // isClearable
                      label={this.props.translate("additionalEmail")}
                      value={
                        this.props?.additionalEmail &&
                        this.props.additionalEmail.id
                          ? {
                              id: this.props.additionalEmail.id,
                              value:
                                this.props.additionalEmail.label,
                              label: this.props?.additionalEmail?.label,
                            }
                          : ""
                      }
                      handleChange={this.props.handleChange}
                      canAssign={true}
                    />
                    </Grid>
              ):null} */}
            {/* )}  */}

            {this.props.form?.reportNumber?.key === 121 ||
            this.props.form?.reportNumber === 121 ||
            this.props.form?.reportNumber?.key === 125 ||
            this.props.form?.reportNumber === 125 ||
            this.props.form?.reportNumber?.key === 126 ||
            this.props.form?.reportNumber === 126 ? (
              <>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                  >
                    <InputLabel id="remarks-label">
                      {this.props.translate("garageVerticals")}
                    </InputLabel>
                    <Select
                      labelId="remarks-label"
                      id="garageVerticals"
                      multiple
                      margin="dense"
                      size="small"
                      value={this.props.form?.garageVerticals || []}
                      onChange={(event) =>
                        this.props.handleChange(
                          "garageVerticals",
                          event.target.value
                        )
                      }
                      renderValue={(selected) => (
                        <div>
                          {selected.map((key) => {
                            const option = GarageTypes.find(
                              (option) => option.key === key
                            );
                            return option ? (
                              <div key={key}>
                                {this.props.translate(option.name)}
                              </div>
                            ) : null;
                          })}
                        </div>
                      )}
                    >
                      {GarageTypes.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          <Checkbox
                            checked={this.props.form?.garageVerticals?.includes(
                              option.key
                            )}
                          />
                          <ListItemText
                            primary={this.props.translate(option.name)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="area"
                    margin="dense"
                    label={this.props.translate("area")}
                    variant="outlined"
                    fullWidth
                    value={this.props?.form?.area}
                    onChange={(e) => this.props.handleChange("area", e)}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        );

      default:
        return null;
    }
  };

  render() {
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            <Stepper
              {...this.props}
              // downloadInvoices={this.props.downloadInvoices}
              // invoicesData={this.props.invoicesData}
              translate={this.props.translate}
              activeStep={this.state.activeStep}
              reportType={this.state.reportType}
              getStepContent={this.getStepContent}
              steps={this.state.steps}
              handleNext={this.goToNextStep}
              handleBack={this.goToBackStep}
              isSubmitBtn={this.state.isSubmitBtn}
              selecteItem={this.props.selecteItem}
              selectSingleInvoice={this.props.selectSingleInvoice}
              disableEmail={this.props.disableEmail}
              // downloadInvoice={e=>this.props.downloadInvoice(this.state,e)}
              data="reports"
            />
          </>
        }
      </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}

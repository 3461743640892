import React, { Component, Fragment } from 'react'
import CustomDialog from '../common/Dialog'
import { SketchPicker } from 'react-color'
import TextField from '../common/TextField'
import { withStyles } from '@mui/styles'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import Button from '../common/Button'
import SingleSelect from '../common/SingleSelect'
import FormControlLabel from '@mui/material/FormControlLabel'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class addAttributes extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    const {
      formData,
      selectedType,
      htmlTag,
      selectTagData,
      classes
    } = this.props
    let is_drAttriubtes = formData[0].key === 'simpleAttributes' ? true : false
    return (
      <CustomDialog
        title={this.props.translate('sharedAttribute')}
        themecolors={this.props.themecolors}
        visable={true}
        onClose={this.props.onCloseModal}
        isButtonVisable={true}
        draggable={true}
        fullWidth
        CancelText={this.props.translate('sharedCancel')}
        headerActions={
          <Button
            size='small'
            variant='contained'
            color='secondary'
            disabled={!this.props.isVisableBtn}
            onClick={() => this.props.handleSubmit()}
          >
            {' '}
            {this.props.translate('sharedAdd')}
          </Button>
        }
        bodyPadding={20}
      >
        <div>
          {is_drAttriubtes ? (
            <TextField
              id='name'
              label={this.props.translate('sharedName')}
              type='text'
              placeholder=''
              value={this.props.form.name}
              onChange={this.props.handleChange.bind(this, 'name')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : (
            <TextField
              id='name'
              select
              label={this.props.translate('sharedName')}
              value={this.props.form.name}
              onChange={this.props.handleChange.bind(this, 'name')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {formData.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
          )}
          {htmlTag === '' || selectedType === '' ? (
            <TextField
              id='value'
              label={this.props.translate('sharedValue')}
              type='text'
              placeholder=''
              value={this.props.form.value}
              onChange={this.props.handleChange.bind(this, 'value')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : null}
          {htmlTag === 'input' &&
          (selectedType === 'text' || selectedType === 'string') ? (
            <TextField
              id='value'
              label={this.props.translate('sharedValue')}
              type='text'
              placeholder=''
              value={this.props.form.value}
              onChange={this.props.handleChange.bind(this, 'value')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : null}
          {htmlTag === 'input' && selectedType === 'boolean' ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.form.value || false}
                  onChange={this.props.handleChange.bind(this, 'value')}
                  color='primary'
                />
              }
              label={this.props.translate('commandEnable')}
            />
          ) : null}
          {htmlTag === 'input' && selectedType === 'number' ? (
            <TextField
              id='value'
              label={this.props.translate('sharedValue')}
              type='number'
              placeholder=''
              value={this.props.form.value}
              onChange={this.props.handleChange.bind(this, 'value')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          ) : null}
          {htmlTag === 'select' && selectedType === 'string' ? (
            <Fragment>
              {this.props.form.name === 'timezone' ||
              this.props.form.name === 'decoder.timezone' ? (
                <SingleSelect
                  array={selectTagData}
                  selectName='timezone'
                  isClearable={false}
                  label={this.props.translate('Timezone')}
                  value={
                    this.props.form.value
                      ? {
                          key: this.props.form.value,
                          id: this.props.form.value,
                          label: this.props.translate(this.props.form.value)
                        }
                      : ''
                  }
                  handleChange={this.props.handleChange.bind()}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              ) : (
                <TextField
                  id='value'
                  select
                  label={this.props.translate('sharedValue')}
                  value={this.props.form.value}
                  onChange={this.props.handleChange.bind(this, 'value')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin='dense'
                  fullWidth
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {selectTagData.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {this.props.translate(option.key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Fragment>
          ) : null}
          {htmlTag === 'color' ? (
            <SketchPicker
              color={this.props.value ? this.props.form.value : ''}
              onChangeComplete={this.props.handleChange.bind(this, 'value')}
            />
          ) : null}
        </div>
      </CustomDialog>
    )
  }
}

const AddAttributes = withStyles(styles)
export default AddAttributes((addAttributes))

import "date-fns";
import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./usersModal.scss";
import { connect } from "react-redux";
import ConfirmDialoag from "../common/ConfirmDialoag";
import AsyncSelect from "../common/asyncSelect";
import Grid from "@mui/material/Grid";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors;
  return {
    themecolors: state.themecolors,
  };
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    width: "60%",
  },
  label: {
    marginTop: -4,
  },
  shrink: {
    marginTop: 0,
  },
  focused: {
    marginTop: 0,
  },
  margin: {
    margin: theme.spacing(1),
  },
  cssLabel: {
    "&$cssFocused": {
      color: themecolors[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: themecolors[500],
    },
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: themecolors[500],
    },
  },
  notchedOutline: {},
  input: {
    padding: "12px 14px 12px",
  },
});

class ChangeContractModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      at_modal_visable: false,
      resultMessage: "",
    };
  }
  searchAccountsList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let fetchapi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&search=${inputValue}&limit=100`;
    if (this.props.logInUser.userType === -1) {
      fetchapi = `/api/accounts/all?accountId=${this.props.logInUser.accountId}&all=true&search=${inputValue}&limit=100`;
    }
    let response = await fetch(fetchapi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let json = await response.json();
    let result =
      (await json.data.data) &&
      json.data.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };
  contractMenuLoadMore = (e) => {
    if(e.deltaY === 100) {
      if(this.props.accountApiResponse.hasNext) {
        this.props.loadNextPage(this.props.nextPage+1)
      }
    }
  }
  render() {
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.props}
          visable={true}
          isButtonVisable={true}
          isNotShowAttributesBtn={true}
          title={this.props.changeContractOption ? this.props.translate("changeContract") : ""}
          formSubmit={this.props.formSubmit}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          btnclosetext="sharedBack"
          notVisableBackbtn={this.props.notVisableBackbtn}
          isVisableBtn={
             this.props.isVisableUserBtn 
          }
          
          content={
            <Fragment>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid item sm={6} md={3} xs={12} style={{ zIndex: 99 }}>
                 {/* { console.log("thei====", this.props.selecteditemname)} */}
                  <h3>{this.props.translate("accountTitle")} : <span style={{fontWeight:500, fontSize:'15px'}}>{this.props.selecteditemname.name}</span> </h3>
                </Grid>
                <Grid item sm={6} md={3} xs={12} style={{ zIndex: 99 }}>
                 {/* { console.log("thei====", this.props.selecteditemname)} */}
                  <h3>{this.props.translate("parentName")} : <span style={{fontWeight:500, fontSize:'15px'}}>{this.props.selecteditemname.parentName}</span> </h3>
                </Grid>
                <Grid item sm={2} md={2} xs={12} style={{ zIndex: 99 }}>
                  <DoubleArrowIcon style={{ fontSize: '36px', color: 'rgb(0, 193, 121)', position: 'relative', top: '4px' }} />
                </Grid>
                <Grid item sm={4} md={4} xs={12} style={{ zIndex: 99 }}>
                 {/* { console.log("defaultAccountsList=====", this.props.defaultAccountsList)} */}
                  {/* {this.props.userType === -1 && ( */}
                    <AsyncSelect
                      isSearchable={true}
                      isMulti={false}
                      {...this.props}
                      selectName="contractaccountId"
                      placeholder={this.props.translate("searchAccount")}
                      resultMessage={this.state.resultMessage}
                      defaultOptions={this.props.defaultAccountsList || []}
                      handleChange={this.props.handleChangeContractform}
                      loadOptions={this.searchAccountsList.bind()}
                      required
                      onMenuScrollToBottom={(e) => {this.contractMenuLoadMore(e);}}
                      canAssign={true}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  {/* )} */}
                </Grid>
              </Grid>
            </Fragment>
          }
        />
        {this.props.userMigrate && (
          <>
          <ConfirmDialoag
            onCancel={this.props.closeUserMigrateDialog}
            onOk={this.props.handleUserMigrate}
            title={this.props.userMigrateLabel}
            children={<><div>
              <span style={{ fontWeight: 'bold', }}>User : </span>
              {this.props.selecteditemname.name}
            </div>
            <br></br>
            <div>
              <span style={{ fontWeight: 'bold', }}>From : </span>
              {this.props.selecteditemname.parentName}
            </div>
            <br></br>

            <div>
              <span style={{ fontWeight: 'bold', }}>To : </span>
              {this.props.childItemId.label}
            </div>
            
            
            </>}
          />
          </>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(
  (withStyles(styles)(ChangeContractModal))
);

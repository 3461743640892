import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@mui/styles";
import Table from "../../../common/TableMultiCheckbox";
import {
  checkUnitsOfUsers,
  checkPrivileges,
  prepareUserForLogin,
  PaginationConfig,
} from "../../../../Helpers";
import LoginAsUser from "../../../../Helpers/loginAsUser";
import Loader from "../../../../Layout/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Grid, Tooltip } from "@mui/material";
import TextField from "../../../common/TextField";
import Checkbox from "../../../common/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
let source,
  totalUserId = {
    userId: [],
    deviceId: "",
  };
class SpAccess extends Component {
  constructor() {
    super();
    this.state = {
      usersReceived: false,
      selectedUnitsList: "",
      accessUserList: "",
      pagination: { ...PaginationConfig, hasNext: "", pageSize: 20 },
      total: "",
      lastPage: "",
      isChecked: false,
      isIndeterminate: false,
      allSelectedIds: "",
      noRecords: false,
      assignedFilter: false,
      managedUserId: "",
      selected: "all",
      currentUsers: "",
      contractType: [],
      filteredUsersLength: "",
      filteredUsers: [],
      spIds: "",
      loader: false,
      // itemSearch:"",
      citySearch: "",
      selectedSp: {},
    };
    this.unitToUserpermission = this.unitToUserpermission.bind(this);
  }
  componentWillMount() {
    this.getMoreUnits();
  }
  // componentWillUnmount () {
  //   if (source) {
  //     source.cancel()
  //   }
  // }
  handleFilterChange = (name) => {
    this.setState(
      (prevState) => {
        if (prevState.contractType.includes(name)) {
          return {
            contractType: prevState.contractType.filter(
              (item) => item !== name
            ),
          };
        } else {
          return {
            contractType: [...prevState.contractType, name],
          };
        }
      },
      () => {
        this.getMoreUnits();
      }
    );
  };
  getMoreUnits = () => {
    let { pagination, managedUserId, citySearch } = this.state;
    let { page, pageSize, itemSearch } = pagination;
    let count = 0,
      allUserId = 0;
    let res1 = this.props.units;
    let sellectedIds = [];
    let allIds = [];
    source = axios.CancelToken.source();
    if (this.cancelToken) {
      this.cancelToken.cancel("Operation canceled by the user.");
    }
    let userType = this.props.aaCreated ? 4 : 3;
    this.cancelToken = source;
    let url = `/api/users/get?userId=${this.props.logInUser.id}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}&userTypes=${userType}`;
    let urlFiltered = `/api/users/get?userId=${this.props.logInUser.id}&all=true&limit=500&search=${itemSearch}&userTypes=${userType}`;
    if (this.state.contractType.includes("maintenance")) {
      url += "&contractType=1";
      urlFiltered += "&contractType=1";
    }
    if (this.state.contractType.includes("leasing")) {
      url += "&contractType=2";
      urlFiltered += "&contractType=2";
    }
    if (this.state.contractType.includes("cashcustomer")) {
      url += "&contractType=3";
      urlFiltered += "&contractType=3";
    }
    this.setState({ loader: true }, () => {
      axios
        .get(url, {
          cancelToken: source.token,
        })
        .then((resp) => {
          let response = resp.data;
          if (response.status === "success") {
            if (
              response.data &&
              response.data.data &&
              response.data.data.length
            ) {
              let lastPage = response.data.total / pageSize;
              let IsFloate = this.checkFloteNumber(lastPage);
              let urlChecked = `/api/users/get?userId=${
                this.props.logInUser.id
              }&userTypes=${userType}&limit=${1000}`;
              // let urlChecked = `/api/users/get?userId=${this.props.selectItemId.id}&userTypes=3&limit=${response.data.total}&search=${itemSearch}`;
              if (this.state.contractType.includes("maintenance")) {
                urlChecked += "&contractType=1";
              }
              if (this.state.contractType.includes("leasing")) {
                urlChecked += "&contractType=2";
              }
              if (this.state.contractType.includes("cashcustomer")) {
                urlChecked += "&contractType=3";
              }
              axios
                .get(urlChecked, {
                  cancelToken: source.token,
                })
                .then((res1) => {
                  let res = res1?.data;
                  if (res.status === "success") {
                    allUserId = 0;
                    response.data.data.map((loginData) => {
                      loginData.entity.groupAccess = 0;
                      // res.data.data.map(main=>{

                      if (
                        loginData.entity.id &&
                        this.state.selectedSp.id &&
                        loginData.entity.id === this.state.selectedSp.id
                      ) {
                        loginData.entity.check = true;
                      }
                      // })
                    });

                    res.data.data.map((userId) => {
                      sellectedIds.push(userId.entityId);
                    });
                    this.setState({
                      // currentUsers:response.data.data,
                      allSelectedIds: sellectedIds,
                    });
                    axios
                      .get(urlFiltered, {
                        cancelToken: source.token,
                      })
                      .then((res2) => {
                        let res3 = res2?.data;
                        this.setState({
                          filteredUsersLength: res3.data.data.length,
                        });
                        res.data.data.map((userId) => {
                          allIds.push(userId.entity.id);
                        });
                        const mappedIds = res3.data.data.map(
                          (item) => item.entity.id
                        );
                        this.setState({
                          spIds: allIds,
                          filteredUsers: mappedIds,
                        });
                        if (this.state.contractType.length > 0) {
                          if (
                            allIds.length === this.state.filteredUsersLength
                          ) {
                            this.setState(
                              {
                                isChecked: true,
                              },
                              () => {
                                this.setState({
                                  loader: false,
                                });
                              }
                            );
                          } else {
                            this.setState(
                              {
                                isChecked: false,
                              },
                              () => {
                                this.setState({
                                  loader: false,
                                });
                              }
                            );
                          }
                        }
                      });
                    if (
                      sellectedIds.length === this.props.usersIds.length &&
                      this.props.usersIds.length
                    ) {
                      this.setState(
                        {
                          isChecked: true,
                        },
                        () => {
                          this.setState({
                            loader: false,
                          });
                        }
                      );
                    } else {
                      this.setState(
                        {
                          isChecked: false,
                        },
                        () => {
                          this.setState({
                            loader: false,
                          });
                        }
                      );
                    }
                    let units = response.data.data.map((item) => {
                      let u = this.props.replaceHtmlEntities(item);
                      if (u.entity.created) {
                        return {
                          ...u,
                          entity: {
                            ...u.entity,
                            contractNumber: u.extraInfo.contractNumber,
                            created: this.props.logInUser.twelveHourFormat
                              ? moment(u.entity.created).format(
                                  "YYYY-MM-DD hh:mm A"
                                )
                              : moment(u.entity.created).format(
                                  "YYYY-MM-DD HH:mm"
                                ),
                          },
                        };
                      } else {
                        return { ...u };
                      }
                    });
                    this.setState({
                      pagination: {
                        ...this.state.pagination,
                        lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                        hasNext: response.data.hasNext,
                        total: response.data.total,
                      },
                      selectedUnitsList: prepareUserForLogin(units),
                      usersReceived: true,
                    });
                  } else if (res?.statusCode === "440") {
                    window.location.replace("/login");
                  } else if (res?.statusCode) {
                    var err = res?.message.split(":");
                    err[1] = err[1].replace(")", "");

                    toast.error(this.props.translate(err[1]));
                  }
                });
            } else {
              this.setState({
                selectedUnitsList: [],
                usersReceived: false,
                noRecords: true,
              });
            }
          } else if (response?.statusCode === "440") {
            window.location.replace("/login");
          } else if (response.statusCode) {
            var err = response?.message.split(":");
            err[1] = err[1].replace(")", "");

            toast.error(this.props.translate(err[1]));
          }
        });
    });
  };

  handleChangeFilter = (id, event) => {
    if (event.target.value === "assigned") {
      this.setState(
        {
          assignedFilter: true,
          managedUserId: id,
          pagination: { ...PaginationConfig, hasNext: "", pageSize: 5 },
          selected: event.target.value,
        },
        () => {
          this.getMoreUnits();
        }
      );
    } else {
      this.setState(
        {
          assignedFilter: false,
          managedUserId: "",
          selected: event.target.value,
        },
        () => {
          this.getMoreUnits();
        }
      );
    }
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value,
        },
      },
      () => {
        this.getMoreUnits();
      }
    );
  };
  handleChangePage = (value) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value,
        },
      },
      () => this.getMoreUnits()
    );
  };
  SearchItem = (name, searchValue) => {
    // source.cancel()
    if (name === "search") {
      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            page: 1,
            itemSearch: searchValue,
          },
        },
        () => this.getMoreUnits()
      );
    } else {
      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            page: 1,
          },
          citySearch: searchValue,
        },
        () => this.getMoreUnits()
      );
    }
  };

  allUnitsId = (event) => {
    let sellectedIds = [];
    let check = event.target.checked;
    let allUserId = [];
    this.selectAllUser(check);
  };

  selectAllUser = (event) => {
    // let array1 =
    //   this.state &&
    //   this.state.currentUsers.filter(
    //     val => !this.state.allSelectedIds.includes(val)
    //   )
    let array1 =
      this.props &&
      this.props.usersIds?.filter(
        (val) => !this.state.allSelectedIds.includes(val)
      );
    if (this.state.contractType.length > 0) {
      array1 = this.state.filteredUsers.filter(
        (val) => !this.state.allSelectedIds.includes(val)
      );
    }
    totalUserId.deviceId = this.props.selectItemId;

    let obj;
    let option = "DELETE";
    if (event) {
      option = "POST";
      obj = { userId: totalUserId.deviceId.id, managedUserId: array1 };
    } else {
      obj = {
        userId: totalUserId.deviceId.id,
        managedUserId: array1,
      };
    }

    fetch(`api/permissions/multiproperty`, {
      method: `${option}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success" || res.status === "ambiguous") {
            if (option === "POST") {
              this.setState(
                {
                  isChecked: true,
                },
                () => {
                  this.getMoreUnits();
                }
              );

              toast.success(this.props.translate("userIsAssignedSuccessfully"));
            } else {
              this.setState(
                {
                  isChecked: false,
                },
                () => {
                  this.getMoreUnits();
                }
              );

              toast.success(
                this.props.translate("userIsUnAssignedSuccessfully")
              );
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");

            toast.error(this.props.translate(err[1]));
          }
        });
      })
      .catch((e) => {
        toast.error("somethingWentWrong");
      });
    totalUserId = {
      userId: [],
      managedUserId: "",
    };
  };

  unitToUserpermission(event, item) {
    const isChecked = event;
    const updatedSelectedUnitsList = this.state.selectedUnitsList.map(
      (selectedItem) => {
        if (selectedItem.entity.id === item.id) {
          // Update the 'check' property of the selected item
          return {
            ...selectedItem,
            entity: { ...selectedItem.entity, check: isChecked },
          };
        }
        return selectedItem;
      }
    );

    // Update the state with the modified selectedUnitsList
    this.setState({
      selectedUnitsList: updatedSelectedUnitsList,
      selectedSp: item,
    });

    let option = isChecked ? "POST" : "DELETE";
    let obj = {
      userId: item.id,
      managedUserId: this.props.selectItemId.id,
    };

    fetch(`/api/permissions`, {
      method: option,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        response.json().then((res) => {
          if (res.status === "success") {
            const successMessage = isChecked
              ? this.props.translate("userIsAssignedSuccessfully")
              : this.props.translate("userIsUnAssignedSuccessfully");

            toast.success(successMessage);
            // this.setState({ itemSearch: '' }, () => {
            //   // this.getMoreUnits();
            // });
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res?.statusCode) {
            var err = res.message.split(":");
            var err2 = err[1].split("&#");

            toast.error(this.props.translate(err2[0]));
          } else {
            throw response;
          }
        });
      })
      .catch((e) => {
        if (e && e.text) {
          e.text().then((err) => {
            if (err.includes("You cannot unlink unit")) {
              toast.error(this.props.translate("youCannotUnlinkUnit"));
            }
          });
        }
      });
  }

  loginAsUser = (data) => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false,
        },
        () => {
          this.setState({
            isUserLogin: true,
          });
        }
      );
    }
  };
  loginAsUserhandleChange = (id, value) => {
    let { selectedUnitsList } = this.state;
    selectedUnitsList.entity.map((item) => {
      if (item.id === id) {
        item.logInAsUserState = value;
      }
      return null;
    });
    this.setState({
      selectedUnitsList,
    });
  };

  render() {
    const { classes, logInUser } = this.props;
    return (
      <Fragment>
        <Grid container className="breadcrumb-row">
          <Grid item xs={12} sm={2}>
            <h3 style={{ margin: 0 }}>{this.props.translate("access")}</h3>
          </Grid>
          {/* <Grid item xs={12} sm={4} style={{ justifyContent: 'flex-end' }}>
                  <Checkbox 
                    name='maintenance'
                    onChange={() => this.handleFilterChange('maintenance')} 
                    canAssign
                    canRemove
                    style={{ padding: '0px' }}
                  />
                  <span style={{ margin: '0 25px 0 0' }}>Maintenance</span>
                  <Checkbox 
                    name='leasing'
                    onChange={() => this.handleFilterChange('leasing')}
                    canAssign
                    canRemove
                    style={{ padding: '0px' }}
                  />
                  <span style={{ margin: '0 25px 0 0' }}>Leasing</span>
                  <Checkbox 
                    name='cashcustomer'
                    onChange={() => this.handleFilterChange('cashcustomer')} 
                    canAssign
                    canRemove
                    style={{ padding: '0px' }}
                  />
                  <span style={{ margin: '0 25px 0 0' }}>Cash Customer</span>
                </Grid> */}
          <Grid item xs={12} sm={3} style={{ justifyContent: "flex-end" }}>
            {/* <div style={{ marginRight: '20px', width: "80%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('searchCity')}
                        onChange={e => this.SearchItem('searchCity', e.target.value)}
                      />
                      </div> */}
          </Grid>
          <Grid item xs={12} sm={3} style={{ justifyContent: "flex-end" }}>
            <div style={{ marginRight: "20px", width: "80%" }}>
              <TextField
                fullWidth
                label={this.props.translate("search")}
                onChange={(e) => this.SearchItem("search", e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        {this.state && this.state.usersReceived ? (
          <>
            <div styles={{ postion: "relative" }}>
              {this.state && this.state.loader === false ? (
                <>
                  <Table
                    rows={
                      this.state.selectedUnitsList.map((item) => {
                        return item.entity;
                      }) || []
                    }
                    allUsersId={this.allUnitsId}
                    selectItemParentId={this.props.selectItemId.id}
                    pagination={this.state.pagination}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    loginAsUser={this.loginAsUser}
                    loginAsUserhandleChange={this.loginAsUserhandleChange}
                    isChecked={this.state.isChecked}
                    isIndeterminate={this.state.isIndeterminate}
                    rowsPerPage={10}
                    isEditable={false}
                    showCheckbox2={true}
                    userAccess={false}
                    showGroupAccess={true}
                    notshowAllCheckbox
                    ServerSetting={this.props.ServerSetting}
                    themecolors={this.props.themecolors}
                    checkHandleChange={this.unitToUserpermission}
                    canAssign={checkPrivileges("userLinkDevice")}
                    canRemove={checkPrivileges("userUnlinkDevice")}
                    // rowDefinition={rowDefinitionR(this.props.translate)}
                    rowDefinition={[
                      {
                        id: "name",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("sharedName"),
                      },
                      {
                        id: "email",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("userEmail"),
                      },
                      {
                        id: "contractNumber",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("contractNumber"),
                      },

                      {
                        id: "city",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("city"),
                      },
                      // (logInUser.attributes?.viewIMEI || logInUser.userType===-1) &&{
                      //   id: 'uniqueId',
                      //   numeric: false,
                      //   disablePadding: false,
                      //   label: this.props.translate('driverUniqeId')
                      // },
                      // {
                      //   id: 'phone',
                      //   numeric: false,
                      //   disablePadding: false,
                      //   label: this.props.translate('sharedPhone')
                      // },
                      // (logInUser.attributes?.viewModel || logInUser.userType===-1) &&{
                      //   id: 'model',
                      //   numeric: false,
                      //   disablePadding: false,
                      //   label: this.props.translate('model')
                      // },

                      {
                        id: "created",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("createdAt"),
                      },
                      // {
                      //   id: 'status',
                      //   numeric: false,
                      //   disablePadding: false,
                      //   label: this.props.translate('status')
                      // }
                    ]}
                  />{" "}
                </>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Loader defaultStyle />
                </div>
              )}
            </div>
          </>
        ) : !this.state.usersReceived && !this.state.noRecords ? (
          <div style={{ textAlign: "center" }}>
            <Loader defaultStyle />
          </div>
        ) : null}

        {!this.state.usersReceived && this.state.noRecords && (
          <div style={{ textAlign: "center" }}>
            <h4> {this.props.translate("No Users Found")}</h4>
          </div>
        )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    );
  }
}

const enhance = compose(withStyles(styles), connect());

export default enhance(SpAccess);

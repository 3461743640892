import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { compose } from 'redux';
import { withStyles } from '@mui/styles'
import Table from '../../../common/TableMultiCheckbox'
import {
  checkUsersOfUnits1,
  checkPrivileges,
  prepareUserForLogin,
  PaginationConfig
} from '../../../../Helpers'
import LoginAsUser from '../../../../Helpers/loginAsUser'
import Loader from '../../../../Layout/Loader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid} from '@mui/material'
import TextField from '../../../common/TextField'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
let source,
  totalUserId = {
    userId: [],
    deviceId: ''
  }
class User extends Component {
  constructor () {
    super()
    this.state = {
      usersReceived: false,
      selectedAccUsersList: '',
      accessUserList: '',
      pagination: { ...PaginationConfig, hasNext: '', pageSize: 5 },
      total: '',
      lastPage: '',
      isChecked: false,
      isIndeterminate: false,
      noRecords: false
    }
    this.userToUnitpermission = this.userToUnitpermission.bind(this)
  }
  componentWillMount () {
    this.getMoreUsers()
  }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }
  getMoreUsers = () => {
    let { pagination } = this.state
    let { page, pageSize, itemSearch } = pagination
    let count = 0,
      allUserId = 0

    source = axios.CancelToken.source()
    axios
      .get(
        `api/users/linked?deviceId=${this.props.selectItemId}&&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: source.token
        }
      )
      .then(res1 => {
        let response = res1.data
        if(response.status ==='success'){
          let lastPage = response.data.total / pageSize
          let IsFloate = this.checkFloteNumber(lastPage)
          axios
            .get(
              `api/users/linked?deviceId=${
                this.props.selectItemId
              }&limit=${-1}`
            )
            .then(resp => {
              let res = resp.data
              if(res.status ==='success'){
                allUserId = 0
                res.data.data.map(userId => {
                  if (!userId.parent) {
                    if (userId.directAccess === true) {
                      count++
                    }
                    allUserId++
                  }
                })
                this.setState({
                  isChecked: allUserId === count,
                  isIndeterminate: allUserId !== count && count !== 0,
                  pagination: {
                    ...this.state.pagination,
                    lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                    hasNext: response.data.hasNext,
                    total: response.data.total
                  },
                  selectedAccUsersList: prepareUserForLogin(
                    checkUsersOfUnits1(response.data.data)
                  ),
                  usersReceived: true,
                  noRecords:false,
                })
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
               
                  toast.error( this.props.translate(err[1])
                )
              }
            })
            .catch(e => {
             toast.error('somethingWentWrong')
            })
        }
        else if(response?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(response.statusCode){
          var err = response?.message.split(':')
          err[1] =err[1].replace(')', "")
         
            toast.error(this.props.translate(err[1])
          )
          this.setState({ 
            selectedAccUsersList: [],
            usersReceived:false,
            noRecords:true, 
          })
        }
      })
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize: value
        }
      },
      () => {
        this.getMoreUsers()
      }
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: value
        }
      },
      () => this.getMoreUsers()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue
        }
      },
      () => this.getMoreUsers()
    )
  }

  allUsersId = event => {
    let check = event.target.checked
    let allUserId = []
    totalUserId.deviceId = this.props.selectItemId

    this.props.logInUser.id &&
      axios
        .get(`api/users/linked?deviceId=${this.props.selectItemId}&limit=${-1}`)
        .then(res1 => {
          let res = res1.data
          if(res.status ==='success'){
            res.data.data.map(userId => {
              allUserId.push(userId)
            })
            allUserId.map(id => {
              if (check) {
                if (id.directAccess === null) {
                  totalUserId.userId.push(id.id)
                }
              } else {
                totalUserId.userId.push(id.id)
              }
            })
            this.selectAllUser(check)
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
        
              toast.error( this.props.translate(err[1])
            )
          }
        })
        .catch(e => e)
  }

  selectAllUser = event => {
    let option = 'DELETE'
    if (event) {
      option = 'POST'
    }

    fetch(`api/permissions/multientity`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...totalUserId
      })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(resp=>{
            if(resp.status === 'success'){
              if (option === 'POST') {
               
                  toast.success(this.props.translate('trackerIsAssignedSuccessfully')
                )
              } else {
      
                  toast.success( this.props.translate(
                      'trackerIsUnAssignedSuccessfully'
                    )
                )
              }
              this.getMoreUsers()
            }
            else if(resp?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(resp.statusCode){
              var err = resp?.message.split(':')
              err[1] =err[1].replace(')', "")
            
                toast.error(
                this.props.translate(err[1])
         
              )
            }
          })
          
        }
         else {
          throw res
        }
      })
      .catch(e => e)
      totalUserId = {
      userId: [],
      deviceId: ''
    }
  }

  userToUnitpermission (event, item) {
    let option = 'DELETE'
    let count = 0,
      allUserId = 0
    if (event) {
      option = 'POST'
    }
    let allData = [...this.state.selectedAccUsersList]
    let obj = {
      userId: item.id,
      deviceId: this.props.selectItemId
    }
    fetch(`/api/permissions`, {
      method: `${option}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res=>{
            if(res.status ==='success'){
              axios
              .get(
                `api/users/linked?deviceId=${this.props.selectItemId}&limit=${-1}`
              )
              .then(resp => {
                let res = resp.data
                if(res.status === 'success'){
                  allUserId = 0
                  res.data.data.map(userId => {
                    if (!userId.parent) {
                      if (userId.directAccess === true) {
                        count++
                      }
                      allUserId++
                    }
                  })
                  if (option === 'POST') {
                    item.check = true
                    item.indeterminate = false
                    allData.map(dt => {
                      if (dt.id === item.userId) {
                        dt.check = true
                        dt.indeterminate = false
                      }
                      return null
                    })
                    this.setState({
                      isChecked: allUserId === count,
                      isIndeterminate: allUserId !== count && count !== 0,
                      selectedAccUsersList: allData
                    })
                    
                      toast.success(
                        this.props.translate(
                          'trackerIsAssignedSuccessfully'
                        )
              
                    )
                  } else {
                    item.check = false
                    allData.map(dt => {
                      if (dt.id === item.userId) {
                        dt.check = false
                      }
                      return null
                    })
                    this.setState({
                      isChecked: allUserId === count,
                      isIndeterminate: allUserId !== count && count !== 0,
                      selectedAccUsersList: allData
                    })
                    
                      toast.success(
                        this.props.translate(
                          'trackerIsUnAssignedSuccessfully'
                        )
                   
                    )
                  }  //end
                }
                else if(res?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(res.statusCode){
                  var err = res?.message.split(':')
                  err[1] =err[1].replace(')', "")
         
                    toast.error(
                      this.props.translate(err[1])
                  )
                }
              })
              .catch(e => {
              toast.error(
               'somethingWentWrong'
               
                )
              })
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              err[1] =err[1].replace(')', "")
            
                toast.error(
                  this.props.translate(err[1])
          
              )
            }
          })
        } 
        else {
          throw response
        }
      })
      .catch(e => {
        toast.error(
        'somethingWentWrong'
        )
      })
   }
  loginAsUser = data => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false
        },
        () => {
          this.setState({
            isUserLogin: true
          })
        }
      )
    }
  }
  loginAsUserhandleChange = (id, value) => {
    let { selectedAccUsersList } = this.state
    selectedAccUsersList.map(item => {
      if (item.id === id) {
        item.logInAsUserState = value
      }
      return null
    })
    this.setState({
      selectedAccUsersList
    })
  }

  render () {
    const { classes } = this.props
    return (
      <Fragment>
          <Grid container className="breadcrumb-row">
               <Grid item xs={12} sm={3}>
                   <h3 style={{margin: 0}}>{this.props.translate('users')}</h3>
                </Grid>
                  <Grid item xs={12} sm={9} style={{justifyContent: 'flex-end'}}>
                    <div style={{ marginRight: '20px', width: "25%" }}>
                       <TextField
                         fullWidth
                         label={this.props.translate('search')}
                        onChange={e => this.SearchItem(e.target.value)}
                      />
                    </div>
                  </Grid>
               </Grid>
      {this.state && this.state.usersReceived && !this.state.noRecords  ? (<> 
          <div styles={{ postion: 'relative' }}>
            <Table
              rows={this.state.selectedAccUsersList || []}
              allUsersId={this.allUsersId}
              selectItemParentId={this.props.selectItemParentId}
              pagination={this.state.pagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              // SearchItem={this.SearchItem}
              loginAsUser={this.loginAsUser}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              isChecked={this.state.isChecked}
              isIndeterminate={this.state.isIndeterminate}
              rowsPerPage={10}
              isEditable={false}
              showCheckbox={true}
              ServerSetting={this.props.ServerSetting}
              themecolors={this.props.themecolors}
              checkHandleChange={this.userToUnitpermission}
              canAssign={checkPrivileges('userLinkDevice')}
              canRemove={checkPrivileges('userUnlinkDevice')}
              rowDefinition={rowDefinitionR(this.props.translate)}
            />{' '}
          </div>
          </>
       )  :  
       !this.state.usersReceived && !this.state.noRecords ?
           (<div style={{ textAlign: 'center' }}>
             <Loader defaultStyle />
         </div>)
      : null
       }
        {this.state.noRecords && (
            <div style={{ textAlign: 'center' }}>
                <h4> {this.props.translate('noUserFound')}</h4>
             </div>
         )}

        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(User)
const rowDefinitionR = translate => {
  if (localStorage.getItem('adminToken')) {
    return [
      {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parentName')
      },
      {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: translate('accountName')
      }
    ]
  } else {
    return [
      {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: translate('sharedName')
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: translate('userEmail')
      },
      {
        id: 'roleName',
        numeric: false,
        disablePadding: false,
        label: translate('roleName')
      },
      {
        id: 'parentName',
        numeric: false,
        disablePadding: false,
        label: translate('parentName')
      },
      {
        id: 'accountName',
        numeric: false,
        disablePadding: false,
        label: translate('accountName')
      },
      {
        id: 'loginAsUser',
        numeric: false,
        disablePadding: false,
        label: translate('loginAsUser')
      }
    ]
  }
}

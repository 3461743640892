import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@mui/styles";
import SingleSelect from "../common/SingleSelect";

import { Grid } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../common/Button";
import TextField from "../common/TextField";
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  typedropdown: {
    color: 'hsl(0,0%,50%)',
    marginTop: '7px',
    '& .MuiOutlinedInput-input': {
      padding: '7px 0 7px 12px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05) !important'
    },
    '& svg': {
      color: 'hsl(0,0%,80%)',
      fontSize: '24px',
      width: '34px',
      borderLeft: '1px solid',
    },
  },
});

const Categories = [
  {
    key: "exclusive",
    name: "Exclusive",
  },
  {
    key: "popular",
    name: "Popular",
  },
  {
    key: "banner",
    name: "Banner",
  }
];
class addWorkShops extends Component {
  constructor(props) {
      super(props);
      this.state = {
      };
    }

  render() {
    // const {form}=this.props.data;

    // const defaultZones = []
    // timeZonesTypes.map((item, i) => {
    //   if (i <= 9) {
    //     defaultZones.push({
    //       id: item.key,
    //       key: item.key,
    //       label: item.key,
    //       value: item.key
    //     })
    //   }
    // })
    const { classes } = this.props;
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          // isVisableBtn={true}
          isVisableBtn={this.props.isVisableAddBtn}
          // isVisableBtn={true}

          notVisableBackbtn={false}
          // showPortList={this.props.showPortList}
          // showPort={this.props.showPort}
          content={
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="title"
                    required
                    margin="dense"
                    label={this.props.translate("title")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.title}
                    onChange={this.props.handleChange("title")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  {/* <TextField
                    id="description"
                    margin="dense"
                    label={this.props.translate("sharedDescription")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.description}
                    onChange={this.props.handleChange("description")}
                  /> */}
                   <TextField
                      id="description"
                      label={this.props.translate("sharedDescription")}
                      type="text"
                      placeholder=""
                      error={this.state.nerrorMgs}
                      value={this.props.data?.description || ''}
                      onChange={this.props.handleChange("description")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      multiline={true}
                      minRows={1} // Start with one row
                      maxRows={10} // You can adjust the maximum number of rows
                    />
                
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="type"
                    // type="number"
                    margin="dense"
                    label={this.props.translate("type")}
                    variant="outlined"
                    fullWidth
                    // required
                    value={this.props.data?.type}
                    onChange={this.props.handleChange("type")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{zIndex:99}}>
                <SingleSelect
                    array={Categories || []}
                    async
                    selectName="category"
                    // isClearable
                    label={this.props.translate("category")}
                    value={
                      this.props.data && this.props.data?.category
                        ? {
                            id: this.props.data?.category.key || this.props.data?.category,
                            value: this.props.data?.category.name || this.props.data?.category,
                            label: this.props.data?.category.name || this.props.translate(this.props.data?.category),
                          }
                        : ""
                    }
                    required
                    // value={this.props.form.reportNumber || ""}
                    handleChange={this.props.handleChange1}
                    canAssign={true}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {/* <TextField
                    id="category"
                    margin="dense"
                    label={this.props.translate("category")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.category}
                    onChange={this.props.handleChange("category")}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{zIndex:99}}>
                  <Select
                    native
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.expenseTypeId || this.state.selectedValue}
                    onChange={(e) => {
                      this.props.handleChange2(e);
                      this.props.handleChange("expenseTypeId")(e);
                    }}
                    label={this.props.translate("expensetype")}
                    IconComponent={KeyboardArrowDownIcon}
                    className={classes.typedropdown}
                  >
                    <option key="" value="" selected disabled>{this.props.translate("expensetype")}</option>
                    {
                      this.props.expensetypedata?.length > 0 ? (
                        this.props.expensetypedata.map((typeitem) => (
                            <option key={typeitem.id} value={typeitem.id}>{this.props.translate(typeitem.name)}</option>
                          ))
                       ) : (
                            <option key="0" value="0">{this.props.translate("No options available")}</option>
                       )
                    }
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{zIndex:99}}>
                  <Select
                    native
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.expenseSubTypeId || this.state.selectedValue}
                    onChange={this.props.handleChange("expenseSubTypeId")}
                    label={this.props.translate("expensesubType")} 
                    IconComponent={KeyboardArrowDownIcon}
                    className={classes.typedropdown}
                  >
                    <option key="" value="" selected disabled>{this.props.translate("expensesubType")}</option>
                    {
                      this.props.expensesubtypedata?.length > 0 ? (
                        this.props.expensesubtypedata.map((item) => (
                            <option key={item.expensetypeId} value={item.id}>{this.props.translate(item.label)}</option>
                          ))
                       ) : (
                            <option key="0" value="0">{this.props.translate("No options")}</option>
                       )
                    }
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <input
                      accept="image/*"
                      id="imgUrl"
                      type="file"
                      onChange={this.props.handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="imgUrl">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                      >
                        Upload Image *
                      </Button>
                      <FormHelperText style={{ color: 'red', margin: '5px 0'  }}>* Upload image between 320 x 190px and 640 x 380px.</FormHelperText>
                    </label>
                  {/* </Grid> */}

                  {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
                    {/* {this.props.selectedImage && (
                      <img
                        src={URL.createObjectURL(this.props.selectedImage)}
                        alt="Selected Image"
                        style={{ width:"250px", height:"200px" }}
                      />
                    )} */}
                     {this.props.data?.imgUrl && (
                      <img
                        src={this.props.data?.imgUrl || ''}
                        alt="Selected Image"
                        style={{ width:"250px", height:"200px" }}
                      />
                     )} 
                  </Grid>







                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="garageArea"
                    margin="dense"
                    label={this.props.translate("garageArea")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageArea}
                    onChange={this.props.handleChange("garageArea")}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="garageManager"
                    margin="dense"
                    label={this.props.translate("garageManager")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageManager}
                    onChange={this.props.handleChange("garageManager")}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="email"
                    margin="dense"
                    label={this.props.translate("userEmail")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.email}
                    onChange={this.props.handleChange("email")}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="phone"
                    margin="dense"
                    label={this.props.translate("sharedPhone")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.phone}
                    onChange={this.props.handleChange("phone")}
                  />
                </Grid> */}
               
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="address"
                    margin="dense"
                    label={this.props.translate("address")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.address}
                    onChange={this.props.handleChange("address")}
                  />
                </Grid> */}
             
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="latitude"
                    type="number"
                    margin="dense"
                    label={this.props.translate("positionLatitude")}
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    value={
                      this.props.data?.latitude ? this.props.data?.latitude : ""
                    }
                    onChange={this.props.handleChange("latitude")}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="longitude"
                    type="number"
                    margin="dense"
                    label={this.props.translate("positionLongitude")}
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    value={
                      this.props.data?.longitude
                        ? this.props.data?.longitude
                        : ""
                    }
                    onChange={this.props.handleChange("longitude")}
                  />
                </Grid> */}
                <Grid item sm={3} xs={12}>
                  {/* <Tooltip title={translate('addLocation')}>
              <IconButton onClick={()=>handleClickShowPostion()}>
                {<EditIcon style={{ color:"#ffffff"}} />}
              </IconButton>
            </Tooltip> */}
                </Grid>
                {/* <Tooltip title={this.props.translate('addLocation')}>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
           <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                margin='dense'
                label={translate('created')}
                variant='outlined'
                fullWidth
                value={form.created? new Date( `${form.created}` ).toString() : null}
                onChange={handleChangeLicenseExpDate('created')}
                InputProps={{
                  classes: {
                    root: 'theme-cssOutlinedInput',
                    input: 'theme-input',
                    focused: 'theme-cssFocused',
                    notchedOutline: 'theme-notchedOutline'
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: 'theme-label',
                    focused: 'theme-label-focused',
                    shrink: 'theme-label-shrink'
                  }
                }}
              />
          </LocalizationProvider>
        </Grid> */}

                {/* <Grid item xs={12}>
            <Checkbox
              checked={form.attributes.enable}
              onChange={e => handleChangeAttributes('archive', e.target.checked)}
              color='primary'
              label={translate('archive')}
              name='enable'
            />
          </Grid> */}
              </Grid>
            </>
          }
        />
      </Fragment>
    );
  }
}

const AddWorkShopsModal = withStyles(styles);
export default AddWorkShopsModal((addWorkShops));

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import { ReportsList } from "../../Components/Simah/Index";
import withRoutes from "../../HOC/HocRoutes";
let serverTimeZoneName = "Asia/Dubai";


class Simah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
   
    };
  }
 

  downloadExcel = (status, type) => {
   console.log("TEST=====",status);
   let accept;
   if (type === "downloadPdf") {
     accept = "application/pdf";
   } else {
     accept =
       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
   }
   let header;
   header = {
     headers: new Headers({
       "Content-Type": "application/json",
       Accept: accept,
     }),
     method: "GET",
   };

   let apiUrl;
   apiUrl = `/api/creditbureaus/export/simah?financialstorage=${status}`;
   this.setState(
    { loader: true },()=>{
    fetch(
      apiUrl,
      { ...header }
    ).then((response1) => {
        if (response1.status === 200) {
          if (type === "downloadPdf") {
            response1.blob().then((response) => {
              this.setState(
                {
                  loader: false,
                },
                () => {
                  this.saveData(response, "Simah.pdf");
                }
              );
            });
          } else {
            response1.blob().then((response) => {
              this.setState(
                {
                  loader: false,
                },
                () => {
                  this.saveData(response, "Simah.xlsx");
                }
              );
            });
          }
        } else {
          throw response1;
        }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
      })
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  };

  render() {
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    if (this.props.logInUser) {
      return (
        <Layout
          getContractsData={this.getContractsData}
          searchItem={this.searchItem}
          user={this.props.logInUser}
          {...this.state}
          {...this.props}
          form={this.state.form}
          handleChange={this.handleChange}
          selecteFilters={this.selecteFilters}
          handleSubmit={this.handleSubmit}
          downloadExcel={this.downloadExcel}
          // childFunction={this.myFunction}
        >
          <ReportsList translate={this.props.translate}/>
        </Layout>
      );
    } else {
      fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
               
                  toast.error(
                    this.props.translate(err[1])
                )
                this.props.navigate('/login')
              }
            })
          } else {
            this.props.navigate('/login')
            throw response
          }
        })
        .catch((e) => {
         
            toast.error(
               "somethingWentWrong"
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(Simah));

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import Style from "style-it";
import AddCommutatorModal from "./addCommutator";

import moment from "moment";

class addCommutator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  addOption:false,
    };
  }
 
  render() {
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        <Fragment>
          {/* {this.props.addOption ? ( */}
            <AddCommutatorModal
              {...this.props}
              handleChangeAccount={this.props.handleChangeAccount}
              title={this.props.translate("campaignsBroadcast")}
              data={this.props.data}
              formSubmit={this.props.handleSubmit}
              handleChangeMode={this.props.handleChangeMode}

              handleChange={this.props.handleChange}
              handleChangeTabs={this.props.handleChangeTabs}
              modalControle={this.props.modalControle}
              buttonText={this.props.translate("send")}
              isVisableAddBtn={this.props.isVisableAddBtn}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          {/* ) : 
            <>
            </>
          } */}

        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const AddCommutator = mapStateToProps((addCommutator));

import { withTranslation as withI18nTranslation } from 'react-i18next';

const withTranslationWrapper = (WrappedComponent) => {
  const TranslatedComponent = (props) => {
    const { t, ...restProps } = props;

    if (typeof t !== 'function') {
      console.error('Expected `t` to be a function, but received:', typeof t);
      return <WrappedComponent {...restProps} />;
    }

    return <WrappedComponent translate={t} {...restProps} />;
  };

  return withI18nTranslation()(TranslatedComponent);
};

export default withTranslationWrapper;
